import {
  useFetchGetRequest,
  useFetchPostRequest,
  useFetchPostRequestWithToken,
} from "./api";
import { useFetchGetRequestWithToken } from "./api";
import { useFetchMultipartWithToken } from "./api";
import axios from "axios";

export const GetParticipantProfileData = async (userId, authToken) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/get-profile/${userId}`;
  const result = await useFetchGetRequestWithToken(apiURL, authToken);

  return result;
};

export const UpdateParticipantInfo = async (inputValue, authToken) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/create-profile`;

  const formData = new FormData();

  for (const key in inputValue) {
    if (
      key !== "errors" &&
      key !== "IsValid" &&
      inputValue[key] !== null &&
      key !== "profilePicUrl" &&
      key !== "mobile" &&
      key !== "email" &&
      key !== "verificationType"
    ) {
      formData.append(key, inputValue[key]);
    }
  }

  //   if (inputValue.userIdProof !== null){
  // formData.append("userIdProof", inputValue.userIdProof);
  //   }

  //   if (inputValue.gender !== null) {
  //     formData.append("gender", inputValue.gender);
  //   }

  //   if (inputValue.clubName !== null) {
  //     formData.append("clubName", inputValue.clubName);
  //   }

  //   if (inputValue.companyName !== null) {
  //     formData.append("companyName", inputValue.companyName);
  //   }

  //   if (inputValue.tShirtSize !== null) {
  //     formData.append("tShirtSize", inputValue.tShirtSize);
  //   }

  //   formData.append("bloodGroup", inputValue.bloodGroup);
  //   formData.append("emergencyContactName", inputValue.emergencyContactName);
  //   formData.append(
  //     "emergencyContactRelation",
  //     inputValue.emergencyContactRelation
  //   );
  //   formData.append("emergencyContactNumber", inputValue.emergencyContactNumber);
  //   formData.append("addressLine1", inputValue.addressLine1);
  //   formData.append("addressLine2", inputValue.addressLine2);
  //   formData.append("landmark", inputValue.landmark);
  //   formData.append("country", inputValue.country);
  //   formData.append("state", inputValue.state);
  //   formData.append("city", inputValue.city);
  //   formData.append("pincode", inputValue.pincode);
  //   formData.append("fullName", inputValue.fullName);
  //   formData.append("dob", inputValue.dob);
  //   formData.append("mobile", inputValue.mobile);
  //   formData.append("email", inputValue.email);

  const result = await useFetchMultipartWithToken(apiURL, formData, authToken);

  return result;
};

export const UpdateProfilePic = async (
  inputValue,
  updatedProfilePic,
  authToken
) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/upload-profile-pic`;

  // const file = e.target.files[0];
  const formData = new FormData();
  // formData.append("userProfilePic", inputValue.userProfilePic);
  // inputValue.profilePicUrl = URL.createObjectURL(file);
  formData.append("userProfilePic", updatedProfilePic.raw);

  const result = await useFetchMultipartWithToken(apiURL, formData, authToken);
  return result;
};

export const SetOrganizerBasicDetails = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/create-profile-pd`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);

  return result;
};

export const SetOrganizerKYCDetails = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/create-profile-kyc`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);

  return result;
};

export const SetOrganizerBankDetails = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/create-profile-bad`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export const GetOrganizerProfile = async (userId, authToken) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}org/get-profile/${userId}`;
  const result = await useFetchGetRequestWithToken(apiURL, authToken);

  return result;
};

export const GetUserProfile = async (userId, authToken) => {
  // const userIdIs = parseInt(userId);
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/get-user/${userId}`;
  const result = await useFetchGetRequestWithToken(apiURL, authToken);

  return result;
};

export const Login = async (authToken) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/login`;
  const result = await useFetchGetRequestWithToken(apiURL, authToken);

  return result;
};

export const GetIFSCDetails = async (ifscCode) => {
  const apiURL = `https://ifsc.razorpay.com/${ifscCode}`;
  try {
    const response = await axios.get(apiURL);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const SubscribeNews = async (email) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/subscribe`;
  const reqBody = JSON.stringify({
    email: email,
  });

  return await useFetchPostRequest(apiURL, reqBody);
};

export const GetMyNotifications = async (token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/get-notifications`;
  return await useFetchGetRequestWithToken(apiURL, token);
};

export const MyOrderData = async (userId, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-my-orders`;
  const formData = new FormData();
  formData.append("mode", "portal");
  formData.append("refUserId", userId);

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export default GetParticipantProfileData;
