import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import EventCardTypeSmall from "./EventCardSmall";
import CheckOutSummary from "../checkout/CheckoutSummary";
import { fetchCartEventsRequest } from "../../redux/actions/events";
import * as comFun from "../../utils/commonFun";
import * as constants from "../../utils/Constants";
import {
  SALE_DATE_NOT_STARTED,
  SALE_END_DATE_REACHED,
} from "../../utils/Messages";
import dayjs from "dayjs";
import {
  convertDateTimeToIST,
  convertDateTimeToISTForUI,
} from "../../utils/DateTimeUtility";
import Icon from "../controls/icons/Icon";

//
const EventCardTypeMedium = ({
  eventDetailsData,
  cartEvents,
  fetchSummary,
  isWidget,
  widgetPath,
}) => {
  let { eventId } = useParams();
  const { eventName } = useParams();
  //var addBtnText="Add";
  // const {eventID,eventName} =useParams();
  const cartEvents1 = useSelector(
    (state) => state.events.cartEvents.cartEvents
  );
  if (eventDetailsData) {
    eventId = eventDetailsData?.eventId;
  }

  const [summary, setsummary] = useState([]);

  // Initialize state for an array of ticket counters
  const [counters, setCounters] = useState([null]);
  const [totalCount, setTotalCount] = useState(0);
  const [showCountError, setCountError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const totalTicketCount = 6;

  useEffect(() => {
    const addTicketCounter = () => {
      for (
        let index = 0;
        index < eventDetailsData.ticketDetails.length;
        index++
      ) {
        const element = eventDetailsData.ticketDetails[index];
        setCounters((prevCounters) => [
          ...prevCounters,
          { id: element.ticketId, count: 0 },
        ]);
      }
    };

    addTicketCounter();
  }, []);

  useEffect(() => {
    fetchSummary();
  }, []);

  useEffect(() => {
    let cleanUp = true;
    if (cleanUp)
      if (summary?.length == 0 && cartEvents1 && cartEvents1 !== null) {
        {
          let setData = true;
          try {
            for (const key in cartEvents) {
              if (cartEvents[key].eventId === eventId) {
                document.getElementById(key).value = parseInt(
                  cartEvents[key].orderQuantity
                );
              } else {
                setData = false;
                //clear store
                comFun.removeItem(constants.LOCAL_CART_EVENT);
              }
            }
          } catch {}
          if (setData) {
            setsummary(() => cartEvents);

            let tempCount = 0;
            for (const key in cartEvents) {
              setCounters((prevCounters) =>
                prevCounters?.map((counter) =>
                  counter?.id === cartEvents[key].ticketId
                    ? { ...counter, count: cartEvents[key].orderQuantity }
                    : counter
                )
              );
              tempCount = tempCount + cartEvents[key].orderQuantity;
            }
            setTotalCount(tempCount);
          }
        }
      }

    return () => (cleanUp = false);
  }, []);

  // Function to increment a counter by ID
  const incrementCounter = (event, type) => {
    event.preventDefault();
    if (totalCount < totalTicketCount) {
      const currentDate = convertDateTimeToIST("");

      const startDate = convertDateTimeToIST(type.ticketSaleStartDate);
      const endDate = convertDateTimeToIST(type.ticketSaleEndDate);
      const startDateIs = dayjs(startDate);
      const endDateIs = dayjs(endDate);
      const currentDateIs = dayjs(currentDate);

      if (
        (currentDateIs.isSame(startDateIs) ||
          currentDateIs.isAfter(startDateIs)) &&
        (currentDateIs.isBefore(endDateIs) || currentDateIs.isSame(endDateIs))
      ) {
        setCounters((prevCounters) =>
          prevCounters?.map((counter) =>
            counter?.id === type.ticketId
              ? { ...counter, count: counter?.count + 1 }
              : counter
          )
        );

        setTotalCount((previousCount) => previousCount + 1);
        addItemToCart(type, getCounterById(type.ticketId));
      } else {
        if (currentDateIs.isBefore(startDateIs)) {
          alert(SALE_DATE_NOT_STARTED + startDate);
        } else if (currentDateIs.isAfter(endDateIs)) {
          alert(SALE_END_DATE_REACHED);
        }
      }
    } else {
      setCountError(true);
    }
  };

  // Function to decrement a counter by ID
  const decrementCounter = (type) => {
    if (totalCount > 0 && getCounterById(type.ticketId) > 0) {
      setCounters((prevCounters) =>
        prevCounters?.map((counter) =>
          counter?.id === type.ticketId && counter?.count > 0
            ? { ...counter, count: counter?.count - 1 }
            : counter
        )
      );

      setTotalCount((previousCounter) => previousCounter - 1);
      removeItem(type, getCounterById(type.ticketId));
      setCountError(false);
    }
  };

  const getCounterById = (id) => {
    const item = counters?.find((counter) => counter?.id === id);
    return item?.count;
  };

  const addItemToCart = (ticketDetails, quantity) => {
    setsummary((item) => ({
      ...item,
      [ticketDetails.ticketId]: {
        eventId: eventId,
        ticketName: ticketDetails.ticketName,
        ticketId: ticketDetails.ticketId,
        ticketPrice: ticketDetails.ticketPrice,
        totalQuantity: ticketDetails.totalQuantity,
        ticketType: ticketDetails.ticketType,
        orderQuantity: quantity + 1, // State is not getting updated so adding it here
      },
    }));
  };

  const removeItem = (ticketDetails, quantity) => {
    setsummary((item) => ({
      ...item,
      [ticketDetails.ticketId]: {
        eventId: eventId,
        ticketName: ticketDetails.ticketName,
        ticketId: ticketDetails.ticketId,
        ticketPrice: ticketDetails.ticketPrice,
        totalQuantity: ticketDetails.totalQuantity,
        ticketType: ticketDetails.ticketType,
        orderQuantity: quantity - 1, // State is not getting updated so removing it here again
      },
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log("available", eventDetailsData.ticketDetails);
  //main return
  return (
    <>
      {eventDetailsData && (
        <div class="row">
          <div class="col-sm-12 mt-3 col-md-8 col-xl-9 ticketInputBtn">
            <EventCardTypeSmall eventDetailsData={eventDetailsData} />

            {eventDetailsData &&
              eventDetailsData.ticketDetails &&
              eventDetailsData.ticketDetails.map((type, index) => {
                return (
                  <>
                    {type?.ticketStatus === "act" ? (
                      <div
                        class=" d-flex flex-row align-items-center border-bottom event-type justify-content-center p-2"
                        key={type.ticketId}
                      >
                        <div class="event-cat1 col-lg-2 col-md-2  tooltip1">
                          {type.ticketName <= 8
                            ? type.ticketName
                            : type.ticketName.substring(0, 8)}
                          <span class="tooltiptext">
                            {type.ticketName}
                            <p>
                              Start :-
                              {convertDateTimeToISTForUI(
                                type?.ticketSaleStartDate
                              )}
                              <br />
                              End :-
                              {convertDateTimeToISTForUI(
                                type?.ticketSaleEndDate
                              )}
                            </p>
                          </span>
                        </div>

                        {/* hiding description from UI if not exists  */}
                        <div class="text-start col-lg-5 col-md-6 p-2">
                          {type.ticketDescription &&
                          type.ticketDescription.trim() ? (
                            <h1 className="name mt-2 m-3 tooltip1 hide-on-mobile">
                              {type.ticketDescription.length <= 8
                                ? type.ticketDescription
                                : type.ticketDescription.substring(0, 100)}
                              <span className="tooltiptext">
                                {type.ticketDescription}
                              </span>
                            </h1>
                          ) : null}

                          <p class="price m-3 ">
                            <Icon
                              iconName="rupee"
                              width={isMobile ? "15px" : "20px"}
                              height={isMobile ? "40px" : "40px"}
                            />
                            {Number(type.ticketPrice).toLocaleString("en-US", {
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </div>

                        {/* Showing Available Tickets */}
                        <div
                          className=" name hide-on-mobile"
                          style={{ marginLeft: "-15px" }}
                        >
                          Available:{type.avlbTickets}
                        </div>

                        <div class="text-end col-lg-4 col-md-4  p-2 ">
                          {/* <Button
                          type="button"
                          info
                          outline
                          //class="btn btn-outline-light select-btn border border-info"
                        >
                          
                          <input
                            type="number"
                            name={`ticket${type.ticketId}`}
                            id={`ticket${type.ticketId}`}
                            value={type.orderQuantity}
                            placeholder="Add"
                            className="quantity"
                            onChange={(event) =>
                              AddItemCart(
                                event,
                                type.ticketId,
                                type.ticketName,
                                type.ticketPrice,
                                type.totalQuantity,
                                type.ticketType,
                                type.ticketSaleStartDate,
                                type.ticketSaleEndDate
                              )
                            }
                          />
                        </Button> */}

                          <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic outlined example"
                          >
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={() => decrementCounter(type)}
                            >
                              -
                            </button>
                            <button
                              type="button"
                              id={type.ticketId}
                              name={type.ticketId}
                              class="btn btn-outline-primary"
                            >
                              {getCounterById(type.ticketId)}
                            </button>
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              onClick={(event) => incrementCounter(event, type)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            {/* {showMsg && (
              <div class="alert alert-info" role="alert">
                {showMsg}
              </div>
            )} */}

            {showCountError ? (
              <div class="alert alert-info" role="alert">
                You can add only {totalTicketCount} tickets.
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-sm-3 mt-3 d-sm-none d-md-block col-md-4 col-xl-3">
            <CheckOutSummary
              summaryData={summary}
              eventDetailsData={eventDetailsData}
              cameFrom="EventCardTypeMedium"
              isWidget={isWidget}
              widgetPath={widgetPath}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cartEvents: state.events.cartEvents.cartEvents,
  /*  state.events.cartEvents.cartEvents != []
      ? state.events.cartEvents.cartEvents[0]
      : state.events.cartEvents.cartEvents, */
});
const mapDispatchToProps = (dispatch) => ({
  //addSummary: (summary) => dispatch(addToCartEventRequest(summary)),
  fetchSummary: () => dispatch(fetchCartEventsRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventCardTypeMedium);
//export default EventCardTypeMedium;
