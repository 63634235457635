import React, { useState } from "react";
import "./middleEventPanel.css";
import EventDetailsStepTabs from "../../controls/Tab/EventDetailsStepTabs";
//

const MiddleEventPanel = ({ eventDetails, onStepComplete }) => {
  return (
    <>
      <div className="row">
        <div className="">
          <div className="mt-0 mb-5">
            <EventDetailsStepTabs
              eventDetails={eventDetails}
              onStepComplete={onStepComplete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleEventPanel;
