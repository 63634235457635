import React from "react";

const NoPage = () => {
  return (
    <div>
      <img src="/images/404.gif" alt="no_image" />
    </div>
  );
};

export default NoPage;
