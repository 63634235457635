import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NO_IMAGE_PATH, FOLDER_EVENT_PHOTO } from "../../utils/Constants";
import Table from "../controls/table/Table";
import { MyOrderData } from "../../services/userAPI";
import { convertDateToIST } from "../../utils/DateTimeUtility";
import Spinner from "../controls/spinner/Spinner";
//
const MyOrder = () => {
  const userDataLocal = useSelector((loginData) => loginData.login.login);
  const [loading, setLoading] = useState(false); // Spinner state

  //m/d
  const handleOnError = (event) => {
    event.target.src = NO_IMAGE_PATH;
  };
  //set order data
  //let myOrderData = [];
  const [myOrderData, setMyOrderData] = useState([]);
  useEffect(() => {
    const getUserOrder = async () => {
      try {
        setLoading(true); // Show spinner when sending starts
        const orderData = await MyOrderData(
          userDataLocal.login.user.userId,
          userDataLocal?.login?.token
        );
        setLoading(false);
        setMyOrderData(() => orderData?.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getUserOrder();
  }, [userDataLocal?.login]);

  const config = {
    tableTitle: "My Orders",
    data: myOrderData,
    displayRecord: 10,
    defaultSortBy: (data) => data?.orderDate,
    columns: [
      // {
      //   title: "Event",
      //   titleClass: "text-center",
      //   dataClass: "py-1 images align-middle",
      //   isImage: true,
      //   render: (item) => (
      //     <a
      //       href={`/eventdetails/${item.eventId + "/" + item.eventName}`}
      //       class="text-center sale"
      //       onError={handleOnError}
      //     >
      //       <img
      //         src={`${
      //           process.env.REACT_APP_EVENTS +
      //           item.eventId +
      //           FOLDER_EVENT_PHOTO +
      //           item.eventName
      //         }`}
      //         class="img-fluid rounded"
      //         onError={handleOnError}
      //       />{" "}
      //     </a>
      //   ),
      // },
      {
        title: "Event Name ",
        titleClass: "text-start",
        dataClass: "text-start",
        isLink: true,
        render: (item) => (
          <a
            href={`/eventdetails/${item.eventId + "/" + item.eventName}`}
            class="sale text-start"
          >
            {item.eventName}
          </a>
        ),
      },
      {
        title: "Order Id",
        titleClass: "text-start",
        dataClass: "text-start",
        dataField: "txnId",
      },
      {
        title: "Date",
        titleClass: "text-start",
        dataClass: "text-start",
        isLink: true,
        isSort: false,
        sortByValue: (data) => data.orderDate,
        render: (item) => <>{convertDateToIST(item.orderDate)}</>,
      },
      {
        title: "Amount",
        titleClass: "text-start",
        dataClass: " text-success-btn text-start",
        dataField: "amount",
      },
      {
        title: "Status",
        titleClass: "text-start",
        dataClass: "text-start",
        dataField: "orderStatus",
        isSort: false,
        sortByValue: (data) => data.orderStatus,
      },
      // {
      //   title: "Message",
      //   titleClass: "",
      //   dataClass: "text-center e-status align-middle text-success-btn",
      //   dataField: "msg",
      // },
      {
        title: "Invoice",
        isLink: true,
        render: (item) => {
          {
            if (item.orderStatus == "Confirmed") {
              return (
                <div class="text-left align-middle">
                  <a
                    class="btn-link btn align-middle p-0 "
                    href={process.env.REACT_APP_CDN_URL + item.invoiceUrl}
                    target="_blank"
                  >
                    View Invoice
                  </a>
                </div>
              );
            }
          }
        },
        titleClass: "text-start",
        dataClass: "text-start",
      },
      {
        title: "Ticket",
        isLink: true,
        render: (item) => {
          {
            if (item.orderStatus == "Confirmed") {
              return (
                <div class="text-left align-middle">
                  <a
                    class="btn-link btn align-middle p-0"
                    href={process.env.REACT_APP_CDN_URL + item?.ticketUrl}
                    target="_blank"
                  >
                    View Ticket
                  </a>
                </div>
              );
            }
          }
        },
        titleClass: "text-start",
        dataClass: "text-start",
      },
    ],
  };

  //main return
  return (
    <>
      <Table config={config} />
      {loading && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex={-1}
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content border-0"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <div className="modal-body d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyOrder;
