import React from "react";
import Tabs from "./Tabs";
import { useSelector } from "react-redux";
import MiddleEventPanel from "../../../components/createEvent/eventDetails/MiddleEventPanel";
import EventSettings from "../../../components/createEvent/eventSetting/EventSettings";
// import "./createEvent.css";
import ParticipantFormInfo from "../../../components/createEvent/participantForm/ParticipantFormInfo";
import DiscountInfo from "../../../components/createEvent/discount/DiscountInfo";
import ShowLoginMsg from "../../login/ShowLoginMsg";

//
const CreateEventTabs = ({ eventDetails }) => {
  const userData = useSelector((loginData) => loginData.login.login);
  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  }
  const handleStepComplete = (index) => {
    if (index > 0 && index < 5) {
      document.getElementById("tabCreateEvent" + index).click();
    }
  };
  //
  const config = {
    isUserLogin: isUserLogin,
    tabId: "tabCreateEvent",
    loginMessage: <ShowLoginMsg />,
    data: [
      {
        title: "Event Details",
        tabBtnClass: "tab col btn org-btn-pro border",
        tabContentClass: "",
        tabContent: (
          <MiddleEventPanel
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
      },
      {
        title: "Participant Form",
        tabBtnClass: "tab col btn org-btn-pro border",
        tabContentClass: "participant-form pt-4  ",
        tabContent: (
          <ParticipantFormInfo
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
        showLogin: true,
      },
      {
        title: " Discount Coupons",
        tabBtnClass: "tab col btn org-btn-pro border",
        tabContentClass: "",
        tabContent: (
          <DiscountInfo
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
        showLogin: true,
      },
      {
        title: "Event Settings",
        tabBtnClass: "tab col btn org-btn-pro border",
        tabContentClass: "",
        tabContent: (
          <EventSettings
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
        showLogin: true,
      },
    ],
    className: {
      mainContainer: "tabs-container row align-items-start  p-0 m-0",
      tabsContainer: "tabs btn-group",
      activeTabContainer: "org-main-contain", //"tab-content card p-4",
    },
  };

  //
  return (
    <>
      <Tabs config={config} />
    </>
  );
};

export default CreateEventTabs;
