import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { GetCategoryData } from "../../services/commonAPI";
import Icon from "../controls/icons/Icon";
import { IsMobile } from "../../utils/commonFun.jsx";
//check screen width
let finish1 = 6;
//useEffect(() => {
let isMobile = IsMobile();
if (isMobile === true) {
  finish1 = 3;
}
//});
//
function SliderCategoryEvent({ city }) {
  //
  const [fadedLeft, setFadedleft] = useState(true);
  const [fadedRight, setFadedright] = useState(false);
  const [start, setStart] = useState(0);
  const [finish, setFinish] = useState(finish1);
  const [noOfImageRotate, setNoOfImageRotate] = useState(1);
  const [categoryData, setCategoryData] = useState([]);

  var startindex = start;
  var finishindex = finish;
  var vfadedleft = fadedLeft ? "disabled" : "";
  var vfadedright = fadedRight ? "disabled" : "";

  useEffect(() => {
    let cleanUp = true;
    if (true) {
      const getAllCategory = async () => {
        try {
          const categoryResponseData = await GetCategoryData();
          if (categoryResponseData?.success === true) {
            const categoryResponse = categoryResponseData.data;
            setCategoryData(categoryResponse);
            //setFinish(categoryResponse.length);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getAllCategory();
    }
    //cleanUp
    return () => (cleanUp = false);
  }, []);

  const leftClick = () => {
    if (start > 0 && finish > 0) {
      startindex = start - noOfImageRotate;
      finishindex = finish - noOfImageRotate;
      setStart(() => startindex);
      setFinish(() => finishindex);
    } else {
      setFadedleft(true);
    }

    setFadedright(false);
  };

  const rightClick = () => {
    if (finish < categoryData.length) {
      startindex = start + noOfImageRotate;
      finishindex = finish + noOfImageRotate;
      setStart(() => startindex);
      setFinish(() => finishindex);
    } else {
      setFadedright(true);
    }

    setFadedleft(false);
  };

  return (
    <div class="row justify-content-center align-items-center my-5 mx-2">
      <div className={`pre-btn ${fadedLeft ? "hidden" : ""}`}>
        <button
          type="button"
          class="btn btn-link border pre-btn-bg pre-btn" 
          onClick={leftClick}
        >
          <Icon iconName={"left-arrow"} />
        </button>
      </div>
      {categoryData &&
        categoryData.slice(start, finish).map((etype, imageindex) => {
          return (
            <div class="col-4 col-md-2 p-2 text-start" key={imageindex}>
              <Link
                to={"/category/" + etype.categoryName + "/" + etype.categoryId}
              >
                <img
                  class="rounded mx-auto d-block img-thumbnail cat-img-thumbnail"
                  src={
                    process.env.REACT_APP_CATEGORYS +
                    etype.categoryId +
                    "/" +
                    etype.categoryPic
                  }
                  alt={etype.categoryName}
                />
              </Link>
            </div>
          );
        })}
      <div className={`next-btn ${fadedRight ? "hidden" : ""}`}>
        <button
          type="button"
          class="btn border next-btn pre-btn-bg" 
          onClick={rightClick}
        >
          <Icon iconName={"right-arrow"} />
        </button>
      </div>
    </div>
  );
}

export default SliderCategoryEvent;
