import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchLoginRequest,
  addToLoginRequest,
} from "../../redux/actions/Login";
import {
  googleLoginToken,
  facebookLogin,
  microsoftLogin,
} from "../../services/firebaseLogin";
import { GetCurrentLocation } from "../../utils/commonFun";

//
const Login = ({
  onClose,
  onUserLoginData,
  fetchLogin,
  login = [],
  saveLogin,
}) => {
  const navigate = useNavigate();
  //
  let currentLocation = GetCurrentLocation();
  currentLocation =
    currentLocation === null || currentLocation === undefined
      ? "/"
      : currentLocation?.pathName;

  //
  const GoogleLogin = async () => {
    let token = await googleLoginToken();
    const result = await saveLogin(token);
    if (onUserLoginData) {
      onUserLoginData(result);
    }
    await navigate(currentLocation + "?successLogin");
  };
  //
  const facebook = async () => {
    const token = await facebookLogin();
    const result = await saveLogin(token);
    if (onUserLoginData) {
      onUserLoginData(result);
    }
    await navigate(currentLocation + "?successLogin");
  };
  //
  const microsoft = async () => {
    const token = await microsoftLogin();
    const result = await saveLogin(token);
    if (onUserLoginData) {
      onUserLoginData(result);
    }
    await navigate(currentLocation + "?successLogin");
  };

  return (
    <div
      onClick={onClose}
      className={`modal fade WelcomeModal ${onClose ? "show" : ""}`}
      style={{
        display: `${onClose ? "block" : "none"}`,
      }}
      id="login-popup"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
              <div class="col p-0">
                <div
                  class="login-hero"
                  // style={{
                  //   backgroundImage: `https://storage.googleapis.com/fitfundabucket/fit-funda-info/LoginImage/login-image.jfif`,
                  // }}
                >
                  <div class="title-line">
                    {/* <div class="logo">Logo Here</div> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                <div className="login-form">
                    <div className="logo order-1 order-md-1 mr-3">
                      <img src="/Logo.svg" width={90} height={80} alt="Logo" style={{ marginTop: "-50px" }}/>
                    </div>
                    <h4 className="mb-4 text-center" style={{fontSize:"17px"}}>Welcome to FitFunda</h4>
                    <h5 className="mb-4 text-center" style={{fontSize:"15px"}}>Sign In or Sign Up</h5>
                    <div className="row sing-form">
                      <div
                        className="col mb-3"
                        onClick={GoogleLogin}
                        type="button"
                      >
                        <div className="border py-2 rounded d-flex justify-content-center align-items-center border-danger">
                          <img
                            src="images/google_icon.png"
                            className="me-2 icon"
                            alt="Google"
                          />
                          Sign in with Google
                        </div>
                      </div>
                      <div
                        className="col mb-3"
                        onClick={facebook}
                        type="button"
                      >
                        <div className="border py-2 rounded d-flex justify-content-center align-items-center border-primary">
                          <img
                            src="images/facebook_icon.png"
                            className="me-2 icon"
                            alt="Facebook"
                          />
                          <span style={{fontSize:"13px"}}>Sign in with Facebook</span>
                        </div>
                      </div>
                      <div
                        className="col-12 mb-3"
                        onClick={microsoft}
                        type="button"
                      >
                         <div className="border py-2 rounded d-flex justify-content-center align-items-center border-secondary">
                          <img
                            src="images/microsoft_logo.png"
                            className="me-2 icon"
                            alt="Microsoft"
                          />
                          <span style={{fontSize:"13px"}}>Sign in with Microsoft</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="copy-right w-50">
                  © 2024 FitFunda | All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default Login;
const mapStateToProps = (state) => ({
  login: state.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  fetchLogin: () => dispatch(fetchLoginRequest()),
  saveLogin: (login1) => dispatch(addToLoginRequest(login1)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
