import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../controls/table/Table";
import {
  GetAllEventByUserId,
  EventActiveOrDeactiveByUserId,
} from "../../../services/eventAPI.jsx";
import { saveToLocal } from "../../../utils/commonFun";
import {
  NO_IMAGE_PATH,
  FOLDER_EVENT_PHOTO,
  KEY_EVENT_ID,
} from "../../../utils/Constants";
import Model from "../../controls/popup/Modal";

//main function
const EventTable = () => {
  //declare start
  const [allEvents, setAllEvents] = useState([]);
  const userData = useSelector((loginData) => loginData.login.login);
  const navigate = useNavigate();
  let ImagePath = process.env.REACT_APP_CATEGORYS_DASHBOARD;
  //const [showData, setShowData] = useState(1);
  const [showActivePopup, setShowActivePopup] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null); //for cancel event it should give correct event data in modal
  const [loadingCancel, setLoadingcancel] = useState(false); // event cancel loading

  //declare end

  //function/events/methods
  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const allEventsResponse = await GetAllEventByUserId(
          userData.login.token
        );
        if (allEventsResponse?.success === true) {
          setAllEvents(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, []);

  //configration
  const config = {
    tableTitle: "Events",
    data: allEvents,
    displayRecord: 10,
    columns: [
      {
        title: "Photo",
        titleClass: "text-left",
        dataClass: "py-1 images align-middle",
        isImage: true,
        render: (item) => handleImagepath(item),
      },
      {
        title: "Name ",
        titleClass: " ",
        dataClass: "",
        dataField: "eventName",
        isSort: false,
        sortByValue: (data) => data.eventName,
      },
      {
        title: "Event Id",
        titleClass: "text-left",
        dataClass: "text-left", // id
        dataField: "eventId",
        isSort: false,
        sortByValue: (data) => data.eventId,
      },
      {
        title: "Start Date",
        titleClass: "",
        dataClass: "text-left",
        dataField: "eventStartDate2",
        isSort: false,
        sortByValue: (data) => data.eventStartDate2,
      },
      {
        title: "Total Paticipants",
        titleClass: "",
        dataClass: "text-left",
        dataField: "ticketCount",
        isSort: false,
        sortByValue: (data) => data.ticketCount,
      },
      {
        title: "Sales",
        titleClass: "",
        dataClass: "text-left",
        dataField: "totalSales",
        isSort: false,
        isLink: true,
        sortByValue: (data) => data.totalSales,
        render: (data) =>
          Number(data.totalSales).toLocaleString("en-US", {
            maximumFractionDigits: 2,
          }),
      },

      {
        title: "Status",
        titleClass: "",
        dataClass: "text-left align-middle text-success-btn",
        dataField: "eventStatus2",
        isSort: false,
        sortByValue: (data) => data.eventStatus2,
      },
      {
        title: "View",
        isLink: true,
        render: (item) => (
          <div class="text-left align-middle">
            <Link
              className="btn-link btn align-middle p-0 "
              to={`/view-event-details/${item.eventId}`}
            >
              View
            </Link>
          </div>
        ),
        titleClass: "",
        dataClass: "text-left",
      },
      {
        title: "Edit",
        isLink: true,
        render: (item) => {
          if (item?.eventStatus === "act" && item?.eventStage === "ongoing") {
            return (
              <>
                <div class="text-left align-middle">
                  <a
                    class="btn-link btn align-middle p-0 "
                    href="#"
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </a>
                </div>
              </>
            );
          }
        },
        titleClass: "",
        dataClass: "text-left",
      },
      {
        title: "Sales",
        isLink: true,
        render: (item) => {
          if (item?.ticketCount > 0) {
            return (
              <div class="text-left align-middle">
                <Link
                  to={`/event-sales-details/${item?.eventId}/${item?.eventName}`}
                  class="btn-link btn align-middle p-0 "
                >
                  Sales
                </Link>
              </div>
            );
          }
        },
        titleClass: "",
        dataClass: "text-left",
      },
      {
        title: "Action",
        isLink: true,
        render: (item) => {
          if (item?.eventStatus === "act" && item?.eventStage === "ongoing") {
            return (
              <>
                <div class="text-left align-middle">
                  <a
                    class="btn-link btn align-middle p-0"
                    href="#"
                    onClick={() => handleAction(item)}
                  >
                    {item?.eventStatus === "act"
                      ? "Cancel"
                      : item?.eventStatus === "iact"
                      ? "" // "Publish"
                      : ""}
                  </a>
                </div>
              </>
            );
          }
        },
        titleClass: "",
        dataClass: "text-left",
      },
    ],
  };

  const handleViewMore = (item) => {
    alert("handleViewMore");
  };
  const handleSortBy = (item) => {
    alert("handleSortBy");
  };
  const handleEdit = (item) => {
    //alert("handleEdit");

    saveToLocal(KEY_EVENT_ID, item.eventId);
    navigate("/create-event/" + item.eventId + "/" + item.eventName);
  };
  const handleAction = (item) => {
    setCurrentEvent(item);
    setShowActivePopup(() => true);
  };
  const handleActionClose = () => {
    setShowActivePopup(() => false);
    setCurrentEvent(null);
  };
  const handleActionSuccess = (item) => {
    //api call for deactivation/ activation

    const getAllEvents = async () => {
      try {
        setLoadingcancel(true);
        const allEventsResponse = await EventActiveOrDeactiveByUserId(
          userData.login.token,
          currentEvent?.eventId,
          currentEvent?.userId
        );

        if (allEventsResponse?.success === true) {
          setLoadingcancel(false);
          handleActionClose();
          alert(allEventsResponse?.message);
        } else if (allEventsResponse?.success === false) {
          setLoadingcancel(false);
          handleActionClose();
          alert(allEventsResponse?.message);
        }
      } catch (error) {
        setLoadingcancel(false);
        handleActionClose();
        alert("Error with cancelling event", error);
      }
    };
    getAllEvents();
  };
  const handleImagepath = (item) => {
    let ImageFullPath = ImagePath + item.categoryName + ".png";

    return (
      <img
        src={ImageFullPath}
        class="img-fluid rounded"
        style={{ maxWidth: "55px", maxHeight: "45px" }}
      />
    );
  };

  //main return
  return (
    <>
      <Table config={config} />
      {/*  {showData == 1 && <Table config={config} />}
      {showData == 2 && <ViewSalesDetails config={config} />} */}

      {showActivePopup && currentEvent && (
        <Model
          title={`Event-${currentEvent?.eventId}`}
          onClose={() => handleActionClose()}
          onSuccess={() => handleActionSuccess(currentEvent)}
          successText={
            currentEvent?.eventStatus === "act"
              ? "Cancel"
              : currentEvent?.eventStatus === "iact"
              ? "" // "Publish"
              : ""
          }
        >
          {loadingCancel ? (
            <span
              className="loaderBreakup"
              style={{ marginLeft: "110px" }}
            ></span>
          ) : (
            <div className="alert alert-warning" role="alert">
              Do you want to <b>{currentEvent?.eventName}</b> cancel?
            </div>
          )}
        </Model>
      )}
    </>
  );
};

export default EventTable;
