import { useState, useEffect } from "react";

let googleMapsScriptLoadingPromise;

const useGoogleMaps = (apiKey, libraries) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!googleMapsScriptLoadingPromise) {
      googleMapsScriptLoadingPromise = new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
          ","
        )}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = (e) => {
          reject(e);
        };
        document.body.appendChild(script);
      });
    }

    googleMapsScriptLoadingPromise
      .then(() => {
        setLoaded(true);
      })
      .catch((e) => {
        console.error("Failed to load Google Maps script:", e);
      });
  }, [apiKey, libraries]);

  return loaded;
};

export default useGoogleMaps;
