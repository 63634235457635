import React, { useState } from "react";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";
import { GetFileExtension } from "../../../utils/commonFun";

let ImagePath = process.env.REACT_APP_USERS;
const ViewBankAccountDetailsOrganizer = ({ organizerDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [docPath, setDocPath] = useState(null);
  const [title, setTitle] = useState("");

  const renderDoc = (type, title, imagePath) => {
    if (imagePath) {
      const fileExtension = GetFileExtension(imagePath);
      const fullPath =
        ImagePath + organizerDetails?.userId + "/" + type + "/" + imagePath;
      if (
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "gif"
      ) {
        setTitle(title);
        setDocPath(fullPath);
        setShowModal(true);
      } else {
        window.open(fullPath, "_blank");
      }
    }
  };

  return (
    <>
      {/* Bank Account Details  */}
      <div className="card-body p-1">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <div
              id="participant-collapseThree"
              className="accordion-collapse collapse accordion-detail px-4 show"
              data-bs-parent="#accordionFlushExample"
            >
              <form className="row g-3 pb-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Bank Name
                  </label>

                  <InputTextBox
                    id="bankName"
                    name="bankName"
                    type="text"
                    value={organizerDetails.bankName}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Account Holde Name
                  </label>

                  <InputTextBox
                    id="accountHolderName"
                    name="accountHolderName"
                    type="text"
                    value={organizerDetails.accountHolderName}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Account Number
                  </label>

                  <InputTextBox
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    value={organizerDetails.accountNumber}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Account Type
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail4"
                    value={organizerDetails.accountType}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    IFSC Code
                  </label>

                  <InputTextBox
                    id="ifsc"
                    name="ifsc"
                    type="text"
                    value={organizerDetails.ifsc}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Bank Proof - Upload Document
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text bg-white border-end-0"
                      id="basic-addon1"
                    >
                      <img
                        src="/images/attachment-icon.svg"
                        alt=""
                        onClick={() =>
                          renderDoc(
                            "bankProofDoc",
                            " Bank Proof - Upload Document",
                            organizerDetails.bankProofDoc
                          )
                        }
                      />
                    </span>

                    <InputTextBox
                      id="bankProofDoc"
                      name="bankProofDoc"
                      type="text"
                      placeholder="File-name.XYZ"
                      value={organizerDetails.bankProofDoc}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Branch Name
                  </label>
                  <div className="input-group">
                    <InputTextBox
                      id="branchName"
                      name="branchName"
                      type="text"
                      placeholder="Please Reenter Account Number"
                      value={organizerDetails.branchName}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {showModal && (
          <Modal onClose={() => setShowModal(false)} title={title}>
            <div>
              <img src={docPath} className="d-block w-100" alt=""></img>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ViewBankAccountDetailsOrganizer;
