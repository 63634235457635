import { Routes, Route } from "react-router-dom";
import EventCity from "./EventCity";
import React from "react";

const Events = (props) => {
  return (
    <Routes>
      <Route index element={<EventCity />} />
      <Route path="/:eventCity/:eventCityId" element={<EventCity />} />
    </Routes>
  );
};

export default Events;
