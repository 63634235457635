import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetEventDetailsData } from "../../services/eventAPI";

const BookingFail = ({ txnId, eventId, message }) => {
  const navigate = useNavigate();
  const [eventName, setEventName] = useState("");

  useEffect(() => {
    const eventDetails = async () => {
      const eventDetailsResponse = await GetEventDetailsData(eventId);
      if (eventDetailsResponse.success) {
        setEventName(eventDetailsResponse.data.eventName);
      } else {
        alert(eventDetailsResponse.message);
      }
    };

    eventDetails();
  }, [eventId]);

  const navigateToHome = () => {
    navigate("/event-ticket-options/" + eventId + "/" + eventName);
  };

  return (
    <div class="card p-4 organizer-logo organizer-success my-3 text-center justify-content-center align-items-center">
      {/* <img
        src="/images/success-profile.svg"
        alt=""
        class="organizer-success-logo my-4"
      /> */}

      <svg
        width="37"
        height="37"
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18.5002" cy="18.3074" r="17.7913" fill="#DE4545" />
        <path
          d="M27.5 13.3084L15.5 25.3084L10 19.8084L11.41 18.3984L15.5 22.4784L26.09 11.8984L27.5 13.3084Z"
          fill="white"
        />
      </svg>

      <h1>Payment Failed</h1>
      <p class="mb-4">
        {/* We are having soome issues with payment. Please try again. */}
        Note :- {message}
      </p>
      <p class="mb-4">Transaction Id :- {txnId}</p>
      <button
        type="submit"
        class="btn btn-select w-50 my-4"
        onClick={navigateToHome}
      >
        Try Again
      </button>
    </div>
  );
};

export default BookingFail;
