import React, { useState } from "react";

//main function

const NavigateFitfunda = () => {
  //declare start
  const [showMore, setShowMore] = useState(true);

  //declare end

  //functions/events/methods
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //main retrun
  return (
    <>
      <div style={{ margin: "30px" }}>
        <div>
          <p
            className="s2"
            style={{
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
              fontSize: "16pt",
            }}
          >
            Navigating FitFunda: A Step-by-Step Guide
          </p>

          <p
            className="s4"
            style={{
              paddingTop: "8pt",
              paddingLeft: "5pt",
              textIndent: "0pt",
              textAlign: "left",
              fontSize: "11pt",
            }}
          >
            <strong>Create Events & Preview in Real-Time</strong>
            <br />
            Start creating your event without the need to sign up right away!
            Simply fill in the required event details and enjoy FitFunda’s
            intuitive interface. As you build your event page, our real-time
            preview feature lets you instantly see your changes. When you’re
            ready to publish, just sign up—it’s quick and easy! Don’t worry,
            your event progress is saved, so you can pick up right where you
            left off.
          </p>

          {showMore && (
            <>
              <p
                style={{
                  paddingTop: "8pt",
                  paddingLeft: "5pt",
                  textIndent: "0pt",
                  textAlign: "left",
                  fontSize: "11pt",
                }}
              >
                <strong>Add Location</strong>
                <br />
                Easily set the event location for attendees to find.
                <br />
                <br />
                <strong>Upload Banners</strong>
                <br />
                Enhance your event page by adding attractive banners that
                reflect your event’s theme.
                <br />
                <br />
                <strong>Create Tickets</strong>
                <br />
                Design various ticket types (e.g., Early Bird, Gold, VIP) by
                specifying ticket categories, tax details, and receipt
                information.
                <br />
                <br />
                <strong>Collect Participant Information</strong>
                <br />
                Use custom attendee forms to ask relevant questions, helping
                organizers better understand their audience. Collect important
                participant details, making fields mandatory or optional as per
                your event's needs.
                <br />
                <br />
                <strong>Offer Discount Coupons</strong>
                <br />
                Attract more participants by offering discount codes, group
                ticket options, or flat-rate discounts (e.g., Early Bird deals).
                <br />
                <br />
                <strong>Event Settings</strong>
                <br />
                Configure GST settings, hosting fees, and payment gateway
                options. You can embed the event page directly on your website
                or add FitFunda’s widget to sell tickets without redirecting
                users.
                <br />
                <br />
                <strong>Verify Your Account & Add Bank Details</strong>
                <br />
                Complete account verification by submitting KYC documents and
                bank details. Once verified, you'll be able to receive payments
                directly to your account.
                <br />
                <br />
                <strong>Publish & Start Selling</strong>
                <br />
                Go live by publishing your event and sharing the event link.
                Start selling tickets and accepting online payments via net
                banking, credit/debit cards, and UPI.
                <br />
                <br />
                <strong>Manage & Track Registrations</strong>
                <br />
                Track revenue, monitor attendee details, and access event
                analytics via your event dashboard. Engage your audience by
                sending personalized emails or text messages.
                <br />
                <br />
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "11pt",
                    textAlign: "center",
                    fontSize: "11pt",
                  }}
                >
                  <strong>
                    Promoting Health & Wellness with FitFunda – Join Our Happy
                    Clients
                  </strong>
                  <br />
                  <br />
                  Still need help? No worries! Feel free to contact us at: +91
                  95294-78968.
                </p>
                <p
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    lineHeight: "11pt",
                    textAlign: "center",
                    fontSize: "15pt",
                  }}
                >
                  <strong>CREATE YOUR EVENT NOW – IT’S FREE!</strong>
                </p>
                {/* <a
                  href="#"
                  onClick={toggleShowMore}
                  style={{
                    color: "#1154CC",
                    fontFamily: "Arial, sans-serif",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    textDecoration: "underline",
                    fontSize: "11pt",
                  }}
                >
                  View less...
                </a> */}
              </p>
            </>
          )}

          {/* {!showMore && (
            <p
              style={{
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "none",
                fontSize: "8pt",
                textAlign: "left",
              }}
            >
              <a href="#" onClick={toggleShowMore} style={{ fontSize: "17px" }}>
                View more...
              </a>
            </p>
          )} */}
        </div>
      </div>
    </>
  );
};

export default NavigateFitfunda;
