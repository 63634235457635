import React, { useEffect, useState } from "react";
import { GetAllCityOfState } from "../../../services/commonAPI";

//
const CityByStateIdDropdown = ({ cityId, stateId, onCityChange, ...rest }) => {
  const [cityData, setCityData] = useState([]);
  // const [cityId1, setCityId] = useState(cityId);
  useEffect(() => {
    const getCityData = async (stateId) => {
      if (stateId !== "" && stateId !== null) {
        const cityData = await GetAllCityOfState(stateId);
        if (cityData !== null) {
          setCityData(cityData?.data || []);
        }
      }
    };

    getCityData(stateId);
  }, [stateId]);

  return (
    <>
      <select
        id="cityDropdown"
        value={cityId}
        name={rest?.name ? rest?.name : "city"}
        className="form-select"
        onChange={onCityChange}
      >
        <option value="0">Select city</option>
        {cityData.map((currentValue) => (
          <option key={currentValue.cityId} value={currentValue.cityId}>
            {currentValue.cityName}
          </option>
        ))}
      </select>
    </>
  );
};

export default CityByStateIdDropdown;
