import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { GetEventDetailsData } from "../../services/eventAPI";
import { IsValidEventId } from "../../utils/validations";
import EventCard from "../../components/event/EventCard.jsx";
import Button from "../../components/controls/buttons/Button.jsx";
import * as commom from "../../utils/commonFun";

//
const PublishEventSuccess = () => {
  const [eventData, setEventDetailsData] = useState(null);

  const { eventId, eventName } = useParams();
  const navigate = useNavigate();
  const userData = useSelector((loginData) => loginData.login.login);
  let isAdmin = false;
  if (userData?.login?.referenceId?.length > 4) {
    isAdmin = true;
    commom.clearLocalData();
    if (document.getElementById("mdnHeader"))
      document.getElementById("mdnHeader").style.display = "none";
  }

  useEffect(() => {
    if (IsValidEventId(eventId)) {
      getEventDetails();
    } else {
      console.log("Invalid eventid");
    }
  }, [eventId]);

  const getEventDetails = async () => {
    try {
      const eventDetailsResponseData = await GetEventDetailsData(eventId);
      if (eventDetailsResponseData.success) {
        setEventDetailsData(eventDetailsResponseData.data);
      } else {
        alert(eventDetailsResponseData.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div class="container text-center topmargin thank-you">
      <div class="row justify-content-center">
        <div class="col-6 mt-2 text-center">
          <h1 class="my-3">Congratulations! </h1>
          <p>
            <h2 class="my-3">Your event has been published successfully.</h2>
          </p>
        </div>

        {/* <div class="card align-items-center mb-4"> */}
        <div className="row justify-content-center align-items-center mx-6 my-6">
          {eventData && (
            //   return (
            <EventCard
              key={eventData.eventId}
              event={eventData}
              // city={categoryName}
            />
          )}
        </div>
        {/* </div> */}
        {isAdmin === true ? (
          <>
            <div class="col-3 mt-2 text-center mb-5">
              <b> Close this page and Go To Admin Dashboard</b>
              {document.getElementById("mdnHeader") &&
                document.getElementById("mdnHeader").style.display.none}
            </div>
          </>
        ) : (
          <>
            <div class="col-3 mt-2 text-center mb-5">
              <Button onClick={navigateToDashboard}>Go To Dashboard</Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PublishEventSuccess;
