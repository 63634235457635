import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import OrganizerMaster from "../master/OrganizerMaster";
import * as commom from "../../utils/commonFun";
import * as initialState from "../../services/initialState";
import EventCardTypeLarge from "../../components/event/EventCardLarge";
import { useSelector } from "react-redux";
import * as storeAction from "../../redux/actions/createEvent";
import CreateEventTabs from "../../components/controls/Tab/CreateEventTabs";

const CreateEvent = ({ eventDetails, fetchEventDetails }) => {
  //const theme = useTheme();
  // const [value, setValue] = useState(0);
  const userData = useSelector((loginData) => loginData.login.login);

  let eventDetailsPreview = initialState.EventDetailsPreview.data;

  const eventDetails1 = useSelector((state) => {
    return state.createEvent.eventDetails.eventDetails || [];
  });

  const loadPreviewData = () => {
    if (eventDetails?.eventDetails) {
      eventDetailsPreview = {
        ...eventDetailsPreview,
        ...eventDetails.eventDetails,
      };
    } else if (eventDetails1) {
      eventDetailsPreview = {
        ...eventDetailsPreview,
        ...eventDetails1,
      };
    }
  };

  loadPreviewData();

  useEffect(() => {
    const checkEventExist = async () => {
      const eventId = commom.getLocalData("eventId");
      if (eventId !== undefined && eventId !== null) {
        //if (!eventDetails1) {
        eventDetails = fetchEventDetails(eventId);
        //}
        //enableTabTwo();
      }
    };
    checkEventExist();
  }, []);

  //
  return (
    <>
      <OrganizerMaster>
        <div className="row">
          <div className="col-sm-12 col-md-8 col-xl-9 org-main-contain">
            <h3 class="font-weight-semibold">Create Events</h3>
            <CreateEventTabs eventDetails={eventDetailsPreview} />
          </div>
          <div className="col-sm-3 d-sm-none d-md-block col-md-4 col-xl-3 org-main-right">
            <EventCardTypeLarge
              eventDetails={eventDetailsPreview}
              hideRightPanel={true}
            />
          </div>
        </div>
      </OrganizerMaster>
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.createEvent.eventDetails,
});
const mapDispatchToProps = (dispatch) => ({
  fetchEventDetails: (eventId) =>
    dispatch(storeAction.fetchEventDetailsRequest(eventId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
//export default CreateEvent;
/* 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
 */
