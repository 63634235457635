import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { createPortal } from "react-dom";
// import MobileNumberPopup from "../popup/MobileNumberPopup";
// import EmailPopup from "../popup/EmailPopup";
import * as common from "../../utils/commonFun";
import ExistingSessionPopup from "../popup/ExistingSessionPopup";
import * as commom from "../../utils/commonFun";
import { addToLoginRequest } from "../../redux/actions/Login";
import LoginMenu from "./LoginMenu";
import Icon from "../controls/icons/Icon";
import TopCitiesPopup from "../controls/popup/TopCitiesPopup";
import SearchCard from "../search/SearchCard";
import { KEY_EVENT_ID } from "../../utils/Constants";
import Login from "../../pages/register/Login";
// import { GetMyNotifications } from "../../services/userAPI";

//main function
const Navbar = ({ children }) => {
  //declare start
  // State to manage the navbar's visibility
  const navigate = useNavigate();
  const [existingEventPopup, setExistingEventPopup] = useState(false);
  // const [mobile, setMobilePopup] = useState(false);
  // const [email, setEmailPopup] = useState(false);
  // const [bothFail, setBothFail] = useState(false);
  let [notificationCount, setNotificationCount] = useState(0);
  // const [showNotification, setShowNotification] = useState(false);
  // let [notificationMsg, setNotificationMsg] = useState([]);
  // const [notificationData, setNotificationData] = useState([]);

  let userData = useSelector((state) => state.login.login.login);
  const [loginPopup, setLoginPopup] = useState(false); 
  //declare end

  //functions/events/methods
  const handleCreateEvent = (event) => {
    event.preventDefault();    
    if (userData?.user) {
      common.removeItem(KEY_EVENT_ID);
      navigate("/create-event");
      window.location.reload();
    } else {
      const eventId = commom.getLocalData(KEY_EVENT_ID);
      if (eventId !== undefined && eventId !== null) {
        setExistingEventPopup(true);
      }     
      else {   
        common.removeItem(KEY_EVENT_ID);     
        navigate("/create-event");
      }
    }
  };

  const handleCloseExistingSession = () => {
    setExistingEventPopup(false);
  };

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       if (userData?.user !== undefined) {
  //         if (
  //           (userData?.user?.mobile === null ||
  //             userData?.user?.mobile === undefined ||
  //             userData?.user?.mobile === "") &&
  //           (userData?.user?.email === null ||
  //             userData?.user?.email === undefined ||
  //             userData?.user?.email === "")
  //         ) {
  //           console.log(
  //             "Login successful but mobile number & Email is not available."
  //           );

  //           setMobilePopup(true);
  //           setBothFail(true);
  //         } else if (
  //           userData?.user?.mobile === null ||
  //           userData?.user?.mobile === undefined ||
  //           userData?.user?.mobile === ""
  //         ) {
  //           console.log("Login successful but mobile number is not available.");
  //           setMobilePopup(true);
  //         } else if (
  //           userData?.user?.email === null ||
  //           userData?.user?.email === undefined ||
  //           userData?.user?.email === ""
  //         ) {
  //           console.log("Login successful but email is not available.");
  //           setEmailPopup(true);
  //         } else {
  //           // handleCreateProfile();
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getUser();
  // }, [userData]);

  useEffect(() => {
    if (userData?.user) {
      const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/connect-sse`;
      const eventSource = new EventSource(
        `${apiURL}?token=Bearer ${userData?.token}`
      );

      eventSource.onmessage = (event) => {
        // This is used to get the data of notification.
        // const notification = JSON.parse(event.data);

        notificationCount = notificationCount + 1;
        // setNotificationCount(notificationCount);
        // setNotificationMsg((notificationMsg) => [
        //   ...notificationMsg,
        //   notification,
        // ]);
      };

      eventSource.onerror = (error) => {
        console.error("EventSource failed:", error);
      };

      return () => {
        setNotificationCount(0);
        eventSource.close(); // Close the SSE connection when component unmounts
      };
    }
  }, [userData?.user]);

  /**
   * This is used to showcase the count of notification as well
   * as message in the UI.
   */
  // useEffect(() => {
  //   const GetNotificationData = async () => {
  //     try {
  //       if (userData?.token) {
  //         const notificationResult = await GetMyNotifications(userData?.token);
  //         if (notificationResult?.success === true) {
  //           setNotificationData(notificationResult?.data);
  //           setNotificationCount(notificationResult?.data.length);
  //         } else {
  //           alert(notificationResult?.message);
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   GetNotificationData();
  // }, [userData?.token]);

  // const dismissMobilePopup = () => {
  //   setMobilePopup(false);

  //   if (bothFail) {
  //     // console.log("Login successful but email is not available.");
  //     setEmailPopup(true);
  //   } else {
  //     handleCreateProfile();
  //   }
  // };

  // const dismissEmailPopup = () => {
  //   setEmailPopup(false);
  //   handleCreateProfile();
  // };

  // const handleCreateProfile = () => {
  //   const eventId = common.getLocalData("eventId");
  //   if (eventId !== undefined && eventId !== null) {
  //     navigate("/create-profile");
  //   }
  // };

  //offcanvas
  const [showOffcanvas, setShowOffcanvas] = useState("");
  const handleOffcanvas = (value) => {
    // alert(value);
    setShowOffcanvas(() => value);
  };

  const handleShowLoginPopup = () => {
    setLoginPopup(true);
  };

  const handleCloseLoginPopup = () => {
    setLoginPopup(false);
  };

  // const displayNotificationList = () => {
  //   if (userData?.user) {
  //     setShowNotification(() => !showNotification);
  //   }
  // };

  // const markReadNotifications = () => {
  //   displayNotificationList();
  //   setNotificationMsg([]);
  //   setNotificationCount(0);
  // };

  const navigateToNotification = () => {
    navigate("/my-info/notifications");
  };

  //main return
  return (
    <Fragment>
      <div className="container-fluid p-0 m-0" id="mdnHeader">
        <div className="row p-0 m-0">
          <nav className="navbar navbar-expand-lg topnavbar d-flex fixed-top">
            {/* logo */}
            <a className="navbar-brand" href="#">
              <div className="logo order-1 order-md-1 mr-3">
                <Link to="/">
                  <img src="/Logo.svg" />
                </Link>
              </div>
            </a>
            {/* Search */}
            <div className="order-2 d-flex order-md-2 mdnSearch">
              <div className="mr-2">
                {/* Add margin here */}
                <SearchCard />
              </div>
              {/* Filer */}
              <div className="order-4 d-flex order-md-4">
                <div className="mr-2">
                  {" "}
                  {/* Add margin here */}
                  <TopCitiesPopup />
                </div>
              </div>
            </div>

            {/* Nav */}
            <div
              className="navbar-toggler border-0 login-dmenu"
              onClick={() => (userData?.user ? handleOffcanvas("show") : handleShowLoginPopup())}
              /*  type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation" */
            >
              <i className="bi bi-justify-right " />
            </div>

            <div
              className={`offcanvas offcanvas-end order-md-5 order-0 ${showOffcanvas}`}
              tabIndex={-1}
              id="offcanvasNavbar"
              // aria-labelledby="offcanvasNavbarLabel"
            >
              {/* Mobile Menu */}
              <div className="offcanvas-header">
                {/* <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  Offcanvas
                </h5> */}
                <button
                  type="button"
                  className="btn-close"
                  /* data-bs-dismiss="offcanvas"
                  aria-label="Close" */
                  onClick={() => handleOffcanvas("hide")}
                />
              </div>
              {/* Create Event */}
              <div className="offcanvas-body1">
                {/* Profile */}
                <div className="float-end">
                  <div className="d-flex flex-row">
                    <a href="" className="event mdnCreateEvent">
                      <div className="p-1">
                        <button
                          type="button"
                          className="btn create-even btn-sm text-center"
                          onClick={(event) => handleCreateEvent(event)}
                        >
                          <span className="p-2">+</span>Create Event
                        </button>
                      </div>
                    </a>
                    <a
                      href="#home"
                      className="event mdnWelcome"
                      style={{
                        display: "none",
                        textDecoration: "none",
                        width: "180px",
                      }}
                    >
                      <div className="p-1">
                        <span className="p-2"></span>
                      </div>
                    </a>
                    <a href="#home" className="event">
                      <div className="p-2">
                        <div
                          className="bell-icon-container"
                          onClick={navigateToNotification}
                        >
                          <Icon iconName="bell" />
                          {notificationCount > 0 && (
                            <div className="notification-count">
                              {notificationCount}
                            </div>
                          )}

                          {/* {showNotification && notificationData.length > 0 && (
                            <>
                              <div
                                className="custom-dropdown"
                                style={{
                                  marginTop: "2px",
                                  marginLeft: "-120px",
                                }}
                              >
                                <div class="dropdown-menu dropdown-menu-right show caret">
                                  {notificationData &&
                                    notificationData.map &&
                                    notificationData.map((msgIs, msgIndex) => {
                                      return (
                                        <>
                                          <div
                                            className="sidemenuicon dropdown-item "
                                            key={msgIndex}
                                          >
                                            <div class="d-flex w-100 justify-content-between">
                                              <Link
                                                to="/my-info/notifications"
                                                // onClick={() => displayMenu()}
                                                onClick={markReadNotifications}
                                              >
                                                <small>{msgIs.message}</small>
                                              </Link>
                                            </div>
                                          </div>
                                          <div class="dropdown-divider" />
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            </>
                          )} */}
                        </div>
                      </div>
                    </a>
                    <a href="#home1" className="event">
                      <LoginMenu handleOffcanvas={handleOffcanvas} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {showOffcanvas === "show" && (
              <div
                class={`offcanvas-backdrop fade  ${showOffcanvas}`}
                onClick={() => handleOffcanvas("hide")}
              ></div>
            )}
          </nav>
          
          {loginPopup &&
        createPortal(
          <Login onClose={handleCloseLoginPopup} />,
          document.body
        )}

          {/* {mobile &&
            createPortal(
              <MobileNumberPopup onDismiss={dismissMobilePopup} />,
              document.body
            )}

          {email &&
            createPortal(
              <EmailPopup onDismiss={dismissEmailPopup} />,
              document.body
            )} */}

          {existingEventPopup &&
            createPortal(
              <ExistingSessionPopup onDismiss={handleCloseExistingSession} />,
              document.body
            )}
        </div>
      </div>
      {/* <Outlet /> */}
      {children}
    </Fragment>
  );
};

// export default Navbar;
const mapStateToProps = (state) => ({
  login: state.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  saveLogin: (login1) => dispatch(addToLoginRequest(login1)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
