import React from 'react';

//main function
const ParticipantDetails = ({event}) => {
  
  //main retrun
  return (
    <>
      <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
        <div >       
          <div
            id="participant-collapseThree"
            className="accordion-collapse collapse accordion-detail px-4 show"
            data-bs-parent="#accordionFlushExample"
          >
           No Participants are available in {event.eventName}.
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ParticipantDetails;
