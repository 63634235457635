// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  position: relative; /* Make the carousel container a positioning context */
}

.carousel-indicators {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 0;
  right: 0;
  text-align: center; /* Center the indicators horizontally */
  z-index: 15;
}

.carousel-indicators li {
  display: inline-block;
  width: 25px;
  height: 4px;
  margin: 0 5px; /* Adjust spacing */
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */
  /* border-radius: 50%; */
}

.carousel-indicators .active {
  background-color: #fff; /* Active indicator color */
}

.carousel-control-prev,
.carousel-control-next {
  display: none; /* Hide arrows */
}
`, "",{"version":3,"sources":["webpack://./src/css/_carousel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAE,sDAAsD;AAC5E;;AAEA;EACE,kBAAkB;EAClB,YAAY,EAAE,qBAAqB;EACnC,OAAO;EACP,QAAQ;EACR,kBAAkB,EAAE,uCAAuC;EAC3D,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,WAAW;EACX,aAAa,EAAE,mBAAmB;EAClC,eAAe;EACf,0CAA0C,EAAE,6BAA6B;EACzE,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB,EAAE,2BAA2B;AACrD;;AAEA;;EAEE,aAAa,EAAE,gBAAgB;AACjC","sourcesContent":[".carousel {\n  position: relative; /* Make the carousel container a positioning context */\n}\n\n.carousel-indicators {\n  position: absolute;\n  bottom: 10px; /* Adjust as needed */\n  left: 0;\n  right: 0;\n  text-align: center; /* Center the indicators horizontally */\n  z-index: 15;\n}\n\n.carousel-indicators li {\n  display: inline-block;\n  width: 25px;\n  height: 4px;\n  margin: 0 5px; /* Adjust spacing */\n  cursor: pointer;\n  background-color: rgba(255, 255, 255, 0.5); /* Adjust opacity as needed */\n  /* border-radius: 50%; */\n}\n\n.carousel-indicators .active {\n  background-color: #fff; /* Active indicator color */\n}\n\n.carousel-control-prev,\n.carousel-control-next {\n  display: none; /* Hide arrows */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
