import { useSelector } from "react-redux";
import Icon from "../controls/icons/Icon";
import AccountSettings from "./AccountSettings";
import MyOrder from "./MyOrder";
import MyProfile from "./MyProfile";
import Notification from "./Notification";
import Tabs from "../controls/Tab/Tabs";
import { GetCurrentLocation } from "../../utils/commonFun";

//
const UserTabs = ({ activeTab }) => {
  let userData = useSelector((loginData) => loginData.login.login.login);

  let isUserLogin = false;
  if (userData?.user) {
    isUserLogin = true;
  }

  let currentLocation = GetCurrentLocation();
  let currentPath = currentLocation?.pathArray[2];
  let activeTabNo = 0;
  switch (currentPath) {
    case "order":
      activeTabNo = 0;
      break;
    case "profile":
      activeTabNo = 1;
      break;
    case "notifications":
      activeTabNo = 2;
      break;
    case "account-settings":
      activeTabNo = 3;
      break;
    default:
      break;
  }
  //
  const config = {
    isUserLogin: isUserLogin,
    loginMessage: "Please login ",

    data: [
      {
        title: (
          <button class="nav-link ">
            <Icon iconName="myorder" />
            My Orders
          </button>
        ),
        tabBtnClass: "col nav-item",
        tabContentClass: "",
        tabContent: (
          <div class="tab-pane fade active show">
            <MyOrder />
          </div>
        ),
        showLogin: true,
      },
      {
        title: (
          <button class="nav-link">
            <Icon iconName="profile1" />
            My Profile
          </button>
        ),
        tabBtnClass: "col nav-item",
        tabContentClass: "",
        tabContent: (
          <div class="tab-pane fade active show">
            <MyProfile />
          </div>
        ),
        showLogin: true,
      },
      {
        title: (
          <button class="nav-link">
            <Icon iconName="notification" />
            Notification
          </button>
        ),
        tabBtnClass: "col nav-item",
        tabContentClass: "",
        tabContent: (
          <div class="tab-pane fade active show">
            <Notification />
          </div>
        ),
        showLogin: true,
      },
      {
        title: (
          <button class="nav-link">
            <Icon iconName="accountsetting" />
            Account Setting
          </button>
        ),
        tabBtnClass: "col nav-item",
        tabContentClass: "",
        tabContent: (
          <div class="tab-pane fade active show">
            <AccountSettings />
          </div>
        ),
        showLogin: true,
      },
    ],
    className: {
      mainContainer: "container text-center topmargin user-profile py-3 ",
      tabsContainer: "row nav nav-underline",
      activeTabContainer: "",
    },
  };

  return (
    <>
      <Tabs config={config} index={activeTabNo} />
    </>
  );
  /*  return (
    <div class="container text-center topmargin user-profile py-3 ">
      <ul class="nav nav-underline" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="my-orders"
            data-bs-toggle="tab"
            data-bs-target="#invite-tab-pane"
            type="button"
            role="tab"
            aria-controls="invite-tab-pane"
            aria-selected="true"
          >
            <Icon iconName="myorder" />
            My Orders
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="my-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#invited-tab-pane"
            type="button"
            role="tab"
            aria-controls="invited-tab-pane"
            aria-selected="false"
            tabindex="-1"
          >
            <Icon iconName="myprofile" />
            My Profile
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="notification"
            data-bs-toggle="tab"
            data-bs-target="#historys-tab-pane"
            type="button"
            role="tab"
            aria-controls="historys-tab-pane"
            aria-selected="false"
            tabindex="-1"
          >
            <Icon iconName="notification" />
            Notification
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="my-account-setting"
            data-bs-toggle="tab"
            data-bs-target="#history-tab-pane"
            type="button"
            role="tab"
            aria-controls="history-tab-pane"
            aria-selected="false"
            tabindex="-1"
          >
            <Icon iconName="profile1" />
            Account Setting
          </button>
        </li>
      </ul>

      <div class="tab-content mt-4" id="myTabContent">
        <div
          class="tab-pane fade active show"
          id="invite-tab-pane"
          role="tabpanel"
          aria-labelledby="my-orders"
          tabindex="0"
        >
          <MyOrder />
        </div>
        <div
          class="tab-pane fade"
          id="invited-tab-pane"
          role="tabpanel"
          aria-labelledby="my-profile-tab"
          tabindex="0"
        >
          <MyProfile />
        </div>
        <div
          class="tab-pane fade"
          id="historys-tab-pane"
          role="tabpanel"
          aria-labelledby="notification"
          tabindex="0"
        >
          <Notification />
        </div>
        <div
          class="tab-pane fade"
          id="history-tab-pane"
          role="tabpanel"
          aria-labelledby="my-account-setting"
          tabindex="0"
        >
          <AccountSettings />
        </div>
      </div>
    </div>
  ); */
};

export default UserTabs;
