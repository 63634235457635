import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Call from "../../../utils/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import ShowDateTimePicker from "../../controls/date/ShowDateTimePicker";
import * as initialState from "../../../services/initialState";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import {
  SaveDiscountCouponInfo,
  UpdateDiscountCouponInfo,
} from "../../../services/createEvent";
import * as common from "../../../utils/commonFun";
import Spinner from "../../controls/spinner/Spinner";

// Form initial Data
let inputValueRef = initialState.DiscountInfo;
let mParam = initialState.DiscountInfo.isRequired;

const DiscountForm = ({
  inputValueRef,
  inputValueListRef,
  onSaveCouponList,
  onShowCouponList,
  eventDetails,
  onUpdateEventDetails,
}) => {
  //const [discountType, setDiscountType] = useState(couponData.discountType);

  //const [minTickets, setMinTickets] = useState(couponData.minTickets);
  const [inputValue, setInputValue] = useState(inputValueRef);
  const [inputValueList, setInputValueList] = useState(inputValueListRef);
  const { errors, IsValid } = inputValue;
  const [validationSummary, setValidationSummary] = useState("");
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false); //spinner

  if (inputValue.tempDiscountPercentValue === undefined) {
    if (inputValue.discountPercent > 0) {
      inputValue.tempDiscountPercentValue = inputValue.discountPercent;
    } else if (inputValue.discountAmount > 0) {
      inputValue.tempDiscountPercentValue = inputValue.discountAmount;
    }
  }

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    // console.log(`Field changed: ${name}, Value: ${value}`);

    //check validation
    let result = isValid(name, value, e);

    if (name === "tempDiscountPercentType") {
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["tempDiscountPercentValue"]: "",
        ["discountPercent"]: "",
        ["discountAmount"]: "",
        errors: {
          ...errors,
          [name]: result.message,
          ["tempDiscountPercentValue"]: "",
          ["discountPercent"]: "",
          ["discountAmount"]: "",
        },
        IsValid: {
          ...IsValid,
          [name]: result.status,
          ["tempDiscountPercentValue"]: false,
          ["discountPercent"]: false,
          ["discountAmount"]: false,
        },
      }));
    } else if (name === "tempDiscountPercentValue") {
      //redio btn group
      let discountPercent = value;
      let discountAmount = value;
      let obj = document.getElementsByName("tempDiscountPercentType");
      if (obj[0].checked) {
        discountAmount = null;
      } else discountPercent = null;

      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["discountPercent"]: discountPercent,
        ["discountAmount"]: discountAmount,
        errors: {
          ...errors,
          [name]: result.message,
          ["discountPercent"]: result.message,
          ["discountAmount"]: result.message,
        },
        IsValid: {
          ...IsValid,
          [name]: result.status,
          ["discountPercent"]: result.status,
          ["discountAmount"]: result.status,
        },
      }));
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        errors: { ...errors, [name]: result.message },
        IsValid: { ...IsValid, [name]: result.status },
      }));
    }
    // console.log("Updated inputValue:", inputValue);

    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "discountName":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter discount name.";
          break;

        case "minTickets":
          if (inputValue?.discountType === "Group") {
            val = value !== "Select Minimum ticket count";
            if (!val) msg = "Please select minimum ticket count.";
          }
          break;

        case "discountType":
          val = value !== "Select Discount Type";
          if (!val) msg = "Please select discount type.";
          break;

        case "tempDiscountPercentValue":
          if (Call.IsEmpty(value)) {
            msg = "Please enter valid input";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
            else {
              let obj = document.getElementsByName("tempDiscountPercentType");
              if (obj[0].checked) {
                if (value.length !== 2) {
                  val = false;
                  msg = "Please enter discount Percent in 2 digit";
                }
              }
            }
          }
          break;
        case "numOfDiscount":
          if (Call.IsEmpty(value)) {
            msg = "Please enter number of discount";
          } else {
            val = Call.IsNumberOnlyInput(value);
            if (!val) msg = "Invalid " + value;
          }
          break;
        case "tempDiscountPercentType":
          if (value === "discountPercent" || value === "discountAmount")
            val = true;
          break;
        case "discountStartDate":
        case "discountEndDate":
          if (name === "discountEndDate") {
            if (inputValue.discountEndDate >= inputValue.discountStartDate)
              val = true;
          } else val = true;
          break;
        default:
          break;
      }
    }

    return { status: val, message: msg };
  };

  const handleInputChange = (name, data) => {
    //alert("handleDateChange");
    //alert(date);
    switch (name) {
      case "sDate":
        setInputValue((prev) => ({
          ...prev,
          discountStartDate: data,
          ["IsValid"]: { ...prev.IsValid, discountStartDate: true },
        }));
        break;
      case "eDate":
        setInputValue((prev) => ({
          ...prev,
          discountEndDate: data,
          ["IsValid"]: { ...prev.IsValid, discountEndDate: true },
        }));
        break;
      default:
        setInputValue((prev) => ({
          ...prev,
          [name]: data,
          ["IsValid"]: { ...prev.IsValid, [name]: true },
        }));
        break;
    }
  };

  const handleSaveCoupon = async () => {
    const isGroupDiscount = inputValue.discountType === "Group";
    const isValidGroupDiscount = isGroupDiscount ? IsValid.minTickets : true;

    if (
      IsValid &&
      IsValid.discountName &&
      //IsValid.tempDiscountPercentType &&
      IsValid?.discountType && // checking discountType validation
      isValidGroupDiscount &&
      IsValid.tempDiscountPercentValue &&
      IsValid.numOfDiscount &&
      IsValid.discountStartDate &&
      IsValid.discountEndDate
    ) {
      setLoading(true);
      const token = userData.token;
      const userId = userData.user.userId;
      const eventId = common.getLocalData(KEY_EVENT_ID);

      var result;

      if (inputValue?.discountCouponId === undefined) {
        result = await SaveDiscountCouponInfo(
          inputValue,
          userId,
          eventId,
          token
        );
      } else {
        result = await UpdateDiscountCouponInfo(
          inputValue,
          userId,
          eventId,
          token
        );
      }

      setLoading(false);
      if (result.success) {
        eventDetails = result.data;
        eventDetails = {
          ...eventDetails,
          IsValid: { ...IsValid, ...inputValue?.IsValid },
        };
        onSaveCouponList(result.data.discountCoupons);
        onUpdateEventDetails(eventDetails);
      } else {
        alert(result.message);
      }
    } else {
      let displaMsg = "";
      for (let key in mParam) {
        if (
          !inputValue?.IsValid[key] &&
          (key !== "minTickets" || isGroupDiscount)
        ) {
          displaMsg += "<li>" + key + "</li>";
        }
      }
      displaMsg =
        "<b>Please enter required fields</b><ul>" + displaMsg + "</ul>";
      setValidationSummary(() => displaMsg);
    }
  };

  //
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div class="card p-4 org-events-photo-upload my-4">
          <h5>Discount Coupons</h5>
          <form>
            <div class="my-3">
              <label for="exampleInputEmail1" class="form-label">
                Discount Type<span class="text-danger">*</span>
              </label>
              <select
                class="form-select p-2"
                aria-label="Default select example"
                value={inputValue.discountType}
                isValid={inputValue?.IsValid?.discountType}
                errorMsg={inputValue?.errors?.discountType}
                name="discountType"
                onChange={handleChange}
                disabled={inputValue?.discountCouponId > 0}
              >
                <option selected="0">Select Discount Type</option>
                <option value="Code">Code</option>
                <option value="Group">Group Discount</option>
                <option value="Flat">FLAT</option>
              </select>
            </div>
            <div class="my-3">
              <label for="exampleInputEmail1" class="form-label">
                Discount Name<span class="text-danger">*</span>
              </label>
              <InputTextBox
                id="discountName"
                name="discountName"
                value={inputValue.discountName}
                placeholder="Please enter discount name"
                isValid={inputValue?.IsValid?.discountName}
                errorMsg={inputValue?.errors?.discountName}
                onChange={handleChange}
              />
            </div>
            <div class="row g-3 my-3">
              <div class="col-md-4 ">
                Select<span class="text-danger">*</span>{" "}
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="tempDiscountPercentType"
                    id="inlineRadio1"
                    value="discountPercent"
                    onChange={handleChange}
                    checked={
                      inputValue.tempDiscountPercentType === "discountPercent"
                    }
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Discount %
                  </label>
                </div>
              </div>

              <div class="col-md-4 ">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="tempDiscountPercentType"
                    id="inlineRadio2"
                    value="discountAmount"
                    onChange={handleChange}
                    checked={
                      inputValue.tempDiscountPercentType === "discountAmount"
                    }
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Discount Amount
                  </label>
                </div>
              </div>
              <InputTextBox
                id="tempDiscountPercentValue"
                name="tempDiscountPercentValue"
                value={inputValue.tempDiscountPercentValue}
                placeholder="Please enter number"
                isValid={inputValue?.IsValid?.tempDiscountPercentValue}
                errorMsg={inputValue?.errors?.tempDiscountPercentValue}
                onChange={handleChange}
                maxlength={4}
              />
            </div>

            <div class="row g-3 my-3">
              <div class="col">
                <label class="" for="inlineFormInputGroupUsername">
                  Number of Discount<span class="text-danger">*</span>
                </label>
                <div class="input-group border rounded-2">
                  <InputTextBox
                    id="numOfDiscount"
                    name="numOfDiscount"
                    value={inputValue.numOfDiscount}
                    placeholder="Please enter num Of Discount"
                    isValid={inputValue?.IsValid?.numOfDiscount}
                    errorMsg={inputValue?.errors?.numOfDiscount}
                    onChange={handleChange}
                    maxlength={5}
                  />
                </div>
              </div>

              {inputValue.discountType == "Group" ? (
                <>
                  <div class="col">
                    <label class="" for="inlineFormInputGroupUsername">
                      Minimum ticket for group discount
                      <span class="text-danger">*</span>
                    </label>
                    <div class="input-group border rounded-2">
                      <select
                        class="form-select p-2"
                        aria-label="Default select example"
                        value={inputValue.minTickets}
                        isValid={inputValue?.IsValid?.minTickets}
                        errorMsg={inputValue?.errors?.minTickets}
                        onChange={handleChange}
                        name="minTickets"
                      >
                        <option selected="0">
                          Select Minimum ticket count
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <div class="row g-3 my-3">
              <div class="col">
                <label class="" for="inlineFormInputGroupUsername">
                  Start Date<span class="text-danger">*</span>
                </label>
                <div class="input-group border rounded-2">
                  <ShowDateTimePicker
                    type="start-date"
                    title=""
                    curDate={inputValue.discountStartDate}
                    minDate={inputValue.discountStartDate}
                    maxDate={eventDetails?.eventEndDate}
                    onDateChange={(newDate) =>
                      handleInputChange("sDate", newDate)
                    }
                  />
                </div>
              </div>
              <div class="col">
                <label class="" for="inlineFormInputGroupUsername">
                  End Date<span class="text-danger">*</span>
                </label>
                <div class="input-group border rounded-2">
                  <ShowDateTimePicker
                    type="end-date"
                    title=""
                    curDate={inputValue?.discountEndDate}
                    minDate={inputValue?.discountStartDate}
                    maxDate={eventDetails?.eventEndDate}
                    onDateChange={(newDate) =>
                      handleInputChange("eDate", newDate)
                    }
                    disabled={inputValue.sDate === true}
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="my-2 text-center">
            <Button type="submit" onClick={handleSaveCoupon}>
              {inputValue.discountCouponId > 0 ? "Update" : "Save"} Coupon
            </Button>
            {inputValueList?.length > 0 && (
              <div className="mb-3 text-center">
                <br />

                <Button light onClick={onShowCouponList}>
                  Back To List
                </Button>
              </div>
            )}

            {/* <a href="#" class="skip">
            Skip
          </a> */}
          </div>
          {validationSummary !== "" && (
            <div
              class="alert alert-danger"
              role="alert"
              dangerouslySetInnerHTML={{ __html: validationSummary }}
            ></div>
          )}
        </div>
      )}
    </>
  );
};

export default DiscountForm;
