import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";

//
const libraries = ["places"];
const mapContainerStyle = {
  /*  width: "600px", */
  height: "300px",
};
const currentLocation = {
  lat: 21.146633,
  lng: 79.08886,
};
//main fun
const ShowGoogleMap = ({ searchInput, cameFrom }) => {
  const searchAddress =
    searchInput.address + " " + searchInput.area + " " + searchInput.city;
  const [location, setLocation] = useState(currentLocation);
  const [markers, setMarker] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    libraries,
  });

  //
  useEffect(() => {
    // Make a request to the Geocoding API

    if (searchAddress?.trim()?.length > 6) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${searchAddress}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        )
        .then((response) => {
          const result = response.data.results[0];
          if (result) {
            const { lat, lng } = result.geometry.location;
            setLocation({ lat, lng });
            setMarker(() => [
              {
                lat: lat,
                lng: lng,
              },
            ]);
          } else {
            setLocation(null);
            //alert("Please enter correct location.");
            console.error("Geocoding was not successful");
          }
        })
        .catch((error) => {
          console.error("Error during geocoding:", error);
        });
    }
  }, [searchInput]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  //main return
  return (
    <>
      <div class="form-floating">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={location}
          ti
        >
          {markers.map((marker) => (
            <Marker
              position={{
                lat: marker.lat,
                lng: marker.lng,
              }}
              title={searchInput.address + " " + searchInput.area}
            />
          ))}
        </GoogleMap>
      </div>
    </>
  );
};

export default ShowGoogleMap;
