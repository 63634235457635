import React from "react";
import LeftMenuPanel from "../../components/createEvent/LeftMenuPanel";

const OrganizerMaster = ({ children }) => {
  return (
    <>
      <div className="row mdnOrgMst">
        <div className="rightside-bar col-xxl-2 col-md-3">
          <LeftMenuPanel />
        </div>
        <div className="col-xxl-10 col-md-9 maincontain-width">{children}</div>
      </div>
    </>
  );
};

export default OrganizerMaster;
