import {
  useFetchPostRequestWithToken,
  useFetchMultipartWithToken,
  consoleLogFormData,
  useFetchMultipart,
  useFetchGetRequest,
  useFetchGetRequestWithToken,
} from "./api";

export const SaveEventDetailsGuest = async (reqBody) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-1`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid" && key !== "faqs") {
      formData.append(key, reqBody[key]);
    } else if (key === "faqs") {
      formData.append("faqs", JSON.stringify(reqBody[key]));
    }
  }

  consoleLogFormData(formData);
  // Not working
  const result = await useFetchMultipart(apiURL, formData);

  return result;
};

export const SaveEventDetails = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-1`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid" && key !== "faqs") {
      formData.append(key, reqBody[key]);
    } else if (key === "faqs") {
      formData.append("faqs", JSON.stringify(reqBody[key]));
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export const SaveEventLocation = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-2`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export const SaveEventLocationGuest = async (reqBody) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-2`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipart(apiURL, formData);
  return result;
};

export const SaveEventPhoto = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-3`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      if (key === "files") {
        reqBody?.files?.forEach((image, index) => {
          formData.append(`file`, image);
        });
      } else {
        formData.append(key, reqBody[key]);
      }
    }
  }
  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export const SaveEventPhotoGuest = async (reqBody) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-3`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key !== "errors" && key !== "IsValid") {
      if (key === "files") {
        reqBody.files.forEach((image, index) => {
          formData.append(`file`, image);
        });
      } else {
        formData.append(key, reqBody[key]);
      }
    }
  }
  const result = await useFetchMultipart(apiURL, formData);
  return result;
};

export const SaveEventTicketDetails = async (reqBody, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-4`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (key === "eventId" || key === "tickets") {
      if (key === "eventId") {
        formData.append(key, reqBody[key]);
      } else if (key === "tickets") {
        formData.append(key, JSON.stringify(reqBody.tickets));
      }
    }
  }

  const result = await useFetchMultipartWithToken(apiURL, formData, token);
  return result;
};

export const SaveEventTicketDetailsGuest = async (reqBody) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-ed-4`;

  const formData = new FormData();
  for (const key in reqBody) {
    if (skipFields(key)) continue;

    if (key === "tickets") {
      formData.append(key, JSON.stringify(reqBody.tickets));
    } else {
      formData.append(key, reqBody[key]);
    }
  }

  const result = await useFetchMultipart(apiURL, formData);
  return result;
};

export const SaveParticipantForm = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-parti-form`;

  const reqBody = JSON.stringify({
    // data: {
    userId: inputValue.userId,
    eventId: inputValue.eventId,
    participantFormData: {
      isName: inputValue.isName,
      isEmail: inputValue.isEmail,
      isMobile: inputValue.isMobile,
      stepCompleted: inputValue.stepCompleted,
      isDob: {
        isActive: inputValue.isDob.isActive,
        isMandatory: inputValue.isDob.isMandatory,
      },
      isGender: {
        isActive: inputValue.isGender.isActive,
        isMandatory: inputValue.isGender.isMandatory,
      },
      isUploadPhoto: {
        isActive: inputValue.isUploadPhoto.isActive,
        isMandatory: inputValue.isUploadPhoto.isMandatory,
      },
      isTshirtSize: {
        isActive: inputValue.isTshirtSize.isActive,
        isMandatory: inputValue.isTshirtSize.isMandatory,
      },
      isClubName: {
        isActive: inputValue.isClubName.isActive,
        isMandatory: inputValue.isClubName.isMandatory,
      },
      isCountry: {
        isActive: inputValue.isCountry.isActive,
        isMandatory: inputValue.isCountry.isMandatory,
      },
      isState: {
        isActive: inputValue.isState.isActive,
        isMandatory: inputValue.isState.isMandatory,
      },
      isCity: {
        isActive: inputValue.isCity.isActive,
        isMandatory: inputValue.isCity.isMandatory,
      },
      isAddress: {
        isActive: inputValue.isAddress.isActive,
        isMandatory: inputValue.isAddress.isMandatory,
      },
      isPincode: {
        isActive: inputValue.isPincode.isActive,
        isMandatory: inputValue.isPincode.isMandatory,
      },
      isGroupName: {
        isActive: inputValue.isGroupName.isActive,
        isMandatory: inputValue.isGroupName.isMandatory,
      },
    },
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveParticipantFormIdProof = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-parti-form`;

  const reqBody = JSON.stringify({
    userId: inputValue.userId,
    eventId: inputValue.eventId,

    participantFormData: {
      isIdProof: {
        isActive: inputValue.isIdProof.isActive,
        isMandatory: inputValue.isIdProof.isMandatory,
      },
      stepCompleted: inputValue.stepCompleted,
    },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveParticipantFormTimingCertificate = async (
  inputValue,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-parti-form`;

  const reqBody = JSON.stringify({
    // data: {
    userId: inputValue.userId,
    eventId: inputValue.eventId,

    participantFormData: {
      isTimingCertificate: {
        isActive: inputValue.isTimingCertificate.isActive,
        isMandatory: inputValue.isTimingCertificate.isMandatory,
      },
      stepCompleted: inputValue.stepCompleted,
    },
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveParticipantFormEmergencyContact = async (
  inputValue,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-parti-form`;

  const reqBody = JSON.stringify({
    // data: {
    userId: inputValue.userId,
    eventId: inputValue.eventId,

    participantFormData: {
      isEmergencyContact: {
        isActive: inputValue.isEmergencyContact.isActive,
        isMandatory: inputValue.isEmergencyContact.isMandatory,
      },
      stepCompleted: inputValue.stepCompleted,
    },
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveParticipantFormMedicalInfo = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-parti-form`;

  const reqBody = JSON.stringify({
    // data: {
    userId: inputValue.userId,
    eventId: inputValue.eventId,

    participantFormData: {
      isBloodGroup: {
        isActive: inputValue.isBloodGroup.isActive,
        isMandatory: inputValue.isBloodGroup.isMandatory,
      },
      isFitnessCertificate: {
        isActive: inputValue.isFitnessCertificate.isActive,
        isMandatory: inputValue.isFitnessCertificate.isMandatory,
      },
      stepCompleted: inputValue.stepCompleted,
    },
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveDiscountCouponInfo = async (
  inputValue,
  userId,
  eventId,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-single-coupon`;

  const reqBody = JSON.stringify({
    userId: userId,
    eventId: eventId,

    coupon: {
      discountType: inputValue.discountType,
      discountName: inputValue.discountName,
      discountPercent: inputValue.discountPercent,
      discountAmount: inputValue.discountAmount,
      minTickets: inputValue.minTickets,
      numOfDiscount: inputValue.numOfDiscount,
      discountStartDate: inputValue.discountStartDate,
      discountEndDate: inputValue.discountEndDate,
    },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const UpdateDiscountCouponInfo = async (
  inputValue,
  userId,
  eventId,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/update-single-coupon`;
  const reqBody = JSON.stringify({
    userId: userId,
    eventId: eventId,

    coupon: {
      discountCouponId: inputValue.discountCouponId,
      discountType: inputValue.discountType,
      discountName: inputValue.discountName,
      discountPercent: inputValue.discountPercent,
      discountAmount: inputValue.discountAmount,
      minTickets: inputValue.minTickets,
      numOfDiscount: inputValue.numOfDiscount,
      discountStartDate: inputValue.discountStartDate,
      discountEndDate: inputValue.discountEndDate,
    },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const SaveTicketSettings = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/create-event-setting`;

  const reqBody = JSON.stringify({
    userId: inputValue.userId,
    eventId: inputValue.eventId,
    eventSettingData: {
      isGstFromAttendees: inputValue.isGstFromAttendees,
      hostingFeesPayer: inputValue.hostingFeesPayer,
      pgFeesPayer: inputValue.pgFeesPayer,
      embeddCode: inputValue.embeddCode,
      isRedirect: inputValue.isRedirect,
      stepCompleted: inputValue.stepCompleted,
    },
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const GetEventDetails = () => {
  const response = {
    eventName: "",
    categoryId: 0,
    eventCategory: "",
    eventStartDate: "DD/MM/YYYY | Time",
    eventEndDate: "DD/MM/YYYY | Time",
    eventDescription: "",
    faq: [{ qid: 1, question: "", answer: "" }], //,{qid:2, que:"",ans:""}],
    terms: "",
    eventPermissionDocument: "",
    errors: {},
    IsValid: {},
  };
  return response.data;
};

export const PublishEventById = async (userId, eventId, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/publish-event`;

  const reqBody = JSON.stringify({
    userId: userId,
    eventId: eventId,
  });

  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const ViewTicketPriceBreakup = async (eventId, ticketPrice) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/get-payment-breakup-org?eventId=${eventId}&ticketPrice=${ticketPrice}`;

  const result = await useFetchGetRequest(apiURL);
  return result;
};

export const DeleteSinglePhoto = async (eventId, docId, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/delete-single-photo?eventId=${eventId}&docId=${docId}`;
  const result = await useFetchGetRequestWithToken(apiURL, token);
  return result;
};

export const DeleteSingleTicket = async (eventId, ticketId) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/delete-single-ticket?eventId=${eventId}&ticketId=${ticketId}`;
  const result = await useFetchGetRequest(apiURL);
  return result;
};

export const DeactivateSingleTicketAfterPublish = async (
  eventId,
  ticketId,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/deactivate-single-ticket?eventId=${eventId}&ticketId=${ticketId}`;
  const result = await useFetchGetRequestWithToken(apiURL, token);
  return result;
};

export const DeleteSingleCoupon = async (eventId, discountCouponId, token) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/delete-single-coupon?eventId=${eventId}&discountCouponId=${discountCouponId}`;
  const result = await useFetchGetRequestWithToken(apiURL, token);
  return result;
};

export const DeactivateSingleCouponAfterPublish = async (
  eventId,
  discountCouponId,
  token
) => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/deactivate-single-coupon?eventId=${eventId}&discountCouponId=${discountCouponId}`;
  const result = await useFetchGetRequestWithToken(apiURL, token);
  return result;
};

const skipFields = (key) => {
  switch (key) {
    case "errors":
    case "IsValid":
      return true;

    default:
      return false;
  }
};

export default SaveEventDetails;
