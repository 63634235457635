import React from "react";

const Dropdown = ({  options, prefixText, isValid = null, errorMsg, ...rest }) => {
  const inputClassName =
    " form-select " +
    rest?.className +
    // (isValid ? " is-valid" : !isValid ? " is-invalid" : "");
    (isValid === null ? "" : isValid ? " is-valid" : " is-invalid");
    
  const feedbackClassName =
    //" form-control " +
    isValid
      ? "valid-feedback"
      : !isValid
      ? "invalid-feedback"
      : "valid-tooltip";

  const prefixClassName = "input-group-text ";

  //
  return (
    <>
      {prefixText && <div class={prefixClassName}>{prefixText}</div>}
      <select {...rest} className={inputClassName}>
        {options?.map((option) => (
          <option key={option?.key} value={option?.value}>
            {option?.title}
          </option>
        ))}
      </select>
      {!isValid && <div class={feedbackClassName}>{errorMsg}</div>}
    </>
  );
};

export default Dropdown;
