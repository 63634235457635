import React, { useState } from "react";
import { FOLDER_EVENT_PHOTO, NO_IMAGE_PATH } from "../../utils/Constants";
import {
  convertDateTimeToISTForUITimeOnly,
  convertDateToIST,
} from "../../utils/DateTimeUtility";

//
let ImagePath = process.env.REACT_APP_EVENTS;
let eventId;
const EventCardTypeSmall = ({ eventDetailsData }) => {
  eventId = eventDetailsData.eventId;
  let ImageFullPath =
    ImagePath +
    eventId +
    FOLDER_EVENT_PHOTO +
    (eventDetailsData &&
      eventDetailsData.eventPhotos[0] &&
      eventDetailsData.eventPhotos[0].docName);

  const handleOnError = (event) => {
    event.target.src = NO_IMAGE_PATH;
  };
  return (
    <>
      {eventDetailsData && (
        <>
          <div class="d-flex flex-row event-banner-card align-items-center mb-4">
            <div class="p-2 col-3">
              <img
                src={ImageFullPath}
                alt=""
                // onError={handleOnError}
                class="img-fluid-eventCard-small"
              />
            </div>
            <div class="ms-2 text-start col-9" id="eventto">
              <h1>{eventDetailsData.eventName}</h1>
              <p>
                <span>
                  {convertDateToIST(eventDetailsData.eventStartDate)} to{" "}
                  {convertDateToIST(eventDetailsData.eventEndDate)}
                </span>
                <span class="mx-2">|</span>
                <span>
                  {convertDateTimeToISTForUITimeOnly(
                    eventDetailsData.eventStartDate
                  )}
                  {/* {eventDetailsData.eventStartTime} */}
                </span>
                <span class="mx-2">|</span>
                <span>{eventDetailsData.address}</span>
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventCardTypeSmall;
