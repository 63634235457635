import React, { useState } from "react";

//main function
const TermsAndConditions = () => {
  //declare start
  const [showMore, setShowMore] = useState(false);

  //declare end

  //functions/events/methods
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //main retrun
  return (
    <>
      <div
        className="terms-container"
        style={{
          margin: "0 auto",
          padding: "30px",
          textAlign: "left",
        }}
      >
        <p style={{ marginBottom: "15px", fontSize: "14px" }}>
          Vrattanta Endurance and Sports, is the holding company of
          FitFunda.co.in and FitFunda.in, and its affiliates operating in the
          event management and various sports technology domain (henceforth
          referred to as 'Vrattanta', 'we', 'us', or 'our') is a corporation
          incorporated and registered in the state of Maharashtra, India, with
          its registered address at : D2-502, Lunkad Goldcoast, Viman nagar,
          Pune, Maharashtra 411014.
        </p>
        <p style={{ marginBottom: "20px", fontSize: "14px" }}>
          Through the Website, fitfunda shall provide you ("User") information
          on events, conferences. This User Agreement ("Agreement") sets out the
          terms and conditions on which FitFunda shall provide the Services to
          the User through the Website.{" "}
        </p>

        <p style={{ marginBottom: "15px", fontSize: "14px" }}>
          This document is an electronic record in terms of:
        </p>
        <ol
          style={{
            marginBottom: "20px",
            paddingLeft: "40px",
            fontSize: "14px",
          }}
        >
          <li style={{ marginBottom: "10px", fontSize: "14px" }}>
            Information Technology Act, 2000.
          </li>
          <li style={{ marginBottom: "10px", fontSize: "14px" }}>
            The rules framed there under as applicable, and
          </li>
          <li style={{ marginBottom: "10px", fontSize: "14px" }}>
            The amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000.
          </li>
        </ol>

        <p style={{ marginBottom: "25px", fontSize: "14px" }}>
          This document is published in accordance with the provisions of Rule 3
          (1) of the Information Technology (Intermediaries guidelines) Rules,
          2011 that require publishing the rules and regulations, privacy policy
          and Terms of Use for access or usage of the Website. This electronic
          record is generated by a computer system and does not require any
          physical or digital signatures.{" "}
        </p>

        {showMore && (
          <>
            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>Services</h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              We are an online platform for events, conferences, webinars,
              specifically ("Events"). We assist in connecting the event
              organisers ("Organizers"), participants ("Participants") and
              speakers ("Speakers").
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Representations, Warranties and Obligations of the Website
            </h1>
            <p style={{ marginBottom: "15px", fontSize: "14px" }}>
              You hereby represent and warrant that:
            </p>
            <ol style={{ marginBottom: "20px", paddingLeft: "40px" }}>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                We are a duly registered as under the relevant provisions of the
                Companies Act, 1956.
              </li>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                We are in compliance with the Applicable Law to provide
                Services, subject to these Terms of Use; and
              </li>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                We will comply with the Privacy Policy and ensure data security
                of the Users at all times.
              </li>
            </ol>

            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              We shall not be liable for any terms and conditions/ arrangements
              between the Organizers and the Users, including but not limited to
              the standards of services provided by the Organizers, price of
              tickets for the Events, cancellation of an Event etc. We shall not
              be liable for the services provided or not provided by the
              Organizers.
              <br />
              <br />
              We do not make any representation or warranty as to the quality/
              standard of the Event. We are not responsible for any
              non-performance or breach of any contract entered into between
              Organizer(s) and the User(s). We shall not be required to mediate
              or resolve any dispute or disagreement between Organizer(s) and
              the User(s).
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Representations, Warranties and Obligations of the User
            </h1>

            <p style={{ marginBottom: "15px", fontSize: "14px" }}>
              You hereby represent and warrant that:
            </p>
            <ol style={{ marginBottom: "20px", paddingLeft: "40px" }}>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                You are a natural person, of the Minimum Age, have read,
                understood and agree to be bound by these Terms of Use.
              </li>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                You shall provide accurate information and details at the time
                of registration and shall also keep the contact information up
                to date.
              </li>
              <li style={{ marginBottom: "10px", fontSize: "14px" }}>
                You agree not to copy, sell, resell, duplicate any part of the
                Website or the Services offered through the Website for
                commercial purposes.
              </li>
            </ol>

            <p style={{ marginBottom: "15px", fontSize: "14px" }}>
              If you use the Website as registered User, you are responsible for
              maintaining the confidentiality of your User ID and Password. You
              are responsible for all activities that occur under your User ID
              and Password.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>Content</h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              The Website and all the content on the Website, is provided to You
              ‘AS IS’ for Your personal, non-commercial use only and may not be
              used, copied, reproduced, distributed, transmitted, broadcast,
              displayed, sold, licensed, or otherwise exploited for any other
              purposes whatsoever without Our prior written consent.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>Links</h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              The Website may contain links to other websites ("Linked Sites").
              The Linked Sites are not under the control of the Company. We are
              not responsible for the content of any Linked Site, including,
              without limitation to, any link contained in a Linked Site, or any
              changes or updates to a Linked Site.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Payment Terms
            </h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              User(s) can make payments for purchasing tickets for various
              Events through the Website, through the payment gateway integrated
              with this website. The User agrees and accepts that all nuances
              and modalities relating to making payment using Internet Banking/
              Debit/Credit Cards/etc ("Virtual Payment Mode") shall be
              separately governed by arrangement(s) / terms and conditions
              between you and the relevant banks/ payment gateway service
              providers. We shall not be responsible, in any manner whatsoever,
              for any liability that may arise in relation to the Virtual
              Payment Modes. While availing any of the payment method(s)
              available on the Website, We will not be responsible or assume any
              liability, whatsoever in respect of any loss or damage arising
              directly or indirectly to You due to (a) Lack of authorization for
              any transactions; (b) Any payment issues arising out of the
              transaction or (c) Decline of such transaction for any reason.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Fraud and Improper Conduct
            </h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              You may only access the Website and use the Services for lawful
              purposes. You are solely responsible for the knowledge of and
              adherence to any and all provisions of Applicable Law pertaining
              to your use of the Website.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Governing Law
            </h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              Terms of Use shall be governed by and constructed in accordance
              with the laws of India, without reference to conflict of laws
              principles. The courts in Pune, Maharashtra, shall have the
              exclusive jurisdiction to determine any disputes arising in
              relation to, or under, these Terms of Use.
            </p>

            <h1 style={{ fontSize: "24px", marginBottom: "10px" }}>
              Grievance Officer
            </h1>
            <p style={{ marginBottom: "30px", fontSize: "14px" }}>
              In accordance with Information Technology Act, 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below:
              <br />
              <br />
              Name: Mr. Sanjib Paul
              <br />
              Email address: sanjib@vrattanta.com
              <br />
              Mobile number: +91 95294-78968
              <br />
              Postal address: D2-502, Lunkad Goldcoast, Viman nagar, Pune,
              Maharashtra 411014.
            </p>

            <a
              href="#"
              onClick={toggleShowMore}
              style={{
                color: "#1154CC",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              View less...
            </a>
          </>
        )}

        {!showMore && (
          <p
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "11pt",
            }}
          >
            <a href="#" onClick={toggleShowMore} style={{ fontSize: "15px" }}>
              View more...
            </a>
          </p>
        )}
      </div>
    </>
  );
};
export default TermsAndConditions;
