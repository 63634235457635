import {
  useFetchGetRequest,
  downloadFile,
  useFetchPostRequest,
  useFetchPostRequestWithToken,
} from "./api";

export const GetTopCities = () => {
  const apiURL = `${process.env.REACT_APP_EVENT_MS}event/get-top-cities`;
  return useFetchGetRequest(apiURL);
};

export const GetCategoryData = () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-all-categories`;
  return useFetchGetRequest(apiURL);
};

export const GetStateList = () => {
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-state`;
  return useFetchGetRequest(apiURL);
};

export const GetAllCityOfState = (stateId) => {
  const apiURL =
    `${process.env.REACT_APP_USER_MS}config/get-cities-by-state/` + stateId;
  return useFetchGetRequest(apiURL);
};

// export const GetAllCityOfStateMasterData = (stateId) => {
//   const apiURL =
//     `${process.env.REACT_APP_USER_MS}config/get-cities-by-state/` + stateId;
//   return useFetchGetRequest(apiURL);
// };

export const GetAllBanners = () => {
  // const apiURL = `${process.env.REACT_APP_USER_MS}config/get-all-banner`;
  const apiURL = `${process.env.REACT_APP_USER_MS}config/get-all-active-banner`;
  return useFetchGetRequest(apiURL);
};

export const downloadFileLocaly = (filePath, fileName) => {
  const apiURL = filePath;
  return downloadFile(apiURL, fileName);
};

export const GetServiceCharges = (inputValue) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/get-payment-breakup-homepage`;

  const body = JSON.stringify({
    ticketPricePerEvent: parseFloat(inputValue.ticketPricePerEvent),
    attendeesPerEvent: parseInt(inputValue.attendeesPerEvent),
    doesTicketInvolveGst: inputValue.doesTicketInvolveGst,
    hostingFeesPayer: inputValue.hostingFeesPayer,
    pgFeesPayer: inputValue.pgFeesPayer,
    isGstFromAttendees: inputValue.isGstFromAttendees,
    gstOrgPercent: parseInt(inputValue.gstOrgPercent),
  });

  return useFetchPostRequest(apiURL, body);
};

export const GetLoginDataAd = (referanceId, userId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}admin/create-user-token/`;
  const body = JSON.stringify({
    userId: userId,
  });

  return useFetchPostRequestWithToken(apiURL, body, token);
};
export default GetTopCities;
