import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetPastEventData } from "../../services/eventAPI.jsx";
import EventCardDraft from "../event/EventCardDraft.jsx";
import Spinner from "../controls/spinner/Spinner.jsx";

const PastEventCard = () => {
  const { eventCity, eventCityId } = useParams();
  const [pastEvents, setPastEvents] = useState([]);
  const userData = useSelector((loginData) => loginData.login.login);
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    const getAllPastEvent = async () => {
      try {
        const pastEventsResponse = await GetPastEventData(userData.login.token);
        if (pastEventsResponse.success === true) {
          const pastEventResponse = pastEventsResponse.data;
          setPastEvents(pastEventResponse);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllPastEvent();
  }, []);

  return (
    <>
     {loading ? (
        <Spinner/>
        ) : (
          <>
      <div className="container text-center mt-0 ml-1">
        <div className="row align-items-start mx-1 my-3">
        {pastEvents.length === 0 ? (
              <p>No Past events found.</p>
            ) : (
          pastEvents &&
            pastEvents.map &&
            pastEvents.map((event, index) => {
              return (
                <EventCardDraft
                  key={event.eventId}
                  event={event}
                  city={eventCity}
                />
              );
            })
            )}
        </div>
      </div>
      </>
        )}
    </>
  );
};

export default PastEventCard;
