import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { convertDateTimeToIST } from "../../../utils/DateTimeUtility";

dayjs.extend(utc);
dayjs.extend(timezone);

const ShowDateTimePicker = ({
  onDateChange,
  curDate: defaultDate,
  minDate,
  maxDate,
  title,
  type,
  ...rest
}) => {
  const today = dayjs().startOf("day");
  let maximumDate = dayjs().add(365, "day");

  if (!defaultDate) {
    defaultDate = type === "start-date" ? today : today.add(1, "day");
  }

  const [date, setDate] = useState(
    // dayjs(defaultDate).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm")
    convertDateTimeToIST(defaultDate)
  );
  const [minDateTimeState, setMinDateTimeState] = useState(
    // today.format("YYYY-MM-DDTHH:mm")
    // convertDateTimeToIST("")
    ""
  );

  useEffect(() => {
    setMinDateTimeState(
      // minDate
      //   ? dayjs(minDate).format("YYYY-MM-DDTHH:mm")
      //   : today.format("YYYY-MM-DDTHH:mm")
      minDate ? convertDateTimeToIST(minDate) : convertDateTimeToIST("")
    );
    if (maxDate) {
      // maximumDate = dayjs(new Date(maxDate)).format("YYYY-MM-DDTHH:mm");
      maximumDate = convertDateTimeToIST(maxDate);
    }
  }, [minDate, maxDate, today]);

  const handleChange = (event) => {
    const value = event.target.value;
    setDate(value);
    // onDateChange(dayjs(value).format("YYYY-MM-DDTHH:mm"));

    // const formattedISTDate = convertDateTimeToIST(value);
    onDateChange(value);
  };

  return (
    <>
      <label>{title}</label>
      <input
        type="datetime-local"
        name="datePicker"
        value={date}
        min={minDateTimeState}
        max={maximumDate}
        onChange={handleChange}
        className="form-control"
        {...rest}
      />
    </>
  );
};

export default ShowDateTimePicker;

// import React, { useState } from "react";
// import dayjs, { Dayjs } from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// //
// let today = dayjs();
// let maximumDate = dayjs().add(365, "day");
// //
// const ShowDateTimePicker = ({
//   onDateChange,
//   curDate: defaultDate,
//   minDate,
//   maxDate,
//   title,
//   type,
//   ...rest
// }) => {
//   if(defaultDate === ""){
//     if(type === "start-date"){
//       defaultDate = today;
//     }else{
//       defaultDate = today.add(1, "day")
//     }
//   }

//   const [date, setDate] = useState(dayjs(defaultDate) || null);
//   try {
//     if(!minDate){
//       minDate = today;
//     }else{
//       minDate = dayjs(new Date(minDate));
//     }

//     if(maxDate) {
//       maximumDate = dayjs(new Date(maxDate));
//     }
//   } catch {}
//   //
//   const handleChange = (value) => {
//     setDate(value);
//     let date = value?.format("DD/MMM/YYYY HH:mm");
//     onDateChange(date);
//   };

//   //
//   return (
//     <>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DateTimePicker
//           label={title}
//           name="datePicker"
//           value={date}
//           minDate={minDate}
//           maxDate={maximumDate}
//           format="DD/MMM/YYYY HH:mm" //"DD/MMM/YYYY HH:mm"
//           onChange={(newValue) => {
//             handleChange(newValue);
//           }}
//           referenceDate={dayjs(today)}
//           className="form-control border-0"
//           {...rest}
//         />
//       </LocalizationProvider>
//     </>
//   );
// };

// export default ShowDateTimePicker;
