import React, { useState } from "react";
import ParticipantForm from "./ParticipantForm";
import IDProof from "../participantForm/IDProof";
import TimingCertificate from "../participantForm/TimingCertificate";
import EmergencyContact from "../participantForm/EmergencyContact";
import MedicalInformation from "../participantForm/MedicalInformation";
import AccordionCard from "../../controls/accordion/AccordionCard";

//
const ParticipantFormInfo = ({
  eventDetails,
  saveParticipantFormInfo,
  handleChangeTab,
}) => {
  let tempdataEntered = [false, false, false, false, false];
  const [activeTabNo, setActiveTabNo] = useState(0);
  const [dataEntered, setDataEntered] = useState(tempdataEntered);
  //
  const handleSave = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
    tempdataEntered = dataEntered;
    tempdataEntered[tabNo] = true;
    setDataEntered(() => tempdataEntered);
    //alert("handleSave-" + tabNo);
  };
  const handleSkip = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };
  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: "Participant Form",
        dataEntered: dataEntered[0],
        content: (
          <ParticipantForm
            eventDetails={eventDetails}
            onSave={() => handleSave(0)}
            onSkip={() => handleSkip(0)}
          />
        ),
      },
      {
        title: "ID Proof",
        dataEntered: dataEntered[1],
        content: (
          <IDProof
            eventDetails={eventDetails}
            onSave={() => handleSave(1)}
            onSkip={() => handleSkip(1)}
          />
        ),
      },
      {
        title: "Timing Certificate",
        dataEntered: dataEntered[2],
        content: (
          <TimingCertificate
            eventDetails={eventDetails}
            onSave={() => handleSave(2)}
            onSkip={() => handleSkip(2)}
          />
        ),
      },
      {
        title: "Emergency Contact",
        dataEntered: dataEntered[3],
        content: (
          <EmergencyContact
            eventDetails={eventDetails}
            onSave={() => handleSave(3)}
            onSkip={() => handleSkip(3)}
          />
        ),
      },
      {
        title: "Medical Information",
        dataEntered: dataEntered[4],
        content: (
          <MedicalInformation
            eventDetails={eventDetails}
            onSave={() => handleSave(4)}
            onSkip={() => handleSkip(4)}
          />
        ),
      },
    ],
  };

  //
  return (
    <>
      <AccordionCard config={config} />
      {/*    <ParticipantForm />
      <IDProof />
      <TimingCertificate />
      <EmergencyContact />
      <MedicalInformation /> */}
    </>
  );
};

export default ParticipantFormInfo;
