import React from "react";
import { useSearchParams } from "react-router-dom";
import BookingSuccess from "../../components/bookingResponse/BookingSuccess";
import BookingFail from "../../components/bookingResponse/BookingFail";
import * as comFun from "../../utils/commonFun";
import * as constants from "../../utils/Constants";

//
function BookingResponse() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = [];

  //clear store
  comFun.removeItem(constants.LOCAL_CART_EVENT);
  comFun.removeItem(constants.LOCAL_CART_USER_DETAILS);

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return (
    <div>
      {params.status === "success" ? (
        <BookingSuccess
          txnId={params.txnId}
          orderConfirmationId={params.orderConfirmationId}
          eventId={params.eventId}
          message={params.message}
        />
      ) : (
        <BookingFail
          txnId={params.txnId}
          eventId={params.eventId}
          message={params.message}
        />
      )}
    </div>
  );
}

export default BookingResponse;
