import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InputTextBox from "../controls/input/InputTextBox";
import * as validator from "../../utils/validations";
import {
  SendAnnouncementIndividual,
  SendAnnouncementToAllParticipants,
} from "../../services/announcementAPI";
import { GetOnGoingEventData } from "../../services/eventAPI";
import TinyEditor from "../controls/editor/TinyEditor";
import Button from "../controls/buttons/Button";
import OrganizerMaster from "../../pages/master/OrganizerMaster";
import Spinner from "../controls/spinner/Spinner";

let initialData = {
  subject: "",
  emailBody: "",
  eventId: "",
  individualEmails: "",
  errors: {},
  isValid: {},
};

//main funtion
const AnnouncementModel = () => {
  // declare start
  const navigate = useNavigate();
  //validition and input value
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, isValid } = inputValue;
  const userData = useSelector((loginData) => loginData.login.login);
  const [onGoingEvents, setOnGoingEvents] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [loading, setLoading] = useState(false); // Spinner state
  // declare end

  // functions/events/methods
  useEffect(() => {
    const getAllOnGoingEvent = async () => {
      try {
        const onGoingEventsResponse = await GetOnGoingEventData(
          userData?.login?.token
        );
        if (onGoingEventsResponse?.success === true) {
          const onGoingEventResponse = onGoingEventsResponse?.data;
          setOnGoingEvents(onGoingEventResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllOnGoingEvent();
  }, []); // Empty array to run effect only once on mount

  // Email validation function
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const makeAnnouncement = async () => {
    if (      
      !inputValue?.subject ||
      !inputValue?.emailBody
    ) {
      alert("Please enter valid input.");
      return;
    }
    if (inputValue?.audienceName === "individual") {
      if (inputValue?.individualEmails?.length === 0) {
        alert("Please enter atleast one email");
        return;
      }

      let emailSeparatedByComma = inputValue?.individualEmails?.trim().split(",");

      // Validate each email
      let invalidEmails = [];
      emailSeparatedByComma = emailSeparatedByComma.map((email) =>
        email.trim()
      );

      emailSeparatedByComma.forEach((email) => {
        if (!validateEmail(email)) {
          invalidEmails.push(email);
        }
      });

      if (invalidEmails.length > 0) {
        alert(`Invalid email(s) found: ${invalidEmails.join(", ")}`);
        return;
      }

      inputValue.individualEmails = emailSeparatedByComma;
      setLoading(true);

      const individualResponse = await SendAnnouncementIndividual(
        inputValue,
        userData?.login?.token
      );
      setLoading(false);

      if (individualResponse?.success) {
        alert(individualResponse?.message);
        navigate(-1);
        resetFields();
      } else if (individualResponse?.error) {
        alert(individualResponse?.message);
      }
    } else {
      if (inputValue?.eventId?.length === 0) {
        alert("Please select event");
        return;
      }

      setLoading(true); // Show spinner when sending starts
      inputValue.userId = userData.login.user.userId;
      const response = await SendAnnouncementToAllParticipants(
        inputValue,
        userData?.login?.token
      );
      setLoading(false);
      if (response?.success) {
        alert(response?.message);
        navigate(-1);
        resetFields();
      } else {
        alert(response?.error);
      }
    }
  };

  //checking validation
  const handleChange = (event) => {
    const { name, value } = event.target;
    //validation
    let result = IsValid(name, value);
    //setValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      isValid: { ...isValid, [name]: result.status },
    }));
  };

  const IsValid = (name, value) => {
    let status = false;
    // let val = false;
    let msg;

    if (validator.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "subject":
          status = validator.IsEmpty(value);
          if (!status) {
            msg = "Invalid " + value;
          }
        case "emailBody":
          status = validator.IsEmpty(value);
          if (!status) {
            msg = "Invalid " + value;
          }
          break;
        // case "individualEmails":
        //   status = validator.IsValidEmail(value);
        //   if (!status) {
        //     val = validator.IsValidEmail(value);
        //     if (!val) msg = "Please enter valid email. " + value;
        //   }
        //   break;
      }
    }

    return { status: status, message: msg };
  };

  const handleOnEventSelect = (event) => {
    const eventIdIs = event.target.value;
    setSelectedEvent(eventIdIs);
    inputValue.eventId = eventIdIs;
  };

  const handleInputChange = (name, data) => {
    switch (name) {
      case "emailBody":
        setInputValue((prev) => ({
          ...prev,
          emailBody: data,
          ["isValid"]: { ...prev.isValid, emailBody: true },
        }));
        break;

      default:
        setInputValue((prev) => ({
          ...prev,
          [name]: data,
        }));
        break;
    }
  };

  const resetFields = () => {
    setInputValue({
      ...inputValue,
      selectedEvent: "",
      subject: "",
      emailBody: "",
      individualEmails: "",
      errors: {},
      isValid: {},
    });
  };

  //main return
  return (
    <>
      <OrganizerMaster>
        <Link
          to={"/my-announcement"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <h3 className=" font-weight-semibold mx-2 my-3 col-3">
            {/* <i className="bi bi-chevron-left me-2 fw-bold" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" />
            </svg>
            Send Announcement
          </h3>
        </Link>
        <div class="m-2 modal-body text-start mt-5">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Select Recipient<span className="text-danger">*</span>
          </label>
          <select
            className="form-select"
            aria-label="Default select example"
            value={inputValue?.audienceName}
            onChange={(e) =>
              setInputValue((prev) => ({
                ...prev,
                audienceName: e.target.value,
              }))
            }
          >
            <option value="">Send Announcement to</option>
            <option value="eventSpecific">Event Specific</option>
            <option value="individual">Send Individual </option>
          </select>
          {inputValue.audienceName === "eventSpecific" && (
            <>
              <label for="exampleFormControlInput1" className="form-label mt-5">
                Select Events<span class="text-danger">*</span>
              </label>
              <select
                className="form-select "
                aria-label="Default select example"
                value={selectedEvent}
                onChange={handleOnEventSelect}
              >
                <option value="">Select Event</option>

                {onGoingEvents?.map((currentValue) => (
                  <option
                    key={currentValue?.eventId}
                    value={currentValue?.eventId}
                  >
                    {currentValue?.eventName}
                  </option>
                ))}
              </select>
            </>
          )}

          {inputValue?.audienceName === "individual" && (
            <div className="mb-3 mt-5">
              <label htmlFor="individualEmails" className="form-label">
                Individual Emails (Comma Separated)
                <span className="text-danger">*</span>
              </label>
              <InputTextBox
                id="individualEmails"
                name="individualEmails"
                placeholder="Enter individual email addresses"
                value={inputValue?.individualEmails}
                // isValid={inputValue?.isValid?.individualEmails}
                // errorMsg={inputValue?.errors?.individualEmails}
                onChange={handleChange}
              />
            </div>
          )}

          <div class="mb-3 mt-5 ">
            <label for="exampleFormControlInput1" class="form-label ">
              Subject<span class="text-danger">*</span>
            </label>
            <InputTextBox
              id="subject"
              name="subject"
              placeholder="Subject of an announcement."
              isValid={inputValue?.subject}
              errorMsg={inputValue?.subject}
              onChange={handleChange}
            />
          </div>

          <div className="my-1 mt-5 mb-4">
            <div className="form-floating">
              <div></div>
              <TinyEditor
                scriptLoading={{ async: true }}
                name="emailBody"
                initialValue={inputValue.emailBody}
                value={inputValue.emailBody}
                handleInput={(data) => handleInputChange("emailBody", data)}
                className="text-gray-400 text-sm whitespace-nowrap ml-3 max-md:ml-2.5"
                titleEditor={" Announcement Description"}
                maxTextLength={50000}
                isMandatory={true}
              />
            </div>
          </div>
          <div class="mb-3 text-center w-100">
            <Button
              onClick={makeAnnouncement}
              style={{ padding: "15px", fontSize: "15px" }}
            >
              Send Announcement
            </Button>
          </div>
        </div>
        {loading && (         
                  <Spinner />             
        )}
      </OrganizerMaster>
    </>
  );
};

export default AnnouncementModel;
