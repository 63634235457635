import { useFetchPostRequestWithToken } from "./api";

export const GenerateOTP = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/send-otp`;

  const body = JSON.stringify({
    userData: {
      userId: inputValue.userId,
      mobile: "91" + inputValue.mobile,
      // email: inputValue.email,
      fullName: inputValue.fullName,
      verificationType: inputValue.verificationType,
    },
  });
  const result = await useFetchPostRequestWithToken(apiURL, body, token);
  return result;
};

export const ValidateOTP = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/verify-otp`;

  const body = JSON.stringify({
    // userData: {
    userId: inputValue.userId,
    otp: inputValue.otp,
    email: inputValue.email,
    mobile: inputValue.mobile,
    // },
  });

  const result = await useFetchPostRequestWithToken(apiURL, body, token);
  return result;
};

export const ReSendInvoice = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/resend-invoice`;

  const body = JSON.stringify({
    eventId: inputValue.eventId,
    orderConfirmationId: inputValue.orderConfirmationId,
    participantId: inputValue.participantId,
    fullName: inputValue.fullName,
    email: inputValue.email,
    mobile: inputValue.mobile,
    remark: inputValue.remark,
  });

  const result = await useFetchPostRequestWithToken(apiURL, body, token);
  return result;
};
