import React, { useState, useEffect } from "react";
import Icon from "../icons/Icon";

/* const items = [
  { header: "Dashboard", text: "/dashboard", iconName: "dashboard" },
  { header: "Events", text: "/events", iconName: "events" },
  { header: "Announcement", text: "/announcement", iconName: "accouncement" },
  { header: "Reconcillation", text: "/reconcillation", iconName: "reconcillation" },
  { header: "Notification", text: "/notification", iconName: "notification" },
  { header: "KYC", text: "/kyc", iconName: "accouncement" },
  { header: "Setting", text: "/setting", iconName: "setting" },
  { header: "Logout", text: "/logout", iconName: "logout" },
];
//or
  const items = [
    { header: "Event Details", content: <EventDetails /> },
    { header: "Ticket Details", content: <EventDetails /> },
    { header: "Discount Details", content: <EventDetails /> },
    { header: "Participant Details", content: <EventDetails /> },
  ];
*/

export const Accordion = ({ items, children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (nextIndex) => {
    // setIsActive(!isActive);
    if (activeIndex == nextIndex) {
      setActiveIndex(() => nextIndex + 1);
    } else {
      setActiveIndex((prevIndex) => {
        if (prevIndex === nextIndex) {
          return prevIndex;
        } else {
          return nextIndex;
        }
      });
    }
  };

  const renderItems = items?.map((item, index) => {
    const isActive = index === activeIndex;
    // alert(isActive);
    console.log(isActive);
    return (
      <>
        <div
          className={` accordion-item collapsed ${isActive ? "collapsed" : ""}`}
          onClick={() => handleClick(index)}
          key={index}
        >
          <div class="accordion-header">
            <button
              className={` accordion-button ${
                isActive ? "collapsed show" : ""
              }`}
              type="button"
            >
              <div class="d-flex justify-content-center align-items-center accordion-detail">
                {item?.iconName && (
                  <div className="accordion-icon me-2">
                    <Icon iconName={item.iconName} />
                  </div>
                )}
                <h1 className="mt-2"> {item?.header}</h1>
              </div>
            </button>
          </div>
          {isActive && item.text && (
            <div
              className={` accordion-collapse p-2 pl-4 accordion-detail collapse text-start  ${
                isActive ? "show" : ""
              }`}
              dangerouslySetInnerHTML={{
                __html: item.text,
              }}
            />
          )}{" "}
          {/* send text or children*/}
          {children && (
            <div
              className={` accordion-collapse p-2 accordion-detail collapse  ${
                isActive ? "show" : ""
              }`}
            >
              {children}
            </div>
          )}
          {item.content && (
            <div
              className={` accordion-collapse p-2 accordion-detail collapse  ${
                isActive ? "show" : ""
              }`}
            >
              {item.content}
            </div>
          )}
        </div>
      </>
    );
  });

  return (
    <>
      <div class="accordion accordion-flush border-top border-bottom mb-5">
        {renderItems}
      </div>
    </>
  );
};

export default Accordion;
