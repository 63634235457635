import React, { useState, useCallback, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

const TinyEditor = ({
  handleInput,
  initialValue,
  maxTextLength,
  titleEditor,
  isMandatory,
  prop,
}) => {
  //const initialText = 'Event Description....';
  const [value, setValue] = useState(initialValue);
  const limit = maxTextLength ?? 2000;
  const [remainingChars, setRemainingChars] = useState(limit);
  const quillRef = useRef(null);

  // const handleChange = (newText) => {
  //   const lines = newText.split("\n").length;
  //   if (newText.length <= sizeLimit) {
  //     setText(newText);
  //     handleInput(newText);
  //     setLength(newText.length);
  //   }
  // };

  const handleChange = useCallback(
    (content, delta, source, editor) => {
      const text = editor.getText().trim(); // Get the plain text content and trim whitespace
      const textLength = text.length;

      if (textLength > limit) {
        // Get the current Quill editor instance
        const quill = quillRef.current.getEditor();

        // Calculate the position from where to delete the extra characters
        const excessLength = textLength - limit;

        // Prevent React Quill from updating the value to avoid an infinite loop
        quill.deleteText(limit, excessLength, "silent");

        // Get the updated content after truncating
        const updatedContent = quill.root.innerHTML;

        // Update the state with the truncated content
        setValue(updatedContent);
        handleInput(updatedContent);
        setRemainingChars(0); // No remaining characters when the limit is reached
      } else {
        setValue(content);
        handleInput(content);
        setRemainingChars(limit - textLength);
      }
    },
    [limit]
  );

  // const handleKeyPress = (event) => {
  //   // Prevent further typing if the maximum length is reached
  //   if (text.length >= limit && event.charCode !== 8) {
  //     event.preventDefault();
  //   }
  // };

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],

        ["link"],
        ["clean"], // remove formatting button
      ],
    },
    clipboard: {
      matchVisual: false,
    },
    keyboard: {
      bindings: {
        // Custom keyboard bindings, if any
      },
    },
  };

  return (
    <>
      <div>
        <label for="exampleInputEmail1" class="form-label">
          {titleEditor}
          {isMandatory ? <span className="text-red-600">*</span> : <></>}
        </label>
        <label for="exampleInputEmail1" class="form-label float-end">
          {remainingChars} / {maxTextLength}
        </label>
      </div>
      <div className="editor-container">
        <ReactQuill
          ref={quillRef}
          modules={modules}
          theme="snow"
          value={value}
          onChange={handleChange}
          // onKeyPress={handleKeyPress}

          {...prop}
        />
      </div>
    </>
  );
};

export default TinyEditor;
