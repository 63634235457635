import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrganizerMaster from "../master/OrganizerMaster";
import DashboardCard from "../../components/dashboard/DashboardCard";
import EventTable from "../../components/dashboard/eventTable/EventTable";
import ShowLoginMsg from "../../components/login/ShowLoginMsg";
import { GetAllCountByUserId } from "../../services/eventAPI.jsx";
import Spinner from "../../components/controls/spinner/Spinner.jsx";

//
const OrganizerDashboard = () => {
  //
  const userData = useSelector((loginData) => loginData.login.login);
  const [allCounts, setAllCounts] = useState(null);
  const [selectedTitleId, setSelectedTitleId] = useState(1);
  const [loading, setLoading] = useState(true); // loading spinner

  //
  useEffect(() => {
    const getAllEvents = async () => {
      try {        
        if (userData?.login?.token !== undefined) {         
        const allCountResponse = await GetAllCountByUserId(
          userData?.login?.token
        );

        if (allCountResponse?.success === true) {
          if (allCountResponse?.data) {
            const resData = allCountResponse?.data;
            let data = {
              totalEvents: resData?.totalEvents,
              totalSales: resData?.totalSales,
              totalParticipants: resData?.totalParticipants,
            };
            //set data
            /*  allCountResponse?.data.map((item, index) => {
              data[item.ctype] = item.count;
            }); */

            setAllCounts(data);
            setLoading(false);
          }
        }
      }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, [userData]);

  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  } else {
    return (
      <OrganizerMaster>
        <div class="col">
          <h3 class="font-weight-semibold">Dashboard</h3>
        </div>
        <ShowLoginMsg />
      </OrganizerMaster>
    );
  }

  //
  let loadDashboardCard = [
    { id: 1, title: "Total Events", count: allCounts?.totalEvents, icon: "1" },
    { id: 2, title: "Total Sales", count: allCounts?.totalSales, icon: "6" },
    {
      id: 3,
      title: "Total Participants",
      count: allCounts?.totalParticipants,
      icon: "2",
    },
    //{ title: "Total Earnings", count: "1234567", icon: "3" },
    //{ title: "Total Refund", count: "1234567", icon: "1" },
    //{ title: "Total Registrations", count: "1234567", icon: "2" },
  ];
  const handleCardClick = (id) => {
    setSelectedTitleId(id);
  };
  //main return
  return (
    <OrganizerMaster>
      <div className="my-10">
        <div className="col-12 main-contain">
          <div class="row mb-3">
            <div class="col">
              <h3 class="font-weight-semibold">Dashboard</h3>
            </div>
            {/*   <div class="col">
              <div class="input-group year-select float-end">
                <select class="form-select" id="inputGroupSelect01">
                  <option selected="">Year</option>
                  <option value="1">2023</option>
                  <option value="2">2024</option>
                  <option value="3">2025</option>
                </select>
              </div>
            </div> */}
          </div>
          {loading ? (
        <Spinner/>
        ) : (
          <>
          <div class="row mb-3">
            <div className="d-flex col">
              {loadDashboardCard &&
                loadDashboardCard.map((item, i) => {
                  return (
                    <div
                      className="d-flex col-3"
                      /*  className={`d-flex col-3 ${
                        selectedTitleId === item?.id
                          ? " bg-info border border-info rounded"
                          : ""
                      }`} 
                      onClick={() => handleCardClick(item?.id)}*/
                    >
                      <DashboardCard key={i} card={item} />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="d-flex flex-column mb-3">
            <EventTable />
          </div>
          </>
          )}
        </div>
      </div>
    </OrganizerMaster>
  );
};

export default OrganizerDashboard;
