import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import AccordionCard from "../../controls/accordion/AccordionCard";
import Table from "../../controls/table/Table";
import {
  GetEventSalesByUserId,
  GetAllParticipantsByEventId,
  GetAllParticipantsDetailsByEventId,
} from "../../../services/eventAPI.jsx";
import { Link } from "react-router-dom";
import ViewParticipantDetails from "../ViewParticipantDetails.jsx";
import Button from "../../controls/buttons/Button";
import ExportToCSV from "../../../utils/ExportToCSV";
import { convertDateTimeToISTForUI } from "../../../utils/DateTimeUtility.jsx";

//main fun
const ViewSalesDetails = () => {
  //variables
  const { eventId, eventName } = useParams();
  const userData = useSelector((loginData) => loginData.login.login);
  const [allEvents, setAllEvents] = useState([]);
  const [participantCount, setParticipantCount] = useState(0);

  //
  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const allEventsResponse = await GetEventSalesByUserId(
          userData.login.token,
          eventId
        );
        if (allEventsResponse?.success === true) {
          setAllEvents(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, []);

  //configration
  const config = {
    activeTabNo: 0,
    data: [
      {
        title: "Event Details",
        content: <EventDetails eventId={eventId} allEvents={allEvents} />,
      },
      {
        title: "Ticket Details",
        content: <TicketDetails eventId={eventId} allEvents={allEvents} />,
      },
      {
        title: "Discount Details",
        content: <DiscountDetails eventId={eventId} allEvents={allEvents} />,
      },
      {
        title: "Participant Details",
        content: <ParticipantDetails eventId={eventId} allEvents={allEvents} />,
      },
    ],
  };

  //main return
  return (
    <>
      <div>
        <Link
          to="/dashboard"
          style={{ textDecoration: "none", color: "black" }}
        >
          <h3 class=" ml-1 font-weight-semibold">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 -960 960 960"
              width="20"
            >
              <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"></path>
            </svg>
            {eventName}
          </h3>
        </Link>{" "}
      </div>
      <AccordionCard config={config} />
    </>
  );
};

const EventDetails = ({ eventId, allEvents, refUserId }) => {
  let totalSales = 0;
  let evetDtails = {};

  if (allEvents?.length > 0) {
    evetDtails = allEvents[0];
    //if (allEvents) {
    allEvents?.map((item) => {
      totalSales = totalSales + item.totalSales;
    });
  } else if (typeof allEvents == "object") {
    evetDtails = allEvents;
    totalSales = totalSales + allEvents.totalSales;
  }

  const renderStatus = (status) => {
    return status === "act" ? "Active" : status === "iact" ? "Cancelled" : "";
  };

  //m/d return
  return (
    <>
      <div>
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>Event Id</td>
              <td className="font-weight-normal">{evetDtails?.eventId}</td>
            </tr>
            <tr>
              <td>Event Name</td>
              <td className="font-weight-normal">{evetDtails?.eventName}</td>
            </tr>
            <tr>
              <td>Event Category</td>
              <td className="font-weight-bold">{evetDtails?.categoryName}</td>
            </tr>
            <tr>
              <td>Event Start Date</td>
              <td className="font-weight-normal">
                {/* {convertDateTimeToISTForUI(evetDtails?.eventStartDate2)} */}
                {evetDtails?.eventStartDate2}
              </td>
            </tr>
            <tr>
              <td>Event End Date</td>
              <td className="font-weight-normal">
                {evetDtails?.eventEndDate2}
              </td>
            </tr>
            {/* <tr>
              <td>Event Total Sales</td>
              <td className="font-weight-bold">{totalSales}</td>
            </tr> */}

            <tr>
              <td>Event Status</td>
              <td className="font-weight-bold">
                {renderStatus(evetDtails?.eventStatus)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const TicketDetails = ({ eventId, allEvents }) => {
  let ticketData = allEvents[0]?.ticketData;
  //m/d return
  return (
    <>
      <div>
        <table class="table table-bordered">
          <thead class="thead-light">
            <tr>
              <th scope="col">Ticket Name</th>
              <th scope="col">Ticket Type</th>
              <th scope="col">Quantity</th>
              <th scope="col">Sale(INR)</th>
            </tr>
          </thead>
          <tbody>
            {ticketData?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.ticketName}</td>
                  <td>{item?.ticketType}</td>
                  <td>{item?.ticketCount}</td>
                  <td className="font-weight-bold">
                    {Number(item?.totalSales).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const DiscountDetails = ({ eventId, allEvents }) => {
  let discountData = allEvents[0]?.discountData;
  //m/d return
  return (
    <>
      <div>
        {discountData?.length > 0 ? (
          <table class="table table-bordered">
            <thead class="thead-light">
              <tr>
                <th scope="col">Discount Name</th>
                <th scope="col">Quantity</th>
                <th scope="col">Amount(INR)</th>
              </tr>
            </thead>
            <tbody>
              {discountData?.map((item, index) => {
                return (
                  <>
                    {item?.discountName && (
                      <tr>
                        <td>{item?.discountName}</td>
                        <td>{item?.totalSalesCount}</td>
                        <td className="font-weight-bold">
                          {Number(item?.totalSales).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>No discount coupon has been applied.</p>
        )}
      </div>
    </>
  );
};

const ParticipantDetails = ({ eventId, allEvents }) => {
  //
  const [getParticipantsData, setGetParticipantsData] = useState([]);
  const userData = useSelector((loginData) => loginData.login.login);
  const [showData, setShowData] = useState(1);
  const [pData, setGetPData] = useState([]);

  //
  useEffect(() => {
    const getAllEvents = async () => {
      try {
        const allEventsResponse = await GetAllParticipantsByEventId(
          userData.login.token,
          eventId
        );

        if (allEventsResponse?.success === true) {
          setGetParticipantsData(allEventsResponse?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllEvents();
  }, []);

  //configration
  const config = {
    //tableTitle: "All Events",
    data: getParticipantsData,
    displayRecord: 10,
    columns: [
      {
        title: "Name ",
        titleClass: " ",
        dataClass: "text-left",
        dataField: "fullName",
        isSort: false,
        sortByValue: (data) => data.fullName,
      },
      {
        title: "Mobile",
        titleClass: "",
        dataClass: "text-left",
        dataField: "mobile",
        isSort: false,
        sortByValue: (data) => data.mobile,
      },
      {
        title: "Email",
        titleClass: "",
        dataClass: "",
        dataField: "email",
        isSort: false,
        sortByValue: (data) => data.email,
      },
      {
        title: "Ticket Type",
        titleClass: "",
        dataClass: "text-left",
        dataField: "ticketType",
        isSort: false,
        sortByValue: (data) => data.ticketType,
      },
      {
        title: "Transaction Id",
        titleClass: "",
        dataClass: "text-left",
        dataField: "txnId",
        isSort: false,
        sortByValue: (data) => data.txnId,
      },
      {
        title: "View",
        isLink: true,
        render: (item) => (
          <div class="text-start">
            <a
              class="btn-link btn align-middle p-0"
              href="#"
              onClick={() => handleViewMore(item)}
            >
              View
            </a>
          </div>
        ),
        titleClass: "",
        dataClass: "text-left",
      },
    ],
  };

  const showPList = () => {
    setShowData(() => 1);
  };

  const handleViewMore = (item) => {
    setShowData(() => 2);
    setGetPData(() => item);
  };
  const handleExportToCSV = () => {
    const getAllEvents = async () => {
      try {
        const allEventsResponse = await GetAllParticipantsDetailsByEventId(
          userData.login.token,
          eventId
        );

        if (allEventsResponse?.success === true) {
          let getParticipantsDetailsData = allEventsResponse?.data;
          if (getParticipantsDetailsData?.length > 0) {

            // Filter out the fields to exclude
        const filteredEvents = getParticipantsDetailsData.map((event) => {
          const { pg_outgo, payment_from_pg_to_host, 
            host_earning, host_total_tax_liab, ...rest } = event;
          return rest;
        });
            var itemsFormatted = filteredEvents;
            var headers = {};
            for (let key in filteredEvents[0]) {
              headers[key] = key;
            }

            var fileTitle = eventId + "_data"; //
            ExportToCSV(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
          } else {
            alert("No record");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllEvents();
  };

  //m/d return
  return (
    <>
      <div>
        {showData == 1 && (
          <>
            <div style={{ width: "100%" }}>
              <Button
                primary
                className="float-right"
                style={{ float: "right" }}
                onClick={() => {
                  handleExportToCSV();
                }}
              >
                Download - Participants Data
              </Button>
            </div>
            <div className="clearBoth"></div>
            <div>
              <Table config={config} />
            </div>
          </>
        )}
        {showData == 2 && (
          <ViewParticipantDetails
            item={pData}
            showPList={showPList}
            userData={userData}
          />
        )}
      </div>
    </>
  );
};

export default ViewSalesDetails;
