import { useFetchPostRequestWithToken } from "./api";
import { useFetchGetRequestWithToken } from "./api";

export const GetAccountSettingOfParticipant = async (userId, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/get-account-setting/${userId}`;
  const result = await useFetchGetRequestWithToken(apiURL, token);

  return result;
};

export const UpdateAccountSettingOfParticipant = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_USER_MS}auth/update-account-setting`;

  const body = JSON.stringify({
    userId: inputValue.userId,
    newAccountSettingData: {
      isEmailNotification: inputValue.isEmailNotification,
      isMobileNotification: inputValue.isMobileNotification,
      isAccountActive: inputValue.isAccountActive,
    },
  });
  const result = await useFetchPostRequestWithToken(apiURL, body, token);
  return result;
};

export default GetAccountSettingOfParticipant;
