import { React, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GetParticipantProfileData,
  UpdateParticipantInfo,
  UpdateProfilePic,
} from "../../services/userAPI";
import * as Call from "../../utils/validations";
import StateDropdown from "../controls/dropdown/StateDropdown";
import FileUploadSingle from "../controls/fileUploader/FileUploadSingle";
import InputTextBox from "../controls/input/InputTextBox";
import OTPVerification from "../popup/OTPVerification";
import { GenerateOTP } from "../../services/notificationAPI";
import { VERIFICATION_TYPE_MOBILE } from "../../utils/Messages";
import CityByStateIdDropdown from "../controls/dropdown/CityByStateIdDropdown";
import Spinner from "../controls/spinner/Spinner";
import { getLocalData, saveToLocal } from "../../utils/commonFun";
import { LOCAL_STORAGE_USER } from "../../utils/Constants";

let initialData = {
  profilePicUrl: "",
  userIdProof: "",
  gender: "",
  mobile: "",
  fullName: "",
  email: "",
  userId: "",
  clubName: "",
  companyName: "",
  tShirtSize: "",
  bloodGroup: "",
  emergencyContactName: "",
  emergencyContactRelation: "",
  emergencyContactNumber: "",
  addressLine1: "",
  addressLine2: "",
  landmark: "",
  country: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  pincode: "",
  dob: "",
  verificationType: VERIFICATION_TYPE_MOBILE,
  errors: {},
  IsValid: {},
};

//main function
const MyProfile = () => {
  //declare start
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  const [updatedProfilePic, setUpdatedProfilePic] = useState({
    preview: "",
    raw: "",
  });
  const [showOTPView, setShowOTPView] = useState(false);
  const userDataLocal = useSelector((loginData) => loginData.login.login);
  const [loading, setLoading] = useState(false); // Spinner state
  const MAX_FILE_SIZE_MB = 1;
  //declare end

  //functions/events/methods
  const handleStateChange = (event) => {
    inputValue.state = event.target.options[event.target.selectedIndex].text;
    inputValue.stateId = event.target.value;
  };

  const handleCityChange = (event) => {
    inputValue.city = event.target.options[event.target.selectedIndex].text;
    inputValue.cityId = event.target.value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      // if (value == "") {
      //set empty value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
      // }
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "fullName":
          if (Call.IsEmpty(value)) {
            msg = "Please enter full name";
          } else {
            val = Call.IsValidCharWithSpace(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "email":
          if (Call.IsEmpty(value)) {
            msg = "Please enter email";
          } else {
            val = Call.IsValidEmail(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "mobile":
          if (Call.IsEmpty(value)) {
            msg = "Please enter contact number.";
          } else {
            val = Call.IsValidMobileNo(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        // case "userIdProof":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please upload id proof";
        //   }
        //   break;

        // case "city":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please enter city";
        //   } else {
        //     val = Call.IsValidEventName(value);
        //     if (!val) msg = "Invalid " + value;
        //   }
        //   break;

        // case "dob":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please enter date of birth";
        //   }
        //   break;

        // case "clubName":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please enter Running club name";
        //   } else {
        //     val = Call.IsValidCharWithSpace(value);
        //     if (!val) msg = "Invalid " + value;
        //   }
        //   break;

        // case "companyName":
        //   if (Call.IsEmpty(value)) {
        //     msg = "Please enter company name";
        //   } else {
        //     val = Call.IsValidCharWithSpace(value);
        //     if (!val) msg = "Invalid " + value;
        //   }
        //   break;

        case "pincode":
          val = Call.IsValidPinCode(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 6 digit pincode ";
          }
          break;

        // case "addressLine1":
        //   val = Call.IsValidCharNumWithSpace(value);
        //   if (!val) msg = "Invalid " + value;
        //   break;

        // case "addressLine2":
        //   val = Call.IsValidCharNumWithSpace(value);
        //   if (!val) msg = "Invalid " + value;
        //   break;

        // case "landmark":
        //   val = Call.IsValidCharNumWithSpace(value);
        //   if (!val) msg = "Invalid " + value;
        //   break;

        // case "emergencyContactName":
        //   val = Call.IsValidCharWithSpace(value);
        //   if (!val) msg = "Invalid " + value;
        //   break;

        case "emergencyContactNumber":
          val = Call.IsValidMobileNo(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 10 digit number ";
          }
          break;

        default:
          break;
      }
    }

    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));

    return val;
  };

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        inputValue.userId = userDataLocal.login.user.userId;
        setLoading(true); // Show spinner when sending starts
        const profileData = await GetParticipantProfileData(
          inputValue?.userId,
          userDataLocal?.login?.token
        );

        if (typeof profileData !== undefined && profileData != null) {
          initialData = { ...initialData, ...profileData.data.userProfile };
          initialData.fullName = profileData.data.user.fullName;
          initialData.profilePicUrl = profileData.data.user.profilePicUrl;
          initialData.email = profileData.data.user.email;
          if (profileData?.data?.user?.mobile?.length === 12) {
            initialData.mobile = profileData.data.user.mobile.substring(2, 12);
          } else {
            initialData.mobile = profileData?.data?.user?.mobile;
          }
          initialData.dob = profileData.data.user.dob;
          setInputValue(initialData);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getUserProfile();
  }, [userDataLocal?.login]);

  const updateUserProfileDetails = async (e) => {
    e.preventDefault();

    // Iterate over each key in the object
    for (const key in inputValue) {
      // Check if the value is null, undefined, or an empty string
      if (
        inputValue[key] === null ||
        inputValue[key] === undefined ||
        inputValue[key] === ""
      ) {
        // Remove the key from the object
        inputValue[key] = null;
      }
    }
    setLoading(true);
    const response = await UpdateParticipantInfo(
      inputValue,
      userDataLocal?.login?.token
    );

    if (response?.success) {
      /**
       * We are updating user info in storage, so that while booking
       * ticket updated info will reflect in check out form.
       */

      let localUserData = getLocalData(LOCAL_STORAGE_USER);
      if (typeof localUserData !== "object") {
        localUserData = JSON.parse(localUserData);
      }

      localUserData.user = response?.data?.user;
      localUserData.userProfile = response?.data?.userProfile;
      localUserData = JSON.stringify(localUserData);
      saveToLocal(LOCAL_STORAGE_USER, localUserData);
      setLoading(false);
      alert(response?.message);
    } else {
      alert(response?.message);
    }
  };

  const handleIdProofFile = (file) => {
    inputValue.userIdProof = file;
  };

  // const handleGenderSelection = (e) => {
  //   e.preventDefault();
  //   inputValue.gender = e.target.value;
  // };

  const handleProfilePicChange = async (e) => {
    if (e.target.files.length) {
      if (e.target.files[0].size < MAX_FILE_SIZE_MB * 1024 * 1024) {
        setUpdatedProfilePic({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
        inputValue.profilePicUrl = URL.createObjectURL(e.target.files[0]);
      } else {
        alert("Please select photo less than 1 MB");
      }
    }
  };

  const handleUploadProfilePic = async (e) => {
    e.preventDefault();    
    if (updatedProfilePic?.raw) {
      setLoading(true);
      const profilePicResponse = await UpdateProfilePic(
        inputValue,
        updatedProfilePic,
        userDataLocal.login.token
      );

      if (profilePicResponse?.success) {
        setLoading(false);
        let localUserData = getLocalData(LOCAL_STORAGE_USER);
        if (typeof localUserData !== "object") {
          localUserData = JSON.parse(localUserData);
        }

        localUserData.user.profilePicUrl =
          profilePicResponse.data.profilePicUrl;
        localUserData = JSON.stringify(localUserData);
        saveToLocal(LOCAL_STORAGE_USER, localUserData);

        alert("Profile picture updated successfully!");
      } else {
        setLoading(false);
        alert(profilePicResponse?.error);
      }
    } else {
      alert("Please select picture to upload.");
    }
  };

  const generateOTPService = async (e) => {
    e.preventDefault();
    inputValue.userId = userDataLocal.login.user.userId;
    inputValue.fullName = userDataLocal.login.user.fullName;

    if (IsValid.mobile) {
      setLoading(true);
      const result = await GenerateOTP(inputValue, userDataLocal.login.token);
      if (result?.success) {
        setLoading(false);
        setShowOTPView(true);
        setInputValue((prev) => ({
          ...prev,
          errors: { ...errors, ["mobile"]: "" },
          IsValid: { ...IsValid, ["mobile"]: true },
        }));
      } else {
        alert(result?.message);
        setLoading(false);
      }
    } else {
      alert("Please enter correct mobile number.");
    }
  };

  const onDismiss = () => {
    setShowOTPView(false);
  };

  //main return
  return (
    <>
      {/* Basic Profile */}
      <div class="d-flex flex-column mb-3">
        <div class="p-2 mt-3">
          <div class="border rounded shadow-sm">
            <h1 class="p-3 text-start bg-secondary-subtle m-0 border-bottom border-secondary">
              My Profile
            </h1>
            <div class="row mx-3_1 px-1 mt-4">
              <div class="col-12 col-md-2 justify-content-center">
                <div class=" position-relative mr-5 mt-5">
                  {/* <img
                    src={inputValue.profilePicUrl}
                    style={{ maxHeight: "96px", maxWidth: "96px" }}
                  />
                  <label htmlFor="profilePicInput" class="pencil-icon mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.0"
                      width="20.000000pt"
                      height="18.000000pt"
                      viewBox="0 0 30.000000 30.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path d="M204 255 c-18 -14 -18 -15 9 -41 16 -14 29 -24 30 -23 2 2 9 12 16 22 12 15 11 20 -4 37 -22 24 -25 24 -51 5z" />
                        <path d="M112 167 c-55 -56 -62 -89 -19 -101 18 -5 33 6 74 52 l51 57 -21 23 c-11 12 -23 22 -27 22 -3 0 -29 -24 -58 -53z" />
                        <path d="M37 63 c-4 -3 -7 -12 -7 -20 0 -15 26 -18 34 -4 7 11 -18 33 -27 24z" />
                      </g>
                    </svg>
                  </label>
                  <InputTextBox
                    type="file"
                    id="profilePicInput"
                    style={{ display: "none" }}
                    onChange={handleProfilePicChange}
                  />

                  <button
                    type="button"
                    class=" btn-outline-dark w-80 btn align-middle"
                    style={{ height: "40px" }}
                    onClick={handleProfilePicChange}
                  >
                    Update
                  </button> */}

                  <label htmlFor="profilePicInput">
                    {inputValue?.profilePicUrl ? (
                      <img
                        alt="photo"
                        src={inputValue.profilePicUrl}
                        style={{ maxHeight: "128px", maxWidth: "128px" }}
                      />
                    ) : (
                      <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                          <i className="fas fa-circle fa-stack-2x" />
                          <i className="fas fa-store fa-stack-1x fa-inverse" />
                        </span>
                        <h5 className="text-center">Upload your photo</h5>
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    id="profilePicInput"
                    style={{ display: "none" }}
                    onChange={handleProfilePicChange}
                  />
                  <br />
                  <button className="mt-3" onClick={handleUploadProfilePic}>
                    Upload
                  </button>
                </div>
              </div>
              <div class="col-12 col-md-10 text-start">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        Full Name
                      </label>
                      <InputTextBox
                        type="text"
                        class="form-control"
                        id="fullName"
                        name="fullName"
                        placeholder="Full Name"
                        value={inputValue.fullName}
                        onChange={handleChange}
                        isValid={inputValue?.IsValid?.fullName}
                        errorMsg={inputValue?.errors?.fullName}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        Email address
                      </label>
                      <InputTextBox
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name@example.com"
                        value={inputValue.email}
                        onChange={handleChange}
                        disabled={true}
                        isValid={inputValue?.IsValid?.email}
                        errorMsg={inputValue?.errors?.email}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        Contact No
                      </label>
                      <div className="input-group mb-3">
                        <span className="input-group-text">+91</span>
                        <input
                          type="text"
                          className={`form-control ${
                            inputValue.IsValid.mobile === false
                              ? "is-invalid"
                              : inputValue.IsValid.mobile === true
                              ? "is-valid"
                              : ""
                          }`}
                          id="exampleFormControlInput1"
                          name="mobile"
                          placeholder="Contact Number"
                          maxLength="10"
                          value={inputValue?.mobile}
                          onChange={handleChange}
                          // isValid={inputValue?.IsValid?.mobile}
                          // errorMsg={inputValue?.errors?.mobile}
                        />
                        <span
                          className="input-group-text verify-button"
                          onClick={generateOTPService}
                        >
                          Verify
                        </span>
                        {inputValue?.errors?.mobile && (
                          <div className="invalid-feedback">
                            {inputValue?.errors?.mobile}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <FileUploadSingle
                        getFileDetail={handleIdProofFile}
                        title={
                          inputValue.userIdProof ? (
                            <>
                              Upload Id Proof <span class="text-danger">*</span>
                            </>
                          ) : (
                            "Identity Proof"
                          )
                        }
                      >
                        fileData
                      </FileUploadSingle>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        Date of Birth
                      </label>
                      <InputTextBox
                        type="date"
                        class="form-control"
                        id="dob"
                        name="dob"
                        value={inputValue.dob}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      <label for="exampleFormControlInput1" class="form-label">
                        Gender
                      </label>
                      <select
                        id="genderdropdown"
                        name="gender"
                        className="form-select"
                        onChange={handleChange}
                        value={inputValue.gender}
                      >
                        <option title="Select Gender">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="mb-3 p-2">
                      {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* General Information */}
            <div class=" rounded shadow-sm">
              <h1 class="p-3 text-start bg-secondary-subtle m-0 border-bottom border-secondary">
                General Information
              </h1>
              <div class="row mx-3_1 px-1 mt-4">
                <div class="col-12 col-md-12 text-start">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Club Name
                        </label>
                        <InputTextBox
                          id="clubName"
                          name="clubName"
                          type="text"
                          placeholder="Please enter Club Name"
                          // isValid={inputValue?.IsValid?.clubName}
                          // errorMsg={inputValue?.errors?.clubName}
                          onChange={handleChange}
                          value={inputValue.clubName}
                          maxlength={40}
                          className="numberWithoutArrowkey"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Company Name
                        </label>

                        <InputTextBox
                          id="companyName"
                          name="companyName"
                          type="text"
                          placeholder="Please enter Company Name"
                          // isValid={inputValue?.IsValid?.companyName}
                          // errorMsg={inputValue?.errors?.companyName}
                          onChange={handleChange}
                          value={inputValue.companyName}
                          maxlength={40}
                          className="numberWithoutArrowkey"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          T-shirt size
                        </label>
                        <select
                          class="form-select p-2"
                          aria-label="Default select example"
                          value={inputValue.tShirtSize}
                          onChange={handleChange}
                          name="tShirtSize"
                        >
                          <option selected="">Select T-Shirt Size</option>
                          "XS", "S", "M", "L", "XL", "XXL"'
                          <option value="XS">Extra Small</option>
                          <option value="S">Small</option>
                          <option value="M">Medium</option>
                          <option value="L">Large</option>
                          <option value="XL">Extra Large</option>
                          <option value="XXL">Extra Extra Large</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Blood Group
                        </label>
                        <select
                          class="form-select p-2"
                          aria-label="Default select example"
                          value={inputValue.bloodGroup}
                          onChange={handleChange}
                          name="bloodGroup"
                        >
                          <option selected="">Select Blood Group</option>
                          <option value="A+">A +</option>
                          <option value="O+">O +</option>
                          <option value="B+">B +</option>
                          <option value="AB+">AB +</option>
                          <option value="A-">A -</option>
                          <option value="O-">O -</option>
                          <option value="B-">B -</option>
                          <option value="AB-">AB -</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Emergency Contact Person
                        </label>
                        <InputTextBox
                          type="text"
                          class="form-control"
                          id="emergencyContactName"
                          name="emergencyContactName"
                          placeholder="Emergency Contact Name"
                          value={inputValue.emergencyContactName}
                          // isValid={inputValue?.IsValid?.emergencyContactName}
                          // errorMsg={inputValue?.errors?.emergencyContactName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Emergency Contact Relation
                        </label>
                        <select
                          class="form-select p-2"
                          aria-label="Default select example"
                          value={inputValue.emergencyContactRelation}
                          onChange={handleChange}
                          name="emergencyContactRelation"
                        >
                          <option selected="">Select relation</option>
                          <option value="Spouse">Spouse</option>
                          <option value="Parent">Parent</option>
                          <option value="Sibling">Sibling</option>
                          <option value="Friend">Friend</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Emergency contact Number
                        </label>
                        <div className="input-group">
                          <div className="input-group-text ">+91</div>
                          <InputTextBox
                            id="emergencyContactNumber"
                            name="emergencyContactNumber"
                            type="number"
                            placeholder="Please enter Mobile No"
                            isValid={
                              inputValue?.IsValid?.emergencyContactNumber
                            }
                            errorMsg={
                              inputValue?.errors?.emergencyContactNumber
                            }
                            onChange={handleChange}
                            maxlength={10}
                            className="numberWithoutArrowkey"
                            value={inputValue.emergencyContactNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Mailing Address */}
            <div class=" rounded shadow-sm">
              <h1 class="p-3 text-start bg-secondary-subtle m-0 border-bottom border-secondary">
                Mailing Address
              </h1>
              <div class="row mx-3_1 px-1 mt-4">
                <div class="col-12 col-md-12 text-start">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Address Line 1
                        </label>
                        <InputTextBox
                          id="addressLine1"
                          name="addressLine1"
                          placeholder="Please enter address"
                          // isValid={inputValue?.IsValid?.addressLine1}
                          // errorMsg={inputValue?.errors?.addressLine1}
                          value={inputValue.addressLine1}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Address Line 2
                        </label>
                        <InputTextBox
                          type="text"
                          class="form-control"
                          id="addressLine2"
                          name="addressLine2"
                          placeholder=" Address Line 2"
                          // isValid={inputValue?.IsValid?.addressLine2}
                          // errorMsg={inputValue?.errors?.addressLine2}
                          value={inputValue.addressLine2}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Landmark
                        </label>
                        <InputTextBox
                          type="text"
                          class="form-control"
                          id="landmark"
                          name="landmark"
                          placeholder="Landmark"
                          // isValid={inputValue?.IsValid?.landmark}
                          // errorMsg={inputValue?.errors?.landmark}
                          value={inputValue.landmark}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <div class="text-start">
                          <label for="exampleInputEmail1" class="form-label">
                            Select County<span class="text-danger">*</span>
                          </label>
                          <select
                            class="form-select p-2"
                            aria-label="Default select example"
                            value={inputValue.country}
                            onChange={handleChange}
                            name="country"
                          >
                            <option selected="">Select County</option>
                            <option value="India">India</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <div class="w-100 text-start">
                          <label for="exampleInputEmail1" class="form-label">
                            State<span class="text-danger">*</span>
                          </label>
                          <StateDropdown
                            name="stateId"
                            stateId={inputValue?.stateId}
                            onStateChange={handleStateChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <div class="w-100 text-start">
                          <label for="exampleInputEmail1" class="form-label">
                            City<span class="text-danger">*</span>
                          </label>
                          <CityByStateIdDropdown
                            name="cityId"
                            stateId={inputValue?.stateId}
                            cityId={inputValue?.cityId}
                            onCityChange={handleCityChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Pin code
                        </label>
                        <InputTextBox
                          id="pincode"
                          name="pincode"
                          type="text"
                          placeholder="Please enter PinCode"
                          isValid={inputValue?.IsValid?.pincode}
                          errorMsg={inputValue?.errors?.pincode}
                          value={inputValue.pincode}
                          onChange={handleChange}
                          maxlength={6}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="mb-3 p-2">
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p> */}
                      </div>
                    </div>

                    <div class="mb-3 text-center">
                      <button
                        type="button"
                        class=" btn-outline-dark w-100 btn align-middle"
                        style={{ height: "45px" }}
                        onClick={updateUserProfileDetails}
                      >
                        Update Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showOTPView ? (
        <div
          class="modal fade show"
          id="mobileNumberPopup"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
            display: `${showOTPView ? "block" : "none"}`,
          }}
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <OTPVerification
                code_sent_to={inputValue.mobile}
                onDismiss={onDismiss}
                showEmailView={onDismiss}
                resendOTP={generateOTPService}
                mobileNumber={inputValue.mobile}
              />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {loading && <Spinner />}
    </>
  );
};

export default MyProfile;
