import React, { useEffect, useState } from "react";
import { GetCategoryData } from "../../../services/commonAPI";

const CategoryDropdown = ({ categoryId, onCategoryChange, ...rest }) => {
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    const fetchCategoryData = async () => {
      const response = await GetCategoryData();
      if (response?.success) {
        setCategoryData(response.data || []);
      } else {
        setCategoryData([]);
      }
    };

    fetchCategoryData();
  }, []);

  return (
    <>
      <select
        id="CategoryDropdown"
        value={categoryId}
        className="form-select"
        name={rest?.name ? rest?.name : "category"}
        onChange={onCategoryChange}
      >
        <option value="0">Select a category</option>
        {categoryData.map((currentValue) => (
          <option
            key={currentValue.categoryId}
            value={currentValue.categoryId}
            id={currentValue.categoryPic}
          >
            {currentValue.categoryName}
          </option>
        ))}
      </select>
    </>
  );
};

export default CategoryDropdown;
