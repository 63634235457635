import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "../icons/Icon";

//main function
const SideBar = ({ config }) => {
  let items = config.data;
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array

  //function/event/methods

  let currentActiveIndex = items?.findIndex((item, index) => {
    if (item?.url === pathname) return index;
  });

  const [activeIndex, setActiveIndex] = useState(currentActiveIndex || 0);

  const handleClick = (index, fnCall) => {
    setActiveIndex(index);
    if (fnCall !== undefined) {
      fnCall();
    }
  };

  const renderItems = items.map((item, index) => {
    if (item?.showOnlyLogin === true && config.isUserLogin === false) {
      return;
    }
    if (item?.subMenu === true) {
      return <SubMenu config={item} pathname={pathname} />;
    }
    return (
      <>
        <Link
          to={item.url}
          className={`m-1 ${index === activeIndex ? "active" : ""}`}
          // className={` m-1 ${isActive ? "active" : ""}`}
          onClick={() => handleClick(index, item?.onClick)}
          key={index}
        >
          <div className="d-flex flex-row">
            <div className="sidemenuicon">
              <Icon iconName={item?.icon} />
            </div>
            <div className="text-start p-0 m-0 mx-2">{item.title}</div>
          </div>
        </Link>
      </>
    );
  });

  //main retrun
  return (
    <>
      <div className="sidebar mt-2">{renderItems}</div>

      {/* {activeIndex == item.length ? createPortal(<Logout />, document.body) : } */}
    </>
  );
};

//submenu
const SubMenu = ({ config, pathname }) => {
  //variables
  let items = config.data;
  let currentActiveIndex = -1;
  items?.findIndex((item, index) => {
    if (item?.url?.toLowerCase() === pathname?.toLowerCase()) {
      currentActiveIndex = index;
      return;
    }
  });

  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeTabIndex, setActiveTabIndex] = useState(false);

  useEffect(() => {
    if (currentActiveIndex >= 0) handleClick(currentActiveIndex);
  }, [currentActiveIndex]);

  //methods
  const handleClick = (index, fnCall) => {
    setActiveIndex(index);
    if (fnCall !== undefined) {
      fnCall();
    }
  };
  const handleTabClick = () => {
    setActiveTabIndex(() => !activeTabIndex);
  };
  const renderSubItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    if (item?.showOnlyLogin === true && config.isUserLogin === false) {
      return;
    }

    return (
      <>
        <div
          type="button"
          className={`list-group-item list-group-item-action menu-list-group-item py-3 ${
            index === activeIndex ? " bg-transparen active" : ""
          }`}
          onClick={() => handleClick(index, item?.onClick)}
          key={index}
        >
          <Link to={item.url}>{item.title}</Link>
        </div>
      </>
    );
  });
  //main return
  return (
    <>
      <div
        href="#home"
        class="accordion mx-1 org-left-overbg"
        id="accordionExample1"
      >
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
              className={`accordion-button ${
                activeTabIndex === true ? "" : "collapsed"
              }`}
              type="button"
              onClick={() => handleTabClick()}
            >
              <div class="d-flex flex-row p-0">
                <div class="sidemenuicon">
                  <Icon iconName={config?.icon} />
                </div>
                <div class="text-start p-0 m-0 mx-2">{config?.title}</div>
              </div>
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${
              activeTabIndex === true ? " show" : ""
            }`}
          >
            <div class="accordion-body p-0">
              <div class="list-group list-group-flush">{renderSubItems}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//
export default SideBar;
