import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FiltersPopup from "../controls/popup/FiltersPopup";
import Icon from "../controls/icons/Icon";
import InputTextBox from "../controls/input/InputTextBox";
import * as initialState from "../../services/initialState";
import { API_RESONSE_FAILED } from "../../utils/Messages";
import * as Call from "../../utils/validations";
import { encryptData } from "../../utils/cryptoJs";

//
let initialData = initialState.Search;
/* let initialData = {
  searchText: "",
  cityId: 0,
  stateId: 0,
  categoryId: 0,
  sDate: "",
  eDate: "",
  minPrice: 0,
  maxPrice: 0,
  errors: {},
  IsValid: {},
}; */
//main function
const SearchCard = () => {
  //declare start
  //variable
  const [filterSave, setFilterSavePopup] = useState(false);
  const [inputValue, setInputValue] = useState(initialData);
  let { errors, IsValid } = inputValue;
  const navigate = useNavigate();
  //declare end

  //functions/events/methods
  const handleFilterSave = (value, inputValue1) => {
    let inputValue2 = inputValue1;
    if (inputValue2?.searchText) delete inputValue2["searchText"];
    if (inputValue2?.IsValid) delete inputValue2["IsValid"];

    setInputValue((prev) => ({
      ...prev,
      ...inputValue1,
    }));
    setFilterSavePopup(() => {
      return value;
    });
    sendSearchData(inputValue.searchText, inputValue1);
  };

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);

    //setInputValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      ["errors"]: { ...errors, [name]: result.message },
      ["IsValid"]: { ...IsValid, [name]: result.status },
    }));
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      //msg = "Please enter " + name;
    } else {
      switch (name) {
        case "searchText":
          val = Call.IsValidSeachText(value);
          if (!val) msg = "Invalid " + value;
          break;
        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const saveAndProceed = async (e) => {
    e && e.preventDefault();
    if (IsValid && IsValid.searchText) {
      sendSearchData(inputValue.searchText, inputValue);
    } else {
      alert("Enter text to search");
    }
  };

  const sendSearchData = (searchText, inputValue1) => {
    if (
      searchText != undefined &&
      searchText != null &&
      searchText.trim() != ""
    ) {
      searchText = searchText.trim();
      setInputValue((prev) => ({
        ...prev,
        ["searchText"]: searchText,
      }));
      //post data logic
      let postValue = initialData;
      let skipKey = initialState.skipKey;
      for (let key in postValue) {
        try {
          if (skipKey.includes(key)) {
            delete postValue[key];
          } else {
            if (key == "searchText") postValue[key] = inputValue1[key].trim();
            else postValue[key] = inputValue1[key];
          }
        } catch {}
      }
      postValue["searchText"] = searchText;
      //post data to search page
      let encData = encryptData(postValue);
      navigate("/search/?text=" + searchText + "&q=" + encData);
    } else alert("Enter text to search");
  };

  //main return
  return (
    <>
      <div className="d-flex order-3 order-md-2 search-input float-start">
        <div className="ps-2 search-input">
          <div className="d-flex border rounded-2 mr-2" role="search">
            <InputTextBox
              className="form-control border-0 search-bg border-end-0"
              type="search"
              placeholder="Search Event.."
              //aria-label="Search"
              id="searchText"
              name="searchText"
              value={inputValue.searchText}
              isValid={inputValue?.IsValid?.searchText}
              errorMsg={inputValue?.errors?.searchText}
              onChange={handleChange}
            />
            <button
              className="btn border-0 search-button-bg border-start-0"
              onClick={(event) => saveAndProceed(event)}
              //type="submit"
              type="button"
            >
              <Icon iconName="search" />
            </button>
          </div>
        </div>
        <div
          className={`flex-shrink-1 ${filterSave === true ? " pl-1 " : ""}`} // pl-1 bg-success
        >
          <FiltersPopup onSave={handleFilterSave} />
        </div>
      </div>
    </>
  );
};

export default SearchCard;
