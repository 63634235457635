import React from "react";
import { Accordion } from "../controls/accordion/Accordion";

const AboutUs = () => {
  let items = [
    { header: "Introduction 1", text: "<b>Testing 1</b>" },
    { header: "Introduction 2", text: "Testing 2" },
    { header: "Introduction 3", text: "Testing 3", iconName: "events" },
  ];
  return (
    <div>
      <Accordion items={items} />
      <div className="bg-white z-[1] flex flex-col items-center w-full py-11 max-md:mt-10">
        <h1>Our Privacy Policy</h1>
        <p></p>
        <p></p>
        <h2>Introduction</h2>
        <p>
          Vrattanta Endurance and Sports, is the holding company of
          FitFunda.co.in and FitFunda.in, and its affiliates operating in the
          event management and various sports technology domain (henceforth
          referred to as 'Vrattanta', 'we', 'us', or 'our') is a corporation
          incorporated and registered in the state of Maharashtra, India, with
          its registered address at : D2-502, Lunkad Goldcoast, Viman nagar,
          Pune, Maharashtra 411014. This privacy policy ("Policy") pertains to
          all individuals engaged in various interactions with us within the
          scope of our business activities. Such interactions may arise when you
          visit our website (www.fitfunda.co and www.fitfunda.co.in,) and the
          www.Vrattanta.com website, whether you are a visitor, end user,
          customer, prospective customer, observer of our operations, a
          potential or existing business partner, an employee, a job applicant,
          an investor, or a supplier. By voluntarily using our Platform or
          creating an account to access it, you assume the role of an authorised
          user ("User," "You," or "Your") and are expected to use our Platform
          in accordance with our Terms of Use. This Policy is designed to
          provide You with information regarding the collection, processing,
          storage, sharing, organisation, utilisation, retention, and deletion
          of information ("Personal Data" or "Personal Information"), whether
          obtained directly or indirectly from You. Additionally, it outlines
          the protective measures we employ to safeguard your data and explains
          how you can exercise your associated rights. If you do not agree with
          the terms outlined in this policy, we kindly request that you refrain
          from accessing or using our Services or engaging with any other aspect
          of our business operations. Please note that this Policy does not
          encompass information collected by third parties, including data
          collected through third-party applications or content (such as
          advertising) that may be linked to or accessible from our applications
          or websites.
        </p>

        <p></p>
        <h2>Our Commitment</h2>
        <p>
          “Vrattanta Endurance and Sports” and its corporate affiliates
          ("FitFunda", “Vrattanta” or "We," "Us," "Our"), are dedicated to
          safeguarding your privacy and protecting Your Personal Data in
          accordance with this Policy. We adhere to a range of data protection
          regulations and industry best practices to ensure the security of Your
          information and to provide You with continuous access and control over
          it. This Policy furnishes comprehensive details on how FitFunda
          gathers information, in addition to outlining the privacy and security
          protocols implemented on the Platform. In the event of any changes in
          how Your information is utilised, we will supply You with further
          information during our communications with You through various
          channels, such as email.
        </p>

        <p></p>
        <h2>General</h2>
        <p>
          Fitfunda has developed the most comprehensive and user-friendly
          platform for hosting virtual, In-person and hybrid events. Through our
          application ("Services"), Customers (as defined below) can licence
          FitFunda’s application to connect with Attendees (as defined below).
          When FitFunda directly collects information from you, FitFunda
          operates as the Data Controller (as defined below). However, if
          FitFunda receives information from its Customer, FitFunda serves as a
          Data Processor (as defined below) and processes data in accordance
          with the instructions provided by the Data Controller. If you have any
          inquiries concerning the handling of Your Personal Data by FitFunda or
          wish to exercise Your privacy rights, please contact us at
          privacy@vrattanta.com
        </p>

        <p></p>
        <h2>Definitions</h2>
        <p>
          ● "Account" refers to the self-service account created by the Customer
          on the FitFunda Platform for utilising the Services.{" "}
          <p>
            ● "Data Subject" or "Data Owners" refers to a natural person whose
            Personal Data is processed by a Data Controller or Data Processor.
          </p>
          ● "Customer Data" encompasses all data submitted, displayed, and/or
          uploaded by the Attendee,Sponsors , Booth Representatives, Authorised
          Administrator(s), and/or the Customer during registration, access,
          event hosting, and/or other usage of the Platform. ● "Customer," "Data
          Controllers," refers to the Organizer who has a service agreement with
          FitFunda to access the FitFunda Platform for hosting in-person ,
          virtual events and hybrid events. This includes employees, independent
          contractors, consultants, affiliates, successors, and assignees
          using/accessing the Platform/Services. Data Controllers are
          responsible for data safekeeping, transportation, storage, and the
          implementation of business rules. They are the entities determining
          the purposes and means of processing Personal Data. If the purposes
          and means of processing are established by national or community laws
          or regulations, the controller or specific criteria for nominating the
          controller may be designated by such laws. ● "Customer Personal Data"
          denotes any Personal Data shared by the Customer with or authorised
          for access, storage, hosting, modification, sharing, deletion, and
          further Processing by FitFunda and/or its Affiliates for performing
          the Services. ● "Personal Data" encompasses information related to a
          Data Subject, where an identifiable natural person is one who can be
          directly or indirectly identified, particularly by reference to an
          identifier such as a name, an identification number, location data, an
          online identifier, or one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural, or social identity
          of that natural person. This includes any special categories of
          Personal Data as defined in Art. 9 of the GDPR. ● "Attendees" denotes
          the clients and all individuals attending or participating in events
          organised by the Customer via FitFunda Services. ● “Sponsors” refers
          to the organisations who have an agreement with the Customer to
          display the organisation’s relevant information inside the Platform
          during the event for certain activities, including , but not limited
          to branding and lead generation .Sponsors shall only have access to
          add/modify their relevant data as defined by the Customer in the event
          creation flow. ● "Data Processor" refers to a natural or legal person,
          public authority, agency, or other entity processing Personal Data on
          behalf of the Data Controller and in accordance with the Data
          Controller's instructions, typically for specific purposes and
          services accessible to the Data Controller. ● "Commercial Purposes"
          and "Sell" bear the meanings ascribed to them in section 1798.140 of
          the CCPA. ● "Applicable Data Protection Laws" collectively includes
          all data protection laws and regulations, encompassing United States
          federal and state privacy laws, European Union Data Protection Laws,
          including but not limited to the General Data Protection Regulation
          ("GDPR"), and any other laws concerning data protection worldwide that
          may apply to the processing of Personal Information (also known as
          “Personal Data”) under the Privacy Policy. ● "Data Protection Officer"
          (DPO) signifies, where applicable, an individual responsible for the
          protection of Personal Data at FitFunda or Vrattanta. ●
          "Sub-processor" or "Third-Party Service Provider" means any natural or
          legal person, public authority, agency, or body appointed by or on
          behalf of the Processor, or by or on behalf of an existing
          Sub-processor, to process Personal Data on behalf of the Controller,
          as defined in Art. 4(10) and further explained in 28(4) of GDPR. ●
          "Supervisory Authority" carries the meaning assigned to it under the
          GDPR
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
