import { Link } from "react-router-dom";
import SliderEvent from "./SliderEvent.jsx";
import {
  GetAllPastEventOfHomePage,
  GetCityEventData,
} from "../../services/eventAPI.jsx";
import { IsMobile } from "../../utils/commonFun.jsx";
import { useEffect, useState } from "react";

const SliderPastEvents = () => {
  //check screen width
  let finish = 3;
  useEffect(() => {
    let isMobile = IsMobile();
    if (isMobile === true) {
      finish = 1;
    }
  });

  //slider data
  const sliderProp = {
    leftInActive: "disabled",
    leftActive: "",
    rightInActive: "disabled",
    rightActive: "",
    finish: finish,
    noOfImageRotate: 1,
  };

  const [eventData, setEventData] = useState("");

  useEffect(() => {
    const getAllPastEvents = async () => {
      try {
        const pastEventOfHomePage = await GetAllPastEventOfHomePage();

        if (
          pastEventOfHomePage.success === true &&
          pastEventOfHomePage?.data?.length > 0
        ) {
          setEventData(pastEventOfHomePage);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllPastEvents();
  }, []);

  return (
    <>
      {eventData && (
        <>
          <div className="row mx-2">
            <div className="col text-start cat-name ml-2">
              <b>Past Events</b>
            </div>
            {eventData?.data?.length > 3 && (
              <div className="col ms-auto text-end">
                <Link to={"/event/past"}>
                  <span className="badge text-bg-light border view-text">
                    View All
                  </span>
                </Link>
              </div>
            )}
          </div>
          <SliderEvent
            sliderProp={sliderProp}
            eventData={eventData && eventData.data}
          />

          <div
            className="row text-end mdnViewAll"
            style={{
              marginTop: "-30px",
              marginBottom: "10px",
              display: "none",
            }}
          >
            {/* <Link to={"/event/" + cityName + "/" + cityId + "/#section1"}> */}
            <span className=" view-text">View all event in </span>
            {/* </Link> */}
          </div>
        </>
      )}
    </>
  );
};

export default SliderPastEvents;
