import React from "react";

const Button = ({
  children,
  primary,
  secondary,
  success,
  danger,
  warning,
  info,
  light,
  dark,
  close,
  outline,
  loading,
  ...rest
}) => {
  let buttonClassName = " btn btn-select w-100 " + rest?.className;

  let btnClass = "";
  if (primary) {
    if (outline) btnClass = " btn btn-outline-primary ";
    else btnClass = " btn btn-primary ";
  } else if (secondary) {
    if (outline) btnClass = " btn btn-outline-secondary ";
    else btnClass = " btn btn-secondary ";
  } else if (success) {
    if (outline) btnClass = " btn btn-outline-success ";
    else btnClass = " btn btn-success ";
  } else if (danger) {
    if (outline) btnClass = " btn btn-outline-danger ";
    else btnClass = " btn btn-danger ";
  } else if (warning) {
    if (outline) btnClass = " btn btn-outline-warning ";
    else btnClass = " btn btn-warning ";
  } else if (info) {
    if (outline) btnClass = " btn btn-outline-info ";
    else btnClass = " btn btn-info ";
  } else if (light) {
    if (outline) btnClass = " btn btn-outline-light ";
    else btnClass = " btn btn-light ";
  } else if (dark) {
    if (outline) btnClass = " btn btn-outline-dark ";
    else btnClass = " btn btn-dark ";
  } else if (close) {
    if (outline) btnClass = " btn btn-outline-close ";
    else btnClass = " btn btn-close ";
  }
  if (btnClass !== "") buttonClassName = btnClass + rest?.className;

  //total new class
  if (rest?.newClassName) buttonClassName = rest?.newClassName;
  //
  return (
    <>
      <button {...rest} disabled={loading} className={buttonClassName}>
        {loading ? "wait..." : children}
      </button>
    </>
  );
};

export default Button;
