import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import DiscountForm from "./DiscountForm";
import DiscountCoupons from "./DiscountCoupons";
import * as storeAction from "../../../redux/actions/createEvent";
import {
  DeactivateSingleCouponAfterPublish,
  DeleteSingleCoupon,
} from "../../../services/createEvent";
import {} from "react-redux";
import Button from "../../controls/buttons/Button";
import * as initialState from "../../../services/initialState";
import Modal from "../../controls/popup/Modal";
import Spinner from "../../controls/spinner/Spinner";

//
// Form initial Data
let initialData = initialState.DiscountInfo;
let eventId;
const DiscountInfo = ({ eventDetails, saveDiscountInfo, onStepComplete }) => {
  const [showCouponAddForm, setShowCouponAddForm] = useState(true);
  const [inputValue, setInputValue] = useState(initialData);
  const [inputValueList, setInputValueList] = useState([]);
  const userData = useSelector((state) => state.login.login.login);
  const { IsValid } = inputValue;
  const [deactivateCouponId, setDeactivateCouponId] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false); //spinner

  //if store data available
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      if (eventDetails?.discountCoupons) {
        // initialData = { ...initialData, ...eventDetails?.discountCoupons };
        // setInputValue(initialData);
        setInputValueList(eventDetails?.discountCoupons);
        setShowCouponAddForm(false);
      }
    }
  }, [eventDetails]);
  //
  const handleEdit = (discountCouponId, couponStatus) => {
    //alert(couponId);

    if (eventDetails?.eventStage === "past") {
      alert("Past events details can't be changed.");
    } else if (
      eventDetails?.eventStatus === "act" &&
      couponStatus !== undefined
    ) {
      alert("Can not edit coupon for published event.");
    } else {
      const items = inputValueList;
      let item = items.find(
        (item) => item.discountCouponId === discountCouponId
      );
      item = {
        ...item,
        ["IsValid"]: { ...initialData.isRequired },
      };
      setShowCouponAddForm(true);
      setInputValue(() => item);
    }
  };

  const handleDelete = async (discountCouponId, couponStatus) => {
    if (
      eventDetails?.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events details can't be changed.");
    } else if (
      eventDetails?.eventStatus === "act" &&
      couponStatus !== undefined
    ) {
      setDeactivateCouponId(discountCouponId);
      setShowConfirmationDialog(true);
    } else {
      handleDeleteTicketsBeforePublish(discountCouponId);
    }
  };

  const handleDeleteTicketsBeforePublish = async (discountCouponId) => {
    const items = [...inputValueList];
    const j = items.findIndex(
      (item) => item.discountCouponId === discountCouponId
    );

    if (j === -1) return; // If the coupon is not found, exit from the function

    setLoading(true);
    const response = await DeleteSingleCoupon(
      eventId,
      discountCouponId,
      userData.token
    );

    setLoading(false);

    if (response?.success) {
      items.splice(j, 1);
      setInputValueList(response.data?.discountCoupons);
      // setInputValue(...inputValue, initialData);

      let eventDetails1 = response?.data;
      eventDetails1 = {
        ...eventDetails1,
        IsValid: { ...IsValid, ...inputValue?.IsValid },
      };
      saveDiscountInfo(eventDetails1);
      alert(response?.message);
    } else {
      alert(response?.message);
    }

    if (
      response.data?.discountCoupons?.length === 0 ||
      response.data?.discountCoupons === null
    ) {
      //show add form
      setInputValue(initialData);
      setShowCouponAddForm(true);
    }
  };

  /**
   * If event is onGoing then coupon will get deactivate.
   * In event details page inactive coupon will not be applicable anymore.
   */
  const handleDeleteDiscountAfterPublish = async () => {
    setShowConfirmationDialog(false);
    setLoading(true);
    const response = await DeactivateSingleCouponAfterPublish(
      eventId,
      deactivateCouponId,
      userData?.token
    );

    setLoading(false);

    if (response?.success) {
      let eventDetails1 = response.data;
      eventDetails1 = {
        ...eventDetails1,
        IsValid: { ...IsValid, ...inputValue?.IsValid },
      };
      setInputValueList(response.data?.discountCoupons);
      saveDiscountInfo(eventDetails1);
      alert(response?.message);
    } else {
      alert(response?.message);
    }
  };

  const handleAddAnotherCoupone = () => {
    setShowCouponAddForm(true);
    setInputValue(() => initialData);
  };
  const handleSaveCouponList = (inputValueList1) => {
    //setInputValue(inputValue);

    setInputValueList(inputValueList1);
    showCouponList();
  };
  const showCouponList = () => {
    //alert("showCouponList");

    setShowCouponAddForm(() => {
      return false;
    });
  };

  const updateEventDetails = async (updatedEventDetails) => {
    saveDiscountInfo(updatedEventDetails);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <div className="flex flex-col w-full">
              {showCouponAddForm ? (
                <div>
                  <DiscountForm
                    inputValueRef={inputValue}
                    inputValueListRef={inputValueList}
                    onSaveCouponList={handleSaveCouponList}
                    onShowCouponList={showCouponList}
                    eventDetails={eventDetails}
                    onUpdateEventDetails={updateEventDetails}
                  />
                </div>
              ) : (
                <>
                  <div className="w-full">
                    {inputValueList &&
                      inputValueList?.map((item, index) => (
                        <DiscountCoupons
                          index={index}
                          inputValue={item}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                          eventDetails={eventDetails}
                        />
                      ))}
                  </div>

                  <div className="mb-3 text-center">
                    <Button light onClick={handleAddAnotherCoupone}>
                      + Add Another Coupon
                    </Button>
                  </div>
                </>
              )}

              {/* <div className="mb-3 text-center">
                {showCouponAddForm ? (
                  <Button secondary outline>
                    Save & Proceed
                  </Button>
                ) : (
                  <Button disabled={showCouponAddForm} onClick={saveAndProceed}>
                    Save & Proceed
                  </Button>
                )}
              </div> */}
            </div>

            {showConfirmationDialog && (
              <Modal
                onClose={() => setShowConfirmationDialog(false)}
                title={""}
                onSuccess={handleDeleteDiscountAfterPublish}
                successText={"Yes"}
              >
                <div class="modal-content">
                  <div class="modal-body">
                    Are you sure you want to delete? This action can not be
                    undone.
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveDiscountInfo: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(DiscountInfo);
//export default DiscountInfo;
