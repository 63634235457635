import React from "react";
import InputTextBox from "../../controls/input/InputTextBox";

//main function
const EventSetting = ({ event }) => {
  if (!event || !event.eventSetting)
    return (
      <div className="card-body p-1">
        Event Setting Details are not available for {event.eventName}!!
      </div>
    );
  const { eventSetting } = event;

  const CopyContent = () => {
    navigator.clipboard
      .writeText(eventSetting?.embeddCode)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const renderFeePay = (value) => {  
    return value === "organizer" ? "Organizer" :value === "participant" ? "Participant" : "";
  };

  //main retrun
  return (
    <div className="card-body p-1">
      <div className="accordion accordion-flush m-0" id="accordionFlushExample">
        <div key={eventSetting.eventSettingId}>
          <div
            id="participant-collapseThree"
            className="accordion-collapse collapse accordion-detail px-4 show"
            data-bs-parent="#accordionFlushExample"
          >
            <form className="row g-3 pb-3 text-start">
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Who will pay hosting fees?
                </label>
                <InputTextBox
                  id="hostingFeesPayer"
                  name="hostingFeesPayer"
                  type="text"
                  value={renderFeePay(eventSetting.hostingFeesPayer)}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Who will pay PG fees?
                </label>
                <InputTextBox
                  id="pgFeesPayer"
                  name="pgFeesPayer"
                  type="text"
                  value= {renderFeePay(eventSetting.pgFeesPayer)}
                  disabled
                />
              </div>

              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Embed Tickets On website
                </label>

                <div class="input-group">
                  <InputTextBox
                    id="embeddCode"
                    name="embeddCode"
                    type="text"
                    value={eventSetting.embeddCode}
                    disabled
                  />
                  <label
                    class="input-group-text"
                    for="inputGroupFile02"
                    onClick={CopyContent}
                  >
                    <i class="bi mx-1 select-upload mt-1"></i>
                    Copy
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Is GST from attendees?
                </label>
                <InputTextBox
                  id="isGstFromAttendees"
                  name="isGstFromAttendees"
                  type="text"
                  value={eventSetting.isGstFromAttendees ? "Yes" : "No"}
                  disabled
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="inputEmail4" className="form-label">
                  Redirect to website’s landing page
                </label>
                <InputTextBox
                  id="isRedirect"
                  name="isRedirect"
                  type="text"
                  value={eventSetting.isRedirect ? "Yes" : "No"}
                  disabled
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSetting;
