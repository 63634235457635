import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
//import ParticipantFormDialog from "./ParticipantFormDialog";
import { SaveParticipantForm } from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import * as common from "../../../utils/commonFun";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";

//
let initialData = {
  userId: "",
  isName: true,
  isEmail: true,
  isMobile: true,
  isDob: {
    isActive: false,
    isMandatory: false,
  },
  isGender: {
    isActive: false,
    isMandatory: false,
  },
  isUploadPhoto: {
    isActive: false,
    isMandatory: false,
  },
  isTshirtSize: {
    isActive: false,
    isMandatory: false,
  },
  isClubName: {
    isActive: false,
    isMandatory: false,
  },
  isCountry: {
    isActive: false,
    isMandatory: false,
  },
  isState: {
    isActive: false,
    isMandatory: false,
  },
  isCity: {
    isActive: false,
    isMandatory: false,
  },
  isAddress: {
    isActive: false,
    isMandatory: false,
  },
  isPincode: {
    isActive: false,
    isMandatory: false,
  },
  isGroupName: {
    isActive: false,
    isMandatory: false,
  },
  eventId: "",
};
const initialDataTitle = {
  isDob: {
    title: "DOB", //"Date of birth",
  },
  isGender: {
    title: "Gender",
  },
  isUploadPhoto: {
    title: "Upload Photo",
  },
  isTshirtSize: {
    title: "T-Shirt Size",
  },
  isClubName: {
    title: "Name of club", //"Running club name",
  },
  /*  isCountry: {
    title: "Country", //"Select country",
  },
  isState: {
    title: "State", //"Select state",
  },
  isCity: {
    title: "City", //"Select city",
  }, */
  isAddress: {
    title: "Address", //"Enter Address",
  },
  isGroupName: {
    title: "Group Name", //"Select Group Name",
  },
};

//
let eventId;
const ParticipantForm = ({
  eventDetails,
  saveEventDetails,
  onSave,
  onSkip,
}) => {
  const [more, setMore] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [inputValue, setInputValue] = useState(initialData);
  const userData = useSelector((state) => state.login.login.login);
  //const [popupData, setPopupData] = useState(null);

  //
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      if (eventDetails?.participantForm)
        setInputValue(() => eventDetails?.participantForm);
    }
  }, [eventDetails]);

  /*  const openDialog = (event, key, item) => {
    
    let title = initialDataTitle[key]?.title
      ? initialDataTitle[key]?.title
      : key;
    let popupData1 = {
      key: key,
      title: title,
      isActive: item?.isActive,
      isMandatory: item?.isMandatory,
      item: item,
    };
    setPopupData(() => popupData1);
    setShowDialog(() => true);
  };

  const handleCloseDialog = () => {
    setShowDialog(() => false);
  }; 

  const handleSave = (key, updatedData) => {
    setInputValue((prev) => ({
      ...prev,
      [key]: { ...updatedData },
    }));

    handleCloseDialog();
  };*/

  const handleSaveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);
      inputValue.userId = userData.user.userId;
      inputValue.eventId = eventId;
      const result = await SaveParticipantForm(inputValue, userData.token);

      if (result) {
        if (result.success) {
          common.saveToLocal(KEY_EVENT_ID, result?.data?.eventId);
          eventDetails = result.data;
          saveEventDetails(eventDetails);
          //
          onSave();
        } else {
          alert("Error:-" + result.message);
        }
      } else {
        alert(API_RESONSE_FAILED);
      }
    }
  };

  // //
  const handleChange = (event, type, key) => {
    // event.preventDefault();

    //
    const { name, value } = event.target;
    let checked = event?.target?.checked ? true : false;

    let newValue = { isActive: checked, isMandatory: checked };

    if (type === "isActive") {
      newValue = {
        isActive: checked,
        isMandatory: inputValue[key]?.isMandatory,
      };
    } else if (type === "isMandatory") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: checked,
      };
    } else if (type === "isOptional") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: false,
      };
    }

    if (key === "isAddress") {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [key]: newValue,
        ["isCountry"]: newValue,
        ["isState"]: newValue,
        ["isCity"]: newValue,
        ["isPincode"]: newValue,
      }));
    } else {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    }
  };
  //
  const renderCheckBoxBtn = () => {
    let renderUI = [];

    for (let key in inputValue) {
      if (initialDataTitle[key]?.title) {
        /*  renderUI.push(
          <div class="col-md-6 px-4 mb-3">
            <div class="p-2 border rounded-2">
              {initialDataTitle[key]?.title}
              <div class="ms-5 form-switch form-check-reverse float-end">
                <input
                  class="form-check-input py-2 px-3"
                  type="checkbox"
                  id={key}
                  name={key}
                  value={initialDataTitle[key]?.title}
                  checked={inputValue[key]?.isActive === true}
                  onChange={(event) => openDialog(event, key, inputValue[key])}
                />
              </div>
            </div>
          </div>
        ); */

        //
        renderUI.push(
          <>
            <div class="col-md-6 px-4 mb-3">
              <div class="p-2 border rounded-2 ">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={key}
                    name={key}
                    value={key}
                    checked={inputValue[key]?.isActive === true}
                    onChange={(event) => handleChange(event, "isActive", key)}
                  />
                  <label
                    class="form-check-label font-weight-bold"
                    for="flexSwitchCheckDefault"
                  >
                    {initialDataTitle[key]?.title}
                  </label>
                </div>

                <div class="row align-items-center">
                  <div class="col border rounded p-2 m-2">
                    <div class="form-check ms-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        id={`isMandatory${key}`}
                        name={`rBtnMO${key}`}
                        value="isMandatory"
                        checked={inputValue[key]?.isMandatory === true}
                        onChange={(event) =>
                          handleChange(event, "isMandatory", key)
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Mandatory
                      </label>
                    </div>
                  </div>
                  <div class="col border rounded p-2 m-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id={`isOptional${key}`}
                        name={`rBtnMO${key}`}
                        value="isOptional"
                        checked={
                          inputValue[key]?.isMandatory === true ? false : true
                        }
                        onChange={(event) =>
                          handleChange(event, "isOptional", key)
                        }
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Optional
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    return renderUI;
  };
  //
  return (
    <div class="card mb-2">
      <div class="card-body p-1">
        <div class="accordion accordion-flush m-0" id="accordionFlushExample">
          <div class="accordion-item">
            <div>
              <div class="alert alert-warning my-3" role="alert">
                Which content do you need to get from participant{" "}
              </div>
              <h1 class="my-4">Setup The Form</h1>
              <form class="row g-3">
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Participant Name<span class="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Email<span class="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Mobile No.<span class="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
              </form>

              {more ? (
                <div class="row mt-4">
                  {renderCheckBoxBtn()}
                  <div class="d-flex more mt-4" onClick={() => setMore(false)}>
                    <button newclassname="btn btn-light" class="btn btn-light">
                      - Less
                    </button>
                  </div>
                </div>
              ) : (
                <div class="d-flex more mt-4" onClick={() => setMore(true)}>
                  <button newclassname="btn btn-light" class="btn btn-light">
                    + More
                  </button>
                </div>
              )}
              <div class="my-4 text-center">
                <button
                  type="submit"
                  class="btn btn-select w-100"
                  onClick={handleSaveAndProceed}
                >
                  Save &amp; Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <ParticipantFormDialog
        showPopup={showDialog}
        setInitailData={popupData}
        onClose={handleCloseDialog}
        onSuccess={handleSave}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventDetails: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(ParticipantForm);
//export default ParticipantForm;
