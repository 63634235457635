import { Routes, Route, useLocation } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import EventTicketOptions from "../event/EventTicketOptions";
import EventCheckout from "../event/EventCheckout";
//
const Widget = () => {
  const isWidget = true;
  let widgetPath = "/widget";
  //main return
  return (
    <>
      <Routes>
        <Route
          path="/event-ticket-options/:eventId/:eventName/*"
          element={
            <EventTicketOptions isWidget={isWidget} widgetPath={widgetPath} />
          }
        />
        <Route
          path="/event-checkout/:eventId/:eventName/*"
          element={
            <EventCheckout isWidget={isWidget} widgetPath={widgetPath} />
          }
        />
      </Routes>
      <div className="row w-100 text-center">
        <p>Powered By Fitfunda</p>
        <div>
          <img src="/Logo.svg" style={{ width: "60px", height: "60px" }} />
        </div>
      </div>
    </>
  );
};

export default Widget;
