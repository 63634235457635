import React, { useEffect, useState } from "react";
import { GetStateList } from "../../../services/commonAPI";

//
const StateDropdown = ({ stateId, onStateChange, ...rest }) => {
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    const fetchStateData = async () => {
      const response = await GetStateList();
      setStateData(response?.data || []);
    };

    fetchStateData();
  }, []);

  return (
    <>
      <select
        id="stateDropdown"
        value={stateId}
        className="form-select"
        name={rest?.name ? rest?.name : "state"}
        onChange={onStateChange}
      >
        <option value="0">Select a state</option>
        {stateData?.map((currentValue) => (
          <option key={currentValue.stateId} value={currentValue.stateId}>
            {currentValue.stateName}
          </option>
        ))}
      </select>
    </>
  );
};

export default StateDropdown;
