export const GET_EVENTS = "GET_EVENTS";
export const RECEIVE_EVENTS = "RECEIVE_EVENTS";
export const FILTER_EVENTS = "FILTER_EVENTS";

export function getEvents() {
  return {
    type: GET_EVENTS,
  };
}

export function filterEvents(brand) {
  return {
    type: FILTER_EVENTS,
    brand,
  };
}

export function receiveEvents(Events) {
  return {
    type: RECEIVE_EVENTS,
    Events,
  };
}

//add to cart
export const FETCH_CART_EVENTS_REQUEST = "FETCH_CART_EVENTS_REQUEST";
export const FETCH_CART_EVENTS_SUCCESS = "FETCH_CART_EVENTS_SUCCESS";
export const ADD_CART_EVENT_REQUEST = "ADD_CART_EVENT_REQUEST";
export const ADD_CART_EVENT_SUCCESS = "ADD_CART_EVENT_SUCCESS";
export const DELETE_CART_EVENT_REQUEST = "DELETE_CART_EVENT_REQUEST";
export const DELETE_CART_EVENT_SUCCESS = "DELETE_CART_EVENT_SUCCESS";

export const fetchCartEventsRequest = () => ({
  type: FETCH_CART_EVENTS_REQUEST,
});
export const fetchCartEventsSuccess = (cartEvents) => ({
  type: FETCH_CART_EVENTS_SUCCESS,
  payload: cartEvents,
});
export const addToCartEventRequest = (cartEvent) => ({
  type: ADD_CART_EVENT_REQUEST,
  payload: cartEvent,
});
export const addToCartEventSuccess = (cartEvent) => ({
  type: ADD_CART_EVENT_SUCCESS,
  payload: cartEvent,
});
export const deleteFromCartEventRequest = (cartEventID) => ({
  type: DELETE_CART_EVENT_REQUEST,
  payload: cartEventID,
});
export const deleteFromCartEventSuccess = (cartEventID) => ({
  type: DELETE_CART_EVENT_SUCCESS,
  payload: cartEventID,
});

////add to cart user details
export const FETCH_CART_USER_DETAILS_REQUEST =
  "FETCH_CART_USER_DETAILS_REQUEST";
export const FETCH_CART_USER_DETAILS_SUCCESS =
  "FETCH_CART_USER_DETAILS_SUCCESS";
export const ADD_CART_USER_DETAIL_REQUEST = "ADD_CART_USER_DETAIL_REQUEST";
export const ADD_CART_USER_DETAIL_SUCCESS = "ADD_CART_USER_DETAIL_SUCCESS";
export const DELETE_CART_USER_DETAIL_REQUEST =
  "DELETE_CART_USER_DETAIL_REQUEST";
export const DELETE_CART_USER_DETAIL_SUCCESS =
  "DELETE_CART_USER_DETAIL_SUCCESS";

export const fetchUserDetailsRequest = () => ({
  type: FETCH_CART_USER_DETAILS_REQUEST,
});
export const fetchUserDetailsSuccess = (userDetails) => ({
  type: FETCH_CART_USER_DETAILS_SUCCESS,
  payload: userDetails,
});
export const addToUserDetailRequest = (userDetail) => ({
  type: ADD_CART_USER_DETAIL_REQUEST,
  payload: userDetail,
});
export const addToUserDetailSuccess = (userDetail) => ({
  type: ADD_CART_USER_DETAIL_SUCCESS,
  payload: userDetail,
});
export const deleteFromUserDetailRequest = (userDetailID) => ({
  type: DELETE_CART_USER_DETAIL_REQUEST,
  payload: userDetailID,
});
export const deleteFromUserDetailSuccess = (userDetailID) => ({
  type: DELETE_CART_USER_DETAIL_SUCCESS,
  payload: userDetailID,
});
