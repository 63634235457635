import React, { useState, useEffect } from "react";
import FileUploadSingle from "../../controls/fileUploader/FileUploadSingle";
import * as Call from "../../../utils/validations";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import { SetOrganizerKYCDetails } from "../../../services/userAPI";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import { useSelector } from "react-redux";
import * as initialState from "../../../services/initialState";
import Spinner from "../../controls/spinner/Spinner";

// Form initial Data
let initialData = initialState.OrganizerKYCInfo;
let mParam = initialState.OrganizerKYCInfo.isRequired;
let ImagePath = process.env.REACT_APP_USERS;

const OrganizerKYCDetails = ({
  onStepComplete,
  organizerDetails,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(initialData);
  const [valueIsTicketInvolveGst, setIsTicketInvolveGst] = useState(false);
  const { errors, IsValid } = inputValue;
  const userData = useSelector((loginData) => loginData.login.login);
  const [validationSummary, setValidationSummary] = useState("");
  const [loading, setLoading] = useState(false); //spinner

  const thisStepCompleted = (value) => {
    switch (value) {
      // case 1:
      case 2:
      case 3:
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (organizerDetails) {
      const stepNumber = parseInt(
        organizerDetails?.stepCompleted.replace(/\D/g, ""),
        10
      );

      if (thisStepCompleted(stepNumber)) {
        initialData = { ...initialData, ...organizerDetails };
        setIsTicketInvolveGst(organizerDetails.isTicketInvolveGst);
        initialData["IsValid"] = mParam;
        setInputValue(initialData);
      }
    }
  }, [organizerDetails]);

  const saveAndProceed = async (e) => {
    e.preventDefault();

    if (
      IsValid.businessEntityType &&
      IsValid.signatoryName &&
      IsValid.signatoryPanNo &&
      inputValue.signatoryPanDoc !== "" &&
      IsValid.signatoryAadharNo &&
      inputValue.signatoryAadharDoc !== ""
    ) {
      inputValue.userId = userData.login.user.userId;
      inputValue.isTicketInvolveGst = valueIsTicketInvolveGst;

      if (valueIsTicketInvolveGst) {
        if (
          IsValid.businessGstNo &&
          IsValid.businessGstStateNo &&
          IsValid.businessHsnCode &&
          IsValid.businessGstPercent &&
          inputValue.businessGstDoc !== "" &&
          inputValue.businessAddressProofDoc !== ""
        ) {
          await sendKYCInfoToServer(inputValue, userData.login.token);
        } else {
          // alert("Enter required fields");
          let displaMsg = "";
          // for (let key in mParam) {
          //   if (!inputValue?.IsValid[key]) {
          //     displaMsg += "<li>" + key + "</li>";
          //   }
          // }

          if (!inputValue?.IsValid["businessGstNo"]) {
            displaMsg += "<li>" + "Business GST No." + "</li>";
          }

          if (!inputValue?.IsValid["businessGstStateNo"]) {
            displaMsg += "<li>" + "Business GST State No" + "</li>";
          }

          if (!inputValue?.IsValid["businessHsnCode"]) {
            displaMsg += "<li>" + "Business HSN Code" + "</li>";
          }

          if (!inputValue?.IsValid["businessGstPercent"]) {
            displaMsg += "<li>" + "Business GST Percent" + "</li>";
          }

          if (inputValue.businessGstDoc == "") {
            displaMsg += "<li>" + "Business GST Doc" + "</li>";
          }

          if (inputValue.businessAddressProofDoc == "") {
            displaMsg += "<li>" + "Business Address Proof Doc" + "</li>";
          }

          displaMsg =
            "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
          setValidationSummary(() => displaMsg);
        }
      } else {
        sendKYCInfoToServer(inputValue, userData.login.token);
      }
    } else {
      // alert("Enter required fields");
      let displaMsg = "";

      if (!inputValue?.IsValid["businessEntityType"]) {
        displaMsg += "<li>" + "Business Entity Type" + "</li>";
      }

      if (!inputValue?.IsValid["signatoryName"]) {
        displaMsg += "<li>" + "Signatory Name" + "</li>";
      }

      if (!inputValue?.IsValid["signatoryPanNo"]) {
        displaMsg += "<li>" + "Signatory Pan No" + "</li>";
      }

      if (inputValue.signatoryPanDoc == "") {
        displaMsg += "<li>" + "Signatory Pan No Doc" + "</li>";
      }

      if (!inputValue?.IsValid["signatoryAadharNo"]) {
        displaMsg += "<li>" + "Signatory Aadhar No" + "</li>";
      }

      if (inputValue.signatoryAadharDoc == "") {
        displaMsg += "<li>" + "Signatory Aadhar Doc" + "</li>";
      }

      displaMsg =
        "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
      setValidationSummary(() => displaMsg);
    }
  };

  const sendKYCInfoToServer = async (inputValue, token) => {
    // send data to server
    setLoading(true);
    const result = await SetOrganizerKYCDetails(inputValue, token);
    setLoading(false);
    //next step
    if (result) {
      if (result.success) {
        onChange(result?.data);
        onStepComplete(2);
      } else {
        alert("Error:-" + result?.message);
      }
    } else {
      alert(API_RESONSE_FAILED);
    }
  };

  const handleChangeGSTInvolved = (event) => {
    setIsTicketInvolveGst(event.target.checked);
    //inputValue.isTicketInvolveGst = event.target.checked;
    setInputState("isTicketInvolveGst", event.target.checked, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "businessEntityType":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "businessPanNo":
          val = Call.IsValidPANCard(value);
          if (!val) {
            msg = "Please enter correct PAN Card details.";
          }
          break;

        case "businessGstNo":
          if (valueIsTicketInvolveGst === true) {
            if (Call.IsEmpty(value)) {
              msg = "Please enter GST Number";
            } else {
              val = Call.IsValidGSTNumber(value);
              if (!val) msg = "Invalid " + value;
            }
          } else {
            val = true;
          }

          break;

        case "businessGstStateNo":
          if (valueIsTicketInvolveGst === true) {
            if (Call.IsEmpty(value)) {
              msg = "Please enter GST Number";
            } else {
              val = Call.IsNumberOnlyInput(value);
              if (!val) msg = "Invalid " + value;
            }
          } else {
            val = true;
          }

          break;

        case "businessHsnCode":
          if (valueIsTicketInvolveGst === true) {
            if (Call.IsEmpty(value)) {
              msg = "Please enter HSN Number";
            } else {
              val = Call.IsValidHSN(value);
              if (!val) msg = "Invalid " + value;
            }
          } else {
            val = true;
          }

          break;

        case "businessGstPercent":
          if (valueIsTicketInvolveGst === true) {
            if (Call.IsEmpty(value)) {
              msg = "Please enter GST percent.";
            } else {
              val = Call.IsNumberOnlyInput(value);
              if (!val) msg = "Invalid " + value;
            }
          } else {
            val = true;
          }

          break;

        case "signatoryName":
          if (Call.IsEmpty(value)) {
            msg = "Please enter signatory name";
          } else {
            val = Call.IsValidCharNumWithSpace(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "signatoryPanNo":
          val = Call.IsValidPANCard(value);
          if (!val) {
            msg = "Please enter correct PAN Card details.";
          }
          break;

        case "signatoryAadharNo":
          val = Call.IsValidAadhar(value);
          if (!val) {
            msg = "Please enter valid aadhar number.";
          }
          break;

        default:
          break;
      }
    }

    //if(!val && msg.length>0) {
    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
    //}
    return val;
  };
  const setInputState = (name, value, msg, val) => {
    //set value
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
  };
  const handleBusinessPanDoc = (file) => {
    //inputValue.businessPanDoc = file;
    setInputState("businessPanDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleBusinessGstDoc = (file) => {
    //inputValue.businessGstDoc = file;
    setInputState("businessGstDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleBusinessAddressProofDoc = (file) => {
    //inputValue.businessAddressProofDoc = file;
    setInputState("businessAddressProofDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleSignatoryPanDoc = (file) => {
    //inputValue.signatoryPanDoc = file;
    setInputState("signatoryPanDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const handleSignatoryAadharDoc = (file) => {
    //inputValue.signatoryAadharDoc = file;
    setInputState("signatoryAadharDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  //main return
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div class="card p-4 organizer-logo my-3">
            <form>
              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Entity Type<span class="text-danger">*</span>
                </label>
                <select
                  class="form-select p-2"
                  aria-label="Default select example"
                  value={inputValue.businessEntityType}
                  onChange={handleChange}
                  name="businessEntityType"
                  autoFocus
                >
                  <option selected="">Select Entity Type</option>
                  <option value="Partnership">Partnership</option>
                  <option value="LLP">LLP</option>
                  <option value="Proprietary">Proprietary</option>
                  <option value="Ltd">Ltd</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Business PAN Number
                </label>

                <InputTextBox
                  id="businessPanNo"
                  name="businessPanNo"
                  type="text"
                  placeholder="PAN Number"
                  isValid={inputValue?.IsValid?.businessPanNo}
                  errorMsg={inputValue?.errors?.businessPanNo}
                  value={inputValue.businessPanNo}
                  onChange={handleChange}
                  maxlength={10}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  id="businessPanDoc"
                  name="businessPanDoc"
                  getFileDetail={handleBusinessPanDoc}
                  title={"Business PAN Card"}
                >
                  fileData
                </FileUploadSingle>
                {typeof inputValue?.businessPanDoc === "string" &&
                  inputValue?.businessPanDoc && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessPanDoc/" +
                          inputValue?.businessPanDoc
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />
                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessPanDoc/" +
                          inputValue?.businessPanDoc
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue.businessPanDoc})
                      </a>
                    </div>
                  )}
              </div>

              <div class="d-flex my-3 ">
                <label for="exampleInputEmail1" class="form-label">
                  Does Ticket Involve GST?
                </label>
                <div class="ms-5 form-switch form-check-reverse flex-grow-1">
                  <input
                    class="form-check-input py-2 px-3"
                    type="checkbox"
                    id="flexSwitchCheckReverse"
                    defaultChecked={false}
                    value={valueIsTicketInvolveGst}
                    checked={valueIsTicketInvolveGst}
                    onChange={handleChangeGSTInvolved}
                    name="isTicketInvolveGst"
                  />
                </div>
              </div>

              {valueIsTicketInvolveGst ? (
                <div>
                  <div class="my-3 w-100 text-start">
                    <label for="exampleInputEmail1" class="form-label">
                      Business GST Number<span class="text-danger">*</span>
                    </label>

                    <InputTextBox
                      id="businessGstNo"
                      name="businessGstNo"
                      type="text"
                      placeholder="GSTIN"
                      isValid={inputValue?.IsValid?.businessGstNo}
                      errorMsg={inputValue?.errors?.businessGstNo}
                      value={inputValue.businessGstNo}
                      onChange={handleChange}
                      maxlength={15}
                    />
                  </div>

                  <div class="my-3 w-100 text-start">
                    <label for="exampleInputEmail1" class="form-label">
                      GST State Number<span class="text-danger">*</span>
                    </label>

                    <InputTextBox
                      id="businessGstStateNo"
                      name="businessGstStateNo"
                      type="text"
                      placeholder="GST State Number"
                      isValid={inputValue?.IsValid?.businessGstStateNo}
                      errorMsg={inputValue?.errors?.businessGstStateNo}
                      value={inputValue.businessGstStateNo}
                      onChange={handleChange}
                      maxlength={2}
                    />
                  </div>

                  <div class="my-3 w-100 text-start">
                    <label for="exampleInputEmail1" class="form-label">
                      HSN Code<span class="text-danger">*</span>
                    </label>

                    <InputTextBox
                      id="businessHsnCode"
                      name="businessHsnCode"
                      type="text"
                      placeholder="HSN Code"
                      isValid={inputValue?.IsValid?.businessHsnCode}
                      errorMsg={inputValue?.errors?.businessHsnCode}
                      value={inputValue.businessHsnCode}
                      onChange={handleChange}
                      maxlength={6}
                    />
                  </div>

                  <div class="my-3 w-100 text-start">
                    <label for="exampleInputEmail1" class="form-label">
                      GST %<span class="text-danger">*</span>
                    </label>

                    <InputTextBox
                      id="businessGstPercent"
                      name="businessGstPercent"
                      type="text"
                      placeholder="GST %"
                      isValid={inputValue?.IsValid?.businessGstPercent}
                      errorMsg={inputValue?.errors?.businessGstPercent}
                      value={inputValue.businessGstPercent}
                      onChange={handleChange}
                      maxlength={2}
                    />
                  </div>

                  <div class="my-3 w-100 text-start">
                    <FileUploadSingle
                      id="businessGstDoc"
                      name="businessGstDoc"
                      getFileDetail={handleBusinessGstDoc}
                      title={"Upload GST"}
                    >
                      fileData
                    </FileUploadSingle>
                    {typeof inputValue?.businessGstDoc === "string" &&
                      inputValue?.businessGstDoc && (
                        <div class="alert alert-info" role="alert">
                          <img
                            src={
                              ImagePath +
                              userData?.login?.user?.userId +
                              "/businessGstDoc/" +
                              inputValue.businessGstDoc
                            }
                            style={{
                              maxHeight: "100px",
                              maxWidth: "100px",
                              marginRight: "2px",
                              padding: "2px",
                            }}
                          />
                          <a
                            href={
                              ImagePath +
                              userData?.login?.user?.userId +
                              "/businessGstDoc/" +
                              inputValue.businessGstDoc
                            }
                            target="_blank"
                          >
                            Click to download ({inputValue.businessGstDoc})
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  id="businessAddressProofDoc"
                  name="businessAddressProofDoc"
                  getFileDetail={handleBusinessAddressProofDoc}
                  title={"Business Address Proof"}
                >
                  fileData
                </FileUploadSingle>
                {typeof inputValue?.businessAddressProofDoc === "string" &&
                  inputValue?.businessAddressProofDoc && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessAddressProofDoc/" +
                          inputValue.businessAddressProofDoc
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />
                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/businessAddressProofDoc/" +
                          inputValue.businessAddressProofDoc
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue.businessAddressProofDoc})
                      </a>
                    </div>
                  )}
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Authorised Signatory Name<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="signatoryName"
                  name="signatoryName"
                  type="text"
                  placeholder="Authorised Signatory Name"
                  isValid={inputValue?.IsValid?.signatoryName}
                  errorMsg={inputValue?.errors?.signatoryName}
                  value={inputValue.signatoryName}
                  onChange={handleChange}
                  maxlength={30}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Authorised Signatory PAN<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="signatoryPanNo"
                  name="signatoryPanNo"
                  type="text"
                  placeholder="PAN Number"
                  isValid={inputValue?.IsValid?.signatoryPanNo}
                  errorMsg={inputValue?.errors?.signatoryPanNo}
                  value={inputValue.signatoryPanNo}
                  onChange={handleChange}
                  maxlength={10}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  id="signatoryPanDoc"
                  name="signatoryPanDoc"
                  getFileDetail={handleSignatoryPanDoc}
                  title={"Upload Authorised Signatory PAN"}
                >
                  fileData
                </FileUploadSingle>
                {typeof inputValue?.signatoryPanDoc === "string" &&
                  inputValue?.signatoryPanDoc && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/signatoryPanDoc/" +
                          inputValue.signatoryPanDoc
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />
                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/signatoryPanDoc/" +
                          inputValue.signatoryPanDoc
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue.signatoryPanDoc})
                      </a>
                    </div>
                  )}
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Authorised Signatory Aadhaar Card
                  <span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="signatoryAadharNo"
                  name="signatoryAadharNo"
                  type="text"
                  placeholder="Aadhar Card"
                  isValid={inputValue?.IsValid?.signatoryAadharNo}
                  errorMsg={inputValue?.errors?.signatoryAadharNo}
                  value={inputValue.signatoryAadharNo}
                  onChange={handleChange}
                  maxlength={12}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  id="signatoryAadharDoc"
                  name="signatoryAadharDoc"
                  getFileDetail={handleSignatoryAadharDoc}
                  title={"Upload Authorised Signatory Aadhaar Card"}
                >
                  fileData
                </FileUploadSingle>
                {typeof inputValue?.signatoryAadharDoc === "string" &&
                  inputValue?.signatoryAadharDoc && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/signatoryAadharDoc/" +
                          inputValue.signatoryAadharDoc
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />
                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/signatoryAadharDoc/" +
                          inputValue.signatoryAadharDoc
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue.signatoryAadharDoc})
                      </a>
                    </div>
                  )}
              </div>
            </form>
            <div class="mb-3 text-center">
              <Button onClick={saveAndProceed}>Save & Proceed</Button>
            </div>
            {validationSummary !== "" && (
              <div
                class="alert alert-danger"
                role="alert"
                dangerouslySetInnerHTML={{ __html: validationSummary }}
              ></div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrganizerKYCDetails;
