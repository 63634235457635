import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import AnnouncementItem from "../../components/announcement/AnnouncementItem";
import { GetAllAnnouncementOfOrganizer } from "../../services/announcementAPI";
import Button from "../../components/controls/buttons/Button";
import OrganizerMaster from "../master/OrganizerMaster";
import ShowLoginMsg from "../../components/login/ShowLoginMsg";
import Spinner from "../../components/controls/spinner/Spinner";

//main function
const OrganizerAnnouncementPage = () => {
  // declare start
  const userData = useSelector((loginData) => loginData.login.login);
  const [sendAnnouncement, setSendAnnouncement] = useState(false);
  const [loadAnnouncements, setLoadAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true); // loading spinner
  const [currentPage, setCurrentPage] = useState(1);
  const [announcementsPerPage] = useState(10); // Change this value as needed
  // declare end

  // functions/events/methods
  useEffect(() => {
    // Fetch announcements when component mounts

    fetchAnnouncements();
  }, [userData?.login?.token]);

  const fetchAnnouncements = async () => {
    try {
      if (
        userData?.login?.token !== null &&
        userData?.login?.token !== undefined &&
        userData?.login?.token !== ""
      ) {
        const response = await GetAllAnnouncementOfOrganizer(
          userData?.login?.token
        );
        setLoading(false);
        if (response?.success) {
          setLoadAnnouncements(response?.data);
        } else if (response !== undefined) {
          alert(response?.message);
        }
      }
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  };

  const handleFetchAnnouncement = () => {
    fetchAnnouncements();
  };
  //

  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  } else {
    return (
      <OrganizerMaster>
        <div class="col">
          <h3 class="font-weight-semibold">Dashboard</h3>
        </div>
        <ShowLoginMsg />
      </OrganizerMaster>
    );
  }

  const indexOfLastAnnouncement = currentPage * announcementsPerPage;
  const indexOfFirstAnnouncement =
    indexOfLastAnnouncement - announcementsPerPage;
  const currentAnnouncements = loadAnnouncements.slice(
    indexOfFirstAnnouncement,
    indexOfLastAnnouncement
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(loadAnnouncements.length / announcementsPerPage);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  //main return
  return (
    <OrganizerMaster>
      <div>
        <h3 class="font-weight-semibold ">My Announcements</h3>
        <div className="d-flex justify-content-end">
          <Link
            to={"/send-announcement"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Button
              type="button"
              onClick={() => setSendAnnouncement(true)}
              // className="btn btn"
              style={{
                marginTop: "-60px",
                padding: "10px",
                fontSize: "13px",
              }}
            >
              Send Announcement
            </Button>
          </Link>
        </div>

        <div class="col-12 main-contian">
          {/* History Records */}
          {/* <div class="row my-3">
            <div class="col">
              <h3 class="font-weight-semibold mx-2">History</h3>
            </div>
          </div> */}

          {loading ? (
            <Spinner />
          ) : (
            <>
              {loadAnnouncements?.length === 0 ? (
                <p>No announcements.</p>
              ) : (
                <div class="row px-2 announcement">
                  {loadAnnouncements &&
                    loadAnnouncements.map((item, i) => (
                      <AnnouncementItem
                        key={i}
                        announcement={item}
                        announcementId={item.announcementId}
                        subject={item.subject}
                        audience={item?.audience}
                        emailBody={item.emailBody}
                        createdAt={item.createdAt}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </div>

        {/* {sendAnnouncement &&
          createPortal(
            <AnnouncementModel
              onClose={() => setSendAnnouncement(false)}
              onClick={handleFetchAnnouncement}
            />,
            document.body
          )} */}

        {/* Pagination */}
        <div className="d-flex justify-content-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination bashboard-pagination pagination-link">
              <li
                className={`page-item m-1 ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={handlePreviousPage}
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="16"
                      viewBox="0 -960 960 960"
                      width="16"
                    >
                      <path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"></path>
                    </svg>
                  </span>
                </a>
              </li>
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  key={index}
                  className={`page-item m-1 ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li
                className={`page-item m-1 ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <a
                  className="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={handleNextPage}
                >
                  <span aria-hidden="true">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.0"
                      width="18"
                      height="18"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path d="M1665 4371 c-49 -23 -99 -74 -119 -123 -22 -51 -20 -144 3 -193 13 -27 250 -271 745 -765 l726 -725 -734 -735 c-667 -668 -735 -739 -750 -784 -30 -91 -16 -169 42 -236 51 -60 104 -84 182 -84 47 0 76 6 105 21 24 13 380 362 871 855 722 723 834 839 849 880 21 56 17 124 -10 183 -28 61 -1644 1677 -1705 1704 -58 27 -150 27 -205 2z" />
                      </g>
                    </svg>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* </div> */}
    </OrganizerMaster>
  );
};

export default OrganizerAnnouncementPage;
