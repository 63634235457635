import EventCard from "../../components/event/EventCard.jsx";
import { GetAllPastEventOfHomePage } from "../../services/eventAPI.jsx";
import { useEffect, useState } from "react";

const PastEventHomePage = () => {
  const [eventData, setEventData] = useState("");

  useEffect(() => {
    const getAllPastEvents = async () => {
      try {
        const pastEventOfHomePage = await GetAllPastEventOfHomePage();

        // const pastEventOfHomePage = await GetCityEventData("656");

        if (
          pastEventOfHomePage.success === true &&
          pastEventOfHomePage?.data?.length > 0
        ) {
          setEventData(pastEventOfHomePage);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllPastEvents();
  }, [eventData]);

  return (
    <>
      <div className="container text-center">
        <div class="row mx-2">
          <div class="col text-start cat-name">
            <b>Past Events</b>
          </div>
        </div>
        <div className="row align-items-start mx-2 my-3">
          {eventData &&
            eventData.data.map &&
            eventData.data.map((event, index) => {
              return (
                <EventCard
                  key={event.eventId}
                  event={event}
                  city={event.cityId}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default PastEventHomePage;
