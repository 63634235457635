import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import ImageUploader from "../../controls/uploader/imageUploader";
import {
  DeleteSinglePhoto,
  SaveEventPhoto,
  SaveEventPhotoGuest,
} from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import Button from "../../controls/buttons/Button";
import { NO_IMAGE_PATH, FOLDER_EVENT_PHOTO } from "../../../utils/Constants";
import * as initialState from "../../../services/initialState";
import * as common from "../../../utils/commonFun";
import Spinner from "../../controls/spinner/Spinner";

//
// Form initial Data
let initialData = initialState.EventPhotos;
let ImagePath = process.env.REACT_APP_EVENTS;
let eventId;
const EventPhotos = ({ eventDetails, saveEventPhotos, onStepComplete }) => {
  //if store data available

  useEffect(() => {
    if (eventDetails) {
      eventId = eventDetails.eventId;
      //initialData.files = eventDetails.eventPhotos;
      let photoList = eventDetails.eventPhotos?.map((item, index) => {
        let imgPath = ImagePath + eventId + FOLDER_EVENT_PHOTO + item.docName;
        return {
          file: { name: imgPath },
          thumbnail: imgPath,
        };
      });
      initialData.files = photoList;
    }
  }, [eventDetails]);
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid, files } = inputValue;
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false); //spinner

  const handleEventPhotos = (file) => {
    let newfile = [file];
    if (inputValue && inputValue.files) {
      //set value
      newfile.map((item) => {
        setInputValue((prev) => ({
          ...prev,
          files: [...prev?.files, ...item],
        }));
      });
    } else {
      //set value
      setInputValue((prev) => ({
        ...prev,
        files: [...file],
      }));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const saveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);

      //post data logic
      let postValue = initialState.EventPhotos;
      let skipKey = initialState.skipKey;
      for (let key in postValue) {
        try {
          if (skipKey.includes(key)) {
            delete postValue[key];
          } else {
            postValue[key] = inputValue[key];
          }
        } catch {}
      }

      if (eventId) postValue.eventId = eventId;
      //send to server
      setLoading(true);
      let result;
      if (userData?.user) {
        postValue.userId = userData.user.userId;
        const token = userData.token;
        result = await SaveEventPhoto(postValue, token);
      } else {
        result = await SaveEventPhotoGuest(postValue);
      }
      setLoading(false);
      //next step
      if (result) {
        if (result.success) {
          eventDetails = result.data;
          eventDetails = {
            ...eventDetails,
            IsValid: { ...IsValid, ...inputValue?.IsValid },
          };
          saveEventPhotos(eventDetails);
          onStepComplete(3);
        } else {
          alert("Error:-" + result.message);
        }
      } else {
        alert(API_RESONSE_FAILED);
      }
    }
  };
  const openFileUpdload = () => {
    const obj = document.getElementById("eventPhotoUploads");
    if (obj) obj.click();
  };

  /**
   * This function is used to delete file which is uploaded on server.
   *
   * @param item
   */
  const handledDelete = async (item) => {
    let eventId = eventDetails?.eventId;
    const response = await DeleteSinglePhoto(
      eventId,
      item.docId,
      userData.token
    );
    if (response?.success) {
      alert(response?.message);
      eventDetails = response.data;
      eventDetails = {
        ...eventDetails,
        IsValid: { ...IsValid, ...inputValue?.IsValid },
      };
      saveEventPhotos(eventDetails);
    } else {
      alert(response?.message);
    }
  };

  /**
   * This function is used to delete local file.
   *
   * @param {fileList} fileList
   */
  const handledDeleteLocalFile = (fileList) => {
    if (fileList?.files) {
      var list = fileList?.files.map((item) => {
        return item.file;
      });
      //set value
      setInputValue((prev) => ({
        ...prev,
        files: [...list],
      }));
    }
  };

  const renderOldPhotos = eventDetails.eventPhotos?.map((item, index) => {
    let imgPath =
      ImagePath + eventDetails.eventId + FOLDER_EVENT_PHOTO + item.docName;
    return (
      <>
        {index === 0 && <h2>Old Photos</h2>}

        <div class="d-flex justify-content-center align-items-center">
          <div class="p-3 flex-grow-1 alert-info alert d-flex" role="alert">
            <div className="col-sm-4 ml-1">
              <img
                src={imgPath}
                style={{ maxHeight: "100px", maxWidth: "100px" }}
              />
            </div>
            <div className="col-sm-4 mt-4">
              <a href={imgPath} target="_blank">
                Click to download ({item.docName})
              </a>
            </div>
            <div className="col-sm-4 mt-4">
              <Button secondary outline onClick={() => handledDelete(item)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  });

  //
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="card p-4 org-events-photo-upload">
            <h5>Event Photos</h5>
            <div class="d-flex justify-content-center align-items-center">
              <div class="p-2 flex-grow-1">
                Upload Cover Image (1920x1080px)
              </div>
              <div class="p-2">
                <Button
                  newClassName="btn btn-outline-dark select-btn"
                  onClick={openFileUpdload}
                >
                  + Add
                </Button>
              </div>
            </div>
            <div className="form-group file-area">
              <ImageUploader
                handleEventPhotos={handleEventPhotos}
                multiple={true}
                files={initialData.files}
                deleteLocalFile={handledDeleteLocalFile}
              />
            </div>

            <div className="row my-3">{renderOldPhotos}</div>

            {eventDetails.eventId && (
              <div className="mb-3 text-center">
                <Button onClick={saveAndProceed}>Save & Proceed</Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventPhotos: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(EventPhotos);
//export default EventPhotos;
