import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetEventDetailsData } from "../../services/eventAPI";
import { FOLDER_EVENT_PHOTO } from "../../utils/Constants";
import Accordion from "../controls/accordion/Accordion";
import { convertDateTimeToISTForUITimeOnly } from "../../utils/DateTimeUtility";

let ImagePath = process.env.REACT_APP_EVENTS;

const BookingSuccess = ({ txnId, orderConfirmationId, eventId, message }) => {
  const navigate = useNavigate();

  const [eventDetails, setEventDetails] = useState("");
  const [eventImage, setEventImage] = useState("");

  useEffect(() => {
    const eventDetails = async () => {
      const eventDetailsResponse = await GetEventDetailsData(eventId);
      if (eventDetailsResponse.success) {
        setEventDetails(eventDetailsResponse.data);

        setEventImage(
          ImagePath +
            eventId +
            FOLDER_EVENT_PHOTO +
            eventDetailsResponse.data?.eventPhotos[0]?.docName
        );
      } else {
        alert(eventDetailsResponse.message);
      }
    };

    eventDetails();
  }, [eventId]);

  const navigateToHome = () => {
    navigate("/");
  };

  function getAccordionData(eventDetails) {
    try {
      let eventTab = [
        {
          tabNo: 1,
          header: "Terms & Conditions",
          iconName: "thank-message",
          text: eventDetails.terms,
        },
      ];

      return eventTab;
    } catch (error) {}
  }

  return (
    // <div>
    <div class="container text-center topmargin thank-you">
      <div class="row justify-content-center">
        <div class="col-6 mt-2 text-center">
          <img src="/images/thank-you.svg" alt="" />
          <h1 class="my-3">You have successfully booked your ticket </h1>
          {txnId === undefined ? (
            <p>Order Confirmation Id: {orderConfirmationId}</p>
          ) : (
            <p>Transaction Id: {txnId}</p>
          )}

          <p>You will receive invoice of ticket over email.</p>
          <div class="card align-items-center mb-4">
            <img src={eventImage} class="img-fluid" alt="" />
          </div>
          <h2 class="my-3">{eventDetails.eventName} </h2>
          <div class="d-flex justify-content-center mb-5">
            <div class="me-2 time-location">
              <img src="/images/thanks_calender.svg" alt="" />
              {/* <span>Oct</span> | <span>7:00 AM</span> */}
              <span class="ms-2">
                {convertDateTimeToISTForUITimeOnly(eventDetails.eventStartDate)}
              </span>
            </div>{" "}
            |
            <div class="ms-2 time-location">
              <img src="/images/thanks_location.svg" alt="" />{" "}
              {eventDetails.cityName} {", "} {eventDetails.stateName}
            </div>
          </div>
          <div class="mb-5">
            {/* <div class="btn btn-dark px-5 me-2" onClick={downloadTicket}>
              Download Ticket
            </div> */}
            <div class="btn btn-outline-dark ms-2" onClick={navigateToHome}>
              Go to Home page
            </div>
          </div>
          {/* Accordion  */}
          <Accordion items={getAccordionData(eventDetails)} />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default BookingSuccess;
