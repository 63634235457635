import React from "react";

const ShowLoginMsg = () => {
  return (
    <>
      <div class="alert alert-warning mt-2" role="alert">
        Please login!{" "}
        <a
          href="#"
          onClick={() => {
            return document.getElementById("btnLogin").click();
          }}
        >
          Click Here
        </a>
      </div>
    </>
  );
};

export default ShowLoginMsg;
