import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import SliderRecommededEvent from "../../components/slider/SliderEventRecommended";
import EventCardTypeLarge from "../../components/event/EventCardLarge";
import { GetEventDetailsData } from "../../services/eventAPI";
import Spinner from "../../components/controls/spinner/Spinner";

const ViewEventDetails = () => {
  const { eventId, eventName } = useParams();
  const titleFocus = useRef();
  useEffect(() => {
    // titleFocus.current.focus();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // const refCall = "EventDetails";
  //const [eventid]=useParams();
  // const { eventid, eventname } = useParams();

  //load data
  const [eventDetailsData, setEventDetailsData] = useState(null);
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const eventDetailsResponseData = await GetEventDetailsData(eventId);
        if (eventDetailsResponseData.success === true) {
          setEventDetailsData(eventDetailsResponseData.data);
          setLoading(false);
        } else {
          alert(eventDetailsResponseData.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEventDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        eventDetailsData && (
          <>
            <div class="container">
              <div class="row mx-2">
                <div
                  class="col text-start cat-name"
                  ref={titleFocus}
                  id="titleFocus"
                >
                  {/* <b>Events</b> {eventDetailsData.eventName} */}
                </div>
              </div>

              {/*AccordionEvent*/}
              <EventCardTypeLarge
                eventDetails={eventDetailsData}
                titleFocus={titleFocus}
              />
              <div calss="AppClearBoth"></div>

              <div calss="AppClearBoth"></div>
              {/*Recommend For You*/}
              <SliderRecommededEvent
                from={"ViewEventDetails"}
                titleFocus={titleFocus}
                eventDetails={eventDetailsData}
              />
              {/* <Promotion from={"EventTicketOptions"} /> */}
            </div>
          </>
        )
      )}
    </>
  );
};

export default ViewEventDetails;
