import { fork, all } from "redux-saga/effects";
import * as ce from "./createEvent";
import {
  watchFetchCartEvents,
  watchAddCartEvents,
  watchDeleteCartEvents,
  watchFetchUserDetails,
  watchAddUserDetails,
  watchDeleteUserDetails,
} from "./events";

import { watchFetchLogin, watchAddLogin, watchDeleteLogin } from "./Login";

export default function* rootSaga() {
  yield all([
    //createEvent-start
    //eventDetails
    fork(ce.watchFetchEventDetails),
    fork(ce.watchAddEventDetails),
    /* //eventLocation
    fork(ce.watchFetchEventLocation),
    fork(ce.watchAddEventLocation),
    //eventPhotos
    fork(ce.watchFetchEventPhotos),
    fork(ce.watchAddEventPhotos),
    //ticketDetails
    fork(ce.watchFetchTicketDetails),
    fork(ce.watchAddTicketDetails),
    //createEvent-end */

    //cartEvent
    fork(watchFetchCartEvents),
    fork(watchAddCartEvents),
    fork(watchDeleteCartEvents),

    //cartEvent-userDetails
    fork(watchFetchUserDetails),
    fork(watchAddUserDetails),
    fork(watchDeleteUserDetails),

    //Login
    fork(watchFetchLogin),
    fork(watchAddLogin),
    fork(watchDeleteLogin),
  ]);
}
