import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetOnGoingEventData } from "../../services/eventAPI.jsx";
import EventCardDraft from "../event/EventCardDraft.jsx";
import Spinner from "../controls/spinner/Spinner.jsx";

const ActiveEventCard = () => {
  const { eventCity, eventCityId } = useParams();
  const [onGoingEvents, setOnGoingEvents] = useState("");
  const userData = useSelector((loginData) => loginData.login.login);
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    getAllOnGoingEvent();
  }, []);

  const getAllOnGoingEvent = async () => {
    try {
      const onGoingEventsResponse = await GetOnGoingEventData(
        userData.login.token
      );
      if (onGoingEventsResponse.success === true) {
        const onGoingEventResponse = onGoingEventsResponse.data;
        setOnGoingEvents(onGoingEventResponse);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="container text-center ml-2 mt-0">
            <div className="row align-items-start">
              {onGoingEvents.length === 0 ? (
                <p>No Active events found.</p>
              ) : (
                onGoingEvents &&
                onGoingEvents.map &&
                onGoingEvents.map((event, index) => {
                  return (
                    <EventCardDraft
                      key={event.eventId}
                      event={event}
                      city={eventCity}
                    />
                  );
                })
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ActiveEventCard;
