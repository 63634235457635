import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  linkWithPopup,
} from "firebase/auth";
import firebase from "firebase/compat/app";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
};

firebase.initializeApp(firebaseConfig);

const providerGoogle = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
const providerMicrosoft = new OAuthProvider("microsoft.com");
const auth = getAuth();

export const googleLoginToken = async () => {
  try {
    const signInResponse = await signInWithPopup(auth, providerGoogle);
    const token = signInResponse.user.accessToken;
    // console.log("Google token", signInResponse.user.accessToken);

    return token;
  } catch (error) {
    // const errorCode = error.code;
    // const errorMessage = error.message;
    // The email of the user's account used.
    // const email = error.customData.email;
    // The AuthCredential type that was used.
    // const credential = GoogleAuthProvider.credentialFromError(error);
    // const token = credential.accessToken;
    return mergeAccounts(error, providerGoogle);
  }
};

export const facebookLogin = async () => {
  try {
    const signInResponse = await signInWithPopup(auth, providerFacebook);

    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    // const credential =
    //   FacebookAuthProvider.credentialFromResult(signInResponse);
    // const accessToken = credential.accessToken;
    const accessToken = signInResponse.user.accessToken;
    // console.log("Facebook token", accessToken);

    return accessToken;
  } catch (error) {
    return mergeAccounts(error, providerFacebook);
  }
};

export const microsoftLogin = async () => {
  try {
    const signInResponse = await signInWithPopup(auth, providerMicrosoft);

    // Get the OAuth access token and ID Token
    // const credential = OAuthProvider.credentialFromResult(signInResponse);
    // const token = credential.accessToken;
    const accessToken = signInResponse.user.accessToken;
    // console.log("Microsoft token", accessToken);

    return accessToken;
  } catch (error) {
    return mergeAccounts(error, providerFacebook);
  }
};

const mergeAccounts = async (error, provider) => {
  await linkWithPopup(auth.currentUser, provider)
    .then((result) => {
      // Accounts successfully linked.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      return user.accessToken;
    })
    .catch((error) => {
      // Handle Errors here.
      // ...
      console.log("Linking error :-", error);
    });
};

export const handleFirebaseLogout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
      // console.log("User signed out successfully");
      return true;
    })
    .catch((error) => {
      // An error happened.
      console.error("Error signing out:", error);
      return false;
    });
};
