import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetQueryString } from "../../../utils/commonFun";
import { decryptData } from "../../../utils/cryptoJs";
import CreateEventTabs from "../../../components/controls/Tab/CreateEventTabs";
import EventCardTypeLarge from "../../../components/event/EventCardLarge";
import * as initialState from "../../../services/initialState";
import * as storeAction from "../../../redux/actions/createEvent";
import * as commom from "../../../utils/commonFun";
import {
  addToLoginRequest,
  fetchLoginRequest,
} from "../../../redux/actions/Login";
import { GetLoginDataAd } from "../../../services/commonAPI";
import { KEY_EVENT_ID } from "../../../utils/Constants";

//main function
const CreateEventAd = ({
  saveLogin,
  login,
  fetchLogin,
  eventDetails,
  fetchEventDetails,
}) => {
  //variables
  let queryObj = GetQueryString();
  let searchText = queryObj?.text;
  let inputValue = decryptData(queryObj?.q);
  const [eventData, setEventData] = useState("");
  //const userData = useSelector((loginData) => loginData.login.login);
  const navigate = useNavigate();
  let eventDetailsPreview = initialState.EventDetailsPreview.data;
  useEffect(() => {
    if (inputValue && inputValue?.length > 100) {
      //1st clear data
      //commom.clearLocalData();
      commom.removeItem(KEY_EVENT_ID);
      //get user data
      let userData = inputValue?.split("~");
      if (userData) {
        const fetchData = async () => {
          let referenceId = userData[1];
          let userId = userData[2];
          let token = userData[3];

          const response = await GetLoginDataAd(referenceId, userId, token);
          if (response?.success === true) {
            let loginData = response.data;
            let login = {
              ...loginData,
              update: true,
              referenceId: referenceId,
            };
            setEventData(() => loginData);
            await saveLogin(login);
            await fetchLogin();
            navigate("/create-event-ad/");
          }
        };

        fetchData();
        if (userData?.length === 5) {
          let eventId = userData[4];
          let eventName = "";
          if (eventId) {
            commom.saveToLocal(KEY_EVENT_ID, eventId);
            //localStorage.setItem(KEY_EVENT_ID, eventId);
            eventDetails = fetchEventDetails(eventId);
            eventDetailsPreview = eventDetails;
            navigate("/create-event-ad/" + eventId + "/" + eventName);
          }
        }
      }
    }
  }, [inputValue]);
  //SaveEventDetails(postValue, token);
  const eventDetails1 = useSelector((state) => {
    return state.createEvent.eventDetails.eventDetails || [];
  });

  const loadPreviewData = () => {
    if (eventDetails?.eventDetails) {
      eventDetailsPreview = {
        ...eventDetailsPreview,
        ...eventDetails.eventDetails,
      };
    } else if (eventDetails1) {
      eventDetailsPreview = {
        ...eventDetailsPreview,
        ...eventDetails1,
      };
    }
  };

  loadPreviewData();

  //
  return (
    <>
      {/*  {inputValue} */}
      {eventData === "" ? (
        <>
          <p>Wait.....</p>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-sm-12 col-md-8 col-xl-9 org-main-contain">
              <h3 class="font-weight-semibold">Create Events</h3>
              <CreateEventTabs eventDetails={eventDetailsPreview} />
            </div>
            <div className="col-sm-3 d-sm-none d-md-block col-md-4 col-xl-3 org-main-right">
              <EventCardTypeLarge
                eventDetails={eventDetailsPreview}
                hideRightPanel={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

// export default Navbar;
const mapStateToProps = (state) => ({
  login: state.login.login,
  eventDetails: state.createEvent.eventDetails,
});
const mapDispatchToProps = (dispatch) => ({
  saveLogin: (login1) => dispatch(addToLoginRequest(login1)),
  fetchLogin: () => dispatch(fetchLoginRequest()),
  fetchEventDetails: (eventId) =>
    dispatch(storeAction.fetchEventDetailsRequest(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventAd);
//export default CreateEventAd;
