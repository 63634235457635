import React from "react";

const Promotion = ({ from }) => {
  return (
    <>
      <div className="row mx-2 my-5">
        {/* <img className="img-fluid" src="/images/promotion/adv1.png" /> */}
      </div>
    </>
  );
};

export default Promotion;
