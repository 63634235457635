import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import { SaveTicketSettings } from "../../../services/createEvent";
import * as common from "../../../utils/commonFun";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import Button from "../../controls/buttons/Button";
import * as initialState from "../../../services/initialState";
import Spinner from "../../controls/spinner/Spinner";

//
// Form initial Data
let initialData = initialState.EventSettings;
/* let initialData = {
  isGstFromAttendees: false,
  hostingFeesPayer: "organizer",
  pgFeesPayer: "organizer",
  embeddCode: "",
  isRedirect: false,
  userId: "",
  eventId: "",
}; */
let eventId;
const EventSettings = ({ eventDetails, saveEventSettings }) => {
  initialData.embeddCode = `<iframe  id="fitfunda-widget" title="${
    eventDetails?.eventName
  }" width="100%" height="500" src="${
    process.env.REACT_APP_FRONTEND_USER +
    "widget/event-ticket-options/" +
    eventDetails?.eventId +
    "/" +
    eventDetails?.eventName
  }" title="FITFUNDA"></iframe>
     `;
  initialData.isRedirectChecked = true;
  const [inputValues, setInputValues] = useState(initialData);
  const { IsValid } = inputValues;
  // const userData = useAuthState();
  const userData = useSelector((state) => state.login.login.login);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); //spinner

  //
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      initialData = { ...initialData, ...eventDetails?.eventSetting };
      setInputValues(initialData);
    }
  }, [eventDetails]);

  //

  const handleChange = (event) => {
    if (event.target.name === "gst") {
      setInputValues((prev) => ({
        ...prev,
        isGstFromAttendees: event.target.checked,
      }));
    }

    if (event.target.name === "redirect") {
      setInputValues((prev) => ({
        ...prev,
        isRedirect: event.target.checked,
      }));
    }
  };

  const handleChangeHostingFees = (event) => {
    setInputValues((prev) => ({
      ...prev,
      hostingFeesPayer: event.target.value,
    }));
  };

  const handleChangePGCharges = (event) => {
    setInputValues((prev) => ({
      ...prev,
      pgFeesPayer: event.target.value,
    }));
  };

  const saveAndProceed = async () => {
    if (eventDetails?.eventStage === "past") {
      alert("Past events can't be changed.");
    } else {
      if (
        (inputValues.hostingFeesPayer !== "") &
        (inputValues.pgFeesPayer !== "")
      ) {
        if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);
        inputValues.eventId = eventId;
        inputValues.userId = userData.user.userId;
        setLoading(true);
        const result = await SaveTicketSettings(inputValues, userData.token);
        //next step
        setLoading(false);
        if (result) {
          if (result.success) {
            common.removeItem(KEY_EVENT_ID);
            // onStepComplete();

            eventDetails = result.data;
            eventDetails = {
              ...eventDetails,
              IsValid: { ...IsValid, ...inputValues?.IsValid },
            };
            saveEventSettings(eventDetails);
            let eventName = eventDetails?.eventName;
            navigate("/publish-event/" + eventId + "/" + eventName);
          } else {
            alert("Error:-" + result.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        alert("Please select all the details.");
      }
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div class="card p-4 org-events-photo-upload event-setting my-4">
            <h5>Event Setting</h5>
            <div class="p-3 border rounded-2 bg-body-secondary info-gst">
              <img
                src="./images/collect-your-GST-icon.svg"
                alt=""
                class="me-2"
              />{" "}
              Collect your GST charge from attendees{" "}
              <div class="ms-5 form-switch form-check-reverse float-end">
                <input
                  class="form-check-input py-2 px-3"
                  type="checkbox"
                  id="flexSwitchCheckReverse"
                  name="gst"
                  checked={inputValues.isGstFromAttendees}
                  onChange={handleChange}
                />
              </div>
            </div>
            <form>
              <div class="row g-3 my-3">
                <label class="" for="inlineFormInputGroupUsername">
                  Who will pay hosting fees ?
                </label>
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="hostingFeesPayer"
                      id="flexRadioDefault1"
                      value="organizer"
                      checked={
                        inputValues.hostingFeesPayer === "organizer"
                          ? true
                          : false
                      }
                      onChange={handleChangeHostingFees}
                    />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Organizer
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="hostingFeesPayer"
                      id="flexRadioDefault2"
                      value="participant"
                      checked={
                        inputValues.hostingFeesPayer === "participant"
                          ? true
                          : false
                      }
                      onChange={handleChangeHostingFees}
                    />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Participant
                    </label>
                  </div>
                </div>
              </div>
              <div class="row g-3 my-3">
                <label class="" for="inlineFormInputGroupUsername">
                  Who will pay PG fees ?
                </label>
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="pgFeesPayer"
                      id="flexRadioDefault3"
                      value="organizer"
                      checked={
                        inputValues.pgFeesPayer === "organizer" ? true : false
                      }
                      onChange={handleChangePGCharges}
                    />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Organiser
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="pgFeesPayer"
                      id="flexRadioDefault4"
                      value="participant"
                      checked={
                        inputValues.pgFeesPayer === "participant" ? true : false
                      }
                      onChange={handleChangePGCharges}
                    />
                    <label class="form-check-label" for="flexRadioDefault4">
                      Participant
                    </label>
                  </div>
                </div>
              </div>
              <hr class="my-5" />
              <div class="mb-5">
                <label for="exampleInputEmail1" class="form-label">
                  Embed Tickets On Your website
                </label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                    disabled
                  >
                    {inputValues.embeddCode}
                  </textarea>
                </div>
              </div>
              <div class="p-3 mb-5 border rounded-2 bg-body-secondary d-flex align-items-center info-gst">
                <div>
                  <img src="/images/redirect-icon.svg" alt="" class="me-2" />
                </div>
                <div class="w-100">
                  <b>Redirect Customer to your website: </b>
                  <br />
                  after successful booking, redirect to your website’s landing
                  page
                </div>
                <div class="ms-5 form-switch form-check-reverse flex-shrink-1">
                  <input
                    class="form-check-input py-2 px-3"
                    type="checkbox"
                    id="flexSwitchCheckReverse1"
                    name="redirect"
                    checked={inputValues.isRedirectChecked}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </form>
            <div class="my-2 text-center">
              <Button type="submit" onClick={saveAndProceed}>
                Save &amp; Proceed
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventSettings: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(EventSettings);
//export default EventSettings;
