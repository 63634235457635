import { createContext, useEffect,useState} from "react";

export const addCartItem =(cartItems,itemToAdd) => {
    if(cartItems){

        const existingCartItem = cartItems.find(
            (item)=> item.eventID===itemToAdd.eventID
        );

        if(existingCartItem){
            return cartItems.map((item) => 
            item.eventID===itemToAdd.eventID
            ? {...cartItems,
                quantity : item.quantity+1 }
            : item
            );
        }else{
            cartItems=([...cartItems, itemToAdd]);
        }
    }
    return cartItems;
};

export const removeCartItem =(cartItems,itemToRemove) => {
    if(cartItems){
        const existingCartItem = cartItems.find(
            (item)=> item.eventID===itemToRemove.eventID
        );

        if(existingCartItem){
            return cartItems.map((item) => 
            item.eventID===itemToRemove.eventID
            ? {...cartItems,
                quantity : item.quantity>0 ? item.quantity-1 : 0 
            }
            : item
            );
        }
    }
    return cartItems;
};

export const clearCartItem = (cartItems,itemToRemove) =>{
    if(cartItems){
        cartItems.filter((item) => item.eventID !== itemToRemove.eventID);    
    }
    return cartItems;
};




//context
export const CartContext=createContext({
    cartCount: 0,
    cartTotal: 0,
    cartItems: [],
    handlerAddItemCart: () => {},     
    handlerRemoveCartItem: () => {},
    handlerClearCartItem: () => {},
    setCartItems:[],
   

});

export const CartProvider= ({children}) =>{
   
    const [cartItems, setCartItems] = useState([]);
    const [cartCount, setCartCount] = useState(0);  
    const [cartTotal, setCartTotal] = useState(0);
    
        useEffect(() => {    
            if(cartItems){    
                const newCartCount = cartItems.reduce(
                (total, item) => total + item.quantity,
                0
                );
                setCartCount(newCartCount);
            }
        }, [cartItems]);

        useEffect(() => {
            if(cartItems){
                const newCartTotal = cartItems.reduce(
                (total, item) => total + item.quantity * item.price, 0);
                setCartTotal(newCartTotal);
            }
        }, [cartItems]);
    

      const handlerAddItemCart = (item) => {        
        setCartItems(addCartItem(cartItems,item));
        
      };

      const handlerRemoveCartItem = (item) => {
         setCartItems(removeCartItem(cartItems,item));
      };


      const handlerClearCartItem = (item) => {
         setCartItems(clearCartItem(cartItems,item));
      };
      
      


      const value = {
        cartCount,
        cartTotal,
        cartItems,
        handlerAddItemCart,       
        handlerRemoveCartItem,
        handlerClearCartItem,
        setCartItems,
       

      };


      return <CartContext.Provider value={value}>{children}</CartContext.Provider>

};