import EventCard from "../../components/event/EventCard.jsx";
import { Link, useParams } from "react-router-dom";
import { GetCityEventData } from "../../services/eventAPI.jsx";
import SliderRecommededEvent from "../../components/slider/SliderEventRecommended.jsx";
import Promotion from "../../components/promotion/Promotion.jsx";
import { useEffect, useState } from "react";

const EventCity = () => {
  const { eventCity, eventCityId } = useParams();
  // const { eventCityId } = useParams();
  //load data
  // const eventData = GetCityEventData(eventCityId);
  const [eventsInCity, setEventsInCity] = useState("");

  useEffect(() => {
    const getAllEventByCity = async () => {
      try {
        const eventsByCityResponse = await GetCityEventData(eventCityId);
        if (eventsByCityResponse.success === true) {
          const eventResponse = eventsByCityResponse.data;
          setEventsInCity(eventResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllEventByCity();
  }, [eventCityId]);

  return (
    <>
      <div className="container text-center">
        <div class="row mx-2">
          <div class="col text-start cat-name">
            <b>Events</b> in {eventCity && eventCity.toUpperCase()}
          </div>
        </div>
        <div className="row align-items-start mx-2 my-3">
          {eventsInCity &&
            eventsInCity.map &&
            eventsInCity.map((event, index) => {
              return (
                <EventCard key={event.eventId} event={event} city={eventCity} />
              );
            })}
        </div>

        <SliderRecommededEvent from={"EventCity"} />
        <Promotion from={"EventCity"} />
      </div>
    </>
  );
};

export default EventCity;
