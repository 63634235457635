import { useEffect, useState } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";
import SliderEvent from "./SliderEvent";
import { GetSearchEventData } from "../../services/eventAPI";
import { IsMobile } from "../../utils/commonFun.jsx";
//

let today = dayjs()?.format("DD/MMM/YYYY");
let nextDate = dayjs().add(90, "day")?.format("DD/MMM/YYYY");
//
const SliderRecommededEvent = ({ from, titleFocus, eventDetails }) => {
  const { eventId } = useParams();
  // const city = "ALL";
  // const refCall = "SliderRecommededEvent";
  const [eventData, setEventData] = useState("");
  //

  //load data
  //const [eventData] = GetRecommendedEventData();
  let searchText = eventId;

  let inputValue = {
    searchText: searchText,
    searchFrom: "recommend",
    cityId: eventDetails?.cityId || 0,
    stateId: eventDetails?.stateId || 0,
    categoryId: eventDetails?.categoryId || 0,
    sDate: today,
    eDate: nextDate,
    minPrice: 0,
    maxPrice: 0,
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await GetSearchEventData(searchText, inputValue);
      if (response.success === true) {
        setEventData(response.data);
      }
    };

    fetchData();
  }, []);

  //check screen width
  let finish = 3;
  useEffect(() => {
    let isMobile = IsMobile();
    if (isMobile === true) {
      finish = 1;
    }
  });

  //slider data
  const sliderProp = {
    leftInActive: "disabled",
    leftActive: "",
    rightInActive: "disabled",
    rightActive: "",
    finish: finish,
    noOfImageRotate: 1,
  };

  return (
    <>
      {eventData !== "" &&
        eventData.length > 0 && ( //.length > 0
          <>
            <div class="row mx-2 recommend">
              <div class="col text-start cat-name mdnrecommend">
                <b>Recommended For You</b>
              </div>
              <div class="col col-sm-3 ms-auto text-end mdnrecommend mdnrecdviewall">
                <a href="">
                  <span class="badge text-bg-light border view-text">
                    View All
                  </span>
                </a>
              </div>
            </div>
            <SliderEvent
              sliderProp={sliderProp}
              eventData={eventData !== "" && eventData}
              ref={titleFocus}
            />
          </>
        )}
    </>
  );
};

export default SliderRecommededEvent;
