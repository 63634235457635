// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
#root {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-contet: center;
  }*/
  
  .ed-tab-container {
    /*width: 400px;
    height: 250px;*/
    background-color: #eeeded;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .ed-tab-list {
    height: 50px;
    display: flex;
    list-style: none;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  
  .ed-tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #80808013;
    position: relative;
    cursor: pointer;
    border-radius: 1px solid rgba(94, 92, 92, 0.3);;
  }
  
  .ed-tabs:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
  
  .ed-active-tabs {
    background: #546bc9;
  }
  
  .ed-active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 4px;
    background: #5893f1;
  }
  
  .ed-content {
    display: none;
      /*background-color: #eeeded;      
      padding: 10px;*/
  }
  
  .ed-active-content {
      display: flex;/*flex;*/
  }
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/components/createEvent/eventDetails/middleEventPanel.css"],"names":[],"mappings":"AAAA;;;;;;;IAOI;;EAEF;IACE;mBACe;IACf,yBAAyB;IACzB,oCAAoC;EACtC;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,UAAU;IACV,SAAS;EACX;;EAEA;IACE,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,8CAA8C;EAChD;;EAEA;IACE,0CAA0C;EAC5C;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,uBAAuB;IACvB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;MACX;qBACe;EACnB;;EAEA;MACI,aAAa,CAAC,QAAQ;EAC1B","sourcesContent":["/*\n#root {\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-contet: center;\n  }*/\n  \n  .ed-tab-container {\n    /*width: 400px;\n    height: 250px;*/\n    background-color: #eeeded;\n    border: 1px solid rgba(0, 0, 0, 0.3);\n  }\n  \n  .ed-tab-list {\n    height: 50px;\n    display: flex;\n    list-style: none;\n    font-size: 18px;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .ed-tabs {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #80808013;\n    position: relative;\n    cursor: pointer;\n    border-radius: 1px solid rgba(94, 92, 92, 0.3);;\n  }\n  \n  .ed-tabs:not(:last-child) {\n    border-right: 1px solid rgba(0, 0, 0, 0.3);\n  }\n  \n  .ed-active-tabs {\n    background: #546bc9;\n  }\n  \n  .ed-active-tabs::before {\n    content: \"\";\n    display: block;\n    position: absolute;\n    top: -4px;\n    left: 50%;\n    transform: translateX(-50%);\n    width: calc(100% + 2px);\n    height: 4px;\n    background: #5893f1;\n  }\n  \n  .ed-content {\n    display: none;\n      /*background-color: #eeeded;      \n      padding: 10px;*/\n  }\n  \n  .ed-active-content {\n      display: flex;/*flex;*/\n  }\n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
