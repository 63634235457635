import { React, useEffect, useState } from "react";
import ViewAccountDetailsOrganizer from "../../../components/profile/view/ViewAccountDetailsOrganizer";
import ViewKYCDetailsOrganizer from "../../../components/profile/view/ViewKYCDetailsOrganizer";
import ViewBankAccountDetailsOrganizer from "../../../components/profile/view/ViewBankAccountDetailsOrganizer";
import OrganizerMaster from "../../master/OrganizerMaster";
import { GetOrganizerProfile } from "../../../services/userAPI";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FOLDER_ORGANIZER_LOGO } from "../../../utils/Constants";
import Button from "../../../components/controls/buttons/Button";
import ShowLoginMsg from "../../../components/login/ShowLoginMsg";
import * as initialState from "../../../services/initialState";
import AccordionCard from "../../../components/controls/accordion/AccordionCard";
import Spinner from "../../../components/controls/spinner/Spinner";

let initialData = initialState.OrganizerProfileInfo;

const ViewOrganizerProfile = () => {
  const [inputValue, setInputValue] = useState(initialData);
  //const { errors, IsValid } = inputValue;
  const navigate = useNavigate();
  const userDataLocal = useSelector((loginData) => loginData.login.login);

  const [activeTabNo, setActiveTabNo] = useState(-1);
  const [loading, setLoading] = useState(true); // loading spinner

  //
  useEffect(() => {
    const getOrganizerDetails = async () => {
      try {        
        if (userDataLocal?.login?.token !== undefined) { 
        const profileData = await GetOrganizerProfile(
          userDataLocal.login?.user?.userId,
          userDataLocal.login?.token
        );

        if (profileData?.success) {
          setInputValue(profileData?.data);
          setLoading(false);
        } else {
          alert(profileData?.message);
        }
      }
      } catch (error) {
        console.log(error);
      }
    };
    getOrganizerDetails();
  }, [userDataLocal?.login?.user !== ""]);

  const navigateToEditProfile = () => {
    navigate("/create-profile");
  };
  //
  let isUserLogin = false;
  if (userDataLocal?.login?.user) {
    isUserLogin = true;
    if (parseInt(userDataLocal?.login?.user?.role) !== 4) {
      navigateToEditProfile();
    }
  } else {
    return (
      <OrganizerMaster>
        <div class="col">
          <h3 class="font-weight-semibold mb-3">My Account</h3>
        </div>
        <ShowLoginMsg />
      </OrganizerMaster>
    );
  }

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Basic Profile</h1>
          </div>
        ),

        content: <ViewAccountDetailsOrganizer organizerDetails={inputValue} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Business Profile</h1>
          </div>
        ),

        content: <ViewKYCDetailsOrganizer organizerDetails={inputValue} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Bank Account Details</h1>
          </div>
        ),

        content: (
          <ViewBankAccountDetailsOrganizer organizerDetails={inputValue} />
        ),
      },
    ],
  };

  return (
    <OrganizerMaster>
       {loading ? (
        <Spinner/>
        ) : (
          <>
      <div class="col-sm-12 col-md-8 col-xl-12 org-main-contain">
        <h3 class="font-weight-semibold mb-3">My Account</h3>
        <div class="card p-0 rounded pb-5">
          <div class="card-body p-0 rounded">
            <div class="my-profile-banner mb-3 rounded">
              <div class="d-flex">
                <div class="p-2">
                  <img
                    loading="lazy"
                    src={
                      process.env.REACT_APP_USERS +
                      userDataLocal.login?.user?.userId +
                      FOLDER_ORGANIZER_LOGO +
                      inputValue?.businessLogo
                    }
                    width={100}
                    height={100}
                    alt="No image"
                  />
                </div>
                <div class="p-2 flex-grow-1 mt-3">
                  <h1>{inputValue?.businessName}</h1>
                  {/* <p>
                    {inputValue.incorporateDate} | {inputValue.organizerCountry}
                  </p> */}
                </div>
                <div class="">
                  <Button
                    newClassName="btn btn-light"
                    onClick={navigateToEditProfile}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
            <div class="participant-form mx-5 my-profile-banner-accordion">
              <AccordionCard config={config} />
            </div>
          </div>
        </div>
      </div>
      </>
        )}
    </OrganizerMaster>
  );
};

export default ViewOrganizerProfile;