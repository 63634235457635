import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import TinyEditor from "../../controls/editor/TinyEditor";
import * as Call from "../../../utils/validations";
import {
  SaveEventDetails,
  SaveEventDetailsGuest,
} from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import FileUploadSingle from "../../controls/fileUploader/FileUploadSingle";
import * as commom from "../../../utils/commonFun";
import * as storeAction from "../../../redux/actions/createEvent";
import * as initialState from "../../../services/initialState";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import InputTextBox from "../../controls/input/InputTextBox";
import InputTextArea from "../../controls/input/InputTextArea";
import Button from "../../controls/buttons/Button";
import ShowDateTimePicker from "../../controls/date/ShowDateTimePicker";
import CategoryDropdown from "../../controls/dropdown/CategoryDropdown";
import "./eventDetails.css";
import Spinner from "../../controls/spinner/Spinner";
//import { NO_IMAGE_PATH, FOLDER_EVENT_PHOTO } from "../../utils/Constants";

//
let ImagePath = process.env.REACT_APP_EVENTS;
let eventId;

// Form initial Data
let initialData = initialState.EventDetails;
let mParam = initialState.EventDetails.isRequired;
//
const EventDetails = ({ eventDetails, saveEventDetails, onStepComplete }) => {
  const [inputValue, setInputValue] = useState(initialData);
  let { errors, IsValid } = inputValue;
  const userData = useSelector((state) => state.login.login.login);
  const [validationSummary, setValidationSummary] = useState("");
  const [inputDisable, setInputDisable] = useState(false);
  const [loading, setLoading] = useState(false); //spinner
  //
  const thisStepCompleted = (value) => {
    switch (value) {
      case "E1.1":
      case "E1.2":
      case "E1.3":
      case "E1.4":
      case "E2":
      case "E3":
      case "E4":
      case "iact":
      case "": //empt 1st time
        return true;

      default:
        return false;
    }
  };

  //if store data available
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      //alert("ok");
      eventId = eventDetails.eventId;
      //
      if (thisStepCompleted(eventDetails?.stepCompleted)) {
        initialData = { ...initialData, ...eventDetails };
        initialData["IsValid"] = mParam;
        setInputValue(initialData);
      }
      //
      if (
        eventDetails?.eventStatus === "act" ||
        eventDetails?.eventStatus === "iact"
      ) {
        setInputDisable(() => true);
      }
    }
  }, [eventDetails]);

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    }
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter valid " + name;
    } else {
      switch (name) {
        case "eventName":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter valid event name.";
          break;

        case "categoryId":
          val = Call.IsNumberOnlyInput(value);
          try {
            value2 = e.target.options[e.target.selectedIndex].text;
            inputValue.categoryPic =
              e.target.options[e.target.selectedIndex].id;
            name2 = "categoryName";
          } catch {}
          break;

        case "supportEmail":
          {
            if (Call.IsEmpty(value)) {
              msg = "Please enter email.";
            } else {
              val = Call.IsValidEmail(value);
              if (!val) msg = "Please enter valid email. " + value;
            }
          }
          break;

        case "supportMobile":
          {
            if (Call.IsEmpty(value)) {
              msg = "Please enter mobile.";
            } else {
              val = Call.IsValidMobileNo(value);
              if (!val) msg = "Please enter 10 digit mobile number. " + value;
            }
          }
          break;

        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const handleInputChange = (name, data) => {
    //alert("handleDateChange");
    //alert(date);
    switch (name) {
      case "sDate":
        setInputValue((prev) => ({
          ...prev,
          eventStartDate: data,
          ["IsValid"]: { ...prev.IsValid, eventStartDate: true },
        }));
        break;
      case "eDate":
        setInputValue((prev) => ({
          ...prev,
          eventEndDate: data,
          ["IsValid"]: { ...prev.IsValid, eventEndDate: true },
        }));
        break;

      case "eventDescription":
        setInputValue((prev) => ({
          ...prev,
          eventDescription: data,
          ["IsValid"]: { ...prev.IsValid, eventDescription: true },
        }));
        break;

      default:
        setInputValue((prev) => ({
          ...prev,
          [name]: data,
        }));
        break;
    }
  };

  const [more, setMore] = useState(false);

  const showMoreView = (val) => {
    setMore(val);
  };
  /*   const handleCategory = (cid, cname) => {
    //set value
    setInputValue((prev) => ({
      ...prev,
      categoryId: cid,
      categoryName: cname,
    }));
  }; */

  //file upload

  //questions
  const [AddMore, setAddMore] = useState(true);
  const handleAddMoreQue = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    //{ qid: 1, question: "", answer: "" }
    let newqid = inputValue.faqs.length + 1;
    let newQue = [{ faqId: newqid, question: "", answer: "" }];

    if (inputValue.faqs.length <= 4) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        faqs: [...prev.faqs, ...newQue],
      }));
    } else {
      setAddMore(false);
    }

    setInputValue((prev) => ({
      ...prev,
    }));
  };
  const handleRemoveMoreQue = (event, removeitem) => {
    const items = inputValue.faqs;
    const j = items.findIndex((item) => item.faqId === removeitem.faqId);

    items.splice([j], 1);

    //set value
    setInputValue((prev) => ({
      ...prev,
      faqs: items,
    }));
    //
    setAddMore(true);
  };

  const handleQestionsAns = (e, item2, type) => {
    const { name, value } = e.target;

    // console.log(item2.qid);
    //{ qid: 1, question: "", answer: "" }
    // loop over the todos list and find the provided id.
    let updatedList = inputValue.faqs.map((item) => {
      if (item.faqId == item2.faqId) {
        if (type === "question")
          return { ...item, question: value, done: !item.done }; //gets everything that was already in item, and updates "done"
        if (type === "answer")
          return { ...item, answer: value, done: !item.done };
      }
      return item; // else return unmodified item
    });
    // set state to new object with updated list
    setInputValue((prev) => ({
      ...prev,
      faqs: updatedList,
    }));
  };

  //last step
  const SaveAndProceed = async (e) => {
    e.preventDefault();

    // Check if eventStage is past then don't allow to edit event.
    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      if (
        inputValue?.IsValid &&
        inputValue?.IsValid?.eventName &&
        inputValue?.IsValid?.categoryId &&
        inputValue?.IsValid?.eventStartDate &&
        inputValue?.IsValid?.eventEndDate &&
        inputValue?.IsValid?.supportEmail &&
        inputValue?.IsValid.supportMobile &&
        inputValue?.IsValid?.eventDescription
      ) {
        if (!eventId) eventId = commom.getLocalData(KEY_EVENT_ID);
        //post data logic
        let postValue = initialState.EventDetails;
        let skipKey = initialState.skipKey;
        for (let key in postValue) {
          try {
            if (skipKey.includes(key)) {
              delete postValue[key];
            } else {
              postValue[key] = inputValue[key];
            }
          } catch {}
        }
        if (eventId) postValue.eventId = eventId;
        //send data to server
        setLoading(true);
        let result;
        if (userData) {
          postValue.userId = userData.user?.userId;
          const token = userData.token;
          //added for ad
          postValue.referenceId = userData?.referenceId;
          result = await SaveEventDetails(postValue, token);
        } else {
          result = await SaveEventDetailsGuest(postValue);
        }
        setLoading(false);

        //next step
        if (result) {
          if (result.success) {
            if (result?.data?.eventId) {
              commom.saveToLocal(KEY_EVENT_ID, result?.data?.eventId);
              eventDetails = result.data;
              eventDetails = {
                ...eventDetails,
                IsValid: { ...IsValid, ...inputValue?.IsValid },
              };
              saveEventDetails(eventDetails);
            }
            onStepComplete(1);
          } else {
            alert("Error:-" + result.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        let displaMsg = "";
        for (let key in mParam) {
          if (!inputValue?.IsValid[key]) {
            displaMsg += "<li>" + key + "</li>";
          }
        }
        displaMsg =
          "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
        setValidationSummary(() => displaMsg);
      }
    }
  };

  //upload file
  const handleFileChange = (file) => {
    inputValue.eventPermissionDocument = file;
    //inputValue.permission = file;
  };

  //
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div class="card p-4">
            <h3>Event Details</h3>
            <form>
              <div class="my-3">
                <label for="exampleInputEmail1" class="form-label">
                  Event Name<span className="text-red-600">*</span>
                </label>
                <InputTextBox
                  id="eventName"
                  name="eventName"
                  placeholder="Please enter event name"
                  isValid={inputValue?.IsValid?.eventName}
                  errorMsg={inputValue?.errors?.eventName}
                  value={inputValue.eventName}
                  onChange={handleChange}
                  autoFocus
                  disabled={inputDisable}
                />
              </div>
              <div className="form-label">
                Select category<span className="text-red-600">*</span>
              </div>
              <div className="my-3">
                <div className="row">
                  <div className="col-6">
                    <CategoryDropdown
                      name="categoryId"
                      categoryId={inputValue?.categoryId}
                      onCategoryChange={handleChange}
                    />
                  </div>
                  <div className="col-4  text-left">
                    {inputValue?.categoryId > 0 && (
                      <img
                        className="select-sport "
                        style={{ maxWidth: "250px", maxHeight: "70px" }}
                        src={
                          process.env.REACT_APP_CATEGORYS +
                          inputValue?.categoryId +
                          "/" +
                          inputValue?.categoryPic
                        }
                        alt={inputValue?.categoryName}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row g-3 my-3">
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    Start Date<span className="text-red-600">*</span>
                  </label>
                  <div className="input-group border rounded-2">
                    <ShowDateTimePicker
                      type="start-date"
                      title=""
                      curDate={inputValue.eventStartDate}
                      onDateChange={(newDate) =>
                        handleInputChange("sDate", newDate)
                      }
                    />
                  </div>
                </div>
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    End Date<span className="text-red-600">*</span>
                  </label>
                  <div className="input-group border rounded-2">
                    <ShowDateTimePicker
                      type="end-date"
                      title=""
                      minDate={inputValue.eventStartDate}
                      curDate={inputValue.eventEndDate}
                      onDateChange={(newDate) =>
                        handleInputChange("eDate", newDate)
                      }
                      disabled={inputValue.eventStartDate ? false : true}
                    />
                  </div>
                </div>
              </div>

              <div className="row g-3 my-3">
                <div className="col">
                  <label for="exampleInputEmail1" class="form-label">
                    Support Email<span className="text-red-600">*</span>
                  </label>
                  <InputTextBox
                    id="supportEmail"
                    name="supportEmail"
                    placeholder="Email"
                    type="text"
                    isValid={inputValue?.IsValid?.supportEmail}
                    errorMsg={inputValue?.errors?.supportEmail}
                    value={inputValue.supportEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="col">
                  <label for="exampleInputEmail1" class="form-label">
                    Support Mobile<span className="text-red-600">*</span>
                  </label>
                  <div class="input-group">
                    <InputTextBox
                      id="supportMobile"
                      name="supportMobile"
                      placeholder="Mobile"
                      type="text"
                      maxlength={10}
                      prefixText="+91"
                      className="numberWithoutArrowkey"
                      isValid={inputValue?.IsValid?.supportMobile}
                      errorMsg={inputValue?.errors?.supportMobile}
                      value={inputValue.supportMobile}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="my-5">
                <div className="form-floating">
                  <TinyEditor
                    scriptLoading={{ async: true }}
                    name="eventDescription"
                    initialValue={inputValue.eventDescription}
                    value={inputValue.eventDescription}
                    handleInput={(data) =>
                      handleInputChange("eventDescription", data)
                    }
                    className="text-gray-400 text-sm whitespace-nowrap ml-3 max-md:ml-2.5"
                    maxTextLength={4000}
                    titleEditor={"Event Description"}
                    isMandatory={true}
                  />
                </div>
              </div>

              {more ? (
                <>
                  <div className="w-full mt-10">
                    <FileUploadSingle
                      getFileDetail={handleFileChange}
                      title={"Upload Event Permission"}
                    >
                      fileData
                    </FileUploadSingle>
                    {inputValue?.permission && (
                      <div class="alert alert-info" role="alert">
                        <a
                          href={
                            ImagePath +
                            eventId +
                            "/permission/" +
                            inputValue.permission
                          }
                          target="_blank"
                        >
                          Click to download ({inputValue.permission})
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="my-5 ">
                    <div className="form-floating">
                      <TinyEditor
                        name="terms"
                        value={inputValue?.terms}
                        initialValue={inputValue?.terms}
                        //onChange={(e) => handleOtherInput(e, "tAndC")}
                        handleInput={(data) => handleInputChange("terms", data)}
                        className="text-gray-400 text-sm whitespace-nowrap ml-3 max-md:ml-2.5"
                        maxTextLength={2000}
                        titleEditor={"Terms & Condition"}
                        isMandatory={false}
                      />
                    </div>
                  </div>
                  {/*tAndC= {inputValue.termsCondtion}*/}
                  <div className="row">
                    <div class="col">
                      <label for="exampleInputEmail1" class="form-label">
                        Create FAQ
                      </label>
                    </div>{" "}
                  </div>
                  {inputValue?.faqs &&
                    inputValue?.faqs?.map((value, index) => (
                      <div
                        key={`queGroup-${index}`}
                        className="QuesAnsGroup row"
                      >
                        <div className="form-label">
                          <label for="exampleInputEmail1" class="form-label">
                            Question {index + 1}
                          </label>
                          {index >= 1 ? (
                            <div class="">
                              <Button
                                newClassName="btn btn-light"
                                onClick={(e) => handleRemoveMoreQue(e, value)}
                              >
                                Delete
                              </Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="my-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Question
                          </label>
                          <label
                            for="exampleInputEmail1"
                            class="form-label float-end"
                          >
                            {value?.question?.length}
                            {"/200"}
                          </label>
                          <InputTextArea
                            id="question"
                            name="question"
                            placeholder="Please enter question."
                            isValid={inputValue?.IsValid?.question}
                            errorMsg={inputValue?.errors?.question}
                            value={value.question}
                            onChange={(e) =>
                              handleQestionsAns(e, value, "question")
                            }
                            minRows={1}
                            maxRows={3}
                            maxlength={200}
                          />
                        </div>

                        <div className="my-5">
                          <label for="exampleInputEmail1" class="form-label">
                            Answer{" "}
                          </label>
                          <label
                            for="exampleInputEmail1"
                            class="form-label float-end"
                          >
                            {value?.answer?.length}
                            {"/200"}
                            {/* <span
                          class="float-end mx-2"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-title="Tooltip on bottom"
                          data-toggle="tooltip"
                          attribute=""
                          to=""
                          an=""
                          element=""
                        >
                          <img src="/images/info-icon.png" alt="info" />
                        </span> */}
                          </label>
                          <InputTextArea
                            id="answer"
                            name="answer"
                            placeholder=""
                            isValid={inputValue?.IsValid?.answer}
                            errorMsg={inputValue?.errors?.answer}
                            value={value.answer}
                            onChange={(e) =>
                              handleQestionsAns(e, value, "answer")
                            }
                            minRows={1}
                            maxRows={5}
                            maxlength={200}
                          />
                        </div>
                        <br />
                        <br />
                      </div>
                    ))}

                  <div className="row">
                    <div className="col">&nbsp;</div>
                    <div className="col">
                      <div className="text-end float-end">
                        {AddMore ? (
                          // <button onClick={handleAddMoreQue}> + Add</button>
                          <Button
                            onClick={handleAddMoreQue}
                            newClassName="btn btn-outline-dark select-btn"
                          >
                            + Add
                          </Button>
                        ) : (
                          <Button
                            newClassName="btn btn-outline-dark select-btn"
                            disabled
                          >
                            + Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 text-center">
                    <Button
                      newClassName="btn btn-light"
                      onClick={() => showMoreView(false)}
                    >
                      - Less
                    </Button>
                  </div>
                </>
              ) : (
                <div className="mb-3 text-center">
                  <Button
                    newClassName="btn btn-light"
                    onClick={() => showMoreView(true)}
                  >
                    + More
                  </Button>
                </div>
              )}
              <div class="mb-3 text-center">
                <Button onClick={SaveAndProceed}>Save & Proceed</Button>
              </div>
              {validationSummary !== "" && (
                <div
                  class="alert alert-danger"
                  role="alert"
                  dangerouslySetInnerHTML={{ __html: validationSummary }}
                ></div>
              )}
            </form>
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventDetails: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(EventDetails);
/* 
const mapDispatchToProps = (dispatch) => ({
  add1: (inputValue) => dispatch(storeAction.SaveEventDetails(inputValue)),
});

export default connect(null, mapDispatchToProps)(EventDetails);
 */
//export default EventDetails;
