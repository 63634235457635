import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Icon from "../icons/Icon";
import { GetTopCities } from "../../../services/commonAPI";
import { FOLDER_EVENT_PHOTO, NO_IMAGE_PATH } from "../../../utils/Constants";
//
const TopCitiesPopup = () => {
  const [showModal, setShowModal] = useState(false);
  const [locationDataLocal, setCityData] = useState();
  const ImagePath = process.env.REACT_APP_CITYS;

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const cityResponseData = await GetTopCities();
        if (cityResponseData?.success === true) {
          const cityResponse = cityResponseData.data;
          setCityData(cityResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllCity();
    //cleanup function
    return () => {
      getAllCity();
    };
  }, []);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  /*   const handleSuccess = () => {
    alert("scccess");
  }; */
  const handleOnError = (event) => {
    event.target.src = NO_IMAGE_PATH;
  };

  //
  return (
    <>
      <a
        className="all-city btn btn-outline-light border text-secondary justify-content-center align-items-center"
        href="#"
        onClick={handleClick}
      >
        <Icon iconName="location" />
        <span>Top Cities</span>
      </a>

      {showModal && (
        <Modal
          title="Select city"
          onClose={handleClose}
          //onSuccess={handleSuccess}
        >
          <>
            <div className="cityPopup" /* class="m-4" */>
              <div /* class="otp-form text-center" */>
                <div /* class="container text-center" */>
                  <div class="row row-cols-3">
                    {locationDataLocal &&
                      locationDataLocal.map &&
                      locationDataLocal.map((locationItem, index) => {
                        if (
                          locationItem.cityName &&
                          locationItem.cityName != undefined &&
                          locationItem.cityName != ""
                        ) {
                          return (
                            <>
                              <div className="row row-cols-3 text-center mr-1">
                                <div
                                  style={{
                                    width: "100%",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                  key={index}
                                >
                                  <Link
                                    to={
                                      "/event/" +
                                      locationItem.cityName +
                                      "/" +
                                      locationItem.cityId
                                    }
                                    onClick={handleClose}
                                  >
                                    <img
                                      src={
                                        ImagePath +
                                        locationItem.cityId +
                                        "/" +
                                        locationItem.cityPic
                                      }
                                      class="rounded-circle"
                                      alt={locationItem.cityName}
                                      onError={handleOnError}
                                      /*  style={{
                                      maxWidth: "120px",
                                      maxHeight: "120px",
                                    }} */
                                    />
                                    {locationItem.cityName}
                                  </Link>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default TopCitiesPopup;
