import * as type from "../actions/createEvent";
import { combineReducers } from "redux";

const initialStateForCartEvents = {
  eventDetails: [],
  eventLocation: [],
  eventPhotos: [],
  ticketDetails: [],
};

function eventDetails(state = initialStateForCartEvents.eventDetails, action) {
  // console.log("reducer call for cartEvents");
  // console.log(action);
  switch (action.type) {
    case type.FETCH_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.payload,
      };
    case type.ADD_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.payload,
      };
    case type.DELETE_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        [createEvent.eventDetails]: state.createEvent.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}

const createEvent = combineReducers({
  eventDetails,
});
export default createEvent;
