import React, { useState, useEffect } from "react";
import {
  GetAccountSettingOfParticipant,
  UpdateAccountSettingOfParticipant,
} from "../../services/authAPI";
import { useSelector } from "react-redux";
import Spinner from "../controls/spinner/Spinner";

const initialData = {
  isEmailNotification: true,
  isMobileNotification: true,
  isAccountActive: true,
  userId: "",
};

const AccountSettings = () => {
  // const [isEmailChecked, setEmailChecked] = useState(true);
  // const [isMobileChecked, setMobileChecked] = useState(true);
  // const [isAccountActive, setAccountActive] = useState(true);
  const [inputValue, setInputValue] = useState(initialData);
  // const userData = useAuthState();
  const userData = useSelector((loginData) => loginData.login.login);
  const [loading, setLoading] = useState(false); // Spinner state

  const handleChange = (event) => {
    if (event.target.name === "email") {
      // setEmailChecked(event.target.checked);
      setInputValue((prev) => ({
        ...prev,
        isEmailNotification: event.target.checked,
      }));
      // inputValue.isEmailNotification = isEmailChecked;
    }

    if (event.target.name === "mobile") {
      // setMobileChecked(event.target.checked);
      // inputValue.isMobileNotification = isMobileChecked;
      setInputValue((prev) => ({
        ...prev,
        isMobileNotification: event.target.checked,
      }));
    }

    if (event.target.name === "account") {
      // setAccountActive(event.target.checked);
      // inputValue.isAccountActive = isMobileChecked;

      setInputValue((prev) => ({
        ...prev,
        isAccountActive: event.target.checked,
      }));
    }
  };

  const updateAccountSettings = async () => {
    inputValue.userId = userData.login.user.userId;

    const accountSettingInfo = await UpdateAccountSettingOfParticipant(
      inputValue,
      userData.login.token
    );
    if (accountSettingInfo != null) {
      setInputValue(accountSettingInfo);
    }
  };

  useEffect(() => {
    const getUserAccountSettings = async () => {
      inputValue.userId = userData.login.user.userId;

      try {
        setLoading(true); // Show spinner when sending starts
        const accountSettingInfo = await GetAccountSettingOfParticipant(
          inputValue.userId,
          userData.login.token
        );

        setLoading(false);
        if (accountSettingInfo) {
          setInputValue(accountSettingInfo);
        } else {
          setInputValue(initialData);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getUserAccountSettings();
  }, []);

  return (
    <div class="d-flex flex-column mb-3">
      <div class="p-2">
        <div class="border rounded shadow-sm">
          <h1 class="p-3 text-start bg-secondary-subtle m-0 border-bottom border-secondary">
            Account Setting
          </h1>
          <h1 class="mt-3 mx-4 text-start">Send Notification </h1>
          <div class="row px-3">
            <div class="col-12 col-md-6">
              <div class="p-2 align-items-start border rounded-2 m-2 mb-3">
                <div class="row justify-content-center align-items-center">
                  <div class="col-3">
                    <div class="send-notification-box">
                      <img src="/images/mail.png" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="text-start ms-2 form-switch-text"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      Email Notification
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-switch form-check-reverse">
                      <input
                        name="email"
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckReverse"
                        checked={inputValue.isEmailNotification}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="p-2 align-items-start border rounded-2 m-2 mb-3">
                <div class="row justify-content-center align-items-center">
                  <div class="col-3">
                    <div class="send-notification-box">
                      <img src="/images/notification-bell.png" alt="" />
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="text-start ms-2 form-switch-text"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      Mobile Notification
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-switch form-check-reverse">
                      <input
                        name="mobile"
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckReverse"
                        checked={inputValue.isMobileNotification}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-12 col-md-6">
              <div class="p-2 align-items-start border rounded-2 m-2 mb-3">
                <div class="row justify-content-center align-items-center">
                  <div class="col-3">
                    <div class="send-notification-box">
                      <img src="/images/active-account.png" alt=""/>
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      class="text-start ms-2 form-switch-text"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      Account Active
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-switch form-check-reverse">
                      <input
                        name="account"
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckReverse"
                        checked={inputValue.isAccountActive}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <hr /> */}

            <div class="row">
              <div class="col-12 col-md-6">
                <div class="mb-3 p-2">
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since</p> */}
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="mb-3 p-2">
                  <a
                    href="#"
                    class="btn-outline-dark btn align-middle"
                    type="button"
                    onClick={updateAccountSettings}
                  >
                    Update Details
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <h1 class="mt-3 mx-4 text-start">Account</h1>
          <div class="row px-3">
            <div class="col-12 col-md-6">
              <div class="p-2 align-items-start border rounded-2 m-2 mb-3">
                <div class="row justify-content-center align-items-center">
                  <div class="col-2">
                    <div class="send-notification-box">
                      <img src="/images/profile-circle.png" alt="" />
                    </div>
                  </div>
                  <div class="col-8">
                    <div
                      class="text-start ms-2 form-switch-text"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      Email Notification
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-switch form-check-reverse">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckReverse"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="p-2 align-items-start border rounded-2 m-2 mb-3">
                <div class="row justify-content-center align-items-center">
                  <div class="col-2">
                    <div class="send-notification-box">
                      <img src="./images/copy.png" alt="" />
                    </div>
                  </div>
                  <div class="col-8">
                    <div
                      class="text-start ms-2 form-switch-text"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      {" "}
                      Email Notification
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-switch form-check-reverse">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckReverse"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {loading && (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex={-1}
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.3)" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content border-0"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <div className="modal-body d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSettings;
