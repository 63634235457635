import { Routes, Route } from "react-router-dom";
import React from "react";
import UserTabs from "../../components/participant/UserTabs";

const ParticipantInfo = () => {
  //
  return (
    <Routes>
      <Route index element={<UserTabs />} />
      <Route path=":id" element={<UserTabs />} />
    </Routes>
  );
};

export default ParticipantInfo;
