import React, { useState } from "react";
import Modal from "../controls/popup/Modal";
import * as Call from "../../utils/validations";
import { SubscribeNews } from "../../services/userAPI";

//main function
function SubscribeNewsLetter() {
  //declare start
  const [email, setEmail] = useState(""); // State to store email input value
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  // const [apiMessage, setApiMessage] = useState("");

  //declare end

  //functions/events/methods
  // handle subscribe button click
  const handleSubscribe = async () => {
    // For now, just show the modal when the button is clicked

    if (Call.IsValidEmail(email)) {
      const result = await SubscribeNews(email);
      if (result.success) {
        setShowModal(true);
      } else {
        alert(result.message);
      }
      // setApiMessage(result?.message);
      setEmail("");
    } else {
      alert("Please Enter Valid Email.");
    }
  };

  //main return
  return (
    <>
      <div
        className="row news-letter mx-4 p-4 justify-content-center align-items-center"
        /* style={{ marginTop: "-60px" }} */
      >
        <div className="col-12 col-md-6 my-5 subscribe-letter-message">
          Discover the Best Events and Activities in Your City! Subscribe to the
          FitFunda Newsletter!
        </div>
        <div className="col-12 col-md-6 my-5 subscribe-letter-text">
          {/* SUBSCRIBE TO OUR NEWSLETTER! */}
          <div className="input-group mt-3">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Please Enter Email"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Update email state on change
              maxLength={50}
            />
            <button
              className="btn border-0 subscribe-letter-btn"
              type="button"
              id="button-addon2"
              onClick={handleSubscribe} // Call handleSubscribe function on click
            >
              <i className="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <Modal
          title="Congratulations"
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="modal-body text-center p-2 my-2">
            <div className="my-2">
              <img src="/images/thank-you.svg" alt="Thank You" className="" />
            </div>
            <div className="my-2">
              Thanks For Subscribing Our Newsletter!
              {/* {apiMessage} */}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default SubscribeNewsLetter;
