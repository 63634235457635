import * as React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/controls/icons/Icon";

function FitFundaInfo(props) {
  return (
    <>
      <div class="row text-start mx-2 my-5">
        <div class="col-12 col-md-6">
          {/* <h1 class="fw-600">Logo</h1>
          <p class="footer-info">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since
          </p> */}
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>About</b>
          </h6>
          <p class="footer-text mp-2">
            <a href="/" onClick={() => window.location.reload()}>
              About Us
            </a>
          </p>
          <p class="footer-text mp-2">
            <a href="/" onClick={() => window.location.reload()}>
              Site Map
            </a>
          </p>

          <p class="footer-text mp-2">
            <a
              href="https://www.vrattanta.com/blog/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>Learn More</b>
          </h6>

          <p class="footer-text mp-2">
            <Link to={"/navigate-fitfunda"}>Navigate Fit-Funda</Link>
          </p>
          <p class="footer-text mp-2">
            <Link to={"/pricing"}>Pricing</Link>
          </p>
          <p class="footer-text mp-2">
            <Link to={"/policies"}>Policies</Link>
          </p>
        </div>
        <div class="col-12 col-md-2">
          <h6 class="footer-text-h">
            <b>Contact Us</b>
          </h6>
          <p class="footer-text mp-2">
            Vrattanta Endurance & Sports Viman Nagar, Pune
          </p>
          <p class="footer-text mp-2 d-inline-flex">
            <span className="mr-1">
              <Icon iconName="emailfooterinfo" />
            </span>
            <a href="mailto:fitfundasupport@vrattanta.com">
              fitfundasupport@vrattanta.com
            </a>
          </p>
          <p class="footer-text mp-2">
            <span className="mr-1">
              <Icon iconName="phonefooterinfo" />
            </span>
            <a
              href="https://wa.me/919529478968"
              target="_blank"
              rel="noopener noreferrer"
            >
              +91-9529478968
            </a>
          </p>
        </div>
      </div>
    </>
  );
}

export default FitFundaInfo;
