import EventCard from "../../components/event/EventCard.jsx";
import { useParams } from "react-router-dom";
import { GetCategoryEventData } from "../../services/eventAPI.jsx";
import SliderRecommededEvent from "../../components/slider/SliderEventRecommended.jsx";
import { useEffect, useState } from "react";
import Spinner from "../../components/controls/spinner/Spinner.jsx";

const EventCategory = () => {
  const { categoryId } = useParams();
  const { categoryName } = useParams();
  const [loading, setLoading] = useState(false); //spinner

  const [eventData, setEventData] = useState("");

  useEffect(() => {
    const getAllEventByCategory = async () => {
      try {
        setLoading(true);
        const eventsByCategoryResponse = await GetCategoryEventData(categoryId);
        setLoading(false);
        if (eventsByCategoryResponse.success === true) {
          setEventData(eventsByCategoryResponse);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllEventByCategory();
  }, [categoryId]);

  return (
    <>
      <div className="container text-center">
        <div class="row mx-2">
          <div class="col text-start cat-name">
            <b>Events</b> in {categoryName && categoryName.toUpperCase()}{" "}
            Category
          </div>
        </div>
        <div className="row justify-content-center align-items-center mx-2 my-3">
          {eventData && eventData.data && eventData.data.length > 0 ? (
            eventData.data.map((event, index) => (
              <EventCard
                key={event.eventId}
                event={event}
                city={categoryName}
              />
            ))
          ) : (
            <h5>Sorry, We don't have Events in Selected Category.</h5>
          )}
        </div>

        {/* <div className="row justify-content-center align-items-center mx-2 my-3">
          {eventData &&
            eventData.data &&
            eventData.data.map((event, index) => {
              return (
                <EventCard
                  key={event.eventId}
                  event={event}
                  city={categoryName}
                />
              );
            })}
        </div> */}

        <SliderRecommededEvent from={"EventCategory"} />
        {/* <Promotion from={"EventCategory"} /> */}
        {loading && <Spinner />}
      </div>
    </>
  );
};

export default EventCategory;
