import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/controls/icons/Icon";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import Button from "../../components/controls/buttons/Button";
import { API_RESONSE_FAILED } from "../../utils/Messages";
import RegisterParticipant, {
  InitiatePayment,
  RegisterParticipantGuest,
} from "../../services/paymentAPI";
import {
  addToUserDetailRequest,
  fetchUserDetailsRequest,
} from "../../redux/actions/events";
import * as constants from "../../utils/Constants";
import * as comFun from "../../utils/commonFun";
import Spinner from "../controls/spinner/Spinner";

//
export const AccordionUserProfile = ({
  cartEvents,
  userDetails,
  addUserDetails,
  fetchUserDetails,
  eventDetailsData,
  paymentBreakup,
}) => {
  //
  let { eventId } = useParams();
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [primaryBtnIndex, setPrimaryBtnIndex] = useState(0);
  const [detailsEntered, setdetailsEntered] = useState([]);
  const userData = useSelector((state) => state.login.login.login);
  const [inputValueList, setInputValueList] = useState([]);
  const [loading, setLoading] = useState(false); // loading spinner
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isWidget, setIsWidget] = useState(false);

  useEffect(() => {
    let StoreData = fetchUserDetails();
  }, []);
  const eventUserDetails = useSelector(
    (state) => state.events.userDetails.userDetails
  );
  useEffect(() => {
    //store data
    if (eventUserDetails?.length > 0) {
      if (eventUserDetails[0]?.eventId === eventId) {
        setInputValueList(() => eventUserDetails);
      } else if (eventUserDetails[0]?.eventId !== eventId) {
        //clear store
        comFun.removeItem(constants.LOCAL_CART_USER_DETAILS);
      }
    }
  }, [eventUserDetails?.length > 0]);

  //for iframe
  useEffect(() => {
    if (window.location.pathname.includes("/widget")) {
      setIsWidget(true);
      const script = document.createElement("script");
      script.src =
        "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);
    }
  }, []);

  //
  const handleClick = (nextIndex) => {
    //alert(nextIndex);
    setActiveIndex((prevIndex) => {
      if (prevIndex === nextIndex) {
        return prevIndex + 1;
        /* prevIndex + 1 === totalTicketCount ||
          prevIndex >= totalTicketCount
          ? prevIndex
          : prevIndex + 1; */
      } else {
        return nextIndex;
      }
    });
  };
  const handleClickForDefaultUser = (indexPrimary) => {
    let totalTickets = getTicketlist();
    let val = false;
    for (let index = 0; index < totalTickets.length; index++) {
      if (indexPrimary === index) {
        val = true;
        setPrimaryBtnIndex(indexPrimary);
      }
    }

    return val;
  };

  const getTicketlist = () => {
    let totalTickets = [];
    let tempDetailsEntered = [];
    for (const key in cartEvents) {
      const count = cartEvents[key].orderQuantity;
      for (let index = 0; index < count; index++) {
        totalTickets.push(cartEvents[key]);
        tempDetailsEntered.push(false);
      }
    }
    //setdetailsEntered
    //setdetailsEntered(tempDetailsEntered);
    return totalTickets;
  };

  const handleDetailEntered = (index, inputValue) => {
    let tempDetailsEntered = detailsEntered;
    tempDetailsEntered[index] = true;
    setdetailsEntered(tempDetailsEntered);
    handleClick(index);

    if (inputValueList.length > 0) {
      if (
        inputValue.userProfileId > 0 &&
        inputValue.fullName.trim().length > 0
      ) {
        let idNotFound = true;
        //update old ticket
        let newList = inputValueList.map((item) => {
          if (
            item.userProfileId > 0 &&
            item.userProfileId === inputValue.userProfileId
          ) {
            item = { ...item, ...inputValue };
            idNotFound = false;
            /* item.fullName = inputValue.fullName;
            item.email = inputValue.email;
            item.mobileNo = inputValue.mobileNo;
            item.dob = inputValue.dob;
            item.participantPhoto = inputValue.participantPhoto;
            item.gender = inputValue.gender;
            item.tShirtSize = inputValue.tShirtSize;
            item.clubName = inputValue.clubName;
            item.country = "IN";
            item.stateId = inputValue.stateId;
            item.cityId = inputValue.cityId;
            item.address = inputValue.address;
            item.pincode = inputValue.pincode;
            item.idProofType = inputValue.idProofType;
            item.emergencyContactNumber = inputValue.emergencyContactNumber;
            item.emergencyContactName = inputValue.emergencyContactName;
            item.bloodGroup = inputValue.bloodGroup;
            item.timingCertificate = inputValue.timingCertificate;
            item.ticketId = inputValue.ticketId;
            item.ticketType = inputValue.ticketType;
            if (inputValue?.participantId > 0)
              item.participantId = inputValue.participantId; */
          } else if (
            item?.participantId > 0 &&
            item.participantId === inputValue.participantId
          ) {
            item = { ...item, ...inputValue };
            idNotFound = false;
          }
          return item;
        });
        if (idNotFound) {
          newList.push(inputValue);
        }
        setInputValueList(newList);
      } else {
        //dont add as name and id missing
        /*  //add new ticket
        const templist = [inputValueList, inputValue];
        setInputValueList(templist); */
      }
    } else {
      const templist = [{ ...inputValue }];
      setInputValueList(templist);
    }
  };

  //
  const renderItems = () => {
    const renderedItems = [];
    let index = 0;
    let totalTickets = getTicketlist();

    for (let index = 0; index < totalTickets.length; index++) {
      const isActive = index === activeIndex;
      const isPrimaryBtnIndex = index === primaryBtnIndex;
      const ticketPrice = parseInt(totalTickets[index].ticketPrice);

      renderedItems.push(
        <>
          <div className="card mb-2">
            <div className="card-body p-1">
              <div class="accordion accordion-flush ">
                <div
                  className={` accordion-item collapsed ${
                    isActive ? "collapsed" : ""
                  }  `}
                >
                  <div
                    className={`accordion-header`}
                    onClick={() => handleClick(index)}
                    key={index}
                  >
                    <button
                      className={` accordion-button ${
                        isActive ? "collapsed show border-bottom " : ""
                      } ${detailsEntered[index] === true ? " bg-success" : ""}`}
                      type="button"
                    >
                      {/* ${isPrimaryBtnIndex ? " bg-light " : ""} */}
                      <div class="d-flex justify-content-center align-items-center accordion-detail">
                        <div className="accordion-icon me-2">
                          <Icon iconName="profile" />
                        </div>
                        <h1
                          className={`mt-2 ${
                            isPrimaryBtnIndex ? "active m-0" : ""
                          }`}
                        >
                          {index + 1}{" "}
                          {isPrimaryBtnIndex ? (
                            <b class="text-warning">Primary</b>
                          ) : (
                            ""
                          )}{" "}
                          User Details for {totalTickets[index].ticketName}{" "}
                          {ticketPrice > 0
                            ? `(₹ ${ticketPrice.toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                              })})`
                            : ""}
                          ticket
                        </h1>
                      </div>
                    </button>
                  </div>
                  <div
                    className={` accordion-collapse p-2 accordion-detail collapse  ${
                      isActive ? "show" : ""
                    }`}
                  >
                    <div class="alert alert-secondary" role="alert">
                      Enter the details below for the{" "}
                      {totalTickets[index].ticketName} ticket
                    </div>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <input
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                            onClick={() => handleClickForDefaultUser(index)}
                            value={isPrimaryBtnIndex}
                            checked={isPrimaryBtnIndex}
                            style={{ margin: "4px" }}
                          />
                          {"  "}
                          Primary User Details
                        </div>
                      </div>
                    </div>
                    <CheckoutForm
                      ticket={totalTickets[index]}
                      index={index}
                      onDetailEntered={handleDetailEntered}
                      eventDetailsData={eventDetailsData}
                      inputValueList={inputValueList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return renderedItems;
  };
  //
  function allAreTrue(arr) {
    return arr.every((element) => element === true);
  }
  const renderFinalSubmitButton = () => {
    const check = detailsEntered;
    if (check?.length > 0) {
      const showbtn = allAreTrue(check);
      let totalTickets = getTicketlist();
      if (showbtn === true && check.length === totalTickets.length) {
        return (
          <>
            <div class=" text-center">
              {/* {loading ? (
          <>
          <Spinner/>
          </>
            ):(
            <>
            
            </>
          )} */}
              <Button onClick={saveAndProceed}>Save & Proceed</Button>
            </div>
          </>
        );
      }
    }
  };

  const saveAndProceed = async (e) => {
    e.preventDefault();

    let postValue = {
      eventId: "",
      participantData: [],
      // userId: "",
    };

    let participantPhotoList = inputValueList?.map((item, index) => {
      return (postValue[`participantPhoto${index + 1}`] =
        item["participantPhoto"]);
    });

    let idProofList = inputValueList?.map((item, index) => {
      return (postValue[`idProof${index + 1}`] = item["idProof"]);
    });

    let timingCertificateList = inputValueList?.map((item, index) => {
      return (postValue[`timingCertificate${index + 1}`] =
        item["timingCertificate"]);
    });

    let fitnessCertificateList = inputValueList?.map((item, index) => {
      return (postValue[`fitnessCertificate${index + 1}`] =
        item["fitnessCertificate"]);
    });

    for (let i = 0; i < inputValueList.length; i++) {
      const obj = inputValueList[i];
      // Iterate over each key in the object
      for (const key in obj) {
        // Check if the value is null, undefined, or an empty string
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          // Remove the key from the object
          delete obj[key];
        }
      }
    }

    postValue.participantData = inputValueList?.map((item) => {
      delete item["errors"];
      delete item["IsValid"];
      delete item["isRequired"];

      delete item["participantPhoto"];
      delete item["idProof"];
      delete item["timingCertificate"];
      delete item["fitnessCertificate"];
      parseInt(item["pincode"]);
      parseInt(item["ticketId"]);

      return item;
    });

    /**
     * This checkmark is added to make sure that we are passing correct eventId to the
     * backend. To avoide showing incorrect ticket count in weekly mail. Which is
     * going to Organizer.
     **/

    if (
      window.location.pathname.includes(
        "/event-checkout/" + inputValueList[0].eventId
      )
    ) {
      postValue.eventId = inputValueList[0].eventId;
      inputValueList[primaryBtnIndex].isPrimary = true;

      //send data to server
      let participantResponse;
      setLoading(true);

      if (userData?.user) {
        postValue.userId = userData?.user?.userId;
        const token = userData?.token;
        participantResponse = await RegisterParticipant(postValue, token);
      } else {
        participantResponse = await RegisterParticipantGuest(postValue);
      }

      //next step
      if (participantResponse) {
        if (participantResponse.success) {
          //store
          userDetails = participantResponse?.data;
          addUserDetails(userDetails);

          if (participantResponse.isTicketBooked) {
            setLoading(false);
            navigate(
              "/booking-status?status=success&orderConfirmationId=" +
                participantResponse.orderConfirmationId +
                "&eventId=" +
                inputValueList[0].eventId
            );
          } else {
            let paymentDataInfo = inputValueList[primaryBtnIndex];
            const participantData = participantResponse.data;
            const participantIdObject = participantData.map(
              (obj) => obj.participantId
            );

            const initiatePaymentResult = await InitiatePayment(
              postValue.eventId,
              paymentDataInfo,
              participantIdObject,
              paymentBreakup.netOutgoForParticipant,
              paymentBreakup,
              isWidget
            );
            setLoading(false);

            if (initiatePaymentResult?.success) {
              // window.location.href = initiatePaymentResult.data.url;
              if (isWidget) {
                loadAndInitiatePayment(initiatePaymentResult);
              } else if (initiatePaymentResult?.data?.url) {
                window.open(initiatePaymentResult?.data?.url, "_self");
              }
            } else {
              alert(initiatePaymentResult?.error);
            }
          }
        } else {
          alert(participantResponse?.error);
        }
      } else {
        alert(API_RESONSE_FAILED);
      }
    } else {
      alert("Please reload the page.");
    }
  };

  const loadAndInitiatePayment = (initiatePaymentResult) => {
    if (scriptLoaded) {
      const easebuzzCheckout = new window.EasebuzzCheckout(
        process.env.REACT_APP_EASEBUZZ_KEY,
        process.env.REACT_APP_EASEBUZZ_ENV
      );
      const options = {
        access_key: initiatePaymentResult.data.accessKey,
        onResponse: (response) => {
          console.log(response);
          const url = `${process.env.REACT_APP_FRONTEND_USER}booking-status?status=${response.status}&txnId=${response.txnid}&message=${response.error}&eventId=${response.productinfo}`;
          console.log(url);
          window.open(url, "_self");
        },
        theme: "#123456",
      };
      easebuzzCheckout.initiatePayment(options);
    } else {
      console.error("Script not loaded yet.");
    }
  };

  //
  return (
    <>
      {/* <div className="card mb-2">
        <div className="card-body p-1">
          <div class="accordion accordion-flush border-top border-bottom mb-5"> */}

      {renderItems()}
      {renderFinalSubmitButton()}
      {loading ? (
        <div
          className="modal fade show"
          id="exampleModalCenter"
          tabIndex={-1}
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div
              className="modal-content  border-0"
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <div className="modal-body d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.events.userDetails.userDetails,
  /* ? state.events.cartEvents.cartEvents[0]
    : state.events.cartEvents.cartEvents, */
});
const mapDispatchToProps = (dispatch) => ({
  addUserDetails: (userDetails) =>
    dispatch(addToUserDetailRequest(userDetails)),
  fetchUserDetails: () => dispatch(fetchUserDetailsRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccordionUserProfile);
//export default AccordionUserProfile;
