import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SideBar from "../controls/menu/SideBar";
import ShowLoginMsg from "../login/ShowLoginMsg";
import { clearLocalData } from "../../utils/commonFun";
import { GetCurrentLocation, IsMobile } from "../../utils/commonFun";
import { KEY_EVENT_ID } from "../../utils/Constants";

//
const LeftMenuPanel = () => {
  const navigate = useNavigate();
  const userData = useSelector((loginData) => loginData.login.login);
  const eventDetails = useSelector(
    (state) => state.createEvent.eventDetails.eventDetails
  );

  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  }

  let currentLocation = GetCurrentLocation();
  currentLocation = "/";
    // currentLocation === null || currentLocation === undefined
    //   ? 
    //   : currentLocation?.pathName;
  ///
  const config = {
    isUserLogin: isUserLogin,
    loginMessage: <ShowLoginMsg />,
    data: [
      {
        title: "Dashboard",
        url: "/dashboard",
        icon: "dashboard",
        showLogin: true,
      },
      {
        title: "Events",
        url: "/events",
        icon: "events",
        subMenu: true,
        data: [
          {
            title: "Create Event",
            url: "/create-event",
            icon: "accouncement",
            onClick: () => {
              if (
                localStorage.getItem(KEY_EVENT_ID) !== null ||
                eventDetails?.eventId?.length > 1
              ) {
                localStorage.removeItem(KEY_EVENT_ID);
                //navigate("/create-event?clear");
                window.location.reload();
              }
            },
          },
          {
            title: "Active Events",
            url: "/active-event",
            icon: "accouncement",
            showLogin: true,
          },
          {
            title: "Past Events",
            url: "/past-event",
            icon: "accouncement",
            showLogin: true,
          },
          {
            title: "Draft Events",
            url: "/draft-event",
            icon: "accouncement",
            showLogin: true,
          },
        ],
      },
      {
        title: "My Account",
        url: "/my-account",
        icon: "myaccount",
        showLogin: true,
      },
      {
        title: "Announcement",
        url: "/my-announcement",
        icon: "accouncement",
        showLogin: true,
      },

      {
        title: "Logout",
        class: "event m-1 fixed-bottom logout-icon ms-3",
        url: currentLocation + "?successLogout", //"/logout",
        icon: "logout",
        onClick: () => {
          //return document.getElementById("btnLogout").click();
          clearLocalData();
          //navigate(currentLocation + "?successLogout");
        },
        showOnlyLogin: true,
      },
    ],
  };

  return <SideBar config={config} />;
};

export default LeftMenuPanel;
