import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { addToLoginRequest } from "../../../redux/actions/Login";

const OrganizerProfileCreated = ({
  saveLogin,
  login,
  organizerDisplayMessage,
}) => {
  const navigate = useNavigate();
  let userData = useSelector((state) => state.login.login.login);
  let { user } = userData;

  const handleProceed = async () => {
    login = {
      ...userData,
      user: { ...user, role: 4 },
      update: true,
    };

    await saveLogin(login);
    navigate("/draft-event");
  };

  return (
    <div class="card p-4 organizer-logo organizer-success my-3 text-center justify-content-center align-items-center">
      <img
        src="/images/success-profile.svg"
        alt=""
        class="organizer-success-logo my-4"
      />
      {/* <h1>Your organizer profile is created successfully</h1> */}
      <h1>{organizerDisplayMessage}.</h1>
      {organizerDisplayMessage.includes("updated") ? (
        <>{/* <p class="mb-4">Verifing things again.</p> */}</>
      ) : (
        <>
          <p class="mb-4">Need to setup a few necessary things</p>
        </>
      )}

      <button
        type="submit"
        class="btn btn-select w-50 my-4"
        onClick={handleProceed}
      >
        Proceed
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  saveLogin: (login) => dispatch(addToLoginRequest(login)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizerProfileCreated);
