import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { KEY_EVENT_ID } from "../../utils/Constants";
import Modal from "../controls/popup/Modal";
import Button from "../controls/buttons/Button";
//
const ExistingSessionPopup = ({ onDismiss }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const navigateToExistingEvent = () => {
    setShowModal(false);
    onDismiss();
    navigate("/create-event");
  };

  const navigateToCreateNewEvent = () => {
    setShowModal(false);
    onDismiss();
    localStorage.removeItem(KEY_EVENT_ID);
    navigate("/create-event");
    window.location.reload();
  };

  return (
    <>
      <Modal onClose={onDismiss} title={"Please select create event option"}>
        <div class="modal-content">
          <div class="modal-body">
            Do you want to continue existing session or create new event?
          </div>
          <div class="modal-footer w-100">
            <Button secondary onClick={navigateToCreateNewEvent}>
              Create New
            </Button>
            <Button primary onClick={navigateToExistingEvent}>
              Continue Existing
            </Button>
          </div>
        </div>
      </Modal>
      {/* 
      <div
        class="modal fade show"
        id="modalPopUp"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        style={{
          paddingRight: "15px",
          display: `${showModal ? "block" : "none"}`,
        }}
        aria-modal="true"
        role="dialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              Do you want to continue existing session or create new event?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={navigateToCreateNewEvent}
              >
                Create New
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={navigateToExistingEvent}
              >
                Continue Existing
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ExistingSessionPopup;
