import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GetOrganizerProfile } from "../../../services/userAPI";
import OrganizerProfileTabs from "../../../components/controls/Tab/OrganizerProfileTabs";
import OrganizerProfileCreated from "../../../components/profile/create/OrganizerProfileCreated";
import * as initialState from "../../../services/initialState";

let initialData = initialState.OrganizerProfileInfo;

const CreateOrganizerProfile = () => {
  const [inputValue, setInputValue] = useState(initialData);
  const userData = useSelector((state) => state.login.login.login);
  const [profileCreated, setProfileCreated] = useState(false);
  const [profileCreatedMessage, setProfileCreatedMessage] = useState("");

  useEffect(() => {
    const getOrganizerDetails = async () => {
      try {
        if (userData) {
          const profileData = await GetOrganizerProfile(
            userData.user.userId,
            userData.token
          );

          if (profileData.success) {
            setInputValue(profileData.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getOrganizerDetails();
  }, [userData]);

  return (
    <>
      {userData && (
        <div class="mdnCreateOrgPro row org-main-contain justify-content-center align-items-center">
          <div class="w-50 mx-5 ">
            {profileCreated ? (
              <>
                <OrganizerProfileCreated
                  organizerDisplayMessage={profileCreatedMessage}
                />
              </>
            ) : (
              <>
                <OrganizerProfileTabs
                  organizerDetails={inputValue}
                  onProfileCreated={(value) => setProfileCreated(value)}
                  updatedOrganizerDetails={(value) => setInputValue(value)}
                  organizerDisplayMessage={(value) =>
                    setProfileCreatedMessage(value)
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateOrganizerProfile;
