import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { decryptData, encryptData } from "./cryptoJs";
//
export const GetWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const saveToLocal = (key, value) => {
  let inputValue = value;
  if (value != undefined && value != null && value != "")
    inputValue = encryptData(value);
  try {
    localStorage.setItem(key, inputValue);
  } catch (exception) {
    console.log(exception);
  }
};

export const getLocalData = (key) => {
  //return localStorage.getItem(key);
  let value = localStorage.getItem(key);
  let inputValue = value;
  if (value != undefined && value != null && value != "") {
    inputValue = decryptData(value);
  }

  return inputValue;
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const clearLocalData = () => {
  localStorage.clear();
};

export const getLocalDateFormate = (date, need) => {
  try {
    /* let date1 = dayjs(date);
    if (need === "hhmm") return date1?.format("DD-MMM-YYYY hh:mm");
    else if (need === "hhmm_only") return date1?.format("hh:mm");
    else return date1?.format("DD-MMM-YYYY"); */

    let returnDate = date;
    let arrDate = date.split("T");
    if (arrDate.length >= 2) {
      let onlyDate = dayjs(arrDate[0]).format("DD-MMM-YYYY");
      let arrHm = arrDate[1].split(":");
      if (need === "hhmm")
        returnDate = onlyDate + " " + arrHm[0] + ":" + arrHm[1];
      else if (need === "hhmm_only") returnDate = arrHm[0] + ":" + arrHm[1];
      else returnDate = onlyDate;
    }
    return returnDate;
    /*let date1 = new Date(date);
     let strDate =
      date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
    if (needhhmm === "hhmm") return strDate;
    else strDate = strDate + " " + date.getHours() + ":" + date.getMinutes();
    return strDate; */
  } catch {}
};
export const GetCurrentLocation = () => {
  let currentLocation;
  //assigning location variable
  const location = useLocation();
  try {
    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    //get current Location
    currentLocation = {
      mainRoute: splitLocation[1],
      pathArray: splitLocation,
      pathName: pathname,
    };
    return currentLocation;
  } catch (e) {
    return currentLocation;
  }
};

export const IsMobile = () => {
  const result = window.matchMedia("(max-width: 768px)");
  if (result.matches) {
    // do something
    //alert("mobile");
    return true;
  } else return false;
};

export const GetQueryString = () => {
  let obj = {};
  try {
    var query = window.location.search.substring(1);
    console.log(query);
    var vars = query.split("&");
    // console.log(vars);

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      obj[pair[0]] = pair[1];
      /* console.log(pair);
      if (pair[0] == variable) {
        return pair[1];
      } */
    }
  } catch {}
  return obj;
};

export const GetFileExtension = (fileName) => {
  // Check if the file name contains a dot, and return the extension after the last dot
  return fileName
    .slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
};

//default export
export default GetWindowDimensions;
