import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as Call from "../../../utils/validations";
import {
  SaveEventLocation,
  SaveEventLocationGuest,
} from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import * as common from "../../../utils/commonFun";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import StateDropdown from "../../controls/dropdown/StateDropdown";
import CityByStateIdDropdown from "../../controls/dropdown/CityByStateIdDropdown";
import * as initialState from "../../../services/initialState";
import ShowGoogleMap from "../../controls/googleMap/ShowGoogleMap";
import Spinner from "../../controls/spinner/Spinner";
import { StandaloneSearchBox } from "@react-google-maps/api";
import useGoogleMaps from "../../controls/googleMap/useGoogleMaps";

// Form initial Data
let initialData = initialState.EventLocation;
let mParam = initialState.EventLocation.isRequired;
let eventId;
const libraries = ["places"];

const EventLocation = ({ eventDetails, saveEventLocation, onStepComplete }) => {
  const [inputValue, setInputValue] = useState(initialData);
  let { errors, IsValid } = inputValue;
  const [validationSummary, setValidationSummary] = useState("");
  const userData = useSelector((state) => state.login.login.login);
  const [loading, setLoading] = useState(false); //spinner

  const [places, setPlaces] = useState([]);
  const [searchBox, setSearchBox] = useState(null);
  const scriptLoaded = useGoogleMaps(
    process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries
  );
  // declearation end

  const thisStepCompleted = (value) => {
    switch (value) {
      //case "E1.1":
      case "E1.2":
      case "E1.3":
      case "E1.4":
      case "E2":
      case "E3":
      case "E4":
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  //if store data available
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      //
      if (thisStepCompleted(eventDetails?.stepCompleted)) {
        initialData = { ...initialData, ...eventDetails };
        initialData["IsValid"] = mParam;
        //
        setInputValue(initialData);
        //updateLocateionOnMap(eventDetails.cityName);
      }
    }
  }, [eventDetails]);

  const saveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      if (
        IsValid &&
        IsValid.address &&
        IsValid.area &&
        IsValid.stateId &&
        (IsValid.cityId || inputValue?.cityId > 0) &&
        IsValid.pincode
      ) {
        if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);

        //post data logic
        let postValue = initialState.EventLocation;
        let skipKey = initialState.skipKey;
        for (let key in postValue) {
          try {
            if (skipKey.includes(key)) {
              delete postValue[key];
            } else {
              postValue[key] = inputValue[key];
            }
          } catch {}
        }
        if (eventId) postValue.eventId = eventId;
        //send data to server
        setLoading(true);
        let result;
        if (userData) {
          postValue.userId = userData.user.userId;
          const token = userData.token;
          result = await SaveEventLocation(postValue, token);
        } else {
          result = await SaveEventLocationGuest(postValue);
        }
        setLoading(false);

        //next step
        if (result) {
          if (result.success) {
            eventDetails = result.data;
            eventDetails = {
              ...eventDetails,
              IsValid: { ...IsValid, ...inputValue?.IsValid },
            };
            saveEventLocation(eventDetails);

            onStepComplete(2);
          } else {
            alert("Error:-" + result.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        //alert("Enter all required fields");
        let displaMsg = "";
        const fieldLabels = {
          address: "Event Address",
          area: "Area",
          cityId: "City",
          stateId: "State",
          pincode: "Pincode",
        };
        for (let key in mParam) {
          if (!inputValue?.IsValid[key]) {
            displaMsg += "<li>" + fieldLabels[key] + "</li>";
          }
        }
        displaMsg =
          "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
        setValidationSummary(() => displaMsg);
      }
    }
  };

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
      /* if (result.name2 === "cityName") {
        updateLocateionOnMap(result.value2);
      } */
    } else {
      //setInputValue
      setInputValue((prev) => ({
        ...prev,
        [name]: value === "" ? "" : value,
        ["errors"]: { ...errors, [name]: result.message },
        ["IsValid"]: { ...IsValid, [name]: result.status },
      }));
    }
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "address":
          val = !Call.IsEmpty(value);
          if (val) msg = "Please enter address";
          break;

        case "area":
          val = !Call.IsEmpty(value);
          if (val) msg = "Please enter area.";
          break;
        case "cityId":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Please select " + name;
          else {
            value = parseInt(value);
          }
          try {
            value2 = e.target.options[e.target.selectedIndex].text;
            if (name === "cityId") {
              name2 = "cityName";
            }
          } catch {}
          break;

        case "stateId":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Please select " + name;
          else {
            value = parseInt(value);
          }
          try {
            value2 = e.target.options[e.target.selectedIndex].text;

            if (name === "stateId") {
              name2 = "stateName";
            }
          } catch {}
          break;
        case "pincode":
          val = Call.IsValidPinCode(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 6 digit pincode ";
          }
          break;
        default:
          break;
      }
    }

    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();

    setPlaces(
      places.map((place) => {
        const addressComponents = getAddressComponents(place);
        return {
          name: place.name,
          address: place.formatted_address,
          city: addressComponents.city,
          state: addressComponents.state,
          area: addressComponents.area,
          postal_code: addressComponents.postal_code,
        };
      })
    );
  };

  const getAddressComponents = (place) => {
    let city = "City not found";
    let state = "State not found";
    let area = "";
    let postal_code = "Postal code not found";

    place.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      }
      if (
        component.types.includes("sublocality") ||
        component.types.includes("neighborhood")
      ) {
        if (area === "") {
          area = component.long_name;
        } else {
          area = area + "," + component.long_name;
        }
      }
      if (component.types.includes("postal_code")) {
        postal_code = component.long_name;
      }
    });

    inputValue.address = place.name;
    inputValue.IsValid.address = true;
    inputValue.area = area;
    inputValue.IsValid.area = true;
    inputValue.pincode = postal_code;
    inputValue.IsValid.pincode = true;

    setInputValue(inputValue);

    return { city, state, area, postal_code };
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="card p-4">
            <h5>Event Location</h5>
            <div className="">
              <div className="my-3">
                <label for="exampleInputEmail1" class="form-label">
                  Event Address<span className="text-red-600">*</span>
                </label>
                {scriptLoaded ? (
                  <div>
                    <StandaloneSearchBox
                      onLoad={onLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <>
                        <InputTextBox
                          id="address"
                          name="address"
                          value={inputValue.address}
                          placeholder="Please enter address"
                          isValid={inputValue?.IsValid?.address}
                          errorMsg={inputValue?.errors?.address}
                          onChange={handleChange}
                          autoFocus
                          style={{
                            outline: `none`,
                            textOverflow: `ellipses`,
                          }}
                        />
                      </>
                    </StandaloneSearchBox>
                  </div>
                ) : (
                  <>
                    <div>Loading...</div>
                  </>
                )}
              </div>

              <div className="row g-3 my-3">
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    Area<span className="text-red-600">*</span>
                  </label>
                  <InputTextBox
                    id="area"
                    name="area"
                    value={inputValue.area}
                    placeholder="Please enter area"
                    isValid={inputValue?.IsValid?.area}
                    errorMsg={inputValue?.errors?.area}
                    onChange={handleChange}
                  />
                </div>
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    State<span className="text-red-600">*</span>
                  </label>
                  <StateDropdown
                    name="stateId"
                    stateId={inputValue?.stateId}
                    onStateChange={handleChange}
                  />
                </div>
              </div>

              <div className="row g-3 my-3">
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    City<span className="text-red-600">*</span>
                  </label>
                  <CityByStateIdDropdown
                    name="cityId"
                    stateId={inputValue?.stateId}
                    cityId={inputValue?.cityId}
                    onCityChange={handleChange}
                  />
                </div>
                <div className="col">
                  <label class="" for="inlineFormInputGroupUsername">
                    Pincode<span className="text-red-600">*</span>
                  </label>
                  <InputTextBox
                    id="pincode"
                    name="pincode"
                    value={inputValue.pincode}
                    placeholder="Please enter pincode"
                    isValid={inputValue?.IsValid?.pincode}
                    errorMsg={inputValue?.errors?.pincode}
                    onChange={handleChange}
                    maxlength={6}
                  />
                </div>
              </div>

              <div class="my-5">
                <label for="exampleInputEmail1" class="form-label">
                  Map
                </label>
                <ShowGoogleMap
                  searchInput={{
                    address: inputValue?.address,
                    area: inputValue?.area,
                    city: inputValue?.cityName,
                    state: inputValue?.stateName,
                  }}
                  cameFrom="EventCardTypeLarge"
                />
              </div>
              {eventDetails.eventId && (
                <div className="mb-3 text-center">
                  <Button onClick={saveAndProceed}>Save & Proceed</Button>
                </div>
              )}
              {validationSummary !== "" && (
                <div
                  class="alert alert-danger"
                  role="alert"
                  dangerouslySetInnerHTML={{ __html: validationSummary }}
                ></div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventLocation: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(EventLocation);
//export default EventLocation;
