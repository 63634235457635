import React, { useState, useEffect } from "react";
import { convertDateTimeToISTForUI } from "../../../utils/DateTimeUtility";

//
const DiscountCoupons = ({
  inputValue,
  onEdit,
  onDelete,
  index,
  eventDetails,
}) => {
  const [inputDisable, setInputDisable] = useState(false);
  //if store data available
  useEffect(() => {
    if (eventDetails) {
      //
      if (
        eventDetails?.eventStatus === "act" ||
        eventDetails?.eventStatus === "iact"
      ) {
        setInputDisable(() => true);
      }
    }
  }, [eventDetails]);

  const handleEdit = () => {
    onEdit(inputValue.discountCouponId, inputValue.couponStatus);
  };
  const handleDelete = () => {
    onDelete(inputValue.discountCouponId, inputValue.couponStatus);
  };

  const color = ["yellow", "green"];
  let showColor = index % 2 == 0 ? 1 : 0; //Math.random(0, 1);
  return (
    <>
      <div class="card p-4 org-events-photo-upload my-4">
        <div class="discount-Coupons-box my-3">
          <div
            class={
              "discount-Coupons " +
              (color[showColor] === "green" ? "discount-Coupons-green" : "")
            }
          >
            <div class="d-flex">
              <div class="name">
                <div
                  class={
                    "dis " +
                    (color[showColor] === "green"
                      ? "discount-Coupons-green"
                      : "")
                  }
                >
                  DISCOUNT
                </div>
                <div class="cou">COUPON</div>
              </div>
              <div class="offer">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="p-2 w-100">
                    <h1>
                      FLAT
                      {inputValue.discountPercent > 0 ||
                      inputValue.discountAmount > 0 ? (
                        <>
                          {inputValue.discountPercent > 0
                            ? " " + inputValue.discountPercent + " %"
                            : " " + inputValue.discountAmount}
                        </>
                      ) : (
                        <>
                          {inputValue.tempDiscountPercentType ==
                            "discountPercent"}
                        </>
                      )}
                      {/* 50% */} <span>Off</span>
                    </h1>
                  </div>
                  <div class="p-2 flex-shrink-2 no">
                    {inputValue.numOfDiscount}
                  </div>
                </div>
                <div class="d-flex flex-column mb-3">
                  <div class="p-2 discount-Name">
                    {inputValue.discountName} (
                    {inputValue.discountType == "Group"
                      ? inputValue.discountType +
                        "[" +
                        inputValue.minTickets +
                        "]"
                      : inputValue.discountType}
                    ){/* Discount Name */}
                  </div>
                  <div class="date-time px-2">
                    {convertDateTimeToISTForUI(inputValue.discountStartDate)} |{" "}
                    {convertDateTimeToISTForUI(inputValue.discountEndDate)}
                  </div>
                  <div class="pt-3 px-2">
                    {inputDisable == false ||
                    inputValue?.couponStatus === undefined ? (
                      <a
                        href="#"
                        onClick={() =>
                          handleEdit(
                            inputValue.discountCouponId,
                            inputValue.couponStatus
                          )
                        }
                      >
                        {" "}
                        Edit{" "}
                      </a>
                    ) : (
                      <a href="#" className="text-success">
                        {inputValue?.couponStatus === "act" ? (
                          <>Active</>
                        ) : (
                          <>In-Active</>
                        )}
                      </a>
                    )}
                    <br />

                    {inputValue?.couponStatus === "iact" ? (
                      <></>
                    ) : (
                      <>
                        <a
                          href="#"
                          onClick={() =>
                            handleDelete(
                              inputValue.discountCouponId,
                              inputValue.couponStatus
                            )
                          }
                        >
                          {" "}
                          De-Activate{" "}
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountCoupons;
