import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Icon from "../icons/Icon";
import { GetTopCities } from "../../../services/commonAPI";
import { FOLDER_EVENT_PHOTO, NO_IMAGE_PATH } from "../../../utils/Constants";
import InputTextBox from "../input/InputTextBox";
import StateDropdown from "../dropdown/StateDropdown";
import CityByStateIdDropdown from "../dropdown/CityByStateIdDropdown";
import CategoryDropdown from "../dropdown/CategoryDropdown";
import * as Call from "../../../utils/validations";
import ShowDatePicker from "../date/ShowDatePicker";
import * as initialState from "../../../services/initialState";

//
// Form initial Data
let initialData = initialState.Search;
if (initialData?.searchText) delete initialData["searchText"];
if (initialData?.errors) delete initialData["errors"];
if (initialData?.IsValid) delete initialData["IsValid"];
/* let initialData = {
  cityId: 0,
  stateId: 0,
  categoryId: 0,
  sDate: "",
  eDate: "",
  minPrice: 0,
  maxPrice: 0,
  errors: {},
  IsValid: {},
}; */
const FiltersPopup = ({ onSave }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const ImagePath = process.env.REACT_APP_CITYS;
  const [inputValue, setInputValue] = useState(initialData);
  const [filterSave, setFilterSavePopup] = useState(false);

  const handleClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSuccess = () => {
    //alert("scccess");
    onSave(true, inputValue);
    handleClose();
    setFilterSavePopup(() => {
      return true;
    });
  };
  const handleReset = () => {
    let reset = {
      cityId: 0,
      stateId: 0,
      categoryId: 0,
      cityName: 0,
      stateName: 0,
      categoryName: 0,
      sDate: "",
      eDate: "",
      minPrice: 0,
      maxPrice: 0,
    };
    setInputValue(() => {
      return reset;
    });
    onSave(false, reset);
    setFilterSavePopup(() => {
      return false;
    });
  };
  const handleOnError = (event) => {
    event.target.src = NO_IMAGE_PATH;
  };
  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;

    //check validation
    let result = isValid(name, value, e);
    //setInputValue
    if (result.name2 !== "") {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        [result.name2]: result.value2 === "" ? "" : result.value2,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    } else {
      setInputValue((prev) => ({
        ...prev,
        [result.name]: result.value === "" ? "" : result.value,
        ["errors"]: { ...prev.errors, [name]: result.message },
        ["IsValid"]: { ...prev.IsValid, [name]: result.status },
      }));
    }
  };
  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    let name2 = "";
    let value2 = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "city":
        case "state":
        case "category":
          name2 = name;
          name = name + "Id";
          val = Call.IsNumberOnlyInput(value);
          try {
            value2 = e.target.options[e.target.selectedIndex].text;
            name2 = name2 + "Name";
          } catch {}
          if (!val) msg = "Please select " + name;
          else {
            value = parseInt(value);
          }
          break;
        case "minPrice":
        case "maxPrice":
          //name = name + "Id";
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Please enter proper " + name;
          else {
            value = parseInt(value);
          }
          break;
        default:
          break;
      }
    }

    //if(!val && msg.length>0) {
    /*   setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    })); */
    //}
    return {
      status: val,
      message: msg,
      name: name,
      value: value,
      name2: name2,
      value2: value2,
    };
  };
  const handleStartDateChange = (date) => {
    //setInputValue
    setInputValue((prev) => ({
      ...prev,
      ["sDate"]: date,
    }));
  };
  const handleEndDateChange = (date) => {
    //setInputValue
    setInputValue((prev) => ({
      ...prev,
      ["eDate"]: date,
    }));
  };

  //
  return (
    <>
      <a
        className={`btn btn btn-outline-light mx-1 border ${
          filterSave === true ? "bg-success" : ""
        }`}
        href="#"
        onClick={handleClick}
      >
        <Icon iconName="filter" />
        {/* <span>filter</span> */}
      </a>

      {showModal && (
        <Modal
          title="Select Filter"
          onClose={handleClose}
          onSuccess={handleSuccess}
          onReset={handleReset}
        >
          <>
            <form class="row g-3 needs-validation" novalidate>
              <div class="col-md-6">
                <label for="validationCustom01" class="form-label">
                  Min Price
                </label>
                <InputTextBox
                  id="minPrice"
                  name="minPrice"
                  placeholder="Please enter minPrice"
                  isValid={inputValue?.IsValid?.minPrice}
                  errorMsg={inputValue?.errors?.minPrice}
                  value={inputValue.minPrice}
                  onChange={handleChange}
                />
              </div>
              <div class="col-md-6">
                <label for="validationCustom02" class="form-label">
                  Max Price
                </label>
                <InputTextBox
                  id="maxPrice"
                  name="maxPrice"
                  placeholder="Please enter maxPrice"
                  isValid={inputValue?.IsValid?.maxPrice}
                  errorMsg={inputValue?.errors?.maxPrice}
                  value={inputValue.maxPrice}
                  onChange={handleChange}
                />
              </div>
              <div class="col-md-6">
                <label for="validationCustom03" class="form-label">
                  Start Date
                </label>
                <ShowDatePicker
                  type="start-date"
                  title=""
                  curDate={inputValue.sDate}
                  onDateChange={handleStartDateChange}
                />
              </div>
              <div class="col-md-6">
                <label for="validationCustom03" class="form-label">
                  End Date
                </label>
                <ShowDatePicker
                  type="end-date"
                  title=""
                  curDate={inputValue.eDate}
                  minDate={inputValue.sDate}
                  onDateChange={handleEndDateChange}
                  disabled={showEndDate === true ? true : false}
                />
              </div>
              <div class="col-md-6">
                <label for="validationCustom03" class="form-label">
                  State
                </label>
                <StateDropdown
                  name="state"
                  stateId={inputValue?.stateId}
                  onStateChange={handleChange}
                />
              </div>
              <div class="col-md-6">
                <label for="validationCustom04" class="form-label">
                  City
                </label>
                <CityByStateIdDropdown
                  name="city"
                  stateId={inputValue?.stateId}
                  cityId={inputValue?.cityId}
                  onCityChange={handleChange}
                />
              </div>
              <div class="col-md-12">
                <label for="validationCustom04" class="form-label">
                  Category
                </label>
                <CategoryDropdown
                  name="category"
                  categoryId={inputValue?.categoryId}
                  onCategoryChange={handleChange}
                />
              </div>
            </form>
          </>
        </Modal>
      )}
    </>
  );
};

export default FiltersPopup;
