import React from "react";
import { useSelector } from "react-redux";
import OrganizerMaster from "../master/OrganizerMaster";
import ShowLoginMsg from "../../components/login/ShowLoginMsg";
import ViewSalesDetails from "../../components/dashboard/eventTable/ViewSalesDetails";

//main fun
const OrganizerSalesDetails = () => {
  const userData = useSelector((loginData) => loginData.login.login);
  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  } else {
    return (
      <OrganizerMaster>
        <div class="col">
          <h3 class="font-weight-semibold">Dashboard</h3>
        </div>
        <ShowLoginMsg />
      </OrganizerMaster>
    );
  }
  return (
    <>
      <OrganizerMaster>
        <div className="my-10">
          <div className="col-12 main-contain">
            {/* <div class="row mb-3">
              <div class="col">
                <h3 class="font-weight-semibold">Event Sales Details</h3>
              </div>
            </div> */}
            <div className="d-flex flex-column mb-3">
              <ViewSalesDetails />
            </div>
          </div>
        </div>
      </OrganizerMaster>
    </>
  );
};

export default OrganizerSalesDetails;
