import React, { useState, useEffect } from "react";
import SupportAndFAQ from "./SupportAndFAQ";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import AccordionCard from "../controls/accordion/AccordionCard";
import CancellationAndRefundPolicy from "./CancellationAndRefundPolicy";

//main function
const Policies = () => {
  //declare start
  const [activeTabNo, setActiveTabNo] = useState(0);
  //   declare end

  //functions/events/methods

  //for accordion
  // const handleSave = (tabNo) => {
  //   setActiveTabNo(() => tabNo + 1);
  // };
  // const handleSkip = (tabNo) => {
  //   setActiveTabNo(() => tabNo + 1);
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Cancellation & Refund Policy</h1>
          </div>
        ),
        content: <CancellationAndRefundPolicy />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Support & FAQ</h1>
          </div>
        ),
        content: <SupportAndFAQ />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Terms & Conditions</h1>
          </div>
        ),
        content: <TermsAndConditions />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Privacy Policy</h1>
          </div>
        ),
        content: <PrivacyPolicy />,
      },
    ],
  };

  //main return
  return (
    <>
      <div style={{ marginTop: "10rem" }}>
        <AccordionCard config={config} />
      </div>
    </>
  );
};

export default Policies;
