import {
  useFetchGetRequestWithToken,
  useFetchPostRequestWithToken,
} from "./api";

export const SendAnnouncementToAllParticipants = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/create-announcement-for-event`;
  const reqBody = JSON.stringify({
    userId: inputValue.userId,
    eventId: inputValue.eventId,    
    subject: inputValue.subject,
    emailBody: inputValue.emailBody,
  });
  const result = await useFetchPostRequestWithToken(apiURL, reqBody, token);
  return result;
};

export const GetAllAnnouncementOfOrganizer = async (token) => {  
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/get-all-organizer-announcement`;
  return useFetchGetRequestWithToken(apiURL, token);
};

export const SendAnnouncementIndividual  = async (inputValue, token) => {
  const apiURL = `${process.env.REACT_APP_NOTI_MS}noti/create-personal-announcement`;

  const reqBody = JSON.stringify({
    // audience: inputValue.audienceName,
    subject: inputValue.subject,
    emailBody: inputValue.emailBody,
    emails: [inputValue.individualEmails]
  });  
  return useFetchPostRequestWithToken(apiURL, reqBody, token);
};
