// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.invalid > input,
span.invalid > textarea,
span.invalid > label,
span.invalid > select {
  border: 1px solid red;
}

div.invalid > input,
div.invalid > textarea,
div.invalid > label,
div.invalid > select {
  border: 1px solid red;
}

.input-field-error,
.invalid {
  font-style: italic;
  color: red;
}

.imgBtn-overlay {
  position: relative;
  top: 10%;
  bottom: 0;
  left: 20%;
  right: 0;
  height: 40px;
  width: 104px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  border: 1px solid red;
  z-index: 1;
}

.imgBtn-container:hover .imgBtn-overlay {
  opacity: 1;
}

.imgBtn-text {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
  opacity: 1 !important;
  transition: opacity 0.3s;
}
.imgBtn-Img-Selected,
.imgBtn-Img:hover {
  border: 4px solid #007bfe;
  background-color: #007bfe;
  opacity: 0.5;
  border-radius: 15px;
}
.QuesAnsGroup {
  border: 0.5px solid #9b9da0;
  border-radius: 20px;
  margin: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/createEvent/eventDetails/eventDetails.css"],"names":[],"mappings":"AAAA;;;;EAIE,qBAAqB;AACvB;;AAEA;;;;EAIE,qBAAqB;AACvB;;AAEA;;EAEE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,qBAAqB;EACrB,yBAAyB;EACzB,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,SAAS;EAGT,gCAAgC;EAChC,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,UAAU;EACV,qBAAqB;EACrB,wBAAwB;AAC1B;AACA;;EAEE,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;AACb","sourcesContent":["span.invalid > input,\nspan.invalid > textarea,\nspan.invalid > label,\nspan.invalid > select {\n  border: 1px solid red;\n}\n\ndiv.invalid > input,\ndiv.invalid > textarea,\ndiv.invalid > label,\ndiv.invalid > select {\n  border: 1px solid red;\n}\n\n.input-field-error,\n.invalid {\n  font-style: italic;\n  color: red;\n}\n\n.imgBtn-overlay {\n  position: relative;\n  top: 10%;\n  bottom: 0;\n  left: 20%;\n  right: 0;\n  height: 40px;\n  width: 104px;\n  opacity: 0;\n  transition: 0.5s ease;\n  background-color: #008cba;\n  border: 1px solid red;\n  z-index: 1;\n}\n\n.imgBtn-container:hover .imgBtn-overlay {\n  opacity: 1;\n}\n\n.imgBtn-text {\n  color: white;\n  font-size: 14px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n  -ms-transform: translate(-50%, -50%);\n  transform: translate(-50%, -50%);\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px 0;\n  z-index: 1;\n  opacity: 1 !important;\n  transition: opacity 0.3s;\n}\n.imgBtn-Img-Selected,\n.imgBtn-Img:hover {\n  border: 4px solid #007bfe;\n  background-color: #007bfe;\n  opacity: 0.5;\n  border-radius: 15px;\n}\n.QuesAnsGroup {\n  border: 0.5px solid #9b9da0;\n  border-radius: 20px;\n  margin: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
