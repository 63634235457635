import { React, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Login from "../../../pages/register/Login";
import { PublishEvent } from "../../../services/createEvent";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as common from "../../../utils/commonFun";
import {
  fetchLoginRequest,
  deleteFromLoginRequest,
} from "../../../redux/actions/Login";

const Success = ({
  onStepComplete,
  handleChangeTab,
  handlePreviewData,
  handleChangeTabThree,
  handleChangeTabFour,
  login,
  eventDetails,
  fetchLogin,
  deleteLogin,
}) => {
  const [loginPopup, setLoginPopup] = useState(false);
  let userData = useSelector((state) => state.login.login.login);
  const navigate = useNavigate();

  const thisStepCompleted = (value) => {
    switch (value) {
      //case "E1.1":
      //case "E1.2":
      //case "E1.3":
      case "E1.4":
      case "E2":
      case "E3":
      case "E4":
        return true;

      default:
        return false;
    }
  };
  //if (eventDetails?.eventId && eventDetails?.eventStatus != "inserted") {
  if (!thisStepCompleted(eventDetails?.stepCompleted)) {
    return (
      <>
        <div class="alert alert-warning" role="alert">
          Please finish step 1-4
        </div>
      </>
    );
  }
  //inserted
  const navigateToCreateProfile = () => {
    if (
      userData &&
      userData.user.role === process.env.REACT_APP_ROLE_PARTICIPANT
    ) {
      navigate("/create-profile");
    }
  };

  const navigateToPublishEvent = async (e) => {
    e.preventDefault();
    navigate(
      "/publish-event/" + eventDetails.eventId + "/" + eventDetails.eventName
    );
  };

  const handleUserLoginData = () => {
    navigate("/create-event?step=5");
  };

  const handleStepComplete = (index) => {
    if (index > 0 && index < 5) {
      document.getElementById("tabCreateEvent" + index).click();
    }
  };

  return (
    <div className="card p-4">
      {" "}
      <h5>Completed</h5>
      <div className="my-3">
        <div class="alert alert-success" role="alert">
          Event created sucessfully.
        </div>

        <div class="list-group">
          {userData &&
          userData.user &&
          userData.user.role === process.env.REACT_APP_ROLE_ORGANIZER ? (
            <a href="#" class="list-group-item list-group-item-action">
              For advance setting please check out below steps or simply you can
              publish the event using <b>Publish</b> button.
            </a>
          ) : (
            <a href="#" class="list-group-item list-group-item-action">
              Please check below details to publish
            </a>
          )}

          {userData && userData.user ? (
            <></>
          ) : (
            <div>
              <a
                href="#"
                class="list-group-item list-group-item-action list-group-item-danger"
                onClick={() => setLoginPopup(true)}
              >
                Login *
              </a>

              <a
                href="#"
                class="list-group-item list-group-item-action list-group-item-danger"
                onClick={navigateToCreateProfile}
              >
                Organization Profile *
              </a>
            </div>
          )}

          {userData &&
          userData.user &&
          userData.user.role == process.env.REACT_APP_ROLE_PARTICIPANT ? (
            <a
              href="#"
              class="list-group-item list-group-item-action list-group-item-danger"
              onClick={navigateToCreateProfile}
            >
              Organization Profile *
            </a>
          ) : (
            <></>
          )}

          <a
            href="#"
            class="list-group-item list-group-item-action list-group-item-warning"
            onClick={() => handleStepComplete(1)}
          >
            Participant Form
          </a>
          <a
            href="#"
            class="list-group-item list-group-item-action list-group-item-warning"
            onClick={() => handleStepComplete(2)}
          >
            Discount Coupon
          </a>
          <a
            href="#"
            class="list-group-item list-group-item-action list-group-item-warning"
            onClick={() => handleStepComplete(3)}
          >
            Event Settings
          </a>
        </div>

        <div className="mb-3 text-center my-20">
          {userData &&
          userData.user &&
          userData.user.role == process.env.REACT_APP_ROLE_ORGANIZER ? (
            <button
              variant="text"
              class="btn btn-select w-100"
              onClick={navigateToPublishEvent}
            >
              Save & Publish Event
            </button>
          ) : (
            <button class="btn btn-outline-secondary w-100" disabled={true}>
              Save & Publish Event
            </button>
          )}
        </div>
      </div>
      {loginPopup &&
        createPortal(
          <Login
            onClose={() => setLoginPopup(false)}
            onUserLoginData={handleUserLoginData}
          />,
          document.body
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  fetchLogin: () => dispatch(fetchLoginRequest()),
  deleteLogin: () => dispatch(deleteFromLoginRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
//export default Success;
