export const skipKey = [
  "errors",
  "IsValid",
  "isRequired",
  // "lat",
  // "long",
  "userId",
  "eventId",
  "tempDiscountPercentType", //temp
  "tempDiscountPercentValue",
];

export const EventDetails = {
  eventName: "",
  categoryId: 0,
  categoryName: "",
  categoryPic: "",
  eventStartDate: "", //"DD/MM/YYYY | Time",
  eventEndDate: "", //"DD/MM/YYYY | Time",
  supportEmail: "",
  supportMobile: "",
  eventDescription: "",
  faqs: [{ faqId: 1, question: "", answer: "" }], //,{qid:2, que:"",ans:""}],
  terms: "",
  eventPermissionDocument: "",
  errors: {},
  IsValid: {},
  isRequired: {
    eventName: true,
    categoryId: true,
    eventStartDate: true,
    eventEndDate: true,
    supportEmail: true,
    supportMobile: true,
    eventDescription: true,
  },
};

export const EventLocation = {
  address: "",
  area: "",
  cityId: "",
  stateId: "",
  pincode: "",
  lat: 20.5937,
  long: 78.9629,
  eventId: "",
  userId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    address: true,
    area: true,
    cityId: true,
    stateId: true,
    pincode: true,
  },
};

// Form initial Data
export const EventPhotos = {
  files: [],
  errors: {},
  IsValid: {},
  eventId: "",
  userId: "",
  isRequired: {
    files: true,
  },
};

export const TicketDetails = {
  ticketId: 0,
  ticketName: "",
  ticketType: "",
  totalQuantity: 0,
  ticketPrice: 0,
  ticketDescription: "",
  messageToAttendees: "",
  ticketSaleStartDate: "", //"DD/MM/YYYY | Time",
  ticketSaleEndDate: "", //"DD/MM/YYYY | Time",
  // eventId: "",
  // tickets: [],
  // userId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    ticketName: true,
    ticketType: true,
    totalQuantity: true,
    ticketSaleStartDate: true,
    ticketSaleEndDate: true,
    ticketPrice: true,
  },
};

export const DiscountInfo = {
  //userId: "",
  discountType: "",
  discountName: "",
  discountPercent: null,
  discountAmount: null,
  numOfDiscount: 0,
  minTickets: 0,
  discountStartDate: "", //today,
  discountEndDate: "", //tomorrow,
  isCouponActive: false,
  tempDiscountPercentType: "discountPercent", //temp
  tempDiscountPercentValue: "", //temp
  eventId: "",
  errors: {},
  IsValid: {},
  isRequired: {
    discountType: true,
    discountName: true,
    discountPercent: true,
    discountAmount: true,
    numOfDiscount: true,
    minTickets: true,
    discountStartDate: true,
    discountEndDate: true,
    // isCouponActive: true,
  },
};
export const EventSettings = {
  isGstFromAttendees: false,
  hostingFeesPayer: "",
  pgFeesPayer: "",
  embeddCode: "",
  isRedirect: true,
  userId: "",
  eventId: "",
  isRequired: {},
};

export const EventDetailsPreview = {
  data: {
    eventId: null,
    eventName: null,
    eventStartDate: null,
    eventEndDate: null,
    eventDescription: null,
    terms: null,
    address: null,
    area: null,
    pincode: null,
    lat: null,
    long: null,
    eventStatus: null,
    lastModifiedBy: null,
    categoryId: null,
    categoryName: null,
    categoryPic: null,
    stateId: null,
    stateName: null,
    cityId: null,
    cityName: null,
    cityPic: null,
    permission: null,
    faqs: [{ faqId: 1, question: "", answer: "" }],
    eventPhotos: [],
    ticketDetails: [],
    defaultPrice: null,
    participantForm: null,
    discountCoupons: null,
    eventSetting: null,
  },
};

export const OrganizerProfile = {
  businessName: "",
  businessEmail: "",
  businessMobile: "",
  businessWebsite: "",
  businessLogo: "",
  businessAddress: "",
  businessPincode: "",
  businessCountry: "",
  businessState: "",
  businessCity: "",
  stateId: "",
  cityId: "",
  userId: "",
  errors: {},
  IsValid: {},

  isRequired: {
    businessName: true,
    businessEmail: true,
    businessMobile: true,
    businessLogo: true,
    businessAddress: true,
    businessPincode: true,
    businessCountry: true,
    businessState: true,
    businessCity: true,
  },
};

export const OrganizerKYCInfo = {
  businessEntityType: "",
  businessPanNo: "",
  businessPanDoc: "",
  isTicketInvolveGst: true,
  businessGstNo: "",
  businessGstStateNo: "",
  businessHsnCode: "",
  businessGstPercent: 0,
  businessGstDoc: "",
  businessAddressProofDoc: "",
  signatoryName: "",
  signatoryPanNo: "",
  signatoryPanDoc: "",
  signatoryAadharNo: "",
  signatoryAadharDoc: "",
  userId: "",
  errors: {},
  IsValid: {},

  isRequired: {
    businessEntityType: true,
    businessPanNo: true,
    businessPanDoc: true,
    isTicketInvolveGst: true,
    businessGstNo: true,
    businessGstStateNo: true,
    businessHsnCode: true,
    businessGstPercent: true,
    businessGstDoc: true,
    businessAddressProofDoc: true,
    signatoryName: true,
    signatoryPanNo: true,
    signatoryPanDoc: true,
    signatoryAadharNo: true,
    signatoryAadharDoc: true,
  },
};

export const OrganizerBankDetails = {
  accountHolderName: "",
  ifsc: "",
  bankName: "",
  branchName: "",
  accountType: "",
  accountNumber: "",
  cnfAccountNumber: "",
  bankProofDoc: "",
  userId: "",
  eventId: "",
  errors: {},
  IsValid: {},

  isRequired: {
    accountHolderName: true,
    ifsc: true,
    bankName: true,
    branchName: true,
    accountType: true,
    accountNumber: true,
    cnfAccountNumber: true,
    bankProofDoc: true,
  },
};

export const OrganizerProfileInfo = {
  // Step 1
  businessName: "",
  businessEmail: "",
  businessMobile: "",
  businessWebsite: "",
  businessLogo: "",
  businessAddress: "",
  businessPincode: "",
  businessCountry: "",
  businessState: "",
  stateId: "",
  businessCity: "",
  cityId: "",

  // Step 2
  businessEntityType: "",
  businessPanNo: "",
  businessPanDoc: "",
  isTicketInvolveGst: true,
  businessGstNo: "",
  businessGstStateNo: "",
  businessHsnCode: "",
  businessGstDoc: "",
  businessAddressProofDoc: "",
  signatoryName: "",
  signatoryPanNo: "",
  signatoryPanDoc: "",
  signatoryAadharNo: "",
  signatoryAadharDoc: "",

  // step 3
  accountHolderName: "",
  ifsc: "",
  bankName: "",
  branchName: "",
  accountType: "",
  accountNumber: "",
  cnfAccountNumber: "",
  bankProofDoc: "",

  stepCompleted: "",

  // User Details
  userId: "",
  role: -1,
  profilePicUrl: "",
  step_completed: "",
  errors: {},
  IsValid: {},
};

export const Search = {
  searchText: "",
  cityId: 0,
  stateId: 0,
  categoryId: 0,
  cityName: 0,
  stateName: 0,
  categoryName: 0,
  sDate: "",
  eDate: "",
  minPrice: 0,
  maxPrice: 0,
  errors: {},
  IsValid: {},
};

export const ServiceCharges = {
  ticketPricePerEvent: 100,
  attendeesPerEvent: 1,
  doesTicketInvolveGst: false,
  isGstFromAttendees: false, // This should be true only if doesTicketInvolveGST = true
  gstOrgPercent: 18, // Send 0 (Zero) if doesTicketInvolveGST= false
  hostingFeesPayer: "organizer",
  pgFeesPayer: "organizer",

  errors: {},
  IsValid: {},
};
