import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import { SaveParticipantFormTimingCertificate } from "../../../services/createEvent";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import * as common from "../../../utils/commonFun";

const initialData = {
  isTimingCertificate: {
    isActive: false,
    isMandatory: false,
  },
  eventId: "",
  userId: "",
};
let eventId;
const TimingCertificate = ({
  eventDetails,
  saveEventDetails,
  onSave,
  onSkip,
}) => {
  // const [showDialog, setShowDialog] = useState(false);
  const [inputValue, setInputValue] = useState(initialData);
  const userData = useSelector((state) => state.login.login.login);
  //const [popupData, setPopupData] = useState(null);
  //
  //
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      if (eventDetails?.participantForm) {
        let initialData1 = initialData;
        initialData1.isTimingCertificate.isActive =
          eventDetails?.participantForm?.isTimingCertificate.isActive;
        setInputValue(() => initialData1);
      }
    }
  }, [eventDetails]);

  /* const openDialog = (event, key, item) => {
    let title = "Timing Certificate";
    let popupData1 = {
      key: key,
      title: title,
      isActive: item?.isActive,
      isMandatory: item?.isMandatory,
      item: item,
    };
    setPopupData(() => popupData1);
    setShowDialog(() => true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleSave = (key, updatedData) => {
    
    setInputValue((prev) => ({
      ...prev,
      [key]: { ...updatedData },
    }));

    handleCloseDialog();
  };
 */
  // //
  const handleChange = (event, type, key) => {
    // event.preventDefault();

    //
    const { name, value } = event.target;
    let checked = event?.target?.checked ? true : false;
    let newValue = { isActive: checked, isMandatory: checked };
    if (type === "isActive") {
      newValue = {
        isActive: checked,
        isMandatory: inputValue[key]?.isMandatory,
      };
    } else if (type === "isMandatory") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: checked,
      };
    } else if (type === "isOptional") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: false,
      };
    }
    {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    }
  };
  const handleSaveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      inputValue.userId = userData.user.userId;
      if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);
      inputValue.eventId = eventId;

      if (inputValue.eventId !== null || inputValue.eventId !== undefined) {
        const result = await SaveParticipantFormTimingCertificate(
          inputValue,
          userData.token
        );

        if (result) {
          if (result.success) {
            common.saveToLocal(KEY_EVENT_ID, result?.data?.eventId);
            eventDetails = result.data;
            saveEventDetails(eventDetails);
            //
            onSave();
          } else {
            alert("Error:-" + result.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        alert("Please complete initial steps.");
      }
    }
  };

  return (
    <div class="card mb-2">
      <div class="card-body p-1">
        <div class="accordion accordion-flush m-0" id="accordionFlushExample">
          <div class="accordion-item">
            <div>
              <div class="d-inline-flex">
                <h1 class="my-4">Setup The Form</h1>
                <div class="ms-5 form-switch form-check-reverse my-4">
                  <input
                    class="form-check-input py-2 px-3"
                    type="checkbox"
                    id="isTimingCertificate"
                    value="isTimingCertificate"
                    name="isTimingCertificate"
                    checked={inputValue?.isTimingCertificate?.isActive === true}
                    onChange={(event) =>
                      handleChange(event, "isActive", "isTimingCertificate")
                    }
                  />
                </div>
              </div>
              <form class="row g-3 pb-3">
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Have a Timing Certificate
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Timing
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Distance
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
                <div class="col-md-6">
                  <label for="inputEmail4" class="form-label">
                    Upload timing Certificate
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="inputEmail4"
                    disabled={true}
                  />
                </div>
              </form>
              <div class="my-4 text-center">
                <button
                  type="submit"
                  class="btn btn-select w-100 mb-2"
                  onClick={handleSaveAndProceed}
                >
                  Save &amp; Proceed
                </button>
                <a href="#" onClick={onSkip} class="skip">
                  Skip
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ParticipantFormDialog
        showPopup={showDialog}
        setInitailData={popupData}
        onClose={handleCloseDialog}
        onSuccess={handleSave}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventDetails: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(TimingCertificate);
//export default TimingCertificate;
