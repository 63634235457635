import React, { useState } from "react";

//main function

const SupportAndFAQ = () => {
  //declare start
  const [showMore, setShowMore] = useState(false);

  //declare end

  //functions/events/methods
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //main retrun
  return (
    <>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          lineHeight: 1.5,
          textAlign: "left",
          padding: "30px",
        }}
      >
        {/* <h1 style={{ fontSize: '2rem', marginBottom: '1rem' }}>Support & FAQ</h1> */}
        <h1 style={{ fontSize: "20px", marginBottom: "2rem" }}>
          FAQs - Most Enquired
        </h1>
        <h3 style={{ fontSize: "17px", marginBottom: "0.5rem" }}>
          1. Money Deducted, Confirmation Not Received
        </h3>
        <p style={{ marginBottom: "3rem", fontSize: "14px", color: "blue" }}>
          The next time you receive a notification from the bank saying money
          has been deducted, but you haven't received any confirmation email or
          SMS related to your booking, don't worry. Your money is absolutely
          safe in the banking system.
          <br />
          <br />
          The transaction goes in pending when we don't receive the final
          confirmation from your bank or payment gateway. These exceptions
          happen when banking payment gateways are facing some technical outages
          or load spikes.
          <br />
          <br />
          In such cases, our systems continue to follow-up with the bank/gateway
          for the next 24-48 hrs. If within this time period your transaction
          becomes successful then the tickets are generated and sent to your
          registered email. In case we don't get a confirmation for transaction
          success from your bank/gateway within this stipulated time, the
          transaction fails.
          <br />
          <br />
          Refund Process:
          <br />
          In case the money was deducted from your account, your bank could take
          7 working days to credit the refund to your account. Since this time
          is taken by your bank, Fitfunda has a little capability in expediting
          it. Hence, we suggest you please wait for this duration. You may also
          contact your bank for any delays.
        </p>
        {showMore && (
          <>
            <h1 style={{ fontSize: "17px", marginBottom: "0.5rem" }}>
              2. How much are Fitfunda charges?
            </h1>
            <p
              style={{ marginBottom: "3rem", fontSize: "14px", color: "blue" }}
            >
              <strong>
                We Earn When You Earn! No Setup Costs – Free for Free Events
              </strong>
              <br />
              <br />
              At FitFunda, there’s no setup fee to get started, and if you’re
              hosting a free event, our platform is entirely free to use.
              <br />
              <br />
              <strong>FitFunda Charges:</strong>
              <br />
              For paid events, we charge a{" "}
              <strong>
                1.49% FitFunda fee and 1.99% payment gateway fee
              </strong>{" "}
              on every ticket sold. These fees may be absorbed by the event
              organizer or passed on to the attendees, depending on your event
              setting.
              <br />
              <br />
              Additionally, attendees may incur a platform fee for using
              FitFunda’s services. The specifics of how fees are applied
              (whether to participants or the organizer) depend on the pricing
              plan you choose.
            </p>
            <h1 style={{ fontSize: "17px", marginBottom: "0.5rem" }}>
              3. In how many days does Fitfunda clear money?
            </h1>
            <p
              style={{ marginBottom: "1rem", fontSize: "14px", color: "blue" }}
            >
              For us, event organizers form the very core of this industry. We
              understand the importance of money to manage the events. Hence,
              our money clearance policy is quite flexible.
              <br />
              <br />
              <strong>Payouts on your requirements:</strong>
              <br />
              Clearance totally depends on your requirement. Some of the
              organizers want money on a daily basis, some on a weekly basis and
              some after the event. We generally clear money on{" "}
              <strong>a weekly basis for new organizers.</strong>
              <br />
              However, in case of any different requirements, you can write to
              us at help@fitfunda.in mentioning your requirements and we will
              try our best to meet them.
              <br />
              Also, our accounts team has been advised not to clear money if
              there is just a single transaction in the account as that leads to
              a problem in case of issuing refunds afterward. So, in case you
              have done a test/demo payment and want it to be cleared to your
              bank account please write to us at help@fitfunda.in informing the
              same.
              <br /> <br />
              <strong>For new organisers:</strong> <br />
              It might take a week initially to start clearing the money if you
              are using fitfunda for the first time. The reason is compliance.
              For your benefit, we make sure we are doing the due diligence of
              all the accounts.
              <br /> <br />
              <i style={{ fontWeight: "600", marginBottom: "30px" }}>
                Note: We do not clear money on Saturdays, Sundays, and bank
                holidays.
              </i>
            </p>

            <div>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                4. Do I need to sign a contract with fitfunda?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                When you create an event on fitfunda , it means you have already
                followed our terms and conditions, delivery policy, cancellation
                policy, privacy policy.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                5. Can the event be integrated with my website?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                You can very well sell tickets from your website or blog by
                using our ticket widget(iframe). It's a great way to drive
                ticket sales. You can avail all the features in the settings
                menu of your event. <br />
                <strong>Note:</strong> Your outside website must support iframes
                in order to embed these widgets. Wordpress.org users, for
                instance, will need to install a plugin (Code Embed) allowing
                you to embed iframes in your site.
                <br />
                {/* <span style={{ color: "red" }}>See Tutorial</span> */}
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                6. Have filled all details but ticket shows "Not Started"
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                The scenario occurs when the Ticket Sales start date and time
                are not correctly in order / sequence. You can change all this
                easily from the Dashboard, just follow the below-mentioned
                steps.
                <br />
                {/* <span style={{ color: "red" }}>See Tutorial</span> */}
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                7. Bank Holidays observed at Fitfunda
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Fitfunda aligns its holiday schedule to ICICI Bank holiday, as
                being its primary account for all transaction settlement.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                8. Can an event be directly created by the organizer on
                fitfunda?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Event creation is self-serviced and created by an organizer on
                fitfunda. Anybody who wants to host an event can create their
                event very easily. An organizer can host a Free or Paid event on
                fitfunda. <br />
                In case you need any assistance, you can reach out to us on
                fitfundasupport@vrattanta.com.in or call us on our dedicated
                organizer helpline +91-95294-78968.
                <br />
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                9. Can an event be deleted on fitfunda?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                You can delete an event on fitfunda only when it is in the draft
                phase. Once an event creation is complete, deleting that event
                is not possible. However, you can unpublish your event anytime
                if your event is completed or any problem occurs with your event
                or If you accidentally published your event, you can easily
                unpublish your page. This can be done by a logged-in organizer
                from the dashboard.
                <br />
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                10. How can I process a refund on fitfunda?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Fitfunda currently doesn’t process any refund requests, this
                functionality would be enabled soon.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                11. Can I give access to events to my team members?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Yes, using the organizing team feature you can give event wise
                limited or full access to your team members.
                <br />
              </p>
            </div>

            {/* <h1 style={{ fontSize: "20px", marginBottom: "2rem" }}>
              FAQs - Payments
            </h1>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                Que 1. How to Add/Update KYC Details?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Know your customer or commonly known as KYC is a process of
                collecting information that businesses do to verify the identity
                of their clients in order to ensure tax/legal compliance in
                different countries that they do business in and fight fraud
                and/or avoid any illegal intentions towards the business
                relationship.
                <br /> <br />
                <p>Why do we ask these details?</p>
                In order to ensure tax compliance, creation or listing of valid
                events and security of all its users, be it, organisers or
                attendees. We aim to be fraud-free and to safely transfer your
                ticket sales revenue to you. fitfunda has had event organisers
                who have committed bad practices on the platform, we want to
                make sure it doesn’t repeat.
                <br /> <br />
                To Add or Update your KYC details please follow the steps below:
                <br /> <br />
                Steps to Follow:
                <br /> <br />
                <ol>
                  <li>Login to your account.</li>
                  <li>
                    Go to the billings tab under the Organizing Events menu at
                    the right side corner drop-down menu.
                  </li>
                  <li>Now go to the KYC Details Tab.</li>
                  <li>
                    Click on Enter Your Details Option and start filling all the
                    details.
                    <ul style={{ listStyleType: "upper-alpha" }}>
                      <li>
                        If you are an Individual:
                        <ul>
                          <li>Choose “an Individual”</li>
                          <li>Enter your first and last name</li>
                          <li>
                            Verify your phone number (OTP will be sent to the
                            registered number)
                          </li>
                          <li>Enter your Address, city, state and zip code</li>
                          <li>
                            Select your GST Registration Status.
                            <ul>
                              <li>
                                If you are not registered, click on ‘Not
                                Registered” and then Proceed.
                              </li>
                              <li>
                                If you are registered, click on Registered and
                                enter GST Number and then Proceed.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        If you are a company:
                        <ul>
                          <li>Choose a Company</li>
                          <li>Enter company name</li>
                          <li>Verify your phone number</li>
                          <li>Enter Address, city, state and zip code</li>
                          <li>
                            Select your GST Registration Status.
                            <ul>
                              <li>
                                If you are not registered, click on ‘Not
                                Registered” and enter CIN or N.A and then
                                Proceed.
                              </li>
                              <li>
                                If you are registered, then click on Registered
                                and Enter GST Number and CIN or NA and Proceed.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Verify your identity
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        You have to enter your PAN Number or Aadhar Number
                      </li>
                      <li>
                        If you want to give PAN details, click on PAN Number
                        from the drop-down menu and enter PAN Number.
                      </li>
                      <li style={{ listStyleType: "square" }}>
                        If you want to give Aadhar details, click on Aadhar
                        Number from the drop-down menu and enter Aadhar number
                        and upload front and back scanned copies of the Aadhar
                        card.
                      </li>
                      <li>Click on Proceed</li>
                    </ul>
                  </li>
                  <li>
                    Link your Bank Account
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        Enter the following information
                        <ul>
                          <li>Name of Bank</li>
                          <li>Account Number</li>
                          <li>IFSC Code</li>
                          <li>Account Holder Name</li>
                          <li>Account Type</li>
                        </ul>
                      </li>
                      <li>Click on submit details.</li>
                    </ul>
                  </li>
                </ol>
                KYC Verification takes 24-48 Hours' time.
                <br />
                Note: Our compliance team may ask you for Event/Venue permission
                document and event day photographs in addition to the Bank and
                Tax details.
                <br />
                In case you make any changes, your KYC will be resubmitted for
                verification.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                Que 2. Why is fitfunda asking for KYC Details and how is it
                important?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Know your customer or commonly known as KYC is a process of
                collecting information that businesses do to verify the identity
                of their clients in order to ensure tax/legal compliance in
                different countries that they do business in and fight fraud
                and/or avoid any illegal intentions towards the business
                relationship.
                <br /> <br />
                Why do we ask these details?
                <br /> <br />
                In order to ensure tax compliance, creation, or listing of valid
                events and security of all its users, be it, organisers, or
                attendees. We aim to be fraud-free and to safely transfer your
                ticket sales revenue to you. fitfunda has had event organisers
                who have committed bad practices on the platform, we want to
                make sure it doesn’t repeat.
                <br /> <br />
                What details do we ask?
                <br />
                <ul>
                  <li>
                    We ask for basic details like name and address. CIN (Company
                    Identification Number) if you are signing up as a company.
                  </li>
                  <li>
                    To verify your identity, we ask for a valid ID proof like
                    PAN or Aadhaar number.
                  </li>
                  <li>Your GST number in case you are GST registered.</li>
                  <li>
                    Your bank account details to send your ticket sales revenue
                    to you.
                  </li>
                </ul>
                <br />
                Note: Our compliance team may ask you for Event/Venue permission
                document and event day photographs in addition to the Bank and
                Tax details.
                <br />
                <br />
                When do we ask these details?
                <br />
                You have to provide these details only once and it can be done
                before or after creating your event.
                <br />
                <br />
                Where can you fill these details?
                <br />
                You can visit the KYC details section by clicking on the Billing
                section in the profile drop-down.
                <br />
                <br />
                What happens to your details?
                <br />
                We at fitfunda are very serious about keeping your details safe
                with us. All your details will only be consumed by us to verify
                your identity and will not be shared with anyone.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                Que 3. Why do I need to setup payment method before selling
                tickets?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Why do we need this step?
                <br />
                In order to make payouts to organisers, we need our users to
                complete their KYC (Know Your Customer) process, verification,
                post which they can start selling tickets, and accepting payouts
                from fitfunda.
                <br /> <br />
                What is the KYC process?
                <br />
                Know your customer or commonly known as KYC is a process of
                collecting information that businesses do to verify the identity
                of their clients in order to ensure tax/legal compliance and
                fight fraud and/or avoid any illegal intentions towards the
                business relationship.
                <br /> <br />
                What details do we ask?
                <br />
                <ul>
                  <li>
                    We ask for basic details like name, address, company tax ID,
                    etc.
                  </li>
                  <li>
                    To verify your identity, we ask for a valid ID proof like
                    Passport or Driving licence. We require you to upload a copy
                    of your valid ID proof, it will help us in identifying you
                    to a high degree of fidelity as the inability to accurately
                    identify individuals pose risks with respect to fraudulent
                    claims, tax compliance, and creation or listing of valid
                    events on fitfunda.
                  </li>
                  <li>
                    Your bank account details to send your ticket sales revenue
                    to you.
                  </li>
                </ul>
                When do we ask these details?
                <br />
                You have to provide these details only once and needs to be
                completed before an event can be published (visible on event
                home page).
                <br /> <br />
                Where can you fill these details?
                <br />
                You can visit the KYC details section by clicking on the Billing
                Section in the profile drop-down.
                <br /> <br />
                What happens to your details?
                <br />
                We at fitfunda are very serious about keeping your details safe
                with us. All your details will only be consumed by us to verify
                your identity and will not be shared with anyone.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                4. How to Resend and Download GST invoice?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                You can easily resend the GST Invoice or download the GST
                Invoice, which will help you in GST filings.
                <br />
                <span style={{ color: "red" }}>
                  Steps to follow:
                  <br />
                  <br />
                  <ol>
                    <li>Login or sign-in to your fitfunda account.</li>
                    <li>Click on Billings under the Organizing Events menu.</li>
                    <li>Now go to Transactions Tab</li>
                    <li>
                      Resend GST Invoice by clicking on the first icon in front
                      of the desired transaction details.
                    </li>
                    <li>
                      Download GST Invoice by clicking on the second icon in
                      front of the desired transaction details.
                    </li>
                  </ol>
                  Tip: In case you have multiple invoices to be downloaded, you
                  can click on the Download Invoices button and a link will be
                  sent to the registered email id to download the invoices as a
                  Zip file.
                </span>
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                5. What are Payment Advices and FF Invoices and where to find
                them?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Payment Advice: Payment Advice is generated when any payment is
                made to the organiser. It contains the details of the payments
                such as for which event was the payment made, reference number
                of the payment, etc.
                <br />
                <br />
                Note: When any Payment Advice is generated, the same is sent to
                the organiser’s email id along with the transactions for which
                the settlement has been made.
                <br />
                <br />
                FF Invoices: FF invoices are invoices containing the fitfunda
                Charges for all the transactions that took place for a
                particular event for a month. FF Invoices are generated at the
                start of the following month for each of the events where the
                transaction has taken place.
                <br />
                <br />
                <span style={{ color: "red" }}>
                  {" "}
                  Where to find Payment Advice and FF Invoices?
                  <br />
                  Both Payment Advice and FF Invoices can be found under the
                  PAYOUTS Section of the BILLINGS Tab.
                  <br />
                  <br />
                  Tip: You can download the payment transaction list from the
                  TRANSACTION Tab (can be seen in the image above) using
                  appropriate filters.
                </span>
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                6. How to check/update your payment frequency?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Once you complete your KYC, you can easily set your preference
                for receiving payments from fitfunda. You can choose how
                frequently you would like to receive your payment: Every Week,
                Fortnight, or Month and then can choose the particular day also.
                Just follow the steps below to make the required changes.
                <br />
                <br />
                Steps to follow:
                <br />
                <br />
                <span style={{ color: "red" }}>
                  1) Login or sign-in to your fitfunda account.
                  <br />
                  2) Go to the Billings tab under the Organizing Events menu in
                  the top right corner dropdown.
                  <br />
                  3) Now you can see the current payment frequency on the top,
                  to change it, click on “Change Settings” option below the
                  “Payment is sent every week on” heading.
                  <br />
                  4) Choose the desired frequency of payment from the dropdowns.
                  <ul>
                    <li>
                      If you choose “Every Week” or “Every Fortnight” under ‘I
                      would like to receive payments’ dropdown, then choose the
                      particular day (Tuesday, Wednesday, or Thursday) on which
                      you want to receive payment under the ‘On’ dropdown and
                      click on SUBMIT.
                    </li>
                    <li>
                      If you choose “Every Month” under ‘I would like to receive
                      payments’ dropdown, then choose the particular day (1st to
                      28th) on which you want to receive payment under the ‘On’
                      dropdown and click on SUBMIT.
                    </li>
                  </ul>
                  In case you want to set any other frequency apart from the
                  above-mentioned options, you can drop us a mail on
                  help@fitfunda.in and we will try to accommodate your request
                  if feasible.
                </span>
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "3rem",
                  marginBottom: "0.5rem",
                }}
              >
                7. What is TCS and how does TCS work?
              </h1>
              <p
                style={{ marginTop: "0.5rem", fontSize: "14px", color: "blue" }}
              >
                Starting 1st October 2018, every e-commerce operator has to
                collect TCS as per the GST regulations.
                <br />
                <br />
                <span style={{ color: "red" }}>
                  What is TCS under GST?
                  ***************************************************I WRITE
                  THIS QUESTION'S ANSWER*******************
                  <br />
                  TCS stands for Tax Collected at Source. It is a tax collected
                  by the seller (e-commerce operator) from the buyer (customer)
                  at the time of sale. This tax is collected as per the GST
                  regulations enforced by the government.
                </span>
              </p>
            </div> */}

            <div style={{ marginBottom: "3rem" }}>
              <h1 style={{ fontSize: "20px", marginBottom: "2rem" }}>
                For Organizers:
              </h1>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                1. How can I create and register my event on Fitfunda?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                To register your event, create an organizer account, log in, and
                follow the steps outlined in the dashboard to add a new event.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                2. What are the steps for event approval?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                After submitting your event, it goes through an approval
                process. You'll receive an email notification once your event is
                approved.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                3. How do I modify event details after approval?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                Log in to your organizer account, go to the Event section, and
                select the event you want to modify. Make the necessary changes
                and re-publish.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                4. How are payments processed for ticket sales?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                Payments are processed through our secure payment gateway.
                You'll receive a detailed transaction report for each event.
              </p>
            </div>

            <div style={{ marginBottom: "3rem" }}>
              <h1 style={{ fontSize: "20px", marginBottom: "2rem" }}>
                For Participants:
              </h1>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                1. How do I register for an event?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                To register for an event, visit the event page and click on the
                Buy Ticket / Book Now button. Follow the on-screen instructions
                to complete the registration process.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                2. Can I purchase tickets without creating an account?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                Yes, you can buy tickets as a guest. However, creating an
                account allows you to track your orders and receive event
                updates.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                3. What payment methods are accepted?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                We accept payments via UPI, credit/debit cards, internet
                banking, and digital wallets.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                4. How do I download my event ticket?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                After successful payment, you'll receive a confirmation email
                with a link to download your event ticket. You can also log in
                to your account to access and download your ticket.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                5. What if I forget my password?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                Click on the "Forgot Password" link on the login page, and
                you'll receive instructions on resetting your password via email
                or SMS.
              </p>

              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                6. How can I view my past orders?
              </h1>
              <p
                style={{
                  marginTop: "0.5rem",
                  marginBottom: "3rem",
                  fontSize: "14px",
                }}
              >
                Log in to your account, go to the Order History section to view
                and download details of your past ticket purchases.
              </p>

              <h1 style={{ fontSize: "20px", marginBottom: "2rem" }}>
                Tips for a Smooth Experience:
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                <h1
                  style={{
                    fontSize: "17px",
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  1. Ensure Your Profile Information is Updated:
                </h1>
                Keep your profile details, especially your email and phone
                number, up to date for seamless communication.
                <br />
                <h1
                  style={{
                    fontSize: "17px",
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  2. Check Event Details Carefully:
                </h1>
                Before purchasing tickets, review event details such as date,
                time, and venue carefully to avoid any discrepancies.
                <br />
              </p>

              <h1
                style={{
                  fontSize: "20px",
                  marginBottom: "0.5rem",
                  marginTop: "2rem",
                }}
              >
                Still Have Questions?
              </h1>
              <p style={{ marginTop: "0.5rem", fontSize: "14px" }}>
                If your query isn't addressed above, feel free to reach out to
                our customer support team via email, live chat, or phone. We're
                here to assist you!
              </p>
            </div>

            <div>
              <h1
                style={{
                  fontSize: "17px",
                  marginTop: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                Customer Support:
              </h1>
              <ul style={{}}>
                <li style={{ marginBottom: "0.5rem", fontSize: "14px" }}>
                  Email: fitfundasupport@vrattanta.com
                </li>
                <li style={{ marginBottom: "0.5rem", fontSize: "14px" }}>
                  Live Chat: Available on the website
                </li>
                <li style={{ marginBottom: "0.5rem", fontSize: "14px" }}>
                  Phone: +91 95294-78968
                </li>
              </ul>
            </div>

            <a
              href="#"
              onClick={toggleShowMore}
              style={{
                color: "#1154CC",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              View less...
            </a>
          </>
        )}

        {!showMore && (
          <p
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "11pt",
            }}
          >
            <a href="#" onClick={toggleShowMore} style={{ fontSize: "17px" }}>
              View more...
            </a>
          </p>
        )}
      </div>
    </>
  );
};

export default SupportAndFAQ;
