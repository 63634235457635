import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventCardTypeMedium from "../../components/event/EventCardMedium";
import SliderRecommededEvent from "../../components/slider/SliderEventRecommended";
import Promotion from "../../components/promotion/Promotion";
import { GetEventDetailsData } from "../../services/eventAPI";
import Spinner from "../../components/controls/spinner/Spinner";

const EventTicketOptions = ({ isWidget, widgetPath }) => {
  const { eventId } = useParams();
  const { eventName } = useParams();

  //load data
  // const [eventDetailsData] = GetEventDetailsData(eventId);

  const [eventDetailsData, setEventDetailsData] = useState("");
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    const getEventDetails = async () => {
      try {
        const eventDetailsResponseData = await GetEventDetailsData(eventId);
        if (eventDetailsResponseData.success === true) {
          setEventDetailsData(eventDetailsResponseData);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEventDetails();
  }, []);

  //main return
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        eventDetailsData &&
        eventDetailsData.data && (
          <>
            <div class="container text-center">
              <div class="row mx-2">
                <div class="col text-start cat-name">
                  {/* <b>Events</b> {eventName} */}
                </div>
              </div>
              <EventCardTypeMedium
                eventDetailsData={eventDetailsData.data}
                isWidget={isWidget}
                widgetPath={widgetPath}
              />
              <>
                {isWidget && Boolean(isWidget) === true ? (
                  <div class="hide"></div> //nothing
                ) : (
                  <>
                    <SliderRecommededEvent from={"EventTicketOptions"} />
                    <Promotion from={"EventTicketOptions"} />{" "}
                  </>
                )}
              </>
            </div>
          </>
        )
      )}
    </>
  );
};

export default EventTicketOptions;
