import { put, call, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/events";
import * as eventAPI from "../../services/eventAPI";
import * as comFun from "../../utils/commonFun";
import * as constants from "../../utils/Constants";

//
export function* getAllEvents() {
  //const Events = yield call(api.getEvents);
  //yield put(actions.receiveEvents(Events));
}

export function* watchGetEvents() {
  /*
    takeEvery will fork a new `getEvents` task on each GET_EVENTS action
    i.e. concurrent GET_Events actions are allowed
  */
  yield takeEvery(actions.GET_EVENTS, getAllEvents);
}

//cartEvents worker sagas
function* fetchCartEventsWorker() {
  // console.log("saga call for fetchCartEventsWorker");

  let cartEvents;
  try {
    //api call
    cartEvents = yield call(eventAPI.GetCartEvents);
    //local store
    try {
      let cartEvents1 = yield call(
        comFun.getLocalData,
        constants.LOCAL_CART_EVENT
      );
      cartEvents1 = JSON.parse(cartEvents1);
      cartEvents = cartEvents1;
      //update store
      yield put(actions.fetchCartEventsSuccess(cartEvents1));
    } catch {}
  } catch (error) {
    console.error(error);
  }
  return cartEvents;
}

function* addCartEventWorker(action) {
  // console.log("saga call for addCartEventWorker ");
  console.log(action);

  try {
    //if (action.payload && action.payload !== undefined)
    //api call
    const cartEvent = yield call(eventAPI.SaveAddToCartEvent, action.payload);
    //local store
    let cartEvent1 = JSON.stringify(cartEvent);
    yield call(comFun.saveToLocal, constants.LOCAL_CART_EVENT, cartEvent1);
    //update store
    yield put(actions.addToCartEventSuccess(cartEvent));
  } catch (error) {
    console.error(error);
  }
}

function* deleteCartEventWorker(action) {
  try {
    yield call(eventAPI.DeleteFromCartEvent, action.payload);
    yield put(actions.deleteFromCartEventSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//cartEvents Watcher Sagas
export function* watchFetchCartEvents() {
  // console.log("watchFetchCartEvents saga call2");
  yield takeEvery(actions.FETCH_CART_EVENTS_REQUEST, fetchCartEventsWorker);
}

export function* watchAddCartEvents() {
  // console.log("watchAddCartEvents saga call2");
  yield takeEvery(actions.ADD_CART_EVENT_REQUEST, addCartEventWorker);
}

export function* watchDeleteCartEvents() {
  yield takeEvery(actions.DELETE_CART_EVENT_REQUEST, deleteCartEventWorker);
}

//-------------------------------------------------

//cartuserDetails worker sagas
function* fetchUserDetailsWorker() {
  // console.log("saga call for fetchUserDetailsWorker");
  /* try {
    const userDetail = yield call(eventAPI.GetUserDetails);
    yield put(actions.fetchUserDetailsSuccess(userDetail));
  } catch (error) {
    console.error(error);
  } */

  let userDetail;
  try {
    //api call
    userDetail = yield call(eventAPI.GetUserDetails);
    //local store
    try {
      let userDetail1 = yield call(
        comFun.getLocalData,
        constants.LOCAL_CART_USER_DETAILS
      );
      userDetail1 = JSON.parse(userDetail1);
      userDetail = userDetail1;
      //update store
      yield put(actions.fetchUserDetailsSuccess(userDetail));
    } catch {}
  } catch (error) {
    console.error(error);
  }
  return userDetail;
}

function* addUserDetailWorker(action) {
  // console.log("saga call for addUserDetailWorker ");
  console.log(action);
  /*  try {
    //if (action.payload && action.payload !== undefined)

    const userDetail = yield call(eventAPI.SaveAddToUserDetail, action.payload);
    yield put(actions.addToUserDetailSuccess(userDetail));
  } catch (error) {
    console.error(error);
  } */
  try {
    //if (action.payload && action.payload !== undefined)
    //api call
    const userDetail = yield call(eventAPI.SaveAddToUserDetail, action.payload);
    //local store
    let userDetail1 = JSON.stringify(userDetail);
    yield call(
      comFun.saveToLocal,
      constants.LOCAL_CART_USER_DETAILS,
      userDetail1
    );
    //update store
    yield put(actions.addToUserDetailSuccess(userDetail));
  } catch (error) {
    console.error(error);
  }
}

function* deleteUserDetailWorker(action) {
  try {
    yield call(eventAPI.DeleteFromUserDetail, action.payload);
    yield put(actions.deleteFromUserDetailSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//UserDetails Watcher Sagas
export function* watchFetchUserDetails() {
  // console.log("watchFetchUserDetails saga call2");
  yield takeEvery(
    actions.FETCH_CART_USER_DETAILS_REQUEST,
    fetchUserDetailsWorker
  );
}

export function* watchAddUserDetails() {
  // console.log("watchAddUserDetails saga call2");
  yield takeEvery(actions.ADD_CART_USER_DETAIL_REQUEST, addUserDetailWorker);
}

export function* watchDeleteUserDetails() {
  yield takeEvery(
    actions.DELETE_CART_USER_DETAIL_REQUEST,
    deleteUserDetailWorker
  );
}
