import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertDateTimeToIST = (dateTime) => {
  if (dateTime !== "" && dateTime !== null) {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(dateTime);

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD HH:mm");
    return formattedISTDate;
  } else {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(new Date());

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD HH:mm");
    return formattedISTDate;
  }
};

export const convertDateToIST = (dateTime) => {
  if (dateTime !== "" && dateTime !== null) {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(dateTime);

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD");
    return formattedISTDate;
  } else {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(new Date());

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD");
    return formattedISTDate;
  }
};

// Show case Date Time in format dd/mm/yyyy: hh:mm A :-
export const convertDateTimeToISTForUI = (dateTime) => {
  if (dateTime !== "" && dateTime !== null) {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(dateTime);

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD hh:mm A");
    return formattedISTDate;
  } else {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(new Date());

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("YYYY-MM-DD hh:mm A");
    return formattedISTDate;
  }
};

export const convertDateTimeToISTForUITimeOnly = (dateTime) => {
  if (dateTime !== "" && dateTime !== null) {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(dateTime);

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("hh:mm A");
    return formattedISTDate;
  } else {
    // Parse the input date string using Day.js
    const parsedDate = dayjs(new Date());

    // Convert the parsed date to IST while keeping the date part intact
    const istDateTime = parsedDate.tz("Asia/Kolkata");

    // Format the converted date as per your requirement
    const formattedISTDate = istDateTime.format("hh:mm A");
    return formattedISTDate;
  }
};
