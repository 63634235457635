import { useFetchMultipart, useFetchPostRequest } from "./api";

export const RegisterParticipant = async (requestBody, token) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/register-participant`;

  const formData = new FormData();
  for (const key in requestBody) {
    if (skipFields(key)) continue;

    if (key === "participantData") {
      formData.append(key, JSON.stringify(requestBody.participantData));
    } else {
      formData.append(key, requestBody[key]);
    }
  }

  const result = await useFetchMultipart(apiURL, formData);
  return result;
};

export const RegisterParticipantGuest = async (requestBody) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/register-participant`;

  const formData = new FormData();
  for (const key in requestBody) {
    if (skipFields(key)) continue;

    if (key === "participantData") {
      formData.append(key, JSON.stringify(requestBody.participantData));
    } else {
      formData.append(key, requestBody[key]);
    }
  }

  const result = await useFetchMultipart(apiURL, formData);
  return result;
};

export const InitiatePayment = async (
  eventId,
  paymentInfo,
  participantInfo,
  amount,
  paymentBreakupIs,
  isWidget
) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/initiate`;
  let mobile = paymentInfo?.mobile;
  if (mobile?.length == 10) mobile = "91" + mobile.toString();

  const reqBody = JSON.stringify({
    paymentData: {
      amount: amount,
      name: paymentInfo.fullName,
      email: paymentInfo.email,
      phone: mobile, //paymentInfo.mobile,
      productinfo: eventId,
    },

    participantIdArray: participantInfo,
    paymentBreakup: paymentBreakupIs,
    isWidget:isWidget,
  });

  const response = await useFetchPostRequest(apiURL, reqBody);
  return response;
};

export const GetPaymentBreakUpOfTicket = async (postValues) => {
  const apiURL = `${process.env.REACT_APP_PAYMENT_MS}pay/get-payment-breakup-user`;

  const reqBody = JSON.stringify({
    eventId: postValues.eventId,
    ticketData: postValues.ticketData,
    discountCode: postValues.discountCode,
  });

  const response = await useFetchPostRequest(apiURL, reqBody);
  return response;
};

const skipFields = (key) => {
  switch (key) {
    case "errors":
    case "IsValid":
      return true;

    default:
      return false;
  }
};

export default RegisterParticipant;
