import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import InputTextBox from "../../controls/input/InputTextBox";
import InputTextArea from "../../controls/input/InputTextArea";
import Dropdown from "../../controls/dropdown/Dropdown";
import Button from "../../controls/buttons/Button";
import * as Call from "../../../utils/validations";
import {
  DeactivateSingleTicketAfterPublish,
  DeleteSingleTicket,
  SaveEventTicketDetails,
  SaveEventTicketDetailsGuest,
  ViewTicketPriceBreakup,
} from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import { useNavigate } from "react-router-dom";
import * as common from "../../../utils/commonFun";
import TicketPreview from "./TicketPreview";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import * as initialState from "../../../services/initialState";
import ShowDateTimePicker from "../../controls/date/ShowDateTimePicker";
import Modal from "../../controls/popup/Modal";
import Spinner from "../../controls/spinner/Spinner";

// Form initial Data
let initialData = initialState.TicketDetails;
let mParam = initialState.TicketDetails.isRequired;
let eventId;
const TicketDetails = ({ eventDetails, saveTicketDetails, onStepComplete }) => {
  //const [ticketType, setTicketType] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  // const [vStartDate, setvStartDate] = React.useState(null);
  // const [vEndDate, setvEndDate] = React.useState(null);
  const [inputValueList, setInputValueList] = useState([]);
  const [ShowTicketAddForm, setShowTicketAddForm] = useState(true);
  //const [ticketType, setTicketType] = useState("");
  const [more, setMore] = useState(false);
  const [ticketPriceBreakUp, setTicketPriceBreakUp] = useState("");
  const [showBreakUp, setShowBreakUp] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deactivateTicketId, setDeactivateTicketId] = useState("");
  const [loading, setLoading] = useState(false); //spinner
  const [loadingBreakup, setLoadingBreakup] = useState(false); // Breakup loading
  const [validationSummary, setValidationSummary] = useState("");

  const userData = useSelector((state) => state.login.login.login);
  const navigate = useNavigate();

  const ticketType = [
    { key: 0, value: 0, title: "Select ticket type" },
    { key: 1, value: "Free", title: "Free" },
    { key: 2, value: "Paid", title: "Paid" },
  ];

  //if store data available
  useEffect(() => {
    if (eventDetails) {
      eventId = eventDetails.eventId;
      if (eventDetails?.ticketDetails) {
        initialData = { ...initialData, ...eventDetails };
        initialData = {
          ...initialData,
          IsValid: { ...IsValid, ...initialData.isRequired },
        };
        setInputValue(initialData);

        setInputValueList(eventDetails.ticketDetails);
        setShowTicketAddForm(false);
      }
    }
  }, [eventDetails]);

  /* const setvStartDate1 = (e) => {
    let val = vStartDate.format("YYYY-MM-DD");
    setInputValue((prev) => ({
      ...prev,
      ticketSaleStartDate: vStartDate.format("YYYY-MM-DD"),
    }));
  };
  const setvEndDate1 = (e) => {
    let val = vEndDate.format("YYYY-MM-DD");
    setInputValue((prev) => ({
      ...prev,
      ticketSaleEndDate: vEndDate.format("YYYY-MM-DD"),
    }));
  }; */

  //const handleTicketSelection = (event) => {
  // setTicketType(event.target.value);
  //};

  const saveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      let postValueList = {
        eventId: "",
        tickets: [],
        userId: "",
      };
      if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);

      //post data logic
      let postValue = initialState.TicketDetails;
      let skipKey = initialState.skipKey;
      postValueList.tickets = inputValueList.map((item) => {
        for (let key in postValue) {
          try {
            if (skipKey.includes(key)) {
              delete item[key];
            } else {
              switch (key) {
                case "totalQuantity":
                case "ticketId":
                  try {
                    parseInt(item[key]);
                  } catch {}
                  break;
                case "ticketPrice":
                  try {
                    parseFloat(item[key]);
                  } catch {}
                  break;
                default:
                  break;
              }
            }
          } catch {}
        }
        //
        if (item["tType"] === "newTicket") {
          if (item["ticketId"]) delete item["ticketId"];
        }

        return item;
      });
      if (eventId) postValueList.eventId = eventId;

      /**
       * If event is onGoing then don't send old ticket.
       * Otherwise old ticket details will get updated. So removing
       * old tickets using ticketId.
       */
      if (eventDetails?.eventStatus === "act") {
        const tempTicketList = postValueList.tickets.filter(
          (item) => item?.ticketId === undefined
        );
        postValueList.tickets = tempTicketList;
      }
      //send data to server
      setLoading(true);
      let result;
      if (userData?.user) {
        postValueList.userId = userData.user.userId;
        const token = userData.token;
        result = await SaveEventTicketDetails(postValueList, token);
      } else {
        result = await SaveEventTicketDetailsGuest(postValueList);
      }
      setLoading(false);

      //next step
      if (result) {
        if (result.success) {
          let eventDetails1 = result.data;
          eventDetails1 = {
            ...eventDetails1,
            IsValid: { ...IsValid, ...inputValue?.IsValid },
          };
          saveTicketDetails(eventDetails1);
          onStepComplete(4);
        } else {
          inputValueList.map((item, index) => {
            if (item.ticketId === undefined) {
              item["ticketId"] = index;
            }
          });
          setInputValueList(inputValueList);
          alert(result.error);
        }
      } else {
        alert(API_RESONSE_FAILED);
      }
    }
  };

  const handleChange = (e) => {
    //get Value
    const { name, value } = e.target;
    //check validation
    let result = isValid(name, value, e);
    //setInputValue
    setInputValue((prev) => ({
      ...prev,
      [name]: value === "" ? "" : value,
      errors: { ...errors, [name]: result.message },
      IsValid: { ...IsValid, [name]: result.status },
    }));
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "ticketName":
          val = !Call.IsEmpty(value);
          if (val) msg = "Invalid " + value;
          break;
        case "ticketType":
          val = Call.IsValidTicketType(value);
          if (!val) {
            if (value === "0") msg = "Please Select Ticket Type";
            else msg = "Invalid TicketType" + value;
          }
          break;
        case "totalQuantity":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          break;
        case "ticketPrice":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          break;
        case "ticketDescription":
          val = Call.IsValidCharNumWithSpace(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "messageToAttendees":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter valid message to attendees.";
          break;

        // case "messageToAttendees":
        //   val = Call.IsValidCharNumWithSpace(value);
        //   if (!val) msg = "Invalid " + value;
        //   break;
        default:
          break;
      }
    }

    return { status: val, message: msg };
  };
  const handleInputChange = (name, data) => {
    //alert("handleDateChange");
    //alert(date);
    switch (name) {
      case "sDate":
        setInputValue((prev) => ({
          ...prev,
          ticketSaleStartDate: data,
          ["IsValid"]: { ...prev.IsValid, ticketSaleStartDate: true },
        }));
        break;
      case "eDate":
        setInputValue((prev) => ({
          ...prev,
          ticketSaleEndDate: data,
          ["IsValid"]: { ...prev.IsValid, ticketSaleEndDate: true },
        }));
        break;
      default:
        setInputValue((prev) => ({
          ...prev,
          [name]: data,
          ["IsValid"]: { ...prev.IsValid, [name]: true },
        }));
        break;
    }
  };
  /*  const handleDateTime = (e, type) => {
    //const { name, $d } = e;
    //const { name, value } = e.target;
    //set value
    if (type === "sDate") {
      setvStartDate();
      const newStartDate = inputValue.map((prev) => ({
        ...prev,
        ticketSaleStartDate: newStartDate,
      }));
      setInputValue(newStartDate);
    } else if (type === "eDate") {
      setvEndDate();

      setInputValue((prev) => ({
        ...prev,
        ticketSaleEndDate: vEndDate,
      }));
    }
  };
 */
  const showMoreView = () => {
    setMore(true);
  };

  const hideMoreView = () => {
    setMore(false);
  };

  const handleEdit = (ticketId) => {
    //alert(ticketId);

    const items = inputValueList;
    let item = items.find((item) => item.ticketId === ticketId);

    item = {
      ...item,
      ["IsValid"]: { ...initialData.isRequired },
    };
    setShowTicketAddForm(true);
    setInputValue(item);
  };

  const handleDelete = async (ticketId) => {
    if (eventDetails?.eventStatus === "act") {
      setDeactivateTicketId(ticketId);
      setShowConfirmationDialog(true);
    } else if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events details can't be changed.");
    } else {
      handleDeleteTicketsBeforePublish(ticketId);
    }
  };

  /**
   * If event is onGoing then ticket will get deactivate.
   * In event details page inactive tickets will not be visible.
   */
  const handleDeleteTicketsAfterPublish = async () => {
    setShowConfirmationDialog(false);

    const response = await DeactivateSingleTicketAfterPublish(
      eventId,
      deactivateTicketId,
      userData.token
    );
    if (response?.success) {
      let eventDetails1 = response.data;
      eventDetails1 = {
        ...eventDetails1,
        IsValid: { ...IsValid, ...inputValue?.IsValid },
      };
      saveTicketDetails(eventDetails1);
      alert(response?.message);
    } else {
      alert(response?.message);
    }
  };

  /**
   * Delete ticket from DB. Since event is not published.
   * This function can delete ticket which are there on DB as well as local.
   * @param {*} ticketId
   * @returns
   */
  const handleDeleteTicketsBeforePublish = async (ticketId) => {
    const items = [...inputValueList];
    const j = items.findIndex((item) => item.ticketId === ticketId);

    if (j === -1) return; // If the ticket is not found, exit from the function

    if (items[j].tType === "newTicket") {
      items.splice(j, 1);
      setInputValueList(items);
      alert("Ticket Details deleted.");
    } else {
      const response = await DeleteSingleTicket(eventId, ticketId);
      if (response?.success) {
        // items.splice(j, 1);
        // setInputValueList(items);
        let eventDetails1 = response.data;
        eventDetails1 = {
          ...eventDetails1,
          IsValid: { ...IsValid, ...inputValue?.IsValid },
        };
        saveTicketDetails(eventDetails1);
        alert(response?.message);
      } else {
        alert(response?.message);
      }
    }
    if (items.length === 0) {
      handleAddAnotherTicket();
    }
  };

  const handleSaveTicket = (e) => {
    e.preventDefault();

    if (
      IsValid &&
      IsValid.ticketName &&
      inputValue.ticketName != "" &&
      IsValid.ticketType &&
      IsValid.totalQuantity &&
      IsValid.ticketSaleStartDate &&
      IsValid.ticketSaleEndDate &&
      (inputValue.ticketType === "Free" || IsValid.ticketPrice)
    ) {
      // ticketsArray;
      //show ticket preview
      setShowTicketAddForm(false);
      let newtid = inputValueList.length + 1;
      let tType = "newTicket";
      let newTicket = [{ ...inputValue, ticketId: newtid, tType: tType }];

      let templist = [];
      if (inputValueList.length > 0) {
        //templist=[{...inputValueList}, {...inputValue}];
        if (
          inputValue.ticketId > 0 &&
          inputValue.ticketName.trim().length > 0
        ) {
          //update old ticket
          const newList = inputValueList.map((item) => {
            if (item.ticketId === inputValue.ticketId) {
              item.ticketName = inputValue.ticketName;
              item.ticketType = inputValue.ticketType;
              item.totalQuantity = inputValue.totalQuantity;
              item.ticketPrice = inputValue.ticketPrice;
              item.ticketDescription = inputValue.ticketDescription;
              item.messageToAttendees = inputValue.messageToAttendees;
              item.ticketSaleStartDate = inputValue.ticketSaleStartDate;
              item.ticketSaleEndDate = inputValue.ticketSaleEndDate;
              /*const updatedItem = {
                ...item,
                isComplete: !item.isComplete,
              };
              return updatedItem;*/
            }

            return item;
          });
          setInputValueList(newList);
        } else {
          //add new ticket
          templist = [...inputValueList, ...newTicket];
          setInputValueList(templist);
        }
      } else {
        //templist=[{...newTicket}];
        setInputValueList(newTicket);
      }
      //faq: [...prev.faq, ...newQue],
    } else {
      let displaMsg = "";
      for (let key in mParam) {
        if (!inputValue?.IsValid[key]) {
          displaMsg += "<li>" + key + "</li>";
        }
      }
      displaMsg =
        "<b>Please enter required fields</b><ul>" + displaMsg + "</ul>";
      setValidationSummary(() => displaMsg);
    }
    // else {
    //   alert("Enter all required fields");
    // }
  };

  const handleAddAnotherTicket = () => {
    setShowTicketAddForm(true);
    setInputValue(() => initialState.TicketDetails);
  };

  const showSaveTicketList = () => {
    setShowTicketAddForm(false);
  };

  const RenderViewBreakup = (price) => {
    price = parseInt(price).toFixed(2);
    let renderVB = [];

    let vbData = {
      Amount: {
        title: "Ticket Price ",
        amount: ticketPriceBreakUp.ticketPrice,
      },
      GST: {
        title: "GST ",
        amount: ticketPriceBreakUp.gst,
      },

      fitfundaFees: {
        title: "FitFunda Fee ",
        amount: ticketPriceBreakUp.fitfundaFee,
      },

      pgFees: {
        title: "PG fees ",
        amount: ticketPriceBreakUp.paymentGatewayFee,
      },

      tds: {
        title: "TDS ",
        amount: ticketPriceBreakUp.tds,
      },
    };

    if (parseFloat(ticketPriceBreakUp?.platformUsageFee) > 0) {
      const configPlatformFee = {
        platformFees: {
          title: "Platform Usage Fees (By Buyer) ",
          amount: ticketPriceBreakUp?.platformUsageFee,
        },
      };

      vbData = {
        ...vbData,
        ...(configPlatformFee.platformFees && {
          platformFees: configPlatformFee.platformFees,
        }),
      };
    }

    const renderUI = [];
    for (let key in vbData) {
      renderUI.push(
        <div class="d-flex dropdown-item p-0">
          <div class="flex-grow-1">{vbData[key]?.title}</div>
          <div>₹. {vbData[key]?.amount}</div>
        </div>
      );
    }

    renderVB.push(
      <>
        <i class="bi bi-caret-up-fill view-Breakup-box-arrow"></i>
        <h1>Payment</h1>
        <hr class="dropdown-divider" />
        {renderUI}
        <hr class="dropdown-divider" />
        <div class="d-flex dropdown-item p-0">
          <div class="flex-grow-1">
            <b>Total Payble (By Buyer)</b>
          </div>
          <div>
            <b>₹. {ticketPriceBreakUp.totalPayableByBuyer}</b>
          </div>
        </div>
        <hr class="dropdown-divider" />
        <div class="d-flex dropdown-item p-0">
          <div class="flex-grow-1">
            <b>Payout to You </b>
          </div>
          <div>
            <b>₹. {ticketPriceBreakUp.payableToYou}</b>
          </div>
        </div>
      </>
    );
    return renderVB;
  };

  const getPriceBreakup = async (price) => {
    price = parseInt(price).toFixed(2);
    setLoadingBreakup(true);
    const response = await ViewTicketPriceBreakup(eventId, price);
    if (response?.success) {
      setLoadingBreakup(false);
      setTicketPriceBreakUp(response?.data);
      setShowBreakUp(true);
    } else {
      setTicketPriceBreakUp("");
    }
  };

  //
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="card p-4" onClick={() => setShowBreakUp(false)}>
            {ShowTicketAddForm ? (
              <>
                <h5>Ticket Details</h5>

                <input
                  type="hidden"
                  name="ticketId"
                  id="ticketId"
                  value={inputValue.ticketId}
                />

                <div className="my-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Ticket Name<span className="text-red-600">*</span>
                  </label>
                  <InputTextBox
                    id="ticketName"
                    name="ticketName"
                    type="text"
                    value={inputValue.ticketName}
                    placeholder="Please enter ticket name"
                    isValid={inputValue?.IsValid?.ticketName}
                    errorMsg={inputValue?.errors?.ticketName}
                    onChange={handleChange}
                  />
                </div>

                <div className="my-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Select Ticket Type<span className="text-red-600">*</span>
                  </label>
                  <Dropdown
                    id="ticketType"
                    name="ticketType"
                    options={ticketType}
                    value={inputValue.ticketType}
                    isValid={inputValue?.IsValid?.ticketType}
                    errorMsg={inputValue?.errors?.ticketType}
                    onChange={handleChange}
                  />
                </div>

                <div className="row g-3 my-3">
                  <div className="col">
                    <label class="" for="inlineFormInputGroupUsername">
                      Ticket Quantity<span className="text-red-600">*</span>
                    </label>
                    <InputTextBox
                      id="totalQuantity"
                      name="totalQuantity"
                      type="text"
                      value={inputValue.totalQuantity}
                      placeholder="Please enter ticket name"
                      isValid={inputValue?.IsValid?.totalQuantity}
                      errorMsg={inputValue?.errors?.totalQuantity}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <label class="" for="inlineFormInputGroupUsername">
                      Ticket Price<span className="text-red-600">*</span>
                    </label>
                    <InputTextBox
                      id="ticketPrice"
                      name="ticketPrice"
                      type="text"
                      disabled={inputValue.ticketType === "Free" ? true : false}
                      value={
                        inputValue.ticketType === "Free"
                          ? (inputValue.ticketPrice = "0")
                          : inputValue.ticketPrice
                      }
                      placeholder="Please enter ticket price"
                      isValid={inputValue?.IsValid?.ticketPrice}
                      errorMsg={inputValue?.errors?.ticketPrice}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mt-10 w-full">
                  <div className="text-end my-4" id="showViewBreakup">
                    {inputValue.ticketPrice > 0 && (
                      <a
                        style={{ cursor: "pointer" }}
                        className="show"
                        onClick={() => getPriceBreakup(inputValue.ticketPrice)}
                      >
                        View Breakup
                      </a>
                    )}
                    {loadingBreakup ? (
                      <span
                        className="loaderBreakup"
                        style={{ marginLeft: "800px" }}
                      ></span>
                    ) : (
                      ticketPriceBreakUp !== "" &&
                      inputValue.ticketPrice > 0 &&
                      showBreakUp && (
                        <div
                          className="dropdown-menu view-Breakup-box dropdown-menu-lg-end show"
                          style={{
                            position: "absolute",
                            inset: "321px 0px auto auto",
                            margin: "0px",
                            transform: "translate(-24px, 98px)",
                          }}
                        >
                          {RenderViewBreakup(inputValue?.ticketPrice)}
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="row g-3 my-3">
                  <div className="col">
                    <label class="" for="inlineFormInputGroupUsername">
                      Ticket Sale Start Date
                      <span className="text-red-600">*</span>
                    </label>
                    <div>
                      <ShowDateTimePicker
                        type="start-date"
                        title=""
                        minDate={new Date()}
                        curDate={inputValue.ticketSaleStartDate}
                        maxDate={eventDetails?.eventEndDate}
                        onDateChange={(newDate) =>
                          handleInputChange("sDate", newDate)
                        }
                      />
                    </div>
                  </div>

                  <div className="col">
                    <label class="" for="inlineFormInputGroupUsername">
                      Ticket Sale End Date
                      <span className="text-red-600">*</span>
                    </label>
                    <div>
                      <ShowDateTimePicker
                        type="end-date"
                        title=""
                        curDate={inputValue.ticketSaleEndDate}
                        minDate={inputValue.ticketSaleStartDate}
                        maxDate={eventDetails?.eventEndDate}
                        onDateChange={(newDate) =>
                          handleInputChange("eDate", newDate)
                        }
                        disabled={inputValue.ticketSaleStartDate ? false : true}
                      />
                    </div>
                  </div>
                </div>

                {more ? (
                  <>
                    <div className="my-3">
                      <label class="" for="inlineFormInputGroupUsername">
                        Ticket Description
                      </label>
                      <InputTextArea
                        id="ticketDescription"
                        name="ticketDescription"
                        type="text"
                        value={inputValue.ticketDescription}
                        placeholder="Please enter ticket description"
                        isValid={inputValue?.IsValid?.ticketDescription}
                        errorMsg={inputValue?.errors?.ticketDescription}
                        onChange={handleChange}
                        minRows={5}
                        maxRows={10}
                      />
                    </div>

                    <div className="my-3">
                      <label class="" for="inlineFormInputGroupUsername">
                        Message to attendee to be sent with ticket
                      </label>
                      <InputTextArea
                        id="messageToAttendees"
                        name="messageToAttendees"
                        type="text"
                        value={inputValue.messageToAttendees}
                        placeholder="Please enter message to attendees"
                        isValid={inputValue?.IsValid?.messageToAttendees}
                        errorMsg={inputValue?.errors?.messageToAttendees}
                        onChange={handleChange}
                        minRows={5}
                        maxRows={10}
                      />
                    </div>
                    <div className="my-3">
                      <Button light onClick={hideMoreView}>
                        - Less
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="my-3">
                    <Button light onClick={showMoreView}>
                      + More
                    </Button>
                  </div>
                )}
                <div className="mb-3 text-center">
                  <Button onClick={handleSaveTicket}>
                    {inputValue.ticketId > 0 ? "Update" : "Save"} Ticket
                  </Button>
                </div>
                {inputValueList.length > 0 && (
                  <div className="mb-3 text-center">
                    <Button light onClick={showSaveTicketList}>
                      Back To List
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {inputValueList &&
                  inputValueList.map((item) => (
                    //  {item}
                    <TicketPreview
                      inputValue={item}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      eventDetails={eventDetails}
                    />
                  ))}
                <div className="mb-3 text-center">
                  <Button light onClick={handleAddAnotherTicket}>
                    + Add Another Ticket
                  </Button>
                </div>
              </>
            )}
            {eventDetails.eventId && (
              <div className="mb-3 text-center">
                {ShowTicketAddForm ? (
                  <Button secondary outline>
                    Save & Proceed
                  </Button>
                ) : (
                  <Button disabled={ShowTicketAddForm} onClick={saveAndProceed}>
                    Save & Proceed
                  </Button>
                )}
              </div>
            )}

            {validationSummary !== "" && (
              <div
                class="alert alert-danger"
                role="alert"
                dangerouslySetInnerHTML={{ __html: validationSummary }}
              ></div>
            )}

            {showConfirmationDialog && (
              <Modal
                onClose={() => setShowConfirmationDialog(false)}
                title={""}
                onSuccess={handleDeleteTicketsAfterPublish}
                successText={"Yes"}
              >
                <div class="modal-content">
                  <div class="modal-body">
                    Are you sure you want to deactivate? This action can not be
                    undone.
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveTicketDetails: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(TicketDetails);
//export default TicketDetails;
