import React, { useState, useEffect } from "react";
import { convertDateTimeToISTForUI } from "../../../utils/DateTimeUtility";

const TicketPreview = ({
  inputValue,
  handleEdit,
  handleDelete,
  index,
  eventDetails,
}) => {
  const [more, setMore] = useState(false);
  const showMore = () => {
    if (more) setMore(false);
    else setMore(true);
  };

  const handleTicketEdit = (ticketId) => {
    handleEdit(ticketId);
  };
  const handleTicketDelete = (ticketId) => {
    handleDelete(ticketId);
  };
  const [inputDisable, setInputDisable] = useState(false);
  //if store data available
  useEffect(() => {
    if (eventDetails) {
      //
      if (
        eventDetails?.eventStatus === "act" ||
        eventDetails?.eventStatus === "iact"
      ) {
        setInputDisable(() => true);
      }
    }
  }, [eventDetails]);

  //main return
  return (
    <>
      <div class=" ticket-detail mb-4">
        <div class={index % 2 == 0 ? "ticket-box-green" : "ticket-box-yellow"}>
          <div class="row">
            <div class="col">
              <h1>
                {" "}
                {inputValue.ticketName} (
                {/* {inputValue.ticketType && inputValue.ticketType == 1
                  ? "Free"
                  : "Paid"} */}
                {inputValue.ticketType})
              </h1>
              <p>
                {convertDateTimeToISTForUI(inputValue.ticketSaleStartDate)}
                <span> | </span>{" "}
                <span>
                  {convertDateTimeToISTForUI(inputValue.ticketSaleEndDate)}
                </span>
              </p>
            </div>
            <div class="col">
              <div class="d-flex flex-row mb-3 float-end">
                <div class="p-2 me-2">
                  <p>{inputValue.totalQuantity} tickets</p>
                </div>
                <div class="p-2">
                  <p>
                    <b>Rs.{inputValue.ticketPrice}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row mb-3">
            <div class="p-2">
              {inputDisable == false ||
              inputValue?.ticketStatus === undefined ? (
                <a
                  href="#"
                  onClick={() => handleTicketEdit(inputValue.ticketId)}
                >
                  {" "}
                  Edit{" "}
                </a>
              ) : (
                <a href="#" className="text-success">
                  {inputValue?.ticketStatus === "act" ? (
                    <>Active</>
                  ) : (
                    <>In-Active</>
                  )}
                </a>
              )}
            </div>

            <div class="p-2">
              {inputValue?.ticketStatus === "iact" ? <></> : <>|</>}
            </div>
            <div class="p-2">
              {inputValue?.ticketStatus === "iact" ? (
                <></>
              ) : (
                <>
                  <a
                    href="#"
                    onClick={() => handleTicketDelete(inputValue.ticketId)}
                  >
                    {" "}
                    De-Activate{" "}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          class={index % 2 == 0 ? "ticket-info-green" : "ticket-info-yellow"}
        >
          <div
            class="accordion accordion-flush"
            id={"accordionFlushExample" + index}
          >
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#flush-collapseOne" + index}
                  aria-expanded="false"
                  aria-controls={"flush-collapseOne" + index}
                >
                  <div
                    onClick={() => showMore()}
                    class="d-flex justify-content-center align-items-center accordion-detail"
                  >
                    <div class="mt-2 readmore">
                      <a class="text-secondary">Read More</a>
                    </div>
                  </div>
                </button>
              </h2>
              {more === true && (
                <div
                  id={"flush-collapseOne" + index}
                  class="accordion-collapse collapse p-2 accordion-detail show"
                  data-bs-parent={"#accordionFlushExample" + index}
                >
                  {" "}
                  <div class="accordion-body text-start">
                    <div>
                      <div className="font-weight-bold">Ticket Description</div>
                      <div className=""> {inputValue.ticketDescription}</div>
                    </div>
                    <div>
                      <div className="font-weight-bold">
                        Message to attendees to be sent with ticket
                      </div>
                      <div className=""> {inputValue.messageToAttendees}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketPreview;
