import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { convertDateToIST } from "../../../utils/DateTimeUtility";

const ShowDatePicker = ({
  onDateChange,
  curDate: defaultDate,
  minDate,
  maxDate,
  title,
  type,
  ...rest
}) => {
  const today = dayjs().startOf("day");
  let maximumDate = dayjs().add(365, "day");

  if (!defaultDate) {
    defaultDate = type === "start-date" ? today : today.add(1, "day");
  }

  const [date, setDate] = useState(
    // dayjs(defaultDate).format("YYYY-MM-DD")
    convertDateToIST(defaultDate)
  );
  const [minDateState, setMinDateState] = useState(
    // today.format("YYYY-MM-DD")
    ""
  );

  useEffect(() => {
    // setMinDateState(minDate ? dayjs(new Date(minDate)).format("YYYY-MM-DD") : today.format("YYYY-MM-DD"));
    setMinDateState(minDate ? convertDateToIST(minDate) : convertDateToIST(""));
    if (maxDate) {
      // maximumDate = dayjs(new Date(maxDate)).format("YYYY-MM-DD");
      maximumDate = convertDateToIST(maxDate);
    }
  }, [minDate, maxDate, today]);

  const handleChange = (event) => {
    const value = event.target.value;
    setDate(value);
    // onDateChange(dayjs(value).format("DD/MMM/YYYY"));
    const formattedISTDate = convertDateToIST(value);
    onDateChange(formattedISTDate);
  };

  return (
    <>
      <label>{title}</label>
      <input
        type="date"
        name="datePicker"
        value={date}
        min={minDateState}
        max={maximumDate}
        onChange={handleChange}
        className="form-control"
        {...rest}
      />
    </>
  );
};

export default ShowDatePicker;

// import React, { useState } from "react";
// import dayjs, { Dayjs } from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// let today = dayjs();
// let maximumDate = dayjs().add(365, "day");
// const ShowDatePicker = ({
//   onDateChange,
//   curDate : defaultDate,
//   minDate,
//   maxDate,
//   title,
//   type,
//   ...rest
// }) => {
//   if(defaultDate === ""){
//     if(type === "start-date"){
//       defaultDate = today
//     }else{
//       defaultDate = today.add(1, "day")
//     }
//   }

//   const [date, setDate] = useState(dayjs(defaultDate) || null);
//   try {
//     if(!minDate){
//       minDate = today;
//     }else{
//       minDate = dayjs(new Date(minDate));
//     }

//     if (maxDate) {
//       maximumDate = dayjs(new Date(maxDate));
//     }
//   } catch {}
//   //
//   const handleChange = (value) => {
//     setDate(value);
//     let date = value?.format("DD/MMM/YYYY");
//     onDateChange(date);
//   };

//   //
//   return (
//     <>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DatePicker
//           label={title}
//           name="datePicker"
//           value={date}
//           minDate={minDate}
//           maxDate={maximumDate}
//           format="DD/MMM/YYYY"
//           onChange={(newValue) => {
//             handleChange(newValue);
//           }}
//           referenceDate={dayjs(today)}
//           className="form-control border-0"
//           {...rest}
//         />
//       </LocalizationProvider>
//     </>
//   );
// };

// export default ShowDatePicker;
