import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "../controls/table/Table";
import { GetMyNotifications } from "../../services/userAPI";
import { convertDateTimeToISTForUI } from "../../utils/DateTimeUtility";
import Spinner from "../controls/spinner/Spinner";

const Notification = () => {
  const userData = useSelector((state) => state.login.login.login);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);  // Spinner state

  useEffect(() => {
    const GetNotificationData = async () => {
      try {
        if (userData?.token) {
          setLoading(true); // Show spinner when sending starts
          const notificationResult = await GetMyNotifications(userData.token);
          setLoading(false);
          if (notificationResult?.success === true) {
            setNotificationData(notificationResult?.data);
          } else {
            alert(notificationResult?.message);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    GetNotificationData();
  }, [userData.token]);

  const config = {
    tableTitle: "Notifications",
    data: notificationData,
    displayRecord: 10,
    defaultSortBy: (data) => data?.createdAt,
    columns: [
      {
        title: "Message ",
        titleClass: "text-start ",
        dataClass: "text-start",
        dataField: "message",
        isSort: false,
        sortByValue: (data) => data?.message,
      },
      {
        title: "Date",
        isLink: true,
        // isSort: true,
        render: (data) => (
          <div class="">{convertDateTimeToISTForUI(data.createdAt)}</div>
        ),
        titleClass: "text-start",
        dataClass: "text-start",
        sortByValue: (data) => data?.updatedAt,
      },
    ],
  };

  //main return
  return (
  <>
  <Table config={config} />
  {loading && (
    <div
      className="modal fade show"
      id="exampleModalCenter"
      tabIndex={-1}
      aria-labelledby="exampleModalCenterTitle"
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.3)" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0" style={{ background: "transparent", boxShadow: "none" }}>
          <div className="modal-body d-flex justify-content-center align-items-center">
            <Spinner />
          </div>
        </div>
      </div>
    </div>
  )}
</>
  );
};

export default Notification;
