export const IsEmpty = (value) => {
  if (!value) {
    return true;
  }
  return false;
};

export const IsValidChar = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[a-zA-Z]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidCharWithSpace = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^(?!\s)[a-zA-Z ]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidCharNumWithSpace = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^(?!\s)[0-9a-zA-Z,.:;'"" ]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidEventId = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^E[0-9]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidEventName = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9a-zA-Z ]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidEmail = (value) => {
  const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;

  if (typeof value !== "undefined") {
    if (regEx.test(value)) {
      return true;
    }
    return false;
  }
};

export const IsNumberOnlyInput = (value) => {
  const regNumberOnly = /^[0-9\b]+$/;

  if (typeof value !== "undefined") {
    // if value is not blank, then test the regex
    if (value === "" || regNumberOnly.test(value)) {
      return true;
    }
    return false;
  }
};

export const IsMaxLengthReached = (object) => {
  if (object.target.value.length === object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
    return true;
  } else if (object.target.value.length < object.target.maxLength) {
    return false;
  }
};

export const IsValidMobileNo = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9]{10,10}$/)) {
      ///^\\d{10}$/,[^0-9][+-]?[0-9]{1,10}[^0-9]
      return true;
    }
    return false;
  }
};

export const IsValidPassword = (value) => {
  if (typeof value !== "undefined") {
    if (
      value.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)
    ) {
      return true;
    }
    return false;
  }
};

export const IsValidLocation = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^(?!\s)[0-9a-zA-Z, ]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidPinCode = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidTicketType = (value) => {
  switch (value) {
    case "Free":
    case "Paid":
      return true;

    default:
      return false;
  }
};

export const IsValidCountry = (value) => {
  switch (value) {
    case "India":
      return true;
    default:
      return false;
  }
};

export const IsValidPANCard = (value) => {
  var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  if (value.match(regpan)) {
    return true;
  } else {
    return false;
  }
};

export const IsValidAccountType = (value) => {
  switch (value) {
    case "Saving":
      return true;

    case "Current":
      return true;

    default:
      return false;
  }
};

export const IsValidGSTNumber = (value) => {
  var reggst = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  if (!reggst.test(value) && value !== "") {
    return false;
  }
  return true;
};

export const IsValidIFSCCode = (value) => {
  var reg = /^[A-Za-z]{4}[0-9]{6,7}$/;
  if (value.match(reg)) {
    return true;
  } else {
    return false;
  }
};

export const IsValidHSN = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9]{6,6}$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidAadhar = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9]{12,12}$/)) {
      return true;
    }
    return false;
  }
};
export const IsValidCouponCode = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^(?!\s)[0-9a-zA-Z]+$/)) {
      return true;
    }
    return false;
  }
};

export const IsValidSeachText = (value) => {
  if (typeof value !== "undefined") {
    if (value.match(/^[0-9a-zA-Z@ ]+$/)) {
      return true;
    }
    return false;
  }
};

/*
Explanation:
1). ^ : expects the pattern at the beginning.
2). $ : expects pattern at the end.
3). \s : matches a space.
4). ?! : negation.(in the above pattern it indicates that the first character cannot be a space, i.e; \s)
5). [] : matches a single character.
6). + : matches one or more.
7). i : case-insensitivity flag.
*/
