import { combineReducers } from "redux";
import {
  RECEIVE_EVENTS,
  FILTER_EVENTS,
  // ADD_TO_CART,
  // REMOVE_FROM_CART,
  FETCH_CART_EVENTS_SUCCESS,
  ADD_CART_EVENT_SUCCESS,
  DELETE_CART_EVENT_SUCCESS,
  //
  FETCH_CART_USER_DETAILS_SUCCESS,
  ADD_CART_USER_DETAIL_SUCCESS,
  DELETE_CART_USER_DETAIL_SUCCESS,
} from "../actions/events";

//import { ADD_TO_CART, REMOVE_FROM_CART } from '../actions/cart';
const initialStateForCartEvents = {
  cartEvents: [],
};

function cartEvents(state = initialStateForCartEvents, action) {
  // console.log("reducer call for cartEvents");
  // console.log(action);
  switch (action.type) {
    case FETCH_CART_EVENTS_SUCCESS:
      return {
        ...state,
        cartEvents: action.payload,
      };
    case ADD_CART_EVENT_SUCCESS:
      return {
        ...state,
        //[cartEvents.cartEvents]: [...state.cartEvents.cartEvents, action.payload],
        cartEvents: action.payload,
      };
    case DELETE_CART_EVENT_SUCCESS:
      return {
        ...state,
        cartEvents: state.cartEvents.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}

//userDetails
const initialStateForUserDetails = {
  userDetails: [],
};
function userDetails(state = initialStateForUserDetails, action) {
  // console.log("reducer call for userDetails");
  // console.log(action);
  switch (action.type) {
    case FETCH_CART_USER_DETAILS_SUCCESS:
      return {
        ...state,
        //[userDetails.userDetails]: action.payload,
        userDetails: action.payload,
      };
    case ADD_CART_USER_DETAIL_SUCCESS:
      return {
        //...state,
        //[cartEvents.cartEvents]: [...state.cartEvents.cartEvents, action.payload],
        userDetails: action.payload,
      };
    case DELETE_CART_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetails: state.userDetails.filter(
          (item) => item.eventId !== action.payload
        ),
      };
    default:
      return state;
  }
}

function filterEvents(state) {
  let filteredEvents = [];
  for (var id in state.byId) {
    let event = getEvents(state, id);
    if (event.brand.toUpperCase().includes(state.filterTerm)) {
      filteredEvents.push(event);
    }
  }
  return filteredEvents;
}

function byId(state = {}, action) {
  switch (action.type) {
    case RECEIVE_EVENTS:
      return {
        ...state,
        ...action.events.reduce((obj, event) => {
          //if event id is already in the state use its current inventory
          if (state[event.id]) {
            event.inventory = state[event.id].inventory;
          }
          obj[event.id] = event;
          return obj;
        }, {}),
      };
    default:
      const { eventId } = action;
      if (eventId) {
        return {
          ...state,
          [eventId]: cartEvents(state[eventId], action),
        };
      }
      return state;
  }
}

// function visibleIds(state = [], action) {
//   switch (action.type) {
//     case RECEIVE_EVENTS:
//       return action.events.map((event) => event.id);
//     default:
//       return state;
//   }
// }

// function filterTerm(state = "", action) {
//   switch (action.type) {
//     case FILTER_EVENTS:
//       return action.brand;
//     case RECEIVE_EVENTS:
//       return "";
//     default:
//       return state;
//   }
// }

export default combineReducers({
  cartEvents,
  userDetails,
  /* byId,
  visibleIds,
  filterTerm, */
});

export function getEvents(state, id) {
  return state.byId[id];
}

export function getVisibleEvents(state) {
  return state.filterTerm
    ? filterEvents(state)
    : state.visibleIds.map((id) => getEvents(state, id));
}
