import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { SaveParticipantFormMedicalInfo } from "../../../services/createEvent";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
//import ParticipantFormDialog from "./ParticipantFormDialog";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as storeAction from "../../../redux/actions/createEvent";
import * as common from "../../../utils/commonFun";

//
const initialData = {
  isBloodGroup: {
    isActive: false,
    isMandatory: false,
  },

  isFitnessCertificate: {
    isActive: false,
    isMandatory: false,
  },
  eventId: "",
  userId: "",
};
const initialDataTitle = {
  isBloodGroup: {
    title: "Blood Group", //"Date of birth",
  },
  isFitnessCertificate: {
    title: "Fitness Certificate",
  },
};
let eventId;
const MedicalInformaion = ({
  eventDetails,
  saveEventDetails,
  onSave,
  onSkip,
}) => {
  // const [title, setTitle] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  const userData = useSelector((state) => state.login.login.login);
  // const [showDialog, setShowDialog] = useState(false);

  //const [popupData, setPopupData] = useState(null);

  //
  useEffect(() => {
    if (eventDetails && eventDetails?.eventId?.length > 0) {
      eventId = eventDetails.eventId;
      if (eventDetails?.participantForm) {
        let initialData1 = initialData;
        initialData1.isBloodGroup.isActive =
          eventDetails?.participantForm?.isBloodGroup.isActive;
        initialData1.isBloodGroup.isMandatory =
          eventDetails?.participantForm?.isBloodGroup.isMandatory;
        initialData1.isFitnessCertificate.isActive =
          eventDetails?.participantForm?.isFitnessCertificate.isActive;
        initialData1.isFitnessCertificate.isMandatory =
          eventDetails?.participantForm?.isFitnessCertificate.isMandatory;

        setInputValue(() => initialData1);
      }
    }
  }, [eventDetails]);

  // //
  const handleChange = (event, type, key) => {
    // event.preventDefault();

    //
    const { name, value } = event.target;
    let checked = event?.target?.checked ? true : false;

    let newValue = { isActive: checked, isMandatory: checked };

    if (type === "isActive") {
      newValue = {
        isActive: checked,
        isMandatory: inputValue[key]?.isMandatory,
      };
    } else if (type === "isMandatory") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: checked,
      };
    } else if (type === "isOptional") {
      newValue = {
        isActive: inputValue[key]?.isActive,
        isMandatory: false,
      };
    }
    {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [key]: newValue,
      }));
    }
  };
  //
  const handleSaveAndProceed = async (e) => {
    e.preventDefault();

    if (
      eventDetails.eventStage === "past" &&
      (eventDetails.eventStatus === "iact" ||
        eventDetails.eventStatus === "act")
    ) {
      alert("Past events can't be changed.");
    } else {
      inputValue.userId = userData.user.userId;
      if (!eventId) eventId = common.getLocalData(KEY_EVENT_ID);
      inputValue.eventId = eventId;

      if (inputValue.eventId !== null && inputValue.eventId !== undefined) {
        const result = await SaveParticipantFormMedicalInfo(
          inputValue,
          userData.token
        );

        if (result) {
          if (result.success) {
            common.saveToLocal(KEY_EVENT_ID, result?.data?.eventId);
            eventDetails = result.data;
            saveEventDetails(eventDetails);
            //
            onSave();
          } else {
            alert("Error:-" + result.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        alert("Please complete initial steps.");
      }
    }
  };

  const renderCheckBoxBtn = () => {
    let renderUI = [];
    //
    for (let key in inputValue) {
      if (initialDataTitle[key]?.title) {
        /*  renderUI.push(
          <div class="col-md-6 px-4 mb-3">
            <div class="p-2 border rounded-2">
              {initialDataTitle[key]?.title}
              <div class="ms-5 form-switch form-check-reverse float-end">
                <input
                  class="form-check-input py-2 px-3"
                  type="checkbox"
                  id={key}
                  name={key}
                  value={initialDataTitle[key]?.title}
                  checked={inputValue[key]?.isActive === true}
                  onChange={(event) => openDialog(event, key, inputValue[key])}
                />
              </div>
            </div>
          </div>
        ); */

        //
        renderUI.push(
          <>
            <div class="col-md-6 px-4 mb-3">
              <div class="p-2 border rounded-2 ">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={key}
                    name={key}
                    value={key}
                    checked={inputValue[key]?.isActive === true}
                    onChange={(event) => handleChange(event, "isActive", key)}
                  />
                  <label
                    class="form-check-label font-weight-bold"
                    for="flexSwitchCheckDefault"
                  >
                    {initialDataTitle[key]?.title}
                  </label>
                </div>

                <div class="row align-items-center">
                  <div class="col border rounded p-2 m-2">
                    <div class="form-check ms-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        id={`isMandatory${key}`}
                        name={`rBtnMO${key}`}
                        value="isMandatory"
                        checked={inputValue[key]?.isMandatory === true}
                        onChange={(event) =>
                          handleChange(event, "isMandatory", key)
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Mandatory
                      </label>
                    </div>
                  </div>
                  <div class="col border rounded p-2 m-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id={`isOptional${key}`}
                        name={`rBtnMO${key}`}
                        value="isOptional"
                        checked={
                          inputValue[key]?.isMandatory === true ? false : true
                        }
                        onChange={(event) =>
                          handleChange(event, "isOptional", key)
                        }
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Optional
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }

    return renderUI;
  };
  //
  return (
    <div class="card mb-2">
      <div class="card-body p-1">
        <div class="accordion accordion-flush m-0" id="accordionFlushExample">
          <div class="accordion-item">
            <div>
              <h1 class="my-4">Setup The Form</h1>
              <div class="row">{renderCheckBoxBtn()}</div>
              <div class="my-4 text-center">
                <button
                  type="submit"
                  class="btn btn-select w-100 mb-2"
                  onClick={handleSaveAndProceed}
                >
                  Save &amp; Proceed
                </button>
                <a href="#" onClick={onSkip} class="skip">
                  Skip
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*   <ParticipantFormDialog
        showPopup={showDialog}
        setInitailData={popupData}
        onClose={handleCloseDialog}
        onSuccess={handleSave}
      /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveEventDetails: (eventDetails) =>
    dispatch(storeAction.saveEventDetailsRequest(eventDetails)),
});
export default connect(null, mapDispatchToProps)(MedicalInformaion);
//export default MedicalInformaion;
