import React, { useState } from "react";
import EventCard from "../event/EventCard";

function SliderEvent(props) {
  const [fadedleft, setfadedleft] = useState(true);
  const [fadedright, setfadedright] = useState(false);
  const [start, setstart] = useState(0);
  const [finish, setfinish] = useState(props.sliderProp.finish);
  const [noOfImageRotate, setnoOfImageRotate] = useState(
    props.sliderProp.noOfImageRotate
  );
  //const [cityDetails] = useState(props.city);

  //load data
  const eventData = props.eventData;

  const leftClick = () => {
    if (start > 0 && finish > 0) {
      setstart(start - noOfImageRotate);
      setfinish(finish - noOfImageRotate);
    } else {
      setfadedleft(true);
    }
    setfadedright(false);
  };

  const rightClick = () => {
    if (finish < eventData?.length) {
      setstart(start + noOfImageRotate);
      setfinish(finish + noOfImageRotate);
    } else {
      setfadedright(true);
    }
    setfadedleft(false);
  };

  var startindex = start;
  var finishindex = finish;
  var vfadedleft = fadedleft
    ? props.sliderProp.leftInActive
    : props.sliderProp.leftActive;
  var vfadedright = fadedright
    ? props.sliderProp.rightInActive
    : props.sliderProp.rightActive;

  return (
    <>
      <div className="row align-items-start mx-2 my-3 ffSlider">
      {eventData && eventData.length > 3 && (
        <div className={`pre-btn ${fadedleft ? "hidden" : ""}`}>
          <button
            type="button"
            className="btn btn-link border pre-btn-bg pre-btn"
            onClick={leftClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M14.0117 7.7251L9.01172 12.7251L14.0117 17.7251"
                stroke="#292929"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
        {eventData &&
          eventData.slice(startindex, finishindex).map((event, imageindex) => {
            return (
              <EventCard key={event.eventId} event={event} ref={props.ref} />
            );
          })}
     {eventData && eventData.length > 3 && (
        <div className={`next-btn ${fadedright ? "hidden" : ""}`}>
          <button
            type="button"
            className="btn btn-link border next-btn pre-btn-bg"
            onClick={rightClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M10.7793 17.5103L15.7793 12.5103L10.7793 7.51025"
                stroke="#212B35"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
     )}
      </div>
    </>
  );
}

export default SliderEvent;
