import React, { useState, useEffect } from "react";
import FileUploadSingle from "../../controls/fileUploader/FileUploadSingle";
import * as Call from "../../../utils/validations";
import {
  GetIFSCDetails,
  SetOrganizerBankDetails,
} from "../../../services/userAPI";
import { API_RESONSE_FAILED } from "../../../utils/Messages";
import * as commom from "../../../utils/commonFun";
import { useSelector } from "react-redux";
import { KEY_EVENT_ID } from "../../../utils/Constants";
import * as initialState from "../../../services/initialState";
import InputTextBox from "../../controls/input/InputTextBox";
import Button from "../../controls/buttons/Button";
import Spinner from "../../controls/spinner/Spinner";

let initialData = initialState.OrganizerBankDetails;
let mParam = initialState.OrganizerBankDetails.isRequired;
let ImagePath = process.env.REACT_APP_USERS;

const OrganizerBankDetails = ({
  onStepComplete,
  organizerDetails,
  onChange,
  organizerDisplayMessage,
}) => {
  // const [accountType, setAccountType] = useState("");
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  // const userData = useAuthState();
  const userData = useSelector((loginData) => loginData.login.login);
  const [validationSummary, setValidationSummary] = useState("");
  const [loading, setLoading] = useState(false); //spinner

  const thisStepCompleted = (value) => {
    switch (value) {
      // case 1:
      // case 2:
      case 3:
        return true;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (organizerDetails) {
      const stepNumber = parseInt(
        organizerDetails?.stepCompleted.replace(/\D/g, ""),
        10
      );

      if (thisStepCompleted(stepNumber)) {
        initialData = { ...initialData, ...organizerDetails };
        initialData.cnfAccountNumber = organizerDetails.accountNumber;
        initialData["IsValid"] = mParam;
        setInputValue(initialData);
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const isValid = (name, value) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "bankName":
          if (Call.IsEmpty(value)) {
            msg = "Please enter Bank Name.";
          } else {
            val = Call.IsValidEventName(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "branchName":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Invalid " + value;

          break;

        case "accountHolderName":
          if (Call.IsEmpty(value)) {
            msg = "Please enter account holder name.";
          } else {
            val = Call.IsValidEventName(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "accountNumber":
          if (Call.IsEmpty(value)) {
            msg = "Please enter account number.";
          } else {
            val = Call.IsValidCharNumWithSpace(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "cnfAccountNumber":
          if (Call.IsEmpty(value)) {
            msg = "Please enter confirm account number.";
          } else {
            val = Call.IsValidCharNumWithSpace(value);
            if (!val) msg = "Invalid " + value;
          }
          break;

        case "accountType":
          val = Call.IsValidAccountType(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "ifsc":
          val = Call.IsValidIFSCCode(value);
          if (!val) msg = "Invalid " + value;
          break;

        default:
          break;
      }
    }

    //if(!val && msg.length>0) {
    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
    //}
    return val;
  };
  const setInputState = (name, value, msg, val) => {
    //set value
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
  };

  const handleCancledFileSelection = (file) => {
    //inputValue.bankProofDoc = file;
    setInputState("bankProofDoc", file, "", true);
    if (validationSummary !== "") {
      setValidationSummary("");
    }
  };

  const verifyIFSC = async () => {
    if (inputValue.ifsc) {
      setLoading(true);
      const ifscResponse = await GetIFSCDetails(inputValue.ifsc);
      if (ifscResponse) {
        const bankNameIs = ifscResponse.BANK;
        const branchNameIs = ifscResponse.BRANCH;

        setInputValue((prev) => ({
          ...prev,
          ["bankName"]: bankNameIs,
          ["branchName"]: branchNameIs,
          errors: { ...errors, ["bankName"]: "", ["branchName"]: "" },
          IsValid: { ...IsValid, ["bankName"]: true, ["branchName"]: true },
        }));
      } else {
        alert("Please enter valid IFSC code.");
      }
      setLoading(false);
    } else {
      alert("Please enter IFSC code.");
    }
  };

  const saveAndProceed = async (e) => {
    e.preventDefault();
    if (
      IsValid &&
      IsValid.accountHolderName &&
      IsValid.ifsc &&
      IsValid.bankName &&
      IsValid.branchName &&
      IsValid.accountType &&
      IsValid.accountNumber &&
      IsValid.cnfAccountNumber &&
      inputValue.bankProofDoc !== ""
    ) {
      if (inputValue.accountNumber == inputValue.cnfAccountNumber) {
        inputValue.userId = userData.login.user.userId;
        inputValue.eventId = commom.getLocalData(KEY_EVENT_ID);

        //send data to server
        setLoading(true);
        const result = await SetOrganizerBankDetails(
          inputValue,
          userData.login.token
        );
        setLoading(false);
        // //next step
        if (result) {
          if (result?.success) {
            onChange(result?.data);
            organizerDisplayMessage(result?.message);
            onStepComplete(3);
          } else {
            alert("Error:-" + result?.message);
          }
        } else {
          alert(API_RESONSE_FAILED);
        }
      } else {
        alert("Please enter correct account number.");
      }
    } else {
      // alert("Enter required fields");

      let displaMsg = "";
      if (!inputValue?.IsValid["accountHolderName"]) {
        displaMsg += "<li>" + "Account Holder Name" + "</li>";
      }

      if (!inputValue?.IsValid["ifsc"]) {
        displaMsg += "<li>" + "IFSC Code" + "</li>";
      }

      if (!inputValue?.IsValid["bankName"]) {
        displaMsg += "<li>" + "Bank Name" + "</li>";
      }

      if (!inputValue?.IsValid["branchName"]) {
        displaMsg += "<li>" + "Branch Name" + "</li>";
      }

      if (!inputValue?.IsValid["accountType"]) {
        displaMsg += "<li>" + "Account Type" + "</li>";
      }

      if (!inputValue?.IsValid["accountNumber"]) {
        displaMsg += "<li>" + "Account Number" + "</li>";
      }

      if (!inputValue?.IsValid["cnfAccountNumber"]) {
        displaMsg += "<li>" + "Confirm Account Number" + "</li>";
      }

      if (inputValue.bankProofDoc == "") {
        displaMsg += "<li>" + "Bank Proof Doc" + "</li>";
      }

      displaMsg =
        "<b>Please enter all required fields</b><ul>" + displaMsg + "</ul>";
      setValidationSummary(() => displaMsg);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div class="card p-4 organizer-logo my-3">
            {/* <h5>Bank Account Details</h5> */}
            <form>
              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label  ">
                  Account Holder Name<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="accountHolderName"
                  name="accountHolderName"
                  type="text"
                  placeholder="Please Enter Account Holder Name"
                  isValid={inputValue?.IsValid?.accountHolderName}
                  errorMsg={inputValue?.errors?.accountHolderName}
                  value={inputValue.accountHolderName}
                  onChange={handleChange}
                  autoFocus
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  IFSC Code<span class="text-danger">*</span>
                </label>

                <div class="input-group">
                  <InputTextBox
                    id="ifsc"
                    name="ifsc"
                    type="text"
                    placeholder="Enter IFSC Code"
                    isValid={inputValue?.IsValid?.ifsc}
                    errorMsg={inputValue?.errors?.ifsc}
                    value={inputValue.ifsc}
                    onChange={handleChange}
                    maxlength={11}
                  />
                  <label
                    class="input-group-text"
                    for="inputGroupFile02"
                    onClick={verifyIFSC}
                  >
                    <i class="bi mx-1 select-upload mt-1"></i>
                    Verify
                  </label>
                </div>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Bank Name<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="bankName"
                  name="bankName"
                  type="text"
                  placeholder="Please Enter Bank Name"
                  isValid={inputValue?.IsValid?.bankName}
                  errorMsg={inputValue?.errors?.bankName}
                  value={inputValue.bankName}
                  onChange={handleChange}
                  maxlength={30}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Branch Name<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="branchName"
                  name="branchName"
                  type="text"
                  placeholder="Please Enter Branch Name"
                  isValid={inputValue?.IsValid?.branchName}
                  errorMsg={inputValue?.errors?.branchName}
                  value={inputValue.branchName}
                  onChange={handleChange}
                  maxlength={40}
                />
              </div>

              <div class="my-3 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Account Type<span class="text-danger">*</span>
                </label>
                <select
                  class="form-select p-2"
                  aria-label="Default select example"
                  value={inputValue.accountType}
                  onChange={handleChange}
                  name="accountType"
                >
                  <option selected="">Select Account Type</option>
                  <option value="Saving">Saving</option>
                  <option value="Current">Current</option>
                </select>
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Account Number<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="accountNumber"
                  name="accountNumber"
                  type="text"
                  placeholder="Please Enter Account Number."
                  isValid={inputValue?.IsValid?.accountNumber}
                  errorMsg={inputValue?.errors?.accountNumber}
                  value={inputValue.accountNumber}
                  onChange={handleChange}
                  maxlength={20}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <label for="exampleInputEmail1" class="form-label">
                  Confirm Account Number<span class="text-danger">*</span>
                </label>

                <InputTextBox
                  id="cnfAccountNumber"
                  name="cnfAccountNumber"
                  type="text"
                  placeholder="Please Reenter Account Number"
                  isValid={inputValue?.IsValid?.cnfAccountNumber}
                  errorMsg={inputValue?.errors?.cnfAccountNumber}
                  value={inputValue.cnfAccountNumber}
                  onChange={handleChange}
                  maxlength={20}
                />
              </div>

              <div class="my-3 w-100 text-start">
                <FileUploadSingle
                  id="bankProofDoc"
                  name="bankProofDoc"
                  getFileDetail={handleCancledFileSelection}
                  title={"Bank proof upload document"}
                >
                  fileData
                </FileUploadSingle>
                {typeof inputValue?.bankProofDoc === "string" &&
                  inputValue?.bankProofDoc && (
                    <div class="alert alert-info" role="alert">
                      <img
                        src={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/bankProofDoc/" +
                          inputValue.bankProofDoc
                        }
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100px",
                          marginRight: "2px",
                          padding: "2px",
                        }}
                      />
                      <a
                        href={
                          ImagePath +
                          userData?.login?.user?.userId +
                          "/bankProofDoc/" +
                          inputValue.bankProofDoc
                        }
                        target="_blank"
                      >
                        Click to download ({inputValue.bankProofDoc})
                      </a>
                    </div>
                  )}
              </div>
            </form>
            <div class="mb-3 text-center">
              <Button onClick={saveAndProceed}>Save & Proceed</Button>
            </div>
            {validationSummary !== "" && (
              <div
                class="alert alert-danger"
                role="alert"
                dangerouslySetInnerHTML={{ __html: validationSummary }}
              ></div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrganizerBankDetails;
