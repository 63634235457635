import React from "react";
import Tabs from "./Tabs";
import { useSelector } from "react-redux";
import EventDetails from "../../../components/createEvent/eventDetails/EventDetails";
import EventLocation from "../../../components/createEvent/eventDetails/EventLocation";
import EventPhotos from "../../../components/createEvent/eventDetails/EventPhotos";
import TicketDetails from "../../../components/createEvent/eventDetails/TicketDetails";
import Success from "../../../components/createEvent/eventDetails/Success";
//
const EventDetailsStepTabs = ({ eventDetails, onStepComplete }) => {
  const userData = useSelector((loginData) => loginData.login.login);
  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  }
  const handleStepComplete = (index) => {
    if (index > 0 && index < 5) {
      document.getElementById("tabCreateEventStep" + index).click();
    }
  };

  let stepCompleted = 0;
  if (eventDetails?.eventStatus === "inserting") {
    stepCompleted = 1;
    if (eventDetails?.stepCompleted === "E1.2") stepCompleted = 2;
    if (eventDetails?.stepCompleted === "E1.3") stepCompleted = 3;
    if (eventDetails?.stepCompleted === "E1.4") stepCompleted = 4;
  } else if (
    eventDetails?.eventStatus === "inserted" ||
    eventDetails?.eventStatus === "act"
  ) {
    stepCompleted = 4;
  }
  //
  const config = {
    isUserLogin: isUserLogin,
    loginMessage: "Please login ",
    stepCompleted: stepCompleted,
    tabId: "tabCreateEventStep",
    data: [
      {
        title: "Event Details",
        showStep: true,
        //isStepCompleted:true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <EventDetails
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
      },
      {
        title: "Event Location",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <EventLocation
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
      },
      {
        title: "Event Photos",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <EventPhotos
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
      },
      {
        title: "Ticket Details",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <TicketDetails
            eventDetails={eventDetails}
            onStepComplete={handleStepComplete}
          />
        ),
      },
      {
        title: "Completed",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: <Success eventDetails={eventDetails} />,
      },
    ],
    className: {
      mainContainer: "",
      tabsContainer: "row align-items-start my-4 event-proce pl-3",
      activeTabContainer: "org-main-contain ", //"tab-content card p-4",
    },
  };
  //
  //
  return (
    <>
      <Tabs config={config} />
    </>
  );
};

export default EventDetailsStepTabs;
