import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Call from "../../utils/validations";
import StateDropdown from "../../components/controls/dropdown/StateDropdown";
import InputTextBox from "../../components/controls/input/InputTextBox";
import Button from "../../components/controls/buttons/Button";
import FileUploadSingle from "../controls/fileUploader/FileUploadSingle";
import ShowDatePicker from "../controls/date/ShowDatePicker";
import CityByStateIdDropdown from "../controls/dropdown/CityByStateIdDropdown";

let initialData = {
  eventId: 0,
  ticketId: 0,
  ticketType: "",
  gender: "",
  fullName: "",
  email: "",
  mobile: "",
  dob: "",
  tShirtSize: "",
  clubName: "",
  country: "",
  state: "",
  city: "",
  address: "",
  pincode: "",
  idProofType: "",
  emergencyContactName: "",
  emergencyContactNumber: "",
  // "Spouse", "Parent", "Sibling", "Friend", "Other"
  emergencyContactRelation: "", // NA
  bloodGroup: "",
  participantPhoto: "",
  idProof: "",
  timingCertificate: "",
  fitnessCertificate: "",
  isPrimary: false,
  errors: {},
  IsValid: {},
  isRequired: {
    fullName: true,
    mobile: true,
    email: true,
  },
};

let ImagePath = process.env.REACT_APP_EVENTS;
let mParam = initialData.isRequired;
const CheckoutForm = ({
  ticket,
  index,
  onDetailEntered,
  eventDetailsData,
  inputValueList,
}) => {
  const userData = useSelector((state) => state.login.login.login);
  const eventUserDetails = useSelector(
    (state) => state.events.userDetails.userDetails
  );

  //
  const [inputValue, setInputValue] = useState(initialData);
  //const [inputValueList, setInputValueList] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const { errors, IsValid } = inputValue;
  const [genderIs, setGenderIs] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  // const [dateOfBirth, setDateOfBirth] = useState(null);

  if (!inputValueList[index] && inputValue.eventId === 0) {
    if (ticket) {
      initialData.eventId = ticket.eventId;
      initialData.ticketId = ticket.ticketId;
      initialData.ticketType = ticket.ticketType;
    }
    if (userData?.user && index === 0) {
      initialData.fullName = userData.user.fullName;
      initialData.email = userData.user.email;

      if (userData?.user?.mobile) {
        if (userData?.user?.mobile?.length === 12) {
          initialData.mobile = userData.user.mobile.substring(2, 12);
        } else {
          initialData.mobile = userData?.user?.mobile;
        }
      } else {
        initialData.mobile = "";
        initialData["IsValid"] = mParam.mobile = false;
      }
      if (eventDetailsData?.participantForm?.isDob && userData?.userProfile) {
        initialData.dob = userData.user.dob;
      }
      if (eventDetailsData?.participantForm?.isGender && userData?.userProfile) {
        setGenderIs(userData.userProfile.gender);
        inputValue.gender = userData.userProfile.gender;
      }
      if (eventDetailsData?.participantForm?.isTshirtSize && userData?.userProfile) {
        initialData.tShirtSize = userData.userProfile.tShirtSize;
      }
      if (eventDetailsData?.participantForm?.isClubName && userData?.userProfile) {
        initialData.clubName = userData.userProfile.clubName;
      }

      /**
       * To get city of the selected state we need statId,
       * and in case of user profile we are storing state name directly.
       * Because of this issue we are not able to populate State & City in Checkout form.
       */

      // initialData.state = userData.userProfile.state;
      // initialData.city = userData.userProfile.city;

      if (eventDetailsData?.participantForm?.isAddress && userData?.userProfile) {
        let addressIs = "";
        if (userData.userProfile.addressLine1 !== null) {
          addressIs = userData.userProfile.addressLine1 + " ";
        }
        if (userData.userProfile.addressLine2 !== null) {
          addressIs = addressIs + userData.userProfile.addressLine2 + " ";
        }
        if (userData.userProfile.landmark !== null) {
          addressIs = addressIs + userData.userProfile.landmark;
        }
        initialData.address = addressIs;
      }

      if (eventDetailsData?.participantForm?.isPincode && userData?.userProfile) {
        initialData.pincode = userData.userProfile.pincode;
      }
      if (eventDetailsData?.participantForm?.isEmergencyContact && userData?.userProfile) {
        initialData.emergencyContactName =
          userData.userProfile.emergencyContactName;
        initialData.emergencyContactNumber =
          userData.userProfile.emergencyContactNumber;
        initialData.emergencyContactRelation =
          userData.userProfile.emergencyContactRelation;
      }
      if (eventDetailsData?.participantForm?.isBloodGroup && userData?.userProfile) {
        initialData.bloodGroup = userData.userProfile.bloodGroup;
      }

      initialData["IsValid"] = mParam;
    }
    setInputValue(() => initialData);
  }
  useEffect(() => {
    if (inputValueList[index]?.participantId > 0) {
      initialData = { ...inputValueList[index] };
      initialData["IsValid"] = mParam;
      initialData["errors"] = {};

      setInputValue(() => initialData);
    }
  }, [inputValueList[index]?.participantId > 0]);
  /*   
  //
  useEffect(() => {
    //store data
    if (eventUserDetails?.length > 0) {
      if (eventUserDetails[index]) {
        initialData = { ...initialData, ...eventUserDetails[index] };
        if (eventUserDetails[index]?.mobile?.length === 12) {
          initialData.mobile = eventUserDetails[index]?.mobile?.substring(
            2,
            12
          );
        }
      }

      initialData["IsValid"] = mParam;
      setInputValue(() => initialData);
      setInputValueList(() => eventUserDetails);
    }
  }, [eventUserDetails?.length > 0]); */

  //methods
  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setStateId(stateId);
    inputValue.state = event.target.options[event.target.selectedIndex].text;
  };

  const handleCityChange = (event) => {
    let cityId = event.target.value;
    setCityId(cityId);
    inputValue.city = event.target.options[event.target.selectedIndex].text;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      //show error

      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    console.log(inputValue);
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "fullName":
          val = Call.IsValidCharWithSpace(value);
          if (!val) msg = "Invalid " + value;
          break;
        case "email":
          val = Call.IsValidEmail(value);
          if (!val) msg = "Invalid " + value;
          break;
        case "mobile":
          val = Call.IsValidMobileNo(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 10 digit number ";
          }
          break;

        case "pincode":
          val = Call.IsValidPinCode(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 6 digit pincode ";
          }
          break;

        case "address":
          val = !Call.IsEmpty(value);
          if (!val) msg = "Please enter address";
          break;

        case "emergencyContactName":
          val = Call.IsValidCharWithSpace(value);
          if (!val) msg = "Invalid " + value;
          break;

        case "emergencyContactNumber":
          val = Call.IsValidMobileNo(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 10 digit number ";
          }
          break;

        case "clubName":
          val = Call.IsValidCharWithSpace(value);
          if (!val) msg = "Invalid " + value;
          break;

        default:
          break;
      }
    }

    //if (val)
    // {
    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));
    // }

    return val;
  };

  const handleDateOfBirth = (date) => {
    setInputValue((prev) => ({
      ...prev,
      ["dob"]: date,
    }));
  };

  const handleUploadPhoto = (file) => {
    inputValue.participantPhoto = file;
  };

  const handleIdProofFile = (file) => {
    inputValue.idProof = file;
  };

  const handleTimingCertificatefFile = (file) => {
    inputValue.timingCertificate = file;
  };

  const handleFitnessCertificateFile = (file) => {
    inputValue.fitnessCertificate = file;
  };

  const handleGenderSelection = (e) => {
    // e.preventDefault();
    setGenderIs(e.target.value);
    inputValue.gender = e.target.value;
  };

  const handleSaveUserProfile = (e) => {
    e.preventDefault();

    // setIsLoading(true);
    if (IsValid && IsValid.fullName && IsValid.email && IsValid.mobile) {
      if (
        eventDetailsData?.participantForm?.isDob.isMandatory &&
        inputValue.dob === ""
      ) {
        alert("Enter Date of birth.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isGender.isMandatory &&
        inputValue.gender === ""
      ) {
        alert("Please select gender.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isUploadPhoto.isMandatory &&
        inputValue.participantPhoto === ""
      ) {
        alert("Please upload photo.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isTshirtSize.isMandatory &&
        inputValue.tShirtSize === ""
      ) {
        alert("Select T-Shirt size.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isClubName.isMandatory &&
        inputValue.clubName === ""
      ) {
        alert("Enter club name.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isState.isMandatory &&
        inputValue.state === ""
      ) {
        alert("Select state.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isCity.isMandatory &&
        inputValue.city === ""
      ) {
        alert("Select city.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isAddress.isMandatory &&
        inputValue.address === ""
      ) {
        alert("Select address.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isPincode.isMandatory &&
        inputValue.pincode === ""
      ) {
        alert("Enter Pincode.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isIdProof.isMandatory &&
        inputValue.idProof === ""
      ) {
        alert("Upload Id Proof.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isBloodGroup.isMandatory &&
        inputValue.bloodGroup === ""
      ) {
        alert("Select Blood Group.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isEmergencyContact.isMandatory &&
        inputValue.emergencyContactName === "" &&
        inputValue.emergencyContactNumber === "" &&
        inputValue.emergencyContactRelation === ""
      ) {
        alert("Select emergency contact details.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isTimingCertificate.isMandatory &&
        inputValue.timingCertificate === ""
      ) {
        alert("Select timing certificate.");
        setIsLoading(false);
        return;
      }

      if (
        eventDetailsData?.participantForm?.isFitnessCertificate?.isMandatory &&
        inputValue.fitnessCertificate === ""
      ) {
        alert("Upload fitness certificate.");
        setIsLoading(false);
        return;
      }

      // inputValue.state =
      let newtid = inputValueList.length + 1;
      let pType = "newParticipant";
      inputValue.ticketId = ticket.ticketId;
      inputValue.ticketType = ticket.ticketType;
      inputValue.isPrimary = false;
      //let newTicket = [{ ...inputValue, userProfileId: newtid, pType: pType }];
      let newTicket = { ...inputValue, userProfileId: newtid, pType: pType };
      //old particiepnt data update db
      if (inputValue?.participantId > 0) {
        newTicket = { ...inputValue, userProfileId: inputValue?.participantId };
      }
      // let templist = [];
      onDetailEntered(index, newTicket);
    } else {
      alert("Enter required fields");
    }
    setIsLoading(false);
    //show next user profile preview
  };

  //
  return (
    <div class="card p-3">
      <form>
        <div class="mb-3 text-start">
          <label for="exampleInputEmail1" class="form-label text-start">
            Full Name
            <span class="text-danger">*</span>
          </label>
          <InputTextBox
            id="fullName"
            name="fullName"
            placeholder="Please enter full name"
            value={inputValue.fullName}
            isValid={inputValue?.IsValid?.fullName}
            errorMsg={inputValue?.errors?.fullName}
            onChange={handleChange}
          />
        </div>
        <div class="mb-3 text-start">
          <label for="exampleInputEmail2" class="form-label text-start">
            Email Id
            <span class="text-danger">*</span>
          </label>
          <InputTextBox
            id="email"
            name="email"
            type="email"
            placeholder="Please enter email"
            value={inputValue.email}
            isValid={inputValue?.IsValid?.email}
            errorMsg={inputValue?.errors?.email}
            onChange={handleChange}
          />
        </div>
        <div class="mb-3 text-start">
          <label for="exampleInputEmail2" class="form-label text-start">
            Mobile No.
            <span class="text-danger">*</span>
          </label>
          <div class="input-group">
            <InputTextBox
              id="mobile"
              name="mobile"
              type="text"
              placeholder="Please enter Mobile No"
              value={inputValue.mobile}
              isValid={inputValue?.IsValid?.mobile}
              errorMsg={inputValue?.errors?.mobile}
              onChange={handleChange}
              maxlength={10}
              prefixText="+91"
              className="numberWithoutArrowkey"
            />
          </div>
        </div>

        {eventDetailsData?.participantForm?.isDob ? (
          <div class="mb-3 text-start">
            <label class="" for="inlineFormInputGroupUsername">
              Date of birth
              {eventDetailsData?.participantForm?.isDob.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <div className="input-group border rounded-2">
              <ShowDatePicker
                maxDate={new Date()}
                minDate={new Date("1900-00-00")}
                curDate={inputValue.dob}
                onDateChange={handleDateOfBirth}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isGender ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label w-100">
              Gender
              {eventDetailsData?.participantForm?.isGender.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="Male"
                checked={genderIs === "Male"}
                onChange={handleGenderSelection}
              />
              <label class="form-check-label" for="inlineRadio1">
                Male
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="Female"
                checked={genderIs === "Female"}
                onChange={handleGenderSelection}
              />
              <label class="form-check-label" for="inlineRadio2">
                Female
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio3"
                value="Other"
                checked={genderIs === "Other"}
                onChange={handleGenderSelection}
              />
              <label class="form-check-label" for="inlineRadio3">
                Other
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isUploadPhoto ? (
          <div className="mb-3 text-start">
            <FileUploadSingle
              getFileDetail={handleUploadPhoto}
              title={
                eventDetailsData?.participantForm?.isUploadPhoto.isMandatory ? (
                  <>
                    Upload Photo <span class="text-danger">*</span>
                  </>
                ) : (
                  "Upload Photo"
                )
              }
            >
              fileData
            </FileUploadSingle>
            {inputValue?.permission && (
              <div class="alert alert-info" role="alert">
                <a
                  href={
                    ImagePath +
                    inputValue.eventId +
                    "/permission/" +
                    inputValue.permission
                  }
                  target="_blank"
                >
                  Click to download ({inputValue.permission})
                </a>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isTshirtSize ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              Select T-Shirt size
              {eventDetailsData?.participantForm?.isTshirtSize?.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <select
              class="form-select p-2"
              aria-label="Select T-Shirt size."
              value={inputValue.tShirtSize}
              onChange={handleChange}
              name="tShirtSize"
            >
              <option selected="">Select T-Shirt Size</option>
              "XS", "S", "M", "L", "XL", "XXL"'
              <option value="XS">Extra Small</option>
              <option value="S">Small</option>
              <option value="M">Medium</option>
              <option value="L">Large</option>
              <option value="XL">Extra Large</option>
              <option value="XXL">Extra Extra Large</option>
            </select>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isClubName ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail2" class="form-label">
              Running Club Name
              {eventDetailsData?.participantForm?.isClubName.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <div class="input-group">
              <InputTextBox
                id="clubName"
                name="clubName"
                type="text"
                placeholder="Please enter Running Club Name"
                isValid={inputValue?.IsValid?.clubName}
                errorMsg={inputValue?.errors?.clubName}
                onChange={handleChange}
                value={inputValue.clubName}
                maxlength={40}
                className="numberWithoutArrowkey"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isState ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              State(India){" "}
              {eventDetailsData?.participantForm?.isState.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <StateDropdown
              name="state"
              stateId={stateId}
              onStateChange={handleStateChange}
            />
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isCity ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              City{" "}
              {eventDetailsData?.participantForm?.isCity.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <CityByStateIdDropdown
              name="city"
              stateId={stateId}
              cityId={cityId}
              onCityChange={handleCityChange}
            />
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isAddress ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              Address
              {eventDetailsData?.participantForm?.isAddress.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <InputTextBox
              id="address"
              name="address"
              placeholder="Please enter address"
              isValid={inputValue?.IsValid?.address}
              errorMsg={inputValue?.errors?.address}
              value={inputValue.address}
              onChange={handleChange}
            />
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isPincode ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              PinCode
              {eventDetailsData?.participantForm?.isPincode.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <InputTextBox
              id="pincode"
              name="pincode"
              type="text"
              placeholder="Please enter PinCode"
              isValid={inputValue?.IsValid?.pincode}
              errorMsg={inputValue?.errors?.pincode}
              value={inputValue.pincode}
              onChange={handleChange}
              maxlength={6}
            />
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isIdProof ? (
          <div>
            <div class="mb-3 text-start">
              <label for="exampleInputEmail1" class="form-label">
                Select ID Proof Type
                {eventDetailsData?.participantForm?.isIdProof.isMandatory ? (
                  <span class="text-danger"></span>
                ) : (
                  <></>
                )}
              </label>
              <select
                class="form-select p-2"
                aria-label="Default select example"
                value={inputValue.idProofType}
                onChange={handleChange}
                name="idProofType"
              >
                <option selected="">ID Proof Type</option>
                <option value="Driver's License">Driver's License</option>
                <option value="Aadhar">Aadhar</option>
                <option value="Pan">PAN Card</option>
                <option value="Election Card">Election Card</option>
              </select>
            </div>

            <div className="mb-3 text-start">
              <FileUploadSingle
                getFileDetail={handleIdProofFile}
                title={
                  eventDetailsData?.participantForm?.isUploadPhoto
                    .isMandatory ? (
                    <>
                      Upload Id Proof <span class="text-danger"></span>
                    </>
                  ) : (
                    "Upload Id Proof"
                  )
                }
              >
                fileData
              </FileUploadSingle>
              {inputValue?.permission && (
                <div class="alert alert-info" role="alert">
                  <a
                    href={
                      ImagePath +
                      inputValue.eventId +
                      "/permission/" +
                      inputValue.permission
                    }
                    target="_blank"
                  >
                    Click to download ({inputValue.permission})
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isTimingCertificate?.isActive ? (
          <div className="mb-3 text-start">
            <FileUploadSingle
              getFileDetail={handleTimingCertificatefFile}
              title={
                eventDetailsData?.participantForm?.isTimingCertificate
                  .isMandatory ? (
                  <>
                    Upload Timing Certificate <span class="text-danger">*</span>
                  </>
                ) : (
                  "Upload Timing Certificate"
                )
              }
            >
              fileData
            </FileUploadSingle>
            {inputValue?.permission && (
              <div class="alert alert-info" role="alert">
                <a
                  href={
                    ImagePath +
                    inputValue.eventId +
                    "/permission/" +
                    inputValue.permission
                  }
                  target="_blank"
                >
                  Click to download ({inputValue.permission})
                </a>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isEmergencyContact?.isActive ? (
          <div>
            <div class="mb-3 text-start">
              <label for="exampleInputEmail1" class="form-label">
                Emergency Contact Name
                {eventDetailsData?.participantForm?.isEmergencyContact
                  .isMandatory ? (
                  <span class="text-danger">*</span>
                ) : (
                  <></>
                )}
              </label>
              <InputTextBox
                id="emergencyContactName"
                name="emergencyContactName"
                placeholder="Please enter emergency Contact Name"
                isValid={inputValue?.IsValid?.emergencyContactName}
                errorMsg={inputValue?.errors?.emergencyContactName}
                value={inputValue.emergencyContactName}
                onChange={handleChange}
              />
            </div>

            <div class="mb-3 text-start">
              <label for="exampleInputEmail1" class="form-label">
                Emergency Contact Relation
                {eventDetailsData?.participantForm?.isEmergencyContact
                  .isMandatory ? (
                  <span class="text-danger">*</span>
                ) : (
                  <></>
                )}
              </label>
              <select
                class="form-select p-2"
                aria-label="Default select example"
                value={inputValue.emergencyContactRelation}
                onChange={handleChange}
                name="emergencyContactRelation"
              >
                <option selected="">Select relation</option>
                <option value="Spouse">Spouse</option>
                <option value="Parent">Parent</option>
                <option value="Sibling">Sibling</option>
                <option value="Friend">Friend</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="mb-3 text-start">
              <label for="exampleInputEmail2" class="form-label">
                Emergency Mobile No.
                {eventDetailsData?.participantForm?.isEmergencyContact
                  .isMandatory ? (
                  <span class="text-danger">*</span>
                ) : (
                  <></>
                )}
              </label>
              <div class="input-group">
                <InputTextBox
                  id="emergencyContactNumber"
                  name="emergencyContactNumber"
                  type="text"
                  placeholder="Please enter Mobile No"
                  isValid={inputValue?.IsValid?.emergencyContactNumber}
                  errorMsg={inputValue?.errors?.emergencyContactNumber}
                  onChange={handleChange}
                  value={inputValue?.emergencyContactNumber}
                  maxlength={10}
                  prefixText="+91"
                  className="numberWithoutArrowkey"
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isBloodGroup ? (
          <div class="mb-3 text-start">
            <label for="exampleInputEmail1" class="form-label">
              Select Blood Group
              {eventDetailsData?.participantForm?.isBloodGroup.isMandatory ? (
                <span class="text-danger">*</span>
              ) : (
                <></>
              )}
            </label>
            <select
              class="form-select p-2"
              aria-label="Default select example"
              value={inputValue.bloodGroup}
              onChange={handleChange}
              name="bloodGroup"
            >
              <option selected="">Select Blood Group</option>
              <option value="A+">A +</option>
              <option value="O+">O +</option>
              <option value="B+">B +</option>
              <option value="AB+">AB +</option>
              <option value="A-">A -</option>
              <option value="O-">O -</option>
              <option value="B-">B -</option>
              <option value="AB-">AB -</option>
            </select>
          </div>
        ) : (
          <></>
        )}

        {eventDetailsData?.participantForm?.isFitnessCertificate?.isActive ? (
          <div className="mb-3 text-start">
            <FileUploadSingle
              getFileDetail={handleFitnessCertificateFile}
              title={
                eventDetailsData?.participantForm?.isFitnessCertificate
                  ?.isMandatory ? (
                  <>
                    Upload Medical Certificate{" "}
                    <span class="text-danger">*</span>
                  </>
                ) : (
                  "Upload Medical Certificate"
                )
              }
            >
              fileData
            </FileUploadSingle>
            {inputValue?.permission && (
              <div class="alert alert-info" role="alert">
                <a
                  href={
                    ImagePath +
                    inputValue.eventId +
                    "/permission/" +
                    inputValue.permission
                  }
                  target="_blank"
                >
                  Click to download ({inputValue.permission})
                </a>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        <div class=" text-center">
          <Button loading={isLoading} onClick={handleSaveUserProfile}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
