import { put, call, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/Login";
import * as userAPI from "../../services/userAPI";
import * as comFun from "../../utils/commonFun";
import * as constants from "../../utils/Constants";
import * as Call from "../../utils/validations";

//Logins worker sagas
function* fetchLoginWorker() {
  // console.log("saga call for fetchLoginsWorker");
  try {
    const loginResponse = yield call(
      comFun.getLocalData,
      constants.LOCAL_STORAGE_USER
    );

    yield put(actions.fetchLoginSuccess(loginResponse));
  } catch (error) {
    console.error(error);
  }
}

function* addLoginWorker(action) {
  // console.log("saga call for addLoginWorker ");

  let result;
  try {
    const { update } = action.payload;
    if (update) {
      let loginResponse1 = action.payload;
      // console.log("loginResponse1", loginResponse1);
      delete loginResponse1["update"];
      loginResponse1 = JSON.stringify(loginResponse1);
      yield call(
        comFun.saveToLocal,
        constants.LOCAL_STORAGE_USER,
        loginResponse1
      );
      //yield put(actions.addToLoginSuccess(loginResponse1));
      //result = yield put(actions.fetchLoginSuccess(loginResponse1));
      return result;
    }
    const token = action.payload;

    if (!Call.IsEmpty(token)) {
      let loginResponse = yield call(userAPI.Login, token);
      loginResponse = JSON.stringify(loginResponse.data);
      yield call(
        comFun.saveToLocal,
        constants.LOCAL_STORAGE_USER,
        loginResponse
      );
      yield put(actions.addToLoginSuccess(loginResponse));
      result = yield put(actions.fetchLoginSuccess(loginResponse));
    }
  } catch (error) {
    console.error(error);
  }
  return result;
}

function* deleteLoginWorker(action) {
  // console.log("saga call for deleteLoginWorker ");
  try {
    //yield call(userAPI.DeleteFromLogin, action.payload);
    yield put(actions.deleteFromLoginSuccess(action.payload));
  } catch (error) {
    console.error(error);
  }
}

//Logins Watcher Sagas
export function* watchFetchLogin() {
  // console.log("watchFetchLogins saga call2");
  yield takeEvery(actions.FETCH_LOGIN_REQUEST, fetchLoginWorker);
}

export function* watchAddLogin() {
  // console.log("watchAddLogins saga call2");
  yield takeEvery(actions.ADD_LOGIN_REQUEST, addLoginWorker);
}

export function* watchDeleteLogin() {
  // console.log("watchDeleteLogin saga call2");
  yield takeEvery(actions.DELETE_LOGIN_REQUEST, deleteLoginWorker);
}
