import React from "react";
import { useSelector } from "react-redux";
import OrganizerMaster from "../master/OrganizerMaster";
import ActiveEventCard from "../../components/activeEvents/ActiveEventCard";
import ShowLoginMsg from "../../components/login/ShowLoginMsg";

//
const ActiveEventList = () => {
  //
  const userData = useSelector((loginData) => loginData.login.login);
  let isUserLogin = false;
  if (userData?.login?.user) {
    isUserLogin = true;
  }
  //
  return (
    <OrganizerMaster>
      <div>
      <div class="col">
              <h3 class="font-weight-semibold">Active Events</h3>
            </div>

        {isUserLogin === true ? <ActiveEventCard /> : <ShowLoginMsg />}
      </div>
    </OrganizerMaster>
  );
};

export default ActiveEventList;
