import React, { useState } from "react";

//main function
const PrivacyPolicy = () => {
  //declare start
  const [showMore, setShowMore] = useState(false);

  //declare end

  //functions/events/methods
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  //main retrun
  return (
    <div style={{ margin: "30px" }}>
      <div>
        <p
          className="s2"
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "16pt",
          }}
        >
          Introduction
        </p>
        <p
          className="s4"
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
            fontSize: "11pt",
          }}
        >
          Vrattanta Endurance and Sports is the parent company of FitFunda.co.in
          and FitFunda.in, as well as other affiliates in event management and
          sports technology (referred to as 'Vrattanta,' 'we,' 'us,' or 'our').
          This policy explains how we collect, use, and protect your personal
          information when you interact with us through our websites or other
          business activities. By using our websites (
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a href="http://www.fitfunda.co/" className="s3" target="_blank">
              www.fitfunda.co{" "}
            </a>
          </span>
          and
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a
              href="http://www.fitfunda.co.in/"
              className="s3 ml-1"
              target="_blank"
            >
              www.fitfunda.co.in
            </a>
          </span>
          ,) and
          <span
            style={{
              color: "#1154CC",
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "underline",
              fontSize: "11pt",
            }}
          >
            <a
              href="http://www.vrattanta.com/"
              className="s3 ml-1 mr-1"
              target="_blank"
            >
              www.Vrattanta.com
            </a>
          </span>
          <span style={{ color: "#000", fontSize: "11pt" }}>
            or interacting with us in any way, you agree to this policy. If you
            don’t agree with our terms, we ask you to stop using our services or
            websites.
          </span>
        </p>

        {showMore && (
          <>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "16pt",
              }}
            >
              Our Commitment
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
                fontSize: "11pt",
              }}
            >
              We take your privacy seriously and are committed to protecting
              your personal data. We comply with various data protection laws
              and best practices to keep your information safe. If we change how
              we handle your information, we will inform you, typically through
              email.
              <br />
              <br />
              <strong>What we collect</strong>
              <br />
              <br />
              We collect the following types of information from you:
              <ol id="l">
                <li data-list-text={1}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Personal Information:</strong> Includes your name,
                    contact information, and any other details you provide when
                    you:
                    <ul id="l4">
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Fill out a demo request or job application.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Create an account with us.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Interact with our services (e.g., using FitFunda).
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>

                <li data-list-text={2}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Technology-Related Data:</strong> Automatically
                    collected when you use our websites, such as:
                    <ul id="l4">
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Your IP address, device details, and browser type.
                        </p>
                      </li>
                      <li data-list-text="○">
                        <p
                          style={{
                            paddingTop: "1pt",
                            paddingLeft: "30pt",
                            textIndent: "-18pt",
                            textAlign: "left",
                            fontSize: "11pt",
                          }}
                        >
                          Cookies that help us improve your experience.
                        </p>
                      </li>
                    </ul>
                  </p>
                </li>

                <li data-list-text={3}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Attendee Data: </strong>If you register for an event
                    through FitFunda, we may collect your registration details,
                    such as your name, email, and other relevant information.
                  </p>
                </li>
              </ol>
              <strong>How We Use Your Data</strong>
              <br />
              We use your data to:
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Provide and Improve Our Services:</strong> Deliver
                    the services you’ve requested and enhance our platforms.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Customize Your Experience:</strong> Tailor our
                    platforms to better suit your needs.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Communicate with You:</strong> Inform you about
                    updates, offers, and opportunities related to our services.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Marketing:</strong> Send newsletters and promotional
                    offers if you’ve agreed to receive them.
                  </p>
                </li>

                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Understand Our Audience:</strong> Analyze
                    information like your country, language, and interactions
                    with us to gain insights.
                  </p>
                </li>
              </ul>
              <br />
              <br />
              We won’t use your data for any other purposes without your
              permission.
              <br />
              <br />
              <strong>Legal Basis for Processing Your Data</strong>
              <br />
              We process your personal information based on:
              <ol id="l">
                <li data-list-text={1}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Contractual Need:</strong> To provide the services
                    you've requested or manage your account.
                  </p>
                </li>
                <li data-list-text={1}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Your Consent:</strong> For activities like sending
                    newsletters or sharing your data with third parties.
                  </p>
                </li>
                <li data-list-text={1}>
                  <p
                    style={{
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      lineHeight: "11pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Legal Compliance:</strong> In some cases, we may
                    need to keep your data for legal or tax purposes.
                  </p>
                </li>
              </ol>
              <br />
              <strong>How Long We Keep Your Data</strong>
              <br />
              We retain your data as long as necessary to provide our services
              and meet legal obligations. For customer data, we keep it for up
              to <strong>60 months</strong> after the end of the contract,
              unless you request us to delete it sooner.
              <br />
              <br />
              <strong>Who Controls Your Data</strong>
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Vrattanta:</strong> When we collect data directly
                    (like when you create an account), we control the data.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Customers (Event Organizers):</strong> If you attend
                    an event organized by one of our customers through FitFunda,
                    they control the data. We process it based on their
                    instructions.
                  </p>
                </li>
              </ul>
              <br />
              <strong>Sharing Your Data</strong>
              <br />
              We may share your data with third parties such as:
              <br />
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Authorized Employees:</strong> Employees who need
                    access to your data to perform their job duties.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Third-Party Service Providers:</strong> Companies
                    that help us run our platform or manage our business
                    operations.
                  </p>
                </li>
              </ul>
              <br />
              We ensure that any third party we work with follows strict privacy
              and security guidelines.
              <br />
              <br />
              <strong>Your Rights</strong>
              <br />
              You have control over your data. You can:
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Access Your Data:</strong> Request a copy of your
                    personal information.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Correct or Delete Your Data:</strong> Update or
                    remove your personal information.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Restrict How We Use Your Data:</strong> Limit the
                    ways we process your data.
                  </p>
                </li>

                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Withdraw Consent:</strong> Stop us from processing
                    your data based on your consent.
                  </p>
                </li>
              </ul>
              <br />
              To make a request, contact us at{" "}
              <strong>privacy-fitfunda@vrattanta.com</strong>. If you no longer
              want us to process your data, please refrain from accessing the
              Platform and using our Services. We may delete or anonymize your
              information upon withdrawal of consent, except where required
              otherwise by law.
              <br />
              <br />
              <br />
              <strong>How We Protect Your Information</strong>
              <br />
              We use advanced security measures like encryption to protect your
              data from unauthorized access. Our systems are regularly reviewed
              to ensure they remain secure. We also limit access to your data to
              authorized employees and third-party service providers who need it
              to perform their duties.
              <br />
              <br />
              <strong>Cookies and Tracking Technologies</strong>
              <br />
              We use cookies to:
              <br />
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Ensure Site Functionality:</strong> Essential
                    cookies enable you to log into secure areas of our site.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Remember Your Preferences:</strong> Functional
                    cookies remember your settings and preferences.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Analyze Site Usage:</strong> Performance cookies
                    help us understand how visitors use our site to improve
                    content and services.
                  </p>
                </li>
              </ul>
              You can opt-out of certain cookies if you prefer.
              <br />
              <br />
              <strong>Cross-Border Transfers</strong>
              <br />
              When we share your data with third parties outside your country,
              we ensure it’s protected with strict legal standards, such as the
              <strong>EU-U.S. Privacy Shield </strong>or{" "}
              <strong>Standard Contractual Clauses</strong>. This ensures your
              data remains secure even when transferred internationally.
              <br />
              <br />
              <strong>Additional Information</strong>
              <ul id="l1">
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Links to Other Websites:</strong> Our service may
                    include links to third-party websites. We recommend
                    reviewing their privacy policies as we are not responsible
                    for their practices.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Children's Privacy:</strong> Our services are not
                    intended for individuals under 18. We do not knowingly
                    collect personal information from children. If you become
                    aware that your child has provided us with personal
                    information, please contact us.
                  </p>
                </li>
                <li data-list-text="●">
                  <p
                    style={{
                      paddingTop: "1pt",
                      paddingLeft: "41pt",
                      textIndent: "-18pt",
                      textAlign: "left",
                      fontSize: "11pt",
                    }}
                  >
                    <strong>Data Breaches and Incidents:</strong> We have
                    procedures in place to promptly identify, assess, and
                    address any data breaches to maintain the confidentiality
                    and integrity of your personal data.
                  </p>
                </li>
              </ul>
              <br />
              <strong>Changes to This Privacy Policy</strong>
              <br />
              We may update this policy from time to time. Please review it
              periodically for any changes. Changes are effective when posted on
              this page, and the date will be updated accordingly.
              <br />
              <br />
              <strong>How to Contact Us</strong>
              <br />
              If you have any questions or requests about your personal data,
              please contact us at:
              <br />
              <br />
              <strong>Vrattanta Endurance and Sports</strong>
              <br />
              D2-502, Lunkad Goldcoast Viman Nagar, Pune - 411014,
              Maharashtra,India
              <br />
              <strong>Email:</strong> privacy-fitfunda@vrattanta.com
              <br />
              <br />
              <strong>Data Protection Officer</strong>
              <br />
              <strong>Name:</strong> Sanjib Paul
              <br />
              <strong>Email:</strong> sanjib@vrattanta.com
            </p>

            <a
              href="#"
              onClick={toggleShowMore}
              style={{
                color: "#1154CC",
                fontFamily: "Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: "normal",
                textDecoration: "underline",
                fontSize: "11pt",
              }}
            >
              View less...
            </a>
          </>
        )}

        {!showMore && (
          <p
            style={{
              fontFamily: "Arial, sans-serif",
              fontStyle: "normal",
              fontWeight: "normal",
              textDecoration: "none",
              fontSize: "8pt",
              textAlign: "left",
            }}
          >
            <a href="#" onClick={toggleShowMore} style={{ fontSize: "17px" }}>
              View more...
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
