import { Link, useParams } from "react-router-dom";
import { GetDraftEventData } from "../../services/eventAPI.jsx";
import { useEffect, useState } from "react";
import EventCardDraft from "../event/EventCardDraft.jsx";
import { useSelector } from "react-redux";
import Spinner from "../controls/spinner/Spinner.jsx";

const DraftEventCard = () => {
  const { eventCity, eventCityId } = useParams();
  const userData = useSelector((loginData) => loginData.login.login);
  // const { eventCityId } = useParams();
  //load data
  // const eventData = GetCityEventData(eventCityId);
  const [eventsInCity, setEventsInCity] = useState("");
  const [loading, setLoading] = useState(true); // loading spinner

  useEffect(() => {
    const getAllDraftEvent = async () => {
      try {
        const eventsByCityResponse = await GetDraftEventData(
          userData?.login?.token
        );

        if (eventsByCityResponse.success === true) {
          const eventResponse = eventsByCityResponse.data;
          setEventsInCity(eventResponse);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllDraftEvent();
  }, []);

  return (
    <>
     {loading ? (
        <Spinner/>
        ) : (
          <>
      <div className="container text-center mt-0">
        {/* <div class="row mx-2">
          <div class="col text-start cat-name">
            <b>Events</b> in {eventCity && eventCity.toUpperCase()}
          </div>
        </div> */}
        <div className="row align-items-start ml-1">
        {eventsInCity.length === 0 ? (
              <p>No Draft events found.</p>
            ) : (
          eventsInCity &&
            eventsInCity.map &&
            eventsInCity.map((event, index) => {
              return (
                <EventCardDraft
                  key={event.eventId}
                  event={event}
                  city={eventCity}
                />
              );
            })
            )}
        </div>
      </div>
      </>
        )}
    </>
  );
};

export default DraftEventCard;
