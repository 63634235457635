// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #7b1fa2;
}

.container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.carousel-inner img {
  width: 100%;
  /* height: 100%; */
  height: 350px !important;
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px;
}

#custCarousel .carousel-indicators > li {
  width: 100px;
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75;
}

.carousel-item img {
  width: 80%;
}
.tooltiptext p {
  font-size: xx-small;
}
`, "",{"version":3,"sources":["webpack://./src/components/event/EventCardLarge.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  background-color: #7b1fa2;\n}\n\n.container {\n  margin-top: 100px;\n  margin-bottom: 100px;\n}\n\n.carousel-inner img {\n  width: 100%;\n  /* height: 100%; */\n  height: 350px !important;\n}\n\n#custCarousel .carousel-indicators {\n  position: static;\n  margin-top: 20px;\n}\n\n#custCarousel .carousel-indicators > li {\n  width: 100px;\n}\n\n#custCarousel .carousel-indicators li img {\n  display: block;\n  opacity: 0.5;\n}\n\n#custCarousel .carousel-indicators li.active img {\n  opacity: 1;\n}\n\n#custCarousel .carousel-indicators li:hover img {\n  opacity: 0.75;\n}\n\n.carousel-item img {\n  width: 80%;\n}\n.tooltiptext p {\n  font-size: xx-small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
