import * as React from "react";
import { GetAllBanners } from "../../services/commonAPI";
import "../../css/_carousel.css";
import { Navigate } from "react-router-dom";

//banner path
let ImagePath = process.env.REACT_APP_BANNERS;
let BasePath = process.env.REACT_APP_FRONTEND_USER;
//main function
class ImageSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // images,
      bannerImageData: [],
      // fadedleft: true,
      // fadedright: false,
      // start: 0,
      // finish: 1,
      currentImageIndex: 0,
    };
    this.timer = null;
  }

  //functions/events/methods
  async componentDidMount() {
    const bannerResponse = await GetAllBanners();
    if (bannerResponse?.success) {
      this.setState({ bannerImageData: bannerResponse.data });
      // Start autoplay
      this.startAutoplay();
    }
    window.scrollTo(0, 0);
  }

  // leftClick() {
  //   let start = this.state.start;
  //   let finish = this.state.finish;
  //   if (start > 0 && finish > 0) {
  //     this.setState({
  //       start: start - 1,
  //       finish: finish - 1,
  //     });
  //   } else {
  //     this.setState({
  //       fadedleft: true,
  //     });
  //   }
  //   this.setState({
  //     fadedright: false,
  //   });
  // }

  // rightClick() {
  //   let start = this.state.start;
  //   let finish = this.state.finish;
  //   if (finish < this.state.bannerImageData.length) {
  //     this.setState({
  //       start: start + 1,
  //       finish: finish + 1,
  //     });
  //   } else {
  //     this.setState({
  //       fadedright: true,
  //     });
  //   }

  //   this.setState({
  //     fadedleft: false,
  //   });
  // }

  componentWillUnmount() {
    // Clear the timer when component unmounts
    clearInterval(this.timer);
  }

  startAutoplay() {
    // Set up timer to change images every 2 seconds
    this.timer = setInterval(() => {
      this.nextImage();
    }, 4000);
  }

  nextImage() {
    const { currentImageIndex, bannerImageData } = this.state;
    const nextIndex = (currentImageIndex + 1) % bannerImageData.length;
    this.setState({ currentImageIndex: nextIndex });
    // Restart autoplay from the first image if the last image is reached
    if (nextIndex === 0) {
      clearInterval(this.timer); // Clear the timer to reset autoplay
      this.startAutoplay(); // Restart autoplay
    }
  }

  // navigateToEventDetails(imageInfo) {
  //   TODO Need to update the code as per requirement.
  //   if (imageInfo) {
  //     window.open(BasePath + "eventdetails/", "_self");
  //   }
  // }

  navigateToEventDetails(eventReference) {
    if (eventReference) {  
      window.open(`${BasePath}eventdetails/${eventReference}`);
    }
  };  

  render() {
    const { bannerImageData, currentImageIndex } = this.state;
    // var startindex = this.state.start;
    // var finishindex = this.state.finish;
    // const fadedleft = this.state.fadedleft ? "disabled" : "";
    // const fadedright = this.state.fadedright ? "disabled" : "";

    //main return
    return (
      <>
        <div
          className="carousel slide justify-content-center p-2 home-banner"
          style={{ marginTop: "-10px" }}
        >
          <div className="carousel-inner">
            {bannerImageData.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentImageIndex ? "active" : ""
                } home-banner-img`}
              >
                <img
                  src={`${ImagePath}${image.bannerUrl}`}
                  className="d-block-center w-100"
                  style={{ height: "30rem" }}
                  alt={`Banner ${index}`}
                  onClick={() => this.navigateToEventDetails(image.eventReference)}
                />
              </div>
            ))}
          </div>
          <ol className="carousel-indicators">
            {bannerImageData.map((_, index) => (
              <li
                key={index}
                className={index === currentImageIndex ? "active" : ""}
                onClick={() => this.setState({ currentImageIndex: index })}
              />
            ))}
          </ol>
        </div>
      </>
    );
  }
}

export default ImageSlider;
