import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../../pages/register/Login";
import { createPortal } from "react-dom";

const PreviewPanel = ({ previewData }) => {
  const navigate = useNavigate();
  const [login, setLoginPopup] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");

  return (
    <>
      <div className="bg-white z-[1] flex flex-col items-center w-full py-11 max-md:mt-10">
        <div
          className="text-black text-lg font-medium whitespace-nowrap"
          onClick={() => setLoginPopup(true)}
        >
          Preview{" "}
          {previewData &&
            previewData.EventPhotos &&
            previewData.EventPhotos.files &&
            //previewData.EventPhotos.files.length > 0 &&
            previewData.EventPhotos.files.map((item) => {
              return (
                <div className="text-blue-900 text-xs font-medium whitespace-nowrap justify-center items-stretch bg-blue-200 aspect-[1.2307692307692308] px-1 py-px rounded-sm">
                  {item.name}
                </div>
              );
            })}
        </div>
        <div className="self-stretch flex flex-col items-stretch mt-6 mb-[894px] px-8 max-md:mb-10 max-md:px-5">
          <img
            loading="lazy"
            src={selectedImg}
            className="aspect-[1.94] object-contain object-center w-full fill-gray-100 stroke-[0.987px] stroke-gray-300 overflow-hidden"
          />
          <div className="flex items-stretch justify-between gap-3.5 mt-3">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c307e96c911d25424b59e46401290ea3e6e35f156f72978f23fdccc2d44c4cbb?"
              className="aspect-[1.83] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full"
            />
            {previewData &&
              previewData.EventPhotos &&
              previewData.EventPhotos.files &&
              //previewData.EventPhotos.files.length > 0 &&
              previewData.EventPhotos.files.map((item, index) => {
                return (
                  <>
                    <img
                      src={item.path}
                      onClick={() => setSelectedImg(item.path)}
                      class="aspect-[1.83] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full"
                    />
                  </>
                );
              })}
          </div>
          <div className="flex justify-between gap-5 mt-4 items-start">
            <div className="flex grow basis-[0%] flex-col items-stretch">
              <div className="text-gray-800 text-xs font-semibold">
                {previewData &&
                  previewData.EventDetails &&
                  previewData.EventDetails.eventName}
                <br />
              </div>
              <div className="flex items-stretch gap-1 mt-1.5 px-px self-start">
                {previewData &&
                  previewData.TicketDetails &&
                  //previewData.TicketDetails.length > 0 &&
                  previewData.TicketDetails.map((item) => {
                    return (
                      <div className="text-blue-900 text-xs font-medium whitespace-nowrap justify-center items-stretch bg-blue-200 aspect-[1.2307692307692308] px-1 py-px rounded-sm">
                        {item.ticketName}
                      </div>
                    );
                  })}
              </div>
              <div className="flex items-stretch gap-1.5 mt-6 self-start">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a79e9690ffa3daf40dc7d82c8d3579a7d8652617c181e3b921427a6e41e0c1ee?"
                  className="aspect-square object-contain object-center w-2.5 overflow-hidden shrink-0 max-w-full"
                />
                <div className="text-black text-xs font-medium grow whitespace-nowrap self-start">
                  #{" "}
                  {previewData &&
                    previewData.EventDetails &&
                    previewData.EventDetails.eventCategory}
                </div>
              </div>
              <div className="flex items-start justify-between gap-1 mt-2">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/56df517054207bd5bb6c5b33e7e40961c67c426798e8bcfaffdf9c08356281bb?"
                  className="aspect-square object-contain object-center w-[9px] overflow-hidden shrink-0 max-w-full self-start"
                />
                <div className="text-black text-xs font-medium my-auto">
                  {previewData && previewData.EventDetails.eventStartDate}
                </div>
                <div className="bg-black self-stretch w-1 shrink-0 h-2.5" />
                <div className="text-black text-xs font-medium">
                  {previewData && previewData.EventDetails.eventEndDate}
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/230b7348340bd687cfde0b8002a8c806122c0e8e813b595e02f20da48f646416?"
              className="aspect-square object-contain object-center w-[15px] overflow-hidden shrink-0 max-w-full"
            />
          </div>
          <div className="flex items-stretch justify-between gap-1.5 mt-2.5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/80fba6498c7d3a976a0f71ad98f3158236c03bbb2e4918ebf5b7a4f4cb447102?"
              className="aspect-square object-contain object-center w-[11px] overflow-hidden shrink-0 max-w-full"
            />
            <div className="text-black text-xs font-medium grow shrink basis-auto self-start">
              {previewData &&
                previewData.EventLocation.address +
                  " " +
                  previewData.EventLocation.area +
                  " " +
                  previewData.EventLocation.city +
                  " " +
                  previewData.EventLocation.state +
                  " " +
                  previewData.EventLocation.pincode}
            </div>
          </div>
          <div className="bg-zinc-200 shrink-0 h-px mt-5" />
          <div className="flex w-full items-stretch justify-between gap-5 mt-3 pr-2">
            <div className="flex items-stretch justify-between gap-1.5">
              <img
                loading="lazy"
                srcSet="..."
                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full rounded-[50%]"
              />
              <div className="text-black text-xs font-medium grow whitespace-nowrap self-start">
                About Event{" "}
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f40d049f7848c8357a4548ec6f6d34ae130804bc20b4e5df0190614757ba1e69?"
              className="aspect-[1.86] object-contain object-center w-[13px] stroke-[1.103px] stroke-neutral-500 overflow-hidden shrink-0 max-w-full self-start"
            />
          </div>
          <div
            className="text-black text-xs mt-5"
            dangerouslySetInnerHTML={{
              __html: previewData.EventDetails.eventDescription,
            }}
          />
          <div className="bg-neutral-200 shrink-0 h-px mt-4" />{" "}
          <div className="flex items-stretch justify-between gap-5 mt-2.5 pr-3">
            <div className="flex flex-col items-stretch">
              <div className="flex items-stretch justify-between gap-2">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full rounded-[50%]"
                />{" "}
                <div className="text-black text-xs font-medium self-center grow whitespace-nowrap my-auto">
                  Venue
                </div>
              </div>{" "}
              <div className="flex items-stretch justify-between gap-2 mt-5">
                <img
                  loading="lazy"
                  srcSet="..."
                  className="aspect-[1.06] object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full rounded-[50%]"
                />{" "}
                <div className="text-black text-xs font-medium self-center grow whitespace-nowrap my-auto">
                  Organizer
                </div>
              </div>
            </div>{" "}
            <div className="self-center flex flex-col items-center my-auto">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/60f9fe174930b30aa5e0b6daca437c89aacc82d3452797b284588b73458f3736?"
                className="aspect-[1.57] object-contain object-center w-[11px] stroke-[1.103px] stroke-neutral-500 overflow-hidden"
              />{" "}
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/25e1e7597d46640a1ba3506db34fecc42b9d9d7e22a94a1a4f1de7dfe461e00f?"
                className="aspect-[1.83] object-contain object-center w-[11px] stroke-[1.103px] stroke-neutral-500 overflow-hidden mt-8"
              />
            </div>
          </div>{" "}
          <div className="bg-neutral-200 shrink-0 h-px mt-2.5" />{" "}
          <div className="flex w-full items-stretch justify-between gap-5 mt-2.5 pr-3">
            <div className="flex items-stretch justify-between gap-2">
              <img
                loading="lazy"
                srcSet="..."
                className="aspect-square object-contain object-center w-[18px] overflow-hidden shrink-0 max-w-full rounded-[50%]"
              />{" "}
              <div className="text-black text-xs font-medium self-center my-auto">
                Contact
              </div>
            </div>{" "}
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/dceaf6aae83e70bce96f62f8ac2062c1026e377e9a4e7c983427e78dca548d17?"
              className="aspect-[1.57] object-contain object-center w-[11px] stroke-[1.103px] stroke-neutral-500 overflow-hidden self-center shrink-0 max-w-full my-auto"
            />
          </div>{" "}
          <div className="bg-neutral-200 shrink-0 h-px mt-2.5" />
        </div>

        {login &&
          createPortal(
            <Login onClose={() => setLoginPopup(false)} />,
            document.body
          )}
      </div>
    </>
  );
};

export default PreviewPanel;
