import React from "react";
import InputTextBox from "../../controls/input/InputTextBox";

const ViewAccountDetailsOrganizer = ({ organizerDetails }) => {
  return (
    <>
      <div className="card-body p-1">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <div
              id="participant-collapseOne"
              className="accordion-collapse accordion-detail px-4 collapse show"
              data-bs-parent="#accordionFlushExample"
            >
              <form className="row g-3">
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Email
                  </label>

                  <InputTextBox
                    id="eventName"
                    name="eventName"
                    value={organizerDetails.businessEmail}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Mobile
                  </label>

                  <InputTextBox
                    id="eventName"
                    name="eventName"
                    value={organizerDetails.businessMobile}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Address
                  </label>
                  <InputTextBox
                    id="businessAddress"
                    name="businessAddress"
                    value={organizerDetails.businessAddress}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business Country
                  </label>
                  <InputTextBox
                    id="businessCountry"
                    name="businessCountry"
                    value={organizerDetails.businessCountry}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business State
                  </label>
                  <InputTextBox
                    id="businessState"
                    name="businessState"
                    value={organizerDetails.businessState}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Business City
                  </label>
                  <InputTextBox
                    id="businessCity"
                    name="businessCity"
                    value={organizerDetails.businessCity}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Pin Code
                  </label>
                  <InputTextBox
                    id="businessPincode"
                    name="businessPincode"
                    value={organizerDetails.businessPincode}
                    disabled
                  />
                </div>

                <div className="col-md-6 mb-3">
                  {organizerDetails.businessWebsite.length > 0 ? (
                    <>
                      <label htmlFor="inputEmail4" className="form-label">
                        Web Site
                      </label>
                      <a
                        href={organizerDetails.businessWebsite}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {" "}
                        {organizerDetails.businessName}
                      </a>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAccountDetailsOrganizer;
