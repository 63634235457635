import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useParams } from "react-router";
import * as initialState from "../../services/initialState";
import { API_RESONSE_FAILED } from "../../utils/Messages";
import * as Call from "../../utils/validations";
import EventCard from "../../components/event/EventCard";
import SliderRecommededEvent from "../../components/slider/SliderEventRecommended";
import Promotion from "../../components/promotion/Promotion";
import { GetSearchEventData } from "../../services/eventAPI";
import { GetQueryString } from "../../utils/commonFun";
import { decryptData } from "../../utils/cryptoJs";

//
//let initialData = initialState.Search;
//main function
const SearchAll = () => {
  //variables
  let queryObj = GetQueryString();
  let searchText = queryObj?.text;
  let inputValue = decryptData(queryObj?.q);
  const [eventData, setEventData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetSearchEventData(searchText, inputValue);
      if (response.success === true) {
        setEventData(() => response.data);
      }
    };

    fetchData();
  }, [searchText]);

  //main return
  return (
    <>
      <div className="container text-center">
        <div class="row mx-2">
          <div class="col text-start cat-name">
            <b>Search result for :- </b> {inputValue?.searchText}
            <br />
            <h6>
              {inputValue?.stateId > 0 && <> -{inputValue?.stateName}</>}
              {inputValue?.cityId > 0 && <> -{inputValue?.cityName}</>}
              {inputValue?.categoryId > 0 && <> -{inputValue?.categoryName}</>}
              {inputValue?.maxPrice > 0 && (
                <>
                  {" "}
                  <br /> -Price = {inputValue?.minPrice} to{" "}
                  {inputValue?.maxPrice}
                </>
              )}
              {inputValue?.minDate != inputValue?.maxDate && (
                <>
                  {" "}
                  <br /> -Date = {inputValue?.minDate} to
                  {inputValue?.maxDate}
                </>
              )}
            </h6>
          </div>
        </div>
        <div className="row justify-content-center align-items-center mx-2 my-3">
          {eventData !== "" &&
            eventData?.map((event, index) => {
              return (
                <EventCard
                  key={event.eventId}
                  event={event}
                  city={event.categoryName}
                />
              );
            })}
          {eventData === "" ||
            (eventData?.length === 0 && (
              <>
                <div class="alert alert-warning" role="alert">
                  No data found
                </div>
                <SliderRecommededEvent from={"seachPage"} />
              </>
            ))}
        </div>

        <Promotion from={"EventCategory"} />
      </div>
    </>
  );
};

export default SearchAll;
