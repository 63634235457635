import React, { useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { ValidateOTP } from "../../services/notificationAPI";
import { addToLoginRequest } from "../../redux/actions/Login";
import Button from "../../components/controls/buttons/Button";
import * as Call from "../../utils/validations";

const initialData = {
  userId: "",
  otp: "",
  digit1: "",
  digit2: "",
  digit3: "",
  digit4: "",
  digit5: "",
  digit6: "",
  email: "",
  mobile: "",
  errors: {},
  IsValid: {},
};

//main Function
const OTPVerification = ({
  code_sent_to,
  onDismiss,
  showEmailView,
  resendOTP,
  email,
  mobileNumber,
  saveLogin,
  login,
}) => {
  //declare start
  let userData = useSelector((state) => state.login.login.login);
  let { user } = userData;
  const [inputValue, setInputValue] = useState(initialData);
  const { errors, IsValid } = inputValue;
  // const [otp, setOtp] = useState("");

  const [isOTPVerified, setIsOTPVerified] = useState(true);
  const [focusedInput, setFocusedInput] = useState(1);

  // Refs for input fields
  const inputRefs = {
    digit1: useRef(null),
    digit2: useRef(null),
    digit3: useRef(null),
    digit4: useRef(null),
    digit5: useRef(null),
    digit6: useRef(null),
  };
  const [loading, setLoading] = useState(false); // Spinner state

  //declare end

  //function/events/methods
  const handleChange = (e) => {
    const { name, value } = e.target;
    //validation
    if (isValid(name, value, e)) {
      //set value
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));

      // automatically focus on the next input field
      if (value.length === 1 && focusedInput < 6) {
        const nextInput = `digit${focusedInput + 1}`;
        inputRefs[nextInput].current.focus(); // Focus the next input field
        setFocusedInput(focusedInput + 1);
      }
    } else {
      //show error
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // function to handle backspace key to claer the input box
  const handleBackspace = (e) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (focusedInput === 1) return; // If the first input field, do nothing
      const currentInput = `digit${focusedInput}`;
      const prevInput = `digit${focusedInput - 1}`;

      // If the current input field is digit6 and it already has content, clear it first
      if (currentInput === "digit6" && inputValue.digit6 !== "") {
        setInputValue((prev) => ({
          ...prev,
          digit6: "",
        }));
        inputRefs[prevInput].current.focus(); // Focus the previous input field
        setFocusedInput(focusedInput - 1);
      } else if (inputValue[currentInput] === "") {
        // If the current input field is empty, move the focus to the previous field
        inputRefs[prevInput].current.focus(); // Focus the previous input field
        setFocusedInput(focusedInput - 1);
      } else {
        // If the current input field has content, clear it
        setInputValue((prev) => ({
          ...prev,
          [currentInput]: "",
        }));
      }
    }
  };

  const isValid = (name, value, e) => {
    let val = false;
    let msg = "";
    if (Call.IsEmpty(value)) {
      msg = "Please enter " + name;
    } else {
      switch (name) {
        case "digit1":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 1st digit.";
          }
          break;

        case "digit2":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 2nd digit.";
          }
          break;
        case "digit3":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 3rd digit.";
          }
          break;
        case "digit4":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 4th digit.";
          }
          break;
        case "digit5":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) msg = "enter 5th digit.";
          }
          break;
        case "digit6":
          val = Call.IsNumberOnlyInput(value);
          if (!val) msg = "Invalid " + value;
          else {
            val = Call.IsMaxLengthReached(e);
            if (!val) {
              msg = "enter 6th digit.";
            }
          }
          break;

        default:
          break;
      }
    }

    setInputValue((prev) => ({
      ...prev,
      errors: { ...errors, [name]: msg },
      IsValid: { ...IsValid, [name]: val },
    }));

    return val;
  };

  const verifyOTPService = async (e) => {
    e.preventDefault();

    if (
      IsValid.digit1 &&
      IsValid.digit2 &&
      IsValid.digit3 &&
      IsValid.digit4 &&
      IsValid.digit5 &&
      IsValid.digit6
    ) {
      const otpValue =
        inputValue.digit1 +
        inputValue.digit2 +
        inputValue.digit3 +
        inputValue.digit4 +
        inputValue.digit5 +
        inputValue.digit6;

      inputValue.userId = userData.user.userId;
      inputValue.otp = otpValue;
      inputValue.email = email;
      inputValue.mobile = "91" + mobileNumber;

      setLoading(true);
      const result = await ValidateOTP(inputValue, userData.token);

      if (result?.success === true) {
        setLoading(false);
        if (mobileNumber !== undefined || mobileNumber !== null) {
          login = {
            ...userData,
            user: { ...user, mobile: mobileNumber },
            update: true,
          };
        } else if (email !== undefined || email !== null) {
          login = {
            ...userData,
            user: { ...user, email: email },
            update: true,
          };
        }
        await saveLogin(login);
        onDismiss();
        alert("Mobile no verified!");
      } else {
        setIsOTPVerified(false);
        setLoading(false);
      }
    } else {
      alert("Please enter valid OTP.");
      setLoading(false);
    }
  };

  const updateEmailOrMobileDetails = () => {
    showEmailView();
  };

  const resentOTPService = async () => {
    resendOTP();
  };

  //main return
  return (
    <div id="parent-div" class="bg-transperent">
      <div
        class="modal fade show"
        id="exampleModa2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        role="dialog"
        style={{ display: "block", paddingLeft: "0px" }}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content border-0">
            <div class="m-4">
              <div class="otp-form text-center">
                <h1 class="text-center">Enter verification code</h1>
                <p>Enter 6 digit code sent to you at {code_sent_to}</p>
                <form
                  class="row g-2 my-2 justify-content-center align-items-center"
                  onSubmit={verifyOTPService}
                >
                  <div class="mb-4 w-100">
                    <div class="d-flex justify-content-between align-items-center mx-4">
                      {Object.keys(inputRefs).map((inputName, index) => (
                        <div key={index} className="p-2">
                          <input
                            ref={inputRefs[inputName]}
                            className="form-control-01 rounded-2 border"
                            id={inputName}
                            type="text"
                            name={inputName}
                            value={inputValue[inputName]}
                            onChange={handleChange}
                            onKeyDown={handleBackspace}
                            maxLength={1}
                            autoFocus={focusedInput === index + 1} // focus the input filed based on the state
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {isOTPVerified ? (
                    <div></div>
                  ) : (
                    <div class="otp-form text-center">
                      <div class="m-1">
                        <h1>Please enter valid code.</h1>
                      </div>
                    </div>
                  )}
                   {loading ? (
                  <span
                    className="loaderBreakup"                   
                  ></span>
                ) : (
                  <div class="col-12 mt-4">
                    <Button type="submit" onClick={verifyOTPService}>
                      VERIFY
                    </Button>
                  </div>
                )}
                </form>

                <div class="">
                  <div class="m-1">Dont receive the OTP? </div>{" "}
                  <div class="send-otp">
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={resentOTPService}
                    >
                      RESEND OTP
                    </button>
                    |
                    <button
                      type="button"
                      class="btn btn-link"
                      onClick={updateEmailOrMobileDetails}
                    >
                      CHANGE MOBILE NUMBER
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  login: state.login.login.login,
});
const mapDispatchToProps = (dispatch) => ({
  saveLogin: (login) => dispatch(addToLoginRequest(login)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OTPVerification);
