import { React } from "react";
import Tabs from "./Tabs";
import OrganizerProfileDetails from "../../profile/create/OrganizerProfileDetails";
import OrganizerKYCDetails from "../../profile/create/OrganizerKYCDetails";
import OrganizerBankDetails from "../../profile/create/OrganizerBankDetails";

const OrganizerProfileTabs = ({
  organizerDetails,
  onProfileCreated,
  updatedOrganizerDetails,
  organizerDisplayMessage,
}) => {
  const handleStepComplete = (index) => {
    if (index > 0 && index < 3) {
      document.getElementById("tabCreateOrganizerProfileStep" + index).click();
    } else if (index === 3) {
      onProfileCreated(true);
    }
  };

  let stepCompleted = 0;
  if (organizerDetails?.orgStatus === "inserting") {
    // stepCompleted = 1;
    stepCompleted = parseInt(
      organizerDetails?.stepCompleted.replace(/\D/g, ""),
      10
    );
  } else if (organizerDetails?.orgStatus === "inserted") {
    stepCompleted = 3;
  }

  const config = {
    stepCompleted: stepCompleted,
    tabId: "tabCreateOrganizerProfileStep",
    data: [
      {
        title: "Basic Profile",
        showStep: true,
        //isStepCompleted:true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <OrganizerProfileDetails
            organizerDetails={organizerDetails}
            onStepComplete={(index) => handleStepComplete(index)}
            onChange={(value) => updatedOrganizerDetails(value)}
          />
        ),
      },
      {
        title: "Business Profile",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <OrganizerKYCDetails
            organizerDetails={organizerDetails}
            onStepComplete={(index) => handleStepComplete(index)}
            onChange={(value) => updatedOrganizerDetails(value)}
          />
        ),
      },
      {
        title: "Bank Details",
        showStep: true,
        tabBtnClass: "col p-0 m-0",
        tabContentClass: "",
        tabContent: (
          <OrganizerBankDetails
            organizerDetails={organizerDetails}
            onStepComplete={(index) => handleStepComplete(index)}
            onChange={(value) => updatedOrganizerDetails(value)}
            organizerDisplayMessage={(value) => organizerDisplayMessage(value)}
          />
        ),
      },
    ],
    className: {
      mainContainer: "",
      tabsContainer: "row align-items-start my-4 event-proce pl-3",
      activeTabContainer: "org-main-contain ", //"tab-content card p-4",
    },
  };

  return (
    <>
      <Tabs config={config} />
    </>
  );
};

export default OrganizerProfileTabs;
