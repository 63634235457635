import React, { useState } from "react";
import InputTextBox from "../../controls/input/InputTextBox";
import Modal from "../../controls/popup/Modal";
import TicketDetails from "./TicketDetails";
import AccordionCard from "../../controls/accordion/AccordionCard";
import { convertDateTimeToISTForUI } from "../../../utils/DateTimeUtility";
import { GetFileExtension } from "../../../utils/commonFun";

//main function
const EventBasicDetails = ({ event }) => {
  // for showing Img in modal
  let ImagePath = process.env.REACT_APP_EVENTS;

  //declare start
  const [showModal, setShowModal] = useState(false);
  const [docPath, setDocPath] = useState(null);
  const [title, setTitle] = useState("");
  //declare end

  //function/event/methods
  // const renderDoc = (type, title, imagePath) => {
  //   if (imagePath) {
  //     setTitle(title);
  //     setDocPath(ImagePath + event.eventId + "/" + type + "/" + imagePath);
  //     setShowModal(true);
  //   }
  // };
  const renderDoc = (type, title, imagePath) => {
    if (imagePath) {
      const fileExtension = GetFileExtension(imagePath);
      const fullPath =
        ImagePath + event.eventId + "/" + type + "/" + imagePath;
      if (
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "jpg" ||
        fileExtension === "gif"
      ) {
        setTitle(title);
        setDocPath(fullPath);
        setShowModal(true);
      } else {
        window.open(fullPath, "_blank");
      }
    }
  };


  //accordion
  const [activeTabNo, setActiveTabNo] = useState(0);

  const handleSave = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };
  const handleSkip = (tabNo) => {
    setActiveTabNo(() => tabNo + 1);
  };

  const config = {
    activeTabNo: activeTabNo,
    data: [
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1> Event Details</h1>
          </div>
        ),

        content: <EventDetails event={event} renderDoc={renderDoc} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail">
            <h1>Ticket Details </h1>
          </div>
        ),

        content: (
          <div className="card mb-2">
            <TicketDetails event={event} />
          </div>
        ),
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail ">
            <h1> FAQs</h1>
          </div>
        ),

        content: <Faq event={event} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail ">
            <h1> Event Location</h1>
          </div>
        ),

        content: <EventLocation event={event} />,
      },
      {
        title: (
          <div className="d-flex justify-content-center align-items-center accordion-detail ">
            <h1> Event Photos</h1>
          </div>
        ),

        content: <EventPhoto event={event} renderDoc={renderDoc} />,
      },
    ],
  };

  // main return
  return (
    <>
      <div className="card-body p-1 text-start ">
        <div
          className="accordion accordion-flush m-0"
          id="accordionFlushExample"
        >
          <div className="accordion-item">           
            <div
              id="participant-collapseOne"
              className="accordion-collapse accordion-detail px-4 collapse show "
              data-bs-parent="#accordionFlushExample"
            >
              <AccordionCard config={config} />
            </div>
          </div>
        </div>
        {showModal && (
          <Modal onClose={() => setShowModal(false)} title={title}>
            <div>
              <img src={docPath} className="d-block w-100"></img>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

const renderStage = (stage) => {  
  return stage === "ongoing" ? "Ongoing" :stage === "past" ? "Past" : "";
};

const EventDetails = ({ event, renderDoc }) => {
  return (
    <>
      <form className="row g-3">
        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Category
          </label>

          <InputTextBox
            id="categoryName"
            name="categoryName"
            value={event.categoryName || "-"}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event Id
          </label>

          <InputTextBox
            id="eventId"
            name="eventId"
            value={event.eventId}
            disabled
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event Start Date
          </label>
          <InputTextBox
            id="eventStartDate"
            name="eventStartDate"
            value={convertDateTimeToISTForUI(event.eventStartDate)}
            disabled
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event End Date
          </label>
          <InputTextBox
            id="eventEndDate"
            name="eventEndDate"
            value={convertDateTimeToISTForUI(event.eventEndDate)}
            disabled
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event Description
          </label>
          <InputTextBox
            id="eventDescription"
            name="eventDescription"
            value={stripHtmlTags(event.eventDescription)}
            disabled
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event Permission
          </label>
          <div className="input-group">
            <span
              className="input-group-text bg-white border-end-0"
              id="basic-addon1"
            >
              <img
                src="../../../images/attachment-icon.svg"
                alt=""
                onClick={() =>
                  renderDoc(
                    "permission",
                    "Upload Event Permission",
                    event.permission 
                  )
                }
              />
            </span>
            <input
              type="text"
              className="form-control border-start-0"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={event.permission || "Not available"}
              disabled
            />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Terms & Condition
          </label>
          <InputTextBox id="terms" name="terms"   value={stripHtmlTags(event.terms) || "--"} disabled />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="inputEmail4" className="form-label">
            Event Stage
          </label>
          <InputTextBox
            id="eventStage"
            name="eventStage"
            value={renderStage(event.eventStage)}
            disabled
          />
        </div>
      </form>
    </>
  );
};

const Faq = ({ event }) => {
  const faqsAvailable = event?.faqs && event?.faqs?.length > 0;

  return (
    <>
      <div className="accordion accordion-flush m-0 card mb-2" id="accordionTimingCertificate">
        <div className="accordion-item">
          <div
            id="timingCertificateCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="timingCertificateHeader"
            data-bs-parent="#accordionTimingCertificate"
          >
            <div className="accordion-body">
              {faqsAvailable ? (
                <form className="row g-3 pb-3">
                  {event?.faqs?.map((faq) => {
                    const isEmpty = !faq.question || !faq.answer;
                    return (
                      <div key={faq.faqId} className="col-md-6 mb-3">
                        {isEmpty ? (
                          <p>FAQ data is not available for {event?.eventName}!</p>
                        ) : (
                          <>
                            <label htmlFor={`faq-${faq.faqId}`} className="form-label">
                              {faq.question}
                            </label>
                            <InputTextBox
                              id={`faq-${faq.faqId}`}
                              name={`faq-${faq.faqId}`}
                              value={faq.answer}
                              disabled
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </form>
              ) : (
                <p>FAQs are not available for {event?.eventName}!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const EventLocation = ({ event }) => {
  return (
    <>
      <div
        className="accordion accordion-flush m-0 card mb-2"
        id="accordionTimingCertificate"
      >
        <div className="accordion-item">
          <div
            id="timingCertificateCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="timingCertificateHeader"
            data-bs-parent="#accordionTimingCertificate"
          >
            <div className="accordion-body">
              <form className="row g-3">                
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Event Address
                  </label>
                  <InputTextBox
                    id="address"
                    name="address"
                    value={event.address}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Area
                  </label>
                  <InputTextBox
                    id="area"
                    name="area"
                    value={event.area}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    State
                  </label>
                  <InputTextBox
                    id="stateName"
                    name="stateName"
                    value={event.stateName}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    City
                  </label>
                  <InputTextBox
                    id="cityName"
                    name="cityName"
                    value={event.cityName || "Not Available"}
                    disabled
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="inputEmail4" className="form-label">
                    Pincode
                  </label>
                  <InputTextBox
                    id="pincode"
                    name="pincode"
                    value={event.pincode}
                    disabled
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const EventPhoto = ({ event, renderDoc }) => {
  return (
    <>
      <div
        className="accordion accordion-flush m-0 card mb-2"
        id="accordionTimingCertificate"
      >
        <div className="accordion-item">       
          <div
            id="timingCertificateCollapse"
            className="accordion-collapse collapse show"
            aria-labelledby="timingCertificateHeader"
            data-bs-parent="#accordionTimingCertificate"
          >
            <div className="accordion-body">
              {event &&
                event?.eventPhotos?.map((photo) => (
                  <div key={photo.docId}>
                    <form className="row g-3">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="inputEmail4" className="form-label">
                          Event Cover Image
                        </label>
                        <div className="input-group">
                          <span
                            className="input-group-text bg-white border-end-0"
                            id="basic-addon1"
                          >
                            <img
                              src="../../../images/attachment-icon.svg"
                              alt=""
                              onClick={() =>
                                renderDoc(
                                  "eventPhoto",
                                  " Event Cover Image",
                                  photo.docName
                                )
                              }
                            />
                          </span>
                          <InputTextBox
                            type="text"
                            className="form-control border-start-0"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={photo.docName}
                            disabled
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EventBasicDetails;
