import * as React from "react";
import SliderCityEvent from "../slider/SliderEventCity";
import { GetTopCities } from "../../services/commonAPI";
import { useEffect, useState } from "react";

const EventsInCity = ({ city }) => {
  const [cityData, setCityData] = useState("");

  useEffect(() => {
    const getAllCity = async () => {
      try {
        const cityResponseData = await GetTopCities();
        if (cityResponseData?.success === true) {
          setCityData(cityResponseData?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllCity();
  }, [cityData == ""]);

  return (
    <>
      {cityData &&
        cityData.map((city, index) => {
          return (
            <div key={city} className="mdnSlCityEvents">
              <SliderCityEvent city={[city.cityId, city.cityName]} />

              {index > 0 && index % 2 === 0 ? (
                <div className="row mx-2 my-5">
                  {/* <img className="img-fluid" src="/images/promotion/adv1.png" /> */}
                </div>
              ) : null}
            </div>
          );
        })}
    </>
  );
};

export default EventsInCity;
