import React, { useEffect, useState } from "react";
import EventCardLarge from "../../components/event/EventCardLarge";
import { GetEventDetailsData } from "../../services/eventAPI";
import { useParams } from "react-router";
import { IsValidEventId } from "../../utils/validations";
import { PublishEventById } from "../../services/createEvent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KEY_EVENT_ID, LOCAL_STORAGE_USER } from "../../utils/Constants";
import Spinner from "../../components/controls/spinner/Spinner";

//
const PublishEvent = () => {
  const [eventDetailsData, setEventDetailsData] = useState(null);
  const [loading, setLoading] = useState(false); //spinner

  const { eventId, eventName } = useParams();
  const navigate = useNavigate();

  const userData = useSelector((loginData) => loginData.login.login);
  let isAdmin = false;
  if (userData?.login?.referenceId?.length > 4) {
    isAdmin = true;
    if (document.getElementById("mdnHeader"))
      document.getElementById("mdnHeader").style.display = "none";
  }
  const getEventDetails = async () => {
    try {
      setLoading(true);
      const eventDetailsResponseData = await GetEventDetailsData(eventId);
      setLoading(false);
      if (eventDetailsResponseData.success) {
        setEventDetailsData(eventDetailsResponseData.data);
      } else {
        alert(eventDetailsResponseData.message);
      }
    } catch (error) {
      console.log(error);
    }
    //if (isAdmin === true) commom.clearLocalData();
  };

  useEffect(() => {
    if (IsValidEventId(eventId)) {
      getEventDetails();
    } else {
      console.log("Invalid eventid");
    }
  }, [eventId]);

  const publishEvent = async () => {
    try {
      setLoading(true);
      const publishEvent = await PublishEventById(
        userData.login.user.userId,
        eventId,
        userData.login.token
      );

      setLoading(false);
      if (publishEvent.success) {
        localStorage.removeItem(KEY_EVENT_ID);
        navigate("/publish-event-success/" + eventId + "/" + eventName);
        if (isAdmin === true) {
          localStorage.removeItem(LOCAL_STORAGE_USER);
        }
      } else {
        alert(publishEvent.error);
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const navigateToEditEvent = () => {
    if (isAdmin === true)
      navigate("/create-event-ad/" + eventDetailsData?.eventId);
    else navigate("/create-event");
  };

  return (
    <div>
      {isAdmin === true && (
        <>
          {/*  <div class="col text-start cat-name"> Event created by admin</div> */}
          {document.getElementById("mdnHeader") &&
            document.getElementById("mdnHeader").style.display.none}
        </>
      )}
      {eventDetailsData &&
      (eventDetailsData.eventStatus.toUpperCase() ===
        "Inserted".toUpperCase() ||
        eventDetailsData.eventStatus.toUpperCase() === "act".toUpperCase()) ? (
        <>
          <div class="row mx-2">
            <div
              class="col text-start cat-name"
              style={{ marginLeft: "-14px" }}
            >
              <b>Publish Event</b> {eventDetailsData.eventName}
            </div>
            <div class="row card-body detail-price-card">
              {isAdmin === false && (
                <div
                  class="col text-start cat-name1"
                  onClick={navigateToEditEvent}
                  style={{ marginLeft: "-5px" }}
                >
                  <a class="btn btn-dark w-100 book-btn mt-2">Edit</a>
                </div>
              )}
              {eventDetailsData.eventStatus === "act" &&
              eventDetailsData.eventStage === "ongoing" ? (
                <div
                  class="col text-start cat-name1"
                  style={{ marginRight: "-25px" }}
                >
                  <a
                    class="btn btn-dark w-100 book-btn mt-2"
                    onClick={publishEvent}
                  >
                    Publish Event with Updates
                  </a>
                </div>
              ) : (
                <div
                  class="col text-start cat-name1"
                  style={{ marginRight: "-25px" }}
                >
                  <a
                    class="btn btn-dark w-100 book-btn mt-2"
                    onClick={publishEvent}
                  >
                    Publish Now
                  </a>
                </div>
              )}
            </div>
          </div>
          <EventCardLarge
            eventDetails={eventDetailsData}
            cameFrom="PublishPage"
          />
        </>
      ) : (
        <div></div>
      )}

      {loading && <Spinner />}
    </div>
  );
};

export default PublishEvent;
